import React, { Component } from "react";
import { connect } from "react-redux";
import Popup from "reactjs-popup";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

 import {

api_addNewLab
 } from "./../../../../DS/DS.VisitsNotes";
import Showhelp from './../../../showHelp';
// import cicon from "./../../../assets/img/cicon.png";
class addLabPopup extends Component {
  state = {
    newLabName: "",
    newLabAddress: "",
    newLabEmail: "",
    newLabFax: "",
    newLabContactNo: "",
    
  };
  handleFieldChange = (key, value) => {
      this.setState({[key]: value})
  }

  addNewLab = () => {
      this.props.addNewLab(this.state).then(() => {
        alert("New Lab Record Added");
        this.props.close();
      });
  }
  render() {
    return (
      <div class="invesvalue">
         <Popup open={this.props.open} modal closeOnDocumentClick={false}>
         <div style={{ width:'1000px', marginLeft:'0px', backgroundColor:'#FFF', float:'left'}}> 
         <div className="modal-header" style={{width: '100%', backgroundColor: '', padding: '5px 15px'}}>
          <div className="popup-title">
            <span> Add Lab <Showhelp gotohelp="add_lab_values"/></span> 
            {/*  */}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => this.props.close()}>
                ×
              </span>
            </button>
            </div>
            </div>

            <div className="modal-body addinvestigation ">
            <div
          className="col-sm-12"
       
          style={{ paddingLeft: 0, overflow: "auto" }}
          id="Graphdetails"
        >
          {/* <h5
            style={{
              backgroundColor: "#f4f4f4",
              padding: "5px 10px",
              marginBottom: 10
            }}
          >
             
          </h5> */}
          <div    id="ivntableadd" className="form-group row">
       
            {/* <label className="col-sm-3 col-form-label text-left">
              Family Name:
            </label>
            <div className="col-sm-3">
              <input type="text" className="form-control form-control-sm" />
            </div> */}
          </div>

          <div className="form-group row">
          <div className="col-sm-3"><label className="col-form-label text-left">Lab Name:</label> </div>
          <div className="col-sm-6">
          <input type="text" class="form-control form-control-sm" value={this.state.newLabName} onChange={e => this.handleFieldChange("newLabName", e.target.value)} /> <br />
          </div>
          </div>
          <div className="form-group row">
          <div className="col-sm-3">
          <label className="col-form-label text-left">Address</label></div>

          <div className="col-sm-6"><input type="text" class="form-control form-control-sm" value={this.state.newLabAddress} onChange={e => this.handleFieldChange("newLabAddress", e.target.value)} /> <br />
          </div>
          </div>
          <div className="form-group row">
          <div className="col-sm-3">
          <label className="col-form-label text-left">Email</label></div>
           <div className="col-sm-6"><input type="text" class="form-control form-control-sm" value={this.state.newLabEmail} onChange={e => this.handleFieldChange("newLabEmail", e.target.value)} /> <br />
           </div>
           </div>
          <div className="form-group row">
          <div className="col-sm-3">
          <label className="col-form-label text-left">Contact No:</label> </div>
            <div className="col-sm-6"><input type="text" class="form-control form-control-sm" value={this.state.newLabContactNo} onChange={e => this.handleFieldChange("newLabContactNo", e.target.value)} /> <br />
            </div>
            </div>
          <div className="form-group row">
          <div className="col-sm-3">
          <label className="col-form-label text-left">Fax:</label> </div>
            <div className="col-sm-6"><input type="text" class="form-control form-control-sm" value={this.state.newLabFax} onChange={e => this.handleFieldChange("newLabFax", e.target.value)} /> <br />
            </div>
            </div>
        

               
            </div>
            <div class="modal-footer">
              <button
                type="button"
                className="btn btn-primary btn-xs"
                onClick={(e) => {
                  this.addNewLab(e);
                }}
              >
                Save
              </button>

              <button
                type="button"
                className="btn btn-primary btn-xs"
                data-dismiss="modal"
                onClick={() => this.props.close()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Popup>
        
      </div>
    );
  }
}

const mapStateToProps = state => {

    let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
    let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
   //console.log(state.getDrAppointments.logged_info.content.details[0])
  return {
    redux_patientId,redux_docId
  };
};
const mapDispatchToProps = (dispatch) => {
	return {
    
        addNewLab : data => dispatch(api_addNewLab(data))
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(addLabPopup);
