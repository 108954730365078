
import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment';
import {api_add_new_Pregnancy,api_get_pregnancy_no,api_update_pregnancy,api_get_baby_data} from '../../../DS/DS.ObstetricHistory'
import { api_getDoctors } from '../../../DS/DS.Appointments';
import AddBabyDetails from './addBabyDetails'
import Showhelp from './../../showHelp';

class BabyDetails extends Component
{
    constructor(props)
    {
        super(props)
        this.state=
        {
            open:this.props.showmodal,
            babyData:[],
            addmore:false,
            key:0,
            showEdit:false,
            selectedindex:-1,
            showSelectedContent:[],
            feeding:1
        }
    }
    componentWillMount=()=>
    {
        this.props.getBabyData({id:this.props.prevpregdetailsprev.pregnancy_id}).then(res=>{
            this.setState({babyData:res.data.content})})
    }

    newBabyDetails=(data)=>{
        this.setState({addmore:false})
        if(data!='')
        {
            this.state.babyData.push(data)
            console.log(this.state.babyData)
        }    
     }
     editAddress=(data,index)=>
     {
         this.setState({showEdit:false})
         this.state.babyData[index]=data;
         this.state.selectedindex=-1;
         this.state.showSelectedContent=[];
     }
     deleteAddress=()=>{
         console.log("***",this.state.selectedindex)
         //this.state.addressData.pop(this.state.selectedindex)
         this.state.babyData.splice(this.state.selectedindex, 1);
         this.setState({key:Math.random()})
     }
     showSelectedContent(e, item, i) {
        this.setState({ showSelectedContent: item,selectedindex: i });
       
      }
      setTrClass = (id) => {
        let classs = "";
        classs +=
          id == this.state.selectedindex
            ? " selectedBg "
            : "";
        return classs;
      };

      saveDate=()=>
      {
          const allData={
                number:this.props.prevpregdetailsprev.pregno,
                edcbydate:this.props.prevpregdetailsprev.edcbylmp,
                actuallmp:this.props.prevpregdetailsprev.actuallmp,
                ivf:this.props.prevpregdetailsprev.ivf,
                finaldate:this.props.prevpregdetailsprev.finishdate,
                scanday:this.props.prevpregdetailsprev.scandays,
                finaldate:this.props.prevpregdetailsprev.finishdate,
                scanday:this.props.prevpregdetailsprev.scandays,
                weeks:this.props.prevpregdetailsprev.scanweeks,
                gsl:this.props.prevpregdetailsprev.weeks,
                usedscan:this.props.prevpregdetailsprev.usedscan, 
                outcome:this.props.prevpregdetailsprev.outcome,
                dbts:this.props.prevpregdetailsprev.gestation,
                hypertension:this.props.prevpregdetailsprev.hyper,
                ecla:this.props.prevpregdetailsprev.eclam,
                renaldisease:this.props.prevpregdetailsprev.renal,
                edcbyscan:this.props.prevpregdetailsprev.edcbyscan,
                scandate:this.props.prevpregdetailsprev.scandate,
                s1:this.props.followup.stage1,
                s2:this.props.followup.stage2,
                s3:this.props.followup.stage3,
                delivery:this.props.followup.selectedDelivery,
                onset:this.props.followup.selectedonset,
                augmentation:this.props.followup.selectedaug,
                analg:this.props.followup.selectedana,
                peri:this.props.followup.selectedperineum,
                plac:this.props.followup.selectedplacenta,
                bloodloss:this.props.followup.selectedloss,
                antid:this.props.followup.antid,
                pregnote:this.props.followup.outdesc,
                pregnancy_id:this.props.prevpregdetailsprev.pregnancy_id, 
                babydetails:this.state.babyData
          }
          this.props.updatePregnancyData(allData).then(res=>
            {
                NotificationManager.success(
                    'Details save successfully',
                    this.props.closeAll()
                    )
            })
      }
    render(){
        return(
            <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
            {close => (
                <div style={{ width:'730px', margin:'0px auto'}} >
                <div style={{ width:'730px', marginLeft:'60px', backgroundColor:'#FFF', float:'left'}}> 
                    <div className="modal-header" style={{width:'auto', backgroundColor:'#f4f4f4', padding: '0px 15px',color:'black'}}>
                    <h6 className="popup-title">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.changeKey()} style={{ position: "inherit", right: "10px", fontSize: '25px' }}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <span>Pregnancy outcome <Showhelp gotohelp="pregnancy_outcome_help"/></span>
                        </h6>
                    </div>
                    <div className="modal-body" style={{ fontSize: "14px" }}>
                        <div className="col-sm-12" style={{marginBottom: 10, textAlign: 'left', width: '100%'}}>
                            <div className="form-group row mb-3 mt-3">
                                <div className="col-sm-10">
                                    <table style={{width: '100%'}}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div style={{width: '100%', height: 60}}>
                                                        <table className="table-bordered" style={{float: 'left', width: '100%'}}>
                                                            <thead>
                                                                <tr style={{backgroundColor: 'rgb(244, 244, 244)'}}>
                                                                    <th>Name</th>
                                                                    <th>Sex</th>
                                                                    <th>Birth weight</th>
                                                                    <th>Apgar 1</th>
                                                                    <th>Apgar 5</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody style={{overflowY: 'auto'}}>
                                                            {this.state.babyData != undefined && this.state.babyData.length> 0 && this.state.babyData.map((address,i) =>
                                                            <tr onClick={e =>
                                                                this.showSelectedContent(e, address, i) } className={this.setTrClass(i)}>
                                                                <td>{address.babyname}</td>
                                                                <td>{address.gender}</td>
                                                                <td>{address.birth_weight}</td>
                                                                <td>{address.apgar1}</td>
                                                                <td>{address.apgar5}</td>
                                                            </tr>)}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-sm-2">
                                    <button type="button"  onClick={() => this.setState({addmore:true})} className="btn btn-primary btn-xs" style={{width:'70px', marginBottom:'5px'}} data-dismiss="modal">Add</button>
                                    <button type="button"  className="btn btn-primary btn-xs" data-dismiss="modal" style={{width:'70px', marginBottom:'5px'}} onClick={() => this.setState({showEdit:true})}>Edit</button>
                                    <button type="button"  className="btn btn-primary btn-xs" data-dismiss="modal" style={{width:'70px'}}>Delete</button>
                                </div>     
                            </div>
                            <div className="form-group row">
                                <div className="col-sm-1"><label className="col-form-label text-left">Feeding:</label></div>
                                <div className="col-sm-2"><label>&nbsp;&nbsp;&nbsp;<input type="radio" name="search_condition" checked={this.state.feeding==1?'checked':''} value="1" onChange={(e)=>this.setState({feeding:e.target.value})}/> Breast</label></div>
                                <div className="col-sm-2"><label>&nbsp;&nbsp;&nbsp;<input type="radio" name="search_condition" value="2" checked={this.state.feeding==2?'checked':''} onChange={(e)=>this.setState({feeding:e.target.value})}/> Bolttle</label></div>
                            </div>
                        </div>
                        {this.state.addmore ? <AddBabyDetails showmodal={this.state.addmore} changeKey={this.newBabyDetails} add='add'/> :'' }
                        {(this.state.showEdit) ? <AddBabyDetails showmodal={this.state.showEdit} changeKey={this.editAddress} add='' sendData={this.state.showSelectedContent} sendId={this.state.selectedindex}/> : '' }
                                 
                    </div>
                    <div className="modal-footer">
                        <button type="button"  onClick={() => this.props.changeKey()} className="btn btn-primary btn-xs m-0" data-dismiss="modal">Back</button>
                        <button type="button"  onClick={() => this.saveDate()} className="btn btn-primary btn-xs m-0 ml-2" data-dismiss="modal">Finish</button>
                        <button type="button"  onClick={() => this.props.changeKey()} className="btn btn-primary btn-xs m-0 ml-2" data-dismiss="modal">Cancel</button>
                    </div>   
                </div>
                </div>        
            )}     
        </Popup>
        )}
    } 
    
    const mapDispatchToProps = dispatch => {
        return {
            updatePregnancyData: (data) => dispatch(api_update_pregnancy(data)),
            getBabyData:(data)=>dispatch(api_get_baby_data(data))
               };
        };
    const mapStateToProps = state => {
        let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
        let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
     
      return {
        redux_patientId,redux_docId
      };
     };
     
     export default connect(
     mapStateToProps,mapDispatchToProps
     )(BabyDetails) 
