import React, { Component } from "react";
import Addenhanced from "./addenhanced";
import Createasthma from "./create-asthma";
import Creatediabetes from "./create-diabetes";
import Recordnote from "./recordnotes";

import cicon from "./../../../assets/img/logo-white.png";
import CommonGrid from "./../../crudcomponentV2/combinedcrud";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import moment from "moment";
import Popup from "reactjs-popup";
import "./epc.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {api_selectepc , apiDeleteEPC,api_get_diabetic}  from "./../../../DS/DS.EPC";
import Carer from '../familySocialHistory/carer'
import DimentiaCare from './addDimentia'
import Showhelp from './../../showHelp';

class EnhancedPrimaryCare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCoverLetter: false,
      letter: "TCA",
      patient_id: this.props.redux_patientId,
      all_goals: [],
      custom_text: "",
      goalSelected: "1,2",
      goalSelectedLength: "2",
      addGoal: false,
      editGoal: false,
      doc_name: this.props.logged_info.content.details[0].Firstname,
      goalCrud: {
        jsonGridVersion: "v2.0",
        grid: true,
        viewgrid: {
          isVisible: true,
          isPagination: true,
          isFiltering: true,
          isSelection: true,
          deleteFunction: this.removeGoals,
          isDateGroup: false,
          isYearSeparator: false,
          dateCol: "",
          cellClicked: this.OpenPopupLetter,
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          query: {
            tb: "patients_enchanced_primary_care",
            cols: [
              "p_epc_id",
              "DATE_FORMAT(prev_care_plan_date,'%d-%m-%Y') as prev_care_plan_date",
              "performed_by",
              "(case when duration > 0 then 'TCA,SPECIAL' else 'TCA' end) as type",
              "doctor_tb.Firstname as dr",
              "duration",
              "status",
              "goals"

            ],
            joins: [
              {
                joinType: "left",
                joinTable: "doctor_tb",
                joinCondition:
                  "doctor_tb.doctor_id = patients_enchanced_primary_care.performed_by",
              },
            ],
            where: [
              {
                type: "and",
                key: "patients_enchanced_primary_care.isDeleted",
                operator: "=",
                value: 0,
              },
              {
                type: "and",
                key: "patients_enchanced_primary_care.patient_id",
                operator: "=",
                value: this.props.redux_patientId,
              },
            ],
            primary_key: "p_epc_id",
          },
          viewtabletitle: "",
          columns: [
            { name: "goals", title: "Care plan goals" },
            { name: "type", title: "Type" },
            { name: "dr", title: "Doctor" },
            { name: "duration", title: "No of Visits" },
            { name: "status", title: "Status" },
          ],
          rows: [],
          sortinit: [{ columnName: "p_epc_id", direction: "desc" }],
          pagesize: 10,
          getAllIds: false,
          getallId: "",
        },
      },
      AsthmaGrid: {
        jsonGridVersion: "v2.0",
        grid: true,
        viewgrid: {
          isVisible: true,
          isPagination: true,
          isFiltering: true,
          isSelection: true,
          deleteFunction: this.removeAsthma,
          isDateGroup: false,
          isYearSeparator: false,
          dateCol: "",
          cellClicked: this.OpenPopupLetter,
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          query: {
            tb: "asthma_tb",
            cols: [
              "DATE_FORMAT(cDate,'%d-%m-%Y') as cDate",
              "CASE WHEN severity = 1 THEN 'Yes' ELSE 'No' END AS severity",
              "CASE WHEN devices = 1 THEN 'Yes' ELSE 'No' END AS devices",
              "CASE WHEN action = 1 THEN 'Yes' ELSE 'No' END AS action",
              "CASE WHEN education = 1 THEN 'Yes' ELSE 'No' END AS education",
              "CASE WHEN written = 1 THEN 'Yes' ELSE 'No' END AS written",
            ],
            where: [              
              {
                type: "and",
                key: "asthma_tb.patient_id",
                operator: "=",
                value: this.props.redux_patientId,
              },
            ],
            primary_key: "id",
          },
          viewtabletitle: "",
          columns: [
            { name: "cDate", title: "Date" },
            { name: "severity", title: "Severity of asthma" },
            { name: "devices", title: "Medication and Devices" },
            { name: "action", title: "Action plan" },
            { name: "education", title: "Self-management Education" },
            { name: "written", title: "Written action plan" },
          ],
          rows: [],
          sortinit: [{ columnName: "id", direction: "desc" }],
          pagesize: 10,
          getAllIds: false,
          getallId: "",
        },
      },
      diabeticGrid: {
        jsonGridVersion: "v2.0",
        grid: true,
        viewgrid: {
          isVisible: true,
          isPagination: true,
          isFiltering: true,
          isSelection: true,
          deleteFunction: this.removeGoals,
          isDateGroup: false,
          isYearSeparator: false,
          dateCol: "",
          cellClicked: this.OpenPopupLetter,
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          query: {
            tb: "diabetes_care_tb",
            cols: [
              "care_id",
              "DATE_FORMAT(observation_date,'%d-%m-%Y') as observation_date",
              "doctor_tb.Firstname as dr",
              "next_review"
              
            ],
            joins: [
              {
                joinType: "left",
                joinTable: "doctor_tb",
                joinCondition:
                  "doctor_tb.doctor_id = diabetes_care_tb.doc_id",
              },
            ],
            where: [
              {
                type: "and",
                key: "diabetes_care_tb.isDeleted",
                operator: "=",
                value: 0,
              },
              {
                type: "and",
                key: "diabetes_care_tb.patient_id",
                operator: "=",
                value: this.props.redux_patientId,
              },
            ],
            primary_key: "care_id",
          },
          viewtabletitle: "",
          columns: [
            { name: "observation_date", title: "Observation Date" },
            { name: "dr", title: "Doctor" },
            { name: "next_review", title: "Next Review" },
          
          ],
          rows: [],
          sortinit: [{ columnName: "care_id", direction: "desc" }],
          pagesize: 10,
          getAllIds: false,
          getallId: "",
        },
      },
      showasthama:false,
      addenhancedForm:false,
      showDiabe:false,
      showDiabetic:false,
      dibedata:[],
      enableDimen:false,
      showDimetia:false
    };
  }

  removeAsthma(){

  }
  componentWillReceiveProps(nextProps) {
    this.setState({ logged_info: nextProps.logged_info });
    this.setState({ drId: nextProps.logged_info.content.details[0].doctor_id });
    this.setState({ doc_name: nextProps.logged_info.content.details[0].Firstname });
    if (nextProps.redux_patientId !== this.props.redux_patientId) {
      this.setState({ patientId: nextProps.redux_patientId });
    }
   
  }
  componentDidMount=()=>
  {
    this.selectPurpose(0)
  }
  toggleLetter = (letter) => {
    
    this.setState({letter: letter});
  }
  closeCoverLetter = () => {
    this.setState({ showCoverLetter: false });
  };
  OpenPopupLetter = (...orgs) => {
    
    console.log(orgs);
    let selected_epc = orgs[0].p_epc_id;
    this.setState({ showCoverLetter: true });
        this.props.selectepc({id: selected_epc}).then((res) => {
          this.setState({selectedepc: res.data.content});
          console.log("selectedepc", res.data.content);
        });

  }
  OpenPopupLetter2 = (id) => {
    
    //console.log(orgs);
    let selected_epc = id;
    this.setState({ showCoverLetter: true });
        this.props.selectepc({id: selected_epc}).then((res) => {
          this.setState({selectedepc: res.data.content});
          console.log("selectedepc", res.data.content);
        });

  }

  selectPurpose(value){
  //alert(value);
    if(value == 1){
     //document.getElementById("AsthmaGridDiv").style.display = "block";
     document.getElementById("goalCrudDiv").style.display = "none";
    }else if(value == 3){
     document.getElementById("AsthmaGridDiv").style.display = "none";
     document.getElementById("goalCrudDiv").style.display = "block";
     document.getElementById("diabeticGrid").style.display = "none";
     document.getElementById("dimentiaGrid").style.display = "none";
    }
    else if(value == 4){
      document.getElementById("AsthmaGridDiv").style.display = "none";
      document.getElementById("goalCrudDiv").style.display = "none";
      document.getElementById("dimentiaGrid").style.display = "block";
     }
     else if(value == 6){
      document.getElementById("AsthmaGridDiv").style.display = "none";
      document.getElementById("goalCrudDiv").style.display = "none";
      document.getElementById("diabeticGrid").style.display = "none";
      document.getElementById("dimentiaGrid").style.display = "block";
     }
    else{
      document.getElementById("AsthmaGridDiv").style.display = "none";
     document.getElementById("goalCrudDiv").style.display = "none";
     document.getElementById("diabeticGrid").style.display = "none";
     document.getElementById("dimentiaGrid").style.display = "none";
    }
    this.showAsthma(value);
  }

  showAsthma=(val)=>
  {
    if(val==1)
    {
      this.setState({showasthama:true})
    }
    if(val==4)
    {
      this.setState({showDiabetic:true})
      this.props.getDiabetic({pat_id:this.props.redux_patientId}).then(res=>
        {
         
            if(res.data.content!='')
            {
              this.setState({dibedata:res.data.content})
          //    this.setState({key:Math.random()})
              console.log("&&&&&&&&&&&&&&&",res.data.content)
            }
        })
    }
    if(val==6)
    {
    //  alert(val)
      this.setState({showDimetia:true,showasthama:false,showDiabetic:false})
     
    }

  }

  removeGoals = (ids, action, totalLength) => {
    if (action == "selection") {
      this.state.goalSelected = ids;
      this.state.goalSelectedLength = totalLength;
    }

    if (action == "coverletter") {
      //alert("coverletter");
      if (this.state.goalSelectedLength == 1) {
        this.setState({ showCoverLetter: true });
        this.props.selectepc({id: this.state.goalSelected}).then((res) => {
          this.setState({selectedepc: res.data.content});
          console.log("selectedepc", res.data.content);
        });
      }
    }

    if (action == "delete") {
      
      if (this.state.goalSelectedLength > 0) {
        const deleteData = { ids: this.state.goalSelected };
        confirmAlert({
          title: "",
          message:
            "You are about to delete selected Enchanced Primary Care Record(s). Do you want to proceed ?",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                this.props
                  .apiDeleteEPC(deleteData)
                  .then(
                    (res) => this.reloadPage(),
                    NotificationManager.success(
                      "Enchanced Primary Care Record(s) deleted"
                    )
                  );
              },
            },
            {
              label: "No",
              onClick: () => {
                this.reloadPage();
              },
            },
          ],
        });
      } else {
        NotificationManager.error(
          "Please select a care plan goal(s) to be deleted"
        );
      }
    }
  };
  reloadPage = () => {
    this.setState({
      addGoal: false,
      editGoal: false,
      addTask: false,
      editTask: false,
      openTemplate: false,
      addProblem: false,
      editProblem: false,
    });
    this.setState({
      goalSelected: "",
      goalSelectedLength: -1,
      taskSelected: "",
      taskSelectedLength: -1,
      problemSelected: "",
      problemSelectedLength: -1,
    });
    this.setState({ reloadKey: Math.random() });

  //    this.list_goals();
  };


printLetter = (divName)  => {
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
}

  changeKey = () => {
    this.setState({ reloadKey: Math.random(), showasthama:false , addenhancedForm:false,showDiabe:false,
      enableDimen:false});
  };
  render() {
    return (
      <div
        id="notesdiv13"
        className="col-md-12"
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <div className="panel panel-default">
          <div className="panel-heading panspace">
            <div className="col-lg-12 col-xl-12">
              <h4 className="panel-title">
                <a href="#" className="collapsed" aria-expanded="false">
                  {" "}
                  Enhanced primary Care
                </a><Showhelp gotohelp="enhanced_primary_care_help"/>
              </h4>
              <div style={{ width: "auto", height: 30, position: "absolute", right: 15, top: 0}}>
                <span className="makescreen">
                  <a
                    href="#"
                    title="Make This Default Screen"
                    className="collapsed"
                    aria-expanded="false"
                  >
                    <i
                      className="fa fa-star panelicon"
                      aria-hidden="true"
                    />
                  </a>
                </span>
                <a
                  href="#"
                  title="Email"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    className="fas fa-envelope panelicon"
                    aria-hidden="true"
                  />
                </a>
                <a
                  href="#"
                  title="Print"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    className="fas fa-print panelicon"
                    aria-hidden="true"
                  />
                </a>
                {/*<a href="#" title="Export XLs"><i class="fas fa-file-excel-o" aria-hidden="true" style="font-size:12px; margin-right:5px;"></i></a>*/}
                <a
                  href="#"
                  title="Export PDF"
                  onclick="recentVisitmini();"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    className="fa fa-file-pdf-o panelicon"
                    aria-hidden="true"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          {this.state.addenhancedForm ?
          <Addenhanced
            changeKey={this.changeKey}
            patient_id={this.state.patient_id}
            OpenPopupLetter = {this.OpenPopupLetter2}
            showForm={this.state.addenhancedForm}
          />:''}
          {!this.state.showDiabetic && ! this.state.showDimetia?
        <button  id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={(e)=> this.setState({addenhancedForm:true})}>Add</button>   
       :''}
       {/*} <button  id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={(e)=> this.setState({showasthama:true})}>Create</button>
        */}
          {this.state.showasthama?
          <Createasthma disabled={this.state.disable} showform={this.state.showasthama} changeKey={this.changeKey} />
          :''}
          

          {/*<button id="asthma" data-toggle="modal" data-target="#asthmatemp" type="button" className="btn btn-default" style={{display: 'none', margin: '7px auto 0px', padding: ''}}>Create</button>*/}
         
        
         {this.state.showDiabetic ?
          <Creatediabetes  changeKey={this.changeKey} diabe={this.state.dibedata}/>:''}
         
          {this.state.showDimetia ?
                <button  id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs"
                onClick={()=>this.setState({enableDimen:true})}>Add</button>
          :''}
          {this.state.enableDimen ?
          <DimentiaCare open={this.state.enableDimen} changeKey={this.changeKey} />:''}
                   <button
            id="session-timeout-dialog-logout"
            type="button"
            className="btn btn-primary btn-xs"
            onClick={(e) => this.removeGoals("", "delete", "")}
          >
            Delete
          </button>
          <button
            id="session-timeout-dialog-logout"
            type="button"
            className="btn btn-primary btn-xs"
          >
            Print
          </button>
          <Recordnote />
          {/*<button id="session-timeout-dialog-logout" type="button" className="btn btn-default" style={{marginTop: '21px',marginRight: '10px', padding: ''}}>Record Note</button>*/}
        </div> 
        <div
          className="col-sm-12"
          style={{ textAlign: "left", marginTop: "15px" }}
        >
          <div className="col-sm-3 pr-0" style={{ paddingLeft: 0 }}>

            <select
              id="purpose"  className="form-control"
              style={{ float: "left", fontSize: "15px", padding: "5px", marginTop:'5px' }}
              onChange={e =>
                this.selectPurpose(
                  e.target.value
                )
              }
            >
              <option selected value={0}>All</option>
              <option value={1} >Asthma cycle of care</option>

              <option value={2}>Health assessment</option>
              <option value={3} >
                Care plan
              </option>
              <option value={4}>Diabetes Cycle of Care</option>
              <option value={5}>Medication review</option>
              <option value={6}>Dementia Assessment</option>
              <option value={7}>ADF Post Discharge GP Health Assessment</option>
            </select>
          </div>
          <div className="col-sm-9 pl-0">
            <div className="form-group row">
              <div className="col-sm-3" style={{display:"none"}}>
                <label style={{ fontSize: "11px" }}>
                  {" "}
                  <input
                    type="radio"
                    name="smoking"
                    value="Non-smoker"
                    className="" 
                    style={{ float: "left", width: "auto" }}
                  />
                  <span
                    style={{
                      paddingLeft: "5px",
                      marginRight: "5px",
                      float: "left",
                    }}
                  >
                    GP Management Plan
                  </span>{" "}
                </label>
              </div>
              <div className="col-sm-4" style={{display:"none"}}>
                <label style={{ fontSize: "11px" }}>
                  <input
                    type="radio"
                    name="smoking"
                    value="Non-smoker"
                    className=""
                    style={{ float: "left", width: "auto" }}
                  />
                  <span
                    style={{
                      paddingLeft: "5px",
                      marginRight: "5px",
                      float: "left",
                    }}
                  >
                    Team Care Arrangements
                  </span>{" "}
                </label>
              </div>
              <div className="col-sm-5 mt-2">
                <label style={{ fontSize: "11px" }}>
                  {" "}
                  <input
                    type="checkbox"
                    defaultValue
                    className=""
                    style={{ float: "left", width: "auto" }}
                  />
                  <span
                    style={{
                      paddingLeft: "5px",
                      marginRight: "5px",
                      marginTop: "5px",
                      float: "left",
                    }}
                  >
                    Preload with data from previous care plan
                  </span>{" "}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div style={{ float: "left", width: "100%", marginTop: "15px" }}>
        <div id="goalCrudDiv" className="allGrid col-sm-12">
          <CommonGrid crud={this.state.goalCrud} key={this.state.reloadKey} />
        </div>
        <div id="AsthmaGridDiv" className="allGrid col-sm-12">
          <CommonGrid crud={this.state.AsthmaGrid} key={this.state.reloadKey} />
        </div>
        <div id="diabeticGrid" className="allGrid col-sm-12">
          <CommonGrid crud={this.state.diabeticGrid} key={this.state.reloadKey} />
        </div>
        <div id="dimentiaGrid" className="allGrid col-sm-12">
          <CommonGrid crud={this.state.diabeticGrid} key={this.state.reloadKey} />
        </div>

          <Popup
            style={{ minHeight: "500px" }}
            open={this.state.showCoverLetter}
            close={() => this.closeCoverLetter()}
            position="right center"
            modal
            closeOnDocumentClick={false}
            contentStyle={{}}
          >
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={() => this.closeCoverLetter()}
                data-dismiss="modal"
                aria-label="Close"
              >
                {" "}
                ×
              </button>
              <span>Letter </span>
            </div>

            <div
              className="modal-body newpostpopup"
              style={{ fontSize: "14px", height: "400px", overflowY: "scroll" }}
            >
              <div
                className="col-md-12"
                style={{
                  marginTop: "0px",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
              >

                
                {this.state.selectedepc != undefined && this.state.selectedepc.length>0 && this.state.selectedepc[0].duration>0 && <button  className={this.state.letter == "Cover" ? "btn btn-primary": "btn " } onClick={e => this.toggleLetter('Cover')}> Cover </button>}
                <button className={this.state.letter == "TCA" ? "btn btn-primary": "btn " } onClick={e => this.toggleLetter('TCA')}> TCA </button>

            <div id="printableArea">  

            {this.state.letter == "Cover" && this.state.selectedepc==undefined && 
            <span> No Cover Letter Found </span>}
                {this.state.letter == "Cover" && this.state.selectedepc!=undefined && this.state.selectedepc.length>0 &&  this.state.selectedepc[0].duration>0 && 
                <table className="lettertable">
                  <tr>
                  <div class="row">
                    <div class="col-md-6" style={{ left: '0px',width: '50%'}}>
                      <div style={{top: '50%',width: '100%',padding: '20px'}}>
                          <img src={cicon} />
                      </div>
                    </div>
                    <div class="col-md-6" style={{right: '0px',width: '50%'}}>
                      <div style={{top: '50%',width: '100%',padding: '20px' ,marginTop: '20px',textAlign: 'right'}}>
                          {/* <span>{this.state.selectedepc[0].doc_name} </span><br/> */}
                          Dr. {this.state.doc_name}
                          <span></span>
                      </div>
                    </div>
                  </div>
                  </tr>
                  {/* <tr>
                    {" "}
                    <td> SUPALOGO </td>{" "}
                    <td>
                      {" "}
                      <h1> SupaDoc </h1>
                    </td>
                  </tr> */}
                  <tr>
                    {" "}
                    <td>
                      {" "}
                      Date:{" "}
                      {moment(
                        this.state.selectedepc[0].prev_care_plan_date
                      ).format("DD/MM/YYYY")}{" "}
                    </td>{" "}
                    <td> </td>
                  </tr>
                  <tr>
                    {" "}
                    <td>
                      {" "}
                      Re: {this.state.selectedepc[0].patient_name} <br />{" "}
                      {this.state.selectedepc[0].patient_address}{" "}
                    </td>{" "}
                    <td> </td>
                  </tr>
                  <tr>
                    {" "}
                    <td colSpan="2">
                      {" "}
                      Dear, <br />
                      Thank you for seeing {
                        this.state.selectedepc[0].doc_name
                      }{" "}
                      for an opinion and management. <br /> <br />
                      Current medications are : <br />
                      No Regular Medicatiosn <br />
                      <br />
                      Allergies: <br />
                      <br />
                      <br />
                      Past Medical History: Not Recorded
                      <br />
                      <br />
                      Yours Faithfully <br />
                      <br />
                      {this.state.selectedepc[0].doc_name} <br />
                      MBBS
                    </td>{" "}
                  </tr>
                </table>}
             
                {this.state.letter == "TCA" && this.state.selectedepc!=undefined && this.state.selectedepc.length>0 && 
                <table>
                   <tr>
                  <div class="row">
                    <div class="col-md-6" style={{ left: '0px',width: '50%'}}>
                      <div style={{top: '50%',width: '100%',padding: '20px'}}>
                          <img src={cicon} />
                      </div>
                    </div>
                    <div class="col-md-6" style={{right: '0px',width: '50%'}}>
                      <div style={{top: '50%',width: '100%',padding: '20px' ,marginTop: '20px',textAlign: 'right', marginLeft: "500px"}}>
                          {/* <span>{this.state.selectedepc[0].doc_name} </span><br/> */}
                          Dr. {this.state.doc_name}
                          <span></span>
                      </div>
                    </div>
                  </div>
                  </tr>
<tr><td colSpan="2">
  <h2> Referral form for Individual Allied health Sevices Under Medicare for patients witha a chronic emdical condition and complex care needs </h2>
  </td></tr>

  <tr><td colSpan="2">
  <b>  Note: GPs can use this form issued by the Department of Health and Ageiing or one that contains all of the compeonents of this form. </b>
  </td></tr>

  
  <tr><td colSpan="2">
  <b>   To b completed by referring GP: </b><br /> 
  Please tick: Note: GP's are encouraged to attach a copy of the relevant part of the patients's care plan to this form. 
  
  

  </td></tr>

  <tr>
    <td width="20px">
      <input type="checkbox" checked={true} />
      </td>
      <td> Patient has GP Managment Plan ()   
and Team Care Arrangements ()


      </td>
</tr> <tr>
      <td width="20px">
      <input type="checkbox" checked={true} />
      </td>
      <td> 

        GP has contributed to or reviewed a multidisciplinary care plan prepared by the patient's aged care facility ()
      </td>
    </tr>

    <tr><td colSpan="2">
      Medicare rebates and Private Health Insurance benefits cannot both be claimed for these services. Patients should be advvised that they must choose whether to access one or the other.
      </td></tr>
     <tr> 
       <td colSpan="2">

         <b> GP Details </b><br />

         Provider No: <br />
         Name : {this.state.selectedepc[0].doc_name} <br/>
         Address : <br />

       </td>
       </tr>

       <tr><td colSpan="2">
         <b> Patient Details: </b> <br />
         Medicare No: <br />
         First Name : {this.state.selectedepc[0].patient_name} <br />
         Address : {this.state.selectedepc[0].patient_address} <br/>

          <br />{" "}
                  
         </td>
         </tr>
          
         <tr>
           <td colSpan="2">
             <b> Allied Health Professional (AHP) patient referred to:</b> <br/>
             Name:  {this.state.selectedepc[0].provider_name} <br /> 
             Category:  {this.state.selectedepc[0].provider_category} <br /> 
             Address:  {this.state.selectedepc[0].provider_address} <br/>
             No of Visits : {this.state.selectedepc[0].duration} <br/>
             Task : {this.state.selectedepc[0].task} <br/>
             
             </td>
           </tr>
<tr>
  <td colSpan="2">

    <center> THIS FORM DOES NOT HAVE TO ACCOMPANY MEDICARE CLAIMS </center>
  </td>
  </tr>
                </table>}

            </div>    
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                style={{
                  padding: "6px 12px",
                  fontSize: "14px",
                  width: "",
                  height: "",
                }}
                className="btn btn-primary btn-xs"
                onClick={(e) => {
                  this.sendEmail(e);
                }}
              >
                Email
              </button>

              <button
                type="button"
                style={{
                  padding: "6px 12px",
                  fontSize: "14px",
                  width: "",
                  height: "",
                }}
                className="btn btn-primary btn-xs"
                onClick={(e) => {
                 // this.printLetter(e, "printableArea");
                 window.print();
                }}
              >
                Print
              </button>

              <button
                type="button"
                style={{
                  padding: "6px 12px",
                  fontSize: "14px",
                  width: "",
                  height: "",
                }}
                onClick={() => this.closeCoverLetter()}
                className="btn btn-primary btn-xs"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </Popup>
           
        </div>
        
         
         {" "}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let redux_patientId =
    state.getDrAppointments.getPatientDetails != undefined
      ? state.getDrAppointments.getPatientDetails[0].patient_id
      : "";
  let patient_age =
    state.getDrAppointments.getPatientDetails != undefined
      ? state.getDrAppointments.getPatientDetails[0].age
      : "";
  let logged_info =
    state.getDrAppointments.logged_info != undefined
      ? state.getDrAppointments.logged_info
      : "";

  return {
    redux_patientId,
    patient_age,
    logged_info,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectepc: data => dispatch(api_selectepc(data)),
    apiDeleteEPC: data => dispatch(apiDeleteEPC(data)),
    getDiabetic: data => dispatch(api_get_diabetic(data)),
    
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnhancedPrimaryCare);
