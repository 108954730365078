import React, { Component } from 'react';
import './style.css';
import Carer from './carer';
import { connect } from 'react-redux';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import {api_add_social,api_get_carer} from '../../../DS/DS.FamilyHistory';
 class socialhistory extends Component {
    constructor(props)
    {
        super(props);
        this.state=
        {
            showcarer:false,
            key:0,
            marital_status:0,
            sexuality:0,
            elite_athelte:0,
            healt_directive:0,
            attorney:0,
            on_screen:'',
            recreational_act:'',
            accomodation:0,
            lives_with:0,
            has_carer:0,
            is_carer:0,
            is_safe:0,
            other_history:'',
            disable_btn:false
        }
    }
    componentWillMount=()=>
    {
        const values={patient_id:this.props.redux_patientId}
        this.props.apiListCarer({values}).then(
            req=>
            {
                this.setState({
                    marital_status:req.data.content.marital_status,
                    sexuality:req.data.content.sexuality,
                    elite_athelte:req.data.content.elite_athelte,
                    healt_directive:req.data.content.healt_directive,
                    attorney:req.data.content.attorney,
                    on_screen:req.data.content.on_screen,
                    recreational_act:req.data.content.recreational_act,
                    accomodation:req.data.content.accomodation,
                    lives_with:req.data.content.lives_with,
                    has_carer:req.data.content.has_carer,
                    is_carer:req.data.content.is_carer,
                    is_safe:req.data.content.is_safe,
                    other_history:req.data.content.other_history})
            
            })
    }
    reloadPage=()=>
    {
        this.setState({showcarer:false})
        this.setState({key:Math.random()})
    }
    handleRadio=(val,e)=>
    {
        this.setState({elite_athelte:val})
    }
    handleSafeRadio=(val,e)=>
    {
        if(val==2)
        {
            alert("Ring the ntional Sexual Assault,Domestic Family Violence Counselling service on 1800RESPECT (1800 737 732) or visit their website to find a local service: 'https://www.1800respect.org.au/'")
            this.setState({is_safe:val})
        }
        else
        {
            this.setState({is_safe:val})
        }

    }
    saveHandler=()=>
    {
        const values={
            marital_status:this.state.marital_status,
            sexuality:this.state.sexuality,
            elite_athelte:this.state.elite_athelte,
            healt_directive:this.state.healt_directive,
            attorney:this.state.attorney,
            on_screen:this.state.on_screen,
            recreational_act:this.state.recreational_act,
            accomodation:this.state.accomodation,
            lives_with:this.state.lives_with,
            has_carer:this.state.has_carer,
            is_carer:this.state.is_carer,
            is_safe:this.state.is_safe,
            other_history:this.state.other_history,
            patient_id:this.props.redux_patientId,
            doc_id:this.props.doc_id
        }
        this.props.apiAddSocial({values}).then(
            req=>
            {
                NotificationManager.success('Social history saved for the patient')
            }
        )
    }
    handleChnage=(e)=>
    {
        this.setState({has_carer:e.target.value})
        if(e.target.value==2)
        {
            this.setState({disable_btn:true})
        }
        else
        {
            this.setState({disable_btn:false})
        }

    }
    render() { 
        return ( 
            <div className="col-sm-12 pl-0 pr-0">
           {/*} <div className="col-sm-12 pl-0 pr-0">
                        <h5 class="sheadtitle">Social History</h5>
        </div>*/}

<h5 class="col-sm-12 sheadtitle">Social History</h5>




<div className="col-sm-12" id="Graphdetails">
  <div className="row mt-2">
    <div className="col-sm-1 cus-widf mt-3">
      <label> Marital status: </label>
    </div>
    <div className="col-sm-1">
    <select class="form-control" value={this.state.marital_status}
                                onChange={(e)=>this.setState({marital_status:e.target.value})}>
                                    <option value="0"></option>
                                    <option value="1">Single</option>
                                    <option value="2">Married</option>
                                    <option value="3">Defacto</option>
                                    <option value="4">Separated</option>
                                    <option value="5">Divorced</option>
                                    <option value="6">Widowed</option>
                                </select>
    </div>
    <div className="col-sm-1 cus-widf mt-3">
      <label> Sexulity: </label>
    </div>
    <div className="col-sm-1">
    <select class="form-control" value={this.state.sexuality}
                                onChange={(e)=>this.setState({sexuality:e.target.value})}>
                                <option value="0"></option>
                                <option value="1">Heterosexual</option>
                                <option value="2">Homosexual</option>
                                <option value="3">Bisexual</option>

                                </select>
    </div>
    <div className="col-sm-1 pr-0 mt-3">
      <label> Elite Athlet: </label>
    </div>
    <div className="col-sm-1 mt-3">
    <label className="radio-inline"   onClick={(e)=>{this.handleRadio(1,e)}}> 
                                    <input type="radio"  name="smoking" value="1" style={{marginTop: '10px'}}
                                    checked={this.state.elite_athelte==1 ? 'checked':''} /> Yes 
                                </label>
    <label className="radio-inline"  onClick={(e)=>{this.handleRadio(2,e)}}> 
                                    <input type="radio" name="smoking" value="2" style={{marginTop: '10px'}}
                                     checked={this.state.elite_athelte==2 ? 'checked':''} /> No </label>
    </div>
    <div className="col-sm-1 pr-0 pl-0 mt-3"> 
      <label> Enduring Power of Attomey: </label>
    </div>
    <div className="col-sm-1">
    <select class="form-control" value={this.state.attorney}
                                onChange={(e)=>this.setState({attorney:e.target.value})}>
                                <option value="0"></option>
                                <option value="1">Yes</option>
                                <option value="2">No</option>
                                </select>
    </div>
    <div className="col-sm-1 pr-0 mt-3">
      <label> Recreational activities: </label>
    </div>
    <div className="col-sm-2 pr-0">
    <input type="text" class="form-control"
                                value={this.state.recreational_act} onChange={(e)=>this.setState({recreational_act:e.target.value})}></input>
    </div>
  </div>
  <div className="row">
    <div className="col-sm-1 cus-widf mt-3">
      <label> Acommondation: </label>
    </div>
    <div className="col-sm-1">
    <select  class="form-control" value={this.state.accomodation}
                                onChange={(e)=>this.setState({accomodation:e.target.value})}>
                                    <option value="0"></option>
                                    <option value="1">Own home</option>
                                    <option value="2">Relative's home</option>
                                    <option value="3">Other private house</option>
                                    <option value="4">Hostel</option>
                                    <option value="5">Nursing home (RACF)</option>
                                    <option value="6">Homeless</option>
                                    <option value="7">Rental home</option>
                                </select>
    </div>
    <div className="col-sm-1 cus-widf mt-3">
      <label> Live with: </label>
    </div>
    <div className="col-sm-1">
    <select class="form-control" value={this.state.lives_with}
                                onChange={(e)=>this.setState({lives_with:e.target.value})}>
                                    <option value="0"></option>
                                    <option value="1">Spouse</option>
                                    <option value="2">Relative</option>
                                    <option value="3">Friend</option>
                                    <option value="4">Alone</option>
                                </select>
    </div>
    <div className="col-sm-1 pl-0 pr-0 mt-3">
      <label> Advance Helth Directive: </label>
    </div>
    <div className="col-sm-1">
    <select class="form-control" value={this.state.healt_directive}
                                onChange={(e)=>this.setState({healt_directive:e.target.value})}>

                                    <option value="0"></option>
                                    <option value="1">Yes</option>
                                    <option value="2">No</option>
                                </select>
    </div>
    <div className="col-sm-1 pl-0 pr-0 mt-3">
      <label> Enduring Power of Attomey: </label>
    </div>
    <div className="col-sm-1">
        <select class="form-control" value={this.state.attorney}
                                onChange={(e)=>this.setState({attorney:e.target.value})}>
                                <option value="0"></option>
                                <option value="1">Yes</option>
                                <option value="2">No</option>
                                </select>
    </div>
    <div className="col-sm-1 mt-3">
      <label>On screen comment</label>
    </div>
    <div className="col-sm-2 pr-0">
    <input type="text"  class="form-control"
                                value={this.state.on_screen} onChange={(e)=>this.setState({on_screen:e.target.value})}></input>
    </div>
  </div>
  <div className="row">
    <div className="col-sm-1 cus-widf mt-3">
      <label> Elite Athlet: </label>
    </div>
    <div className="col-sm-1 mt-3">
    <label className="radio-inline"  onClick={(e)=>{this.handleRadio(1,e)}}> 
                                    <input type="radio"  name="smoking" value="1" style={{marginTop: '10px'}}
                                    checked={this.state.elite_athelte==1 ? 'checked':''} /> Yes 
                                </label> 
    <label className="radio-inline" onClick={(e)=>{this.handleRadio(2,e)}}> 
                                    <input type="radio" name="smoking" value="2" style={{marginTop: '10px'}}
                                     checked={this.state.elite_athelte==2 ? 'checked':''} /> No </label>
    </div>
    <div className="col-sm-2 mt-3">
      <label> Do you feel safe in your own home?: </label>
    </div>
    <div className="col-sm-1 mt-3">
    <label className="checkbox-inline" onClick={(e)=>{this.handleSafeRadio(1,e)}}>
                                    <input type="checkbox" name="smoking" style={{marginTop: '10px'}}
                                    checked={this.state.is_safe==1 ? 'checked':''}/> Yes 
                                </label>
    <label className="checkbox-inline" onClick={(e)=>{this.handleSafeRadio(2,e)}}> 
                                    <input type="checkbox" name="smoking" style={{marginTop: '10px'}}
                                    checked={this.state.is_safe==2 ? 'checked':''}/> No
                                </label>
    </div>
    <div className="col-sm-1 mt-3" style={{textAlign: 'left'}}>
      <label>Has carer:</label>
    </div>
    <div className="col-sm-1 pl-0" style={{textAlign: 'left'}}>
    <select class="form-control" value={this.state.has_carer}
                                onChange={(e)=>this.handleChnage(e)}>
                                <option value="0"></option>
                                <option value="1">Yes</option>
                                <option value="2">No</option>
                                <option value="3">Self</option>
                                </select>
    </div>
    <div className="col-sm-1 pl-0 mt-2" style={{textAlign: 'left'}}>
    <button  className="btn btn-primary btn-xs mt-1" onClick={()=>this.setState({showcarer:true})}
                                disabled={this.state.disable_btn}>Carer details</button>
    </div>
    <div className="col-sm-1 mt-3" style={{textAlign: 'left'}}>
      <label>Is carer:</label>
    </div>
    <div className="col-sm-2 pr-0" style={{textAlign: 'left'}}>
    <select class="form-control" value={this.state.is_carer}
                                onChange={(e)=>this.setState({is_carer:e.target.value})}>
                                <option value="0"></option>
                                <option value="1">Yes</option>
                                <option value="2">No</option>
                                </select>
    </div>
  </div>
  <div className="row mt-2">
    <div className="col-sm-12">
      <label> Details: </label>
    </div>
    <div className="col-sm-12">
    <textarea rows="4" cols="" style={{padding:'15px',border:'1px solid rgb(221, 221, 221)',marginTop: '10px',width:'100%'}}
                                value={this.state.other_history} onChange={(e)=>this.setState({other_history:e.target.value})}></textarea>
    </div>
  </div>
  <div className="row">
    <div className="col-sm-12" style={{textAlign: 'right'}}>
      <button className="btn btn-primary btn-xs" onClick={()=>this.saveHandler()}>Save</button>
      <button className="btn btn-primary btn-xs" onClick={()=>this.reloadPage()}>Cancel</button></div>
  </div>
</div>
{(this.state.showcarer) ? 
                        <Carer showmodal={this.state.showcarer} changeKey={this.reloadPage}/> : ''}

                    </div>
                );
            }
    }
  
const mapDispatchToProps = dispatch => {
    return {
      apiAddSocial: (data) => dispatch(api_add_social(data)),
      apiListCarer:(data)=>dispatch(api_get_carer(data))
     };
  };
  
  const mapStateToProps = state => {
  
      let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
      let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
       
    return {
      redux_patientId,redux_docId
    };
  };
  export default connect(
      mapStateToProps,
      mapDispatchToProps
  )(socialhistory);
  
