import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import Showhelp from './../showHelp';
import moment from 'moment';
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { api_list_options, api_getSelectedPatientDetails,  addPaitentData, api_getTitle,api_banknames,api_getGender} from "./../../DS/DS.Appointments";
import "react-datepicker/dist/react-datepicker.css";
import {
    
  api_list_careplans,
  api_add_create_account_item
} from "./../../DS/DS.VisitnotesFinalizeVisit";
import ServiceNotes from "./../crudcomponent/serviceNotes";
import {add_patient_account,get_patient_account} from './../../DS/DS.BankAccount';
import { NotificationManager } from 'react-notifications';

class editPatientDetails extends Component {
    constructor() {
        super();
        this.state = {
          patient_id: "",
          title: "",
          family_name: "",
          given_name: "",
          middle_name: "",
          Perferred_name: "",
          date_of_birth: "",
          age: 0,
          sex: 1,
          ethnicity: "",
          address_line_1: "",
          address_line_2: "",
          city: "",
          postcode: "",
          post_address: "",
          postal_address_city: "",
          postal_address_postcode: "",
          home_phone: "",
          work_phone: "",
          mobile_phone: "",
          contact_via: "",
          email: "",
          general_notes: "",
          appointment_notes: "",
          health_identifier: "",
          hi_status: "",
          medicare_no: "",
          medicare_irn: "",
          medicare_expiry: "",
          pension: "",
          pension_expiry: "",
          pension_card: "",
          dva_no: "",
          sefety_net_no: "",
          record_no: "",
          patient_id: "",
          usual_doctor: "",
          usual_visit_type: "",
          usual_account: "",
          health_ins_no: "",
          religion: "",
          head_of_family: "",
          next_to_kin: "",
          emerygency_contact: "",
          occuption: "",
          healthcare_home: "",
          hi_status_1: "",
          hi_status_2: "",
          dva_no_1: "",
          dva_no_2: "",
          submitdisbled: false,
          list_options: {},
          bank_name: "",
          account_name: "",
          account_number: "",
          bsb_number: "",
          referral_date: "",
          referral_expiry: "",
          referral_type: "", 
          referral_to: "",
          referral_by: "",
          referral_gp_specialist: "",
          show_tab : "bank", 
          list_title:[],
          list_bank_name:[],
          list_gender:[],
          showotherEthinicity:false,
          account_id:''
        };
      }



      handleDate = date => {
        if (new Date() > new Date(date)) {
          //alert(1);
      } else {
          alert("Cannot set Future Date!");
          date = new Date();
      }
        this.setState({ date_of_birth: date });
        this.setState({ age: this.getAge(date) });
      };
    
      handleDate_referral_expiry = date => {
        this.setState({ referral_expiry: date });
      };
    
      handleDate_referral_date = date => {
        this.setState({ referral_date: date });
      };

      addPatientData(e) {
        //validation list
            let validation_check_passed = true;
            let content = '';
            if (this.state.given_name == "") {
              validation_check_passed = false;
              content += " Given Name, ";
            }
            if (this.state.date_of_birth == "") {
              validation_check_passed = false;
              content += " DOB, ";
            }
        if (this.state.family_name == "") {
          validation_check_passed = false;
          content += " Family Name, ";
        }
        if (this.state.sex == "") {
          validation_check_passed = false;
          content += " Sex, ";
        }
        if (this.state.medicare_no == "") {
          validation_check_passed = false;
          content += " Medicare No, ";
        }
            
        if(validation_check_passed){
          this.props.addPatientAction(this.state);
          console.log(this.state);
        
        //  this.props.thenaddpatient();
        } else {
          
          alert("Following Fields are required: "+ content); 
        }
        const values={
          provide:this.state.patient_id,
          bank:this.state.bank_name,
          accnm:this.state.account_name,
          accno:this.state.account_number,
          bsb:this.state.bsb_number,
          mid:'',
          logby:'',
          account:3,
          ids:this.state.account_id
       }

          this.props.saveRecord(values).then(
             req=>{
                if(req.data.status=="Success")
                {
              //     NotificationManager.success('New provider account created successfully')
                }
                if(req.data.status=="Duplicate")
                {
                //   NotificationManager.error("Duplicate record cannot be created")
                }
                if(req.data.status=="Error")
                {
                  // NotificationManager.error("'Error encountered while creating record")
                }
            //    this.props.changeKey();
             }

          )
      }
    
      getAge = dateString => {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        // alert(age);
        return age;
      };
    
      handleDate2 = date => {
        this.setState({ medicare_expiry: date });
      };
      handleDate3 = date => {
        this.setState({ pension_expiry: date });
      };
      handleDate4 = date => {
        this.setState({ dva_no_2: date });
      };
    
      componentWillMount(){
       if(this.props.enlist_pat!='')
       {
        let patientinfo = {patient_id: this.props.patient_id}
        this.props.getBankAccount({provide:this.props.patient_id}).then(res=>
          {
            if(res.data.content!='')
            {
            this.setState({
          //    bank_name: res.data.content[0].bank_name,
            //  account_name:res.data.content[0].account_name,
            //  account_number:res.data.content[0].account_no,
             // bsb_number: res.data.content[0].bsb_no,
            //  account_id:res.data.content[0].account_id,
            //  mid:res.data.content[0].mid
          })
            } 
          })
        
        this.props.getSelectedPatientDetails({patient_id: patientinfo}).then(res => {
         
         if(res.data.content[0].Ethnicity=='Other')
         {
           this.setState({ showotherEthinicity:true})
         }
         else{
          this.setState({ showotherEthinicity:false})
         }
         this.setState({ 
           
          title: res.data.content[0].Title,
          family_name: res.data.content[0].family_name,
          patient_id: res.data.content[0].patient_id,
           given_name: res.data.content[0].Firstname,
          middle_name: res.data.content[0].Middlename,
          Perferred_name: res.data.content[0].Preferredname,
          date_of_birth: res.data.content[0].DOB!=""?new Date(res.data.content[0].DOB):new Date(),
          age: res.data.content[0].age,
          sex: res.data.content[0].Sex,
          ethnicity: res.data.content[0].Ethnicity,
          address_line_1: res.data.content[0].Address1,
          address_line_2: res.data.content[0].Address2,
          city: res.data.content[0].City,
          postcode: res.data.content[0].Postcode,
          post_address: res.data.content[0].PostalAddress,
          postal_address_city: res.data.content[0].PostalCity,
          postal_address_postcode: res.data.content[0].PostalPostcode,
          home_phone: res.data.content[0].home_phone,
          work_phone: res.data.content[0].work_phone,
          mobile_phone: res.data.content[0].phone,
          contact_via: res.data.content[0].Firstname,
          email: res.data.content[0].email_id,
          hi_status: res.data.content[0].hiStatus,
          medicare_no: res.data.content[0].medicare_no,
          medicare_irn: res.data.content[0].irn,
        //  medicare_expiry: res.data.content[0].expiry,
           pension_card: res.data.content[0].pensioncard,
          
          pension: res.data.content[0].pensionNo,
          dva_no: res.data.content[0].dvano,
          sefety_net_no: res.data.content[0].sefetynetno,
          record_no: res.data.content[0].RecordNo,
          patient_id: res.data.content[0].patient_id,
          otherEthnicity:res.data.content[0].ethinicity_other



        
        
        
        });
       //  console.log(this.state.list_options);
       });
      
       }
      }
      componentWillReceiveProps(){
        if(this.state.patient_id!=this.props.patient_id){


        let patientinfo = {patient_id: this.props.patient_id}
        this.props.getBankAccount({provide:this.props.patient_id}).then(res=>
          {
            if(res.data.content!='')
            {
            this.setState({
          //    bank_name: res.data.content[0].bank_name,
           //   account_name:res.data.content[0].account_name,
            //  account_number:res.data.content[0].account_no,
            //  bsb_number: res.data.content[0].bsb_no,
            //  account_id:res.data.content[0].account_id,
            //  mid:res.data.content[0].mid
          })
            } 
          })
        
        this.props.getSelectedPatientDetails({patient_id: patientinfo}).then(res => {
         
         if(res.data.content[0].Ethnicity=='Other')
         {
           this.setState({ showotherEthinicity:true})
         }
         else{
          this.setState({ showotherEthinicity:false})
         }
         this.setState({ 
           
          title: res.data.content[0].Title,
          family_name: res.data.content[0].family_name,
          patient_id: res.data.content[0].patient_id,
           given_name: res.data.content[0].Firstname,
          middle_name: res.data.content[0].Middlename,
          Perferred_name: res.data.content[0].Preferredname,
          date_of_birth: res.data.content[0].DOB!=""?new Date(res.data.content[0].DOB):new Date(),
          age: res.data.content[0].age,
          sex: res.data.content[0].Sex,
          ethnicity: res.data.content[0].Ethnicity,
          address_line_1: res.data.content[0].Address1,
          address_line_2: res.data.content[0].Address2,
          city: res.data.content[0].City,
          postcode: res.data.content[0].Postcode,
          post_address: res.data.content[0].PostalAddress,
          postal_address_city: res.data.content[0].PostalCity,
          postal_address_postcode: res.data.content[0].PostalPostcode,
          home_phone: res.data.content[0].home_phone,
          work_phone: res.data.content[0].work_phone,
          mobile_phone: res.data.content[0].phone,
          contact_via: res.data.content[0].Firstname,
          email: res.data.content[0].email_id,
          hi_status: res.data.content[0].hiStatus,
          medicare_no: res.data.content[0].medicare_no,
          medicare_irn: res.data.content[0].irn,
        //  medicare_expiry: res.data.content[0].expiry,
           pension_card: res.data.content[0].pensioncard,
          
          pension: res.data.content[0].pensionNo,
          dva_no: res.data.content[0].dvano,
          sefety_net_no: res.data.content[0].sefetynetno,
          record_no: res.data.content[0].RecordNo,
          patient_id: res.data.content[0].patient_id,
          otherEthnicity:res.data.content[0].ethinicity_other



        
        
        
        });
       //  console.log(this.state.list_options);
       });
      }
      }
      componentDidMount() {
     //   document.getElementById("addPatientForm").scrollIntoView();


  

        this.props.list_options().then(res => {
          console.log("addPatientForm addPatientForm",res);
          this.setState({ list_options: res.data.content });
          console.log(this.state.list_options);
        });


        this.props.getTitle().then(res=>
          {
            console.log("Patient title",res.data.content)
            this.setState({list_title: res.data.content})
            console.log("Geneder",this.state.list_title)
          })
          
          this.props.getBankNames().then(res=>
            {
              this.setState({list_bank_name: res.data.content})
            })

            this.props.getGender().then(res=>
              {
                this.setState({list_gender: res.data.content})
              })
            
        }

    
      handleFieldValueChanges(key, value) {
        this.setState({
          [key]: value
        });
        if(key=='ethnicity')
        {
          if(value=='Other')
          {
            this.setState({showotherEthinicity:true})
          }
          else
          {
            this.setState({showotherEthinicity:false,otherEthnicity:''})
          }
        }
        
      }
   

  render() {
    return (
      <Popup
         
        // trigger={
          open={this.props.open}
          close={this.props.close}  
        // }
        position="right center"
        modal
        closeOnDocumentClick={false}
      >
        <div>
          <div className="modal-header">
            <div class="popup-title">
              <span> View Patient  </span>
              <Showhelp gotohelp="diagnosis_popup" />
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick= {e=> this.props.close()}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
          </div>
           <div className="modal-body" style={{height: '700px', overflow: 'scroll', color:'#666666'}}>         
           <div id="addPatientForm" className="new-patient-wrapper">
           
             
            <div className="row">
              <div class="col-md-6">
                <div class="row">
                  <label class="col-sm-2 col-form-label text-left">
                    Title:
                  </label>
                  <div class="col-sm-3">

                  <select
                        name="user"
                        class="form-control form-control-sm"
                      onChange={e =>
                        this.handleFieldValueChanges("title", e.target.value)
                      }
                        
                      >
                        {
                        this.state.list_title.map((item, index) => (
                          <option
                            value={item.value}
                            selected={this.state.title === item.value}
                            key={index}
                          >
                            {item.display}
                          </option>
                        ))}
                      </select>

                   {/*} <select  className="form-control"  value={this.state.title} onChange={e =>
                        this.handleFieldValueChanges("title", e.target.value)
                      } >
                        <option value=""> Select </option>
                      <option value="Mr"> Mr. </option>
                      <option value="Mrs"> Mrs. </option>
                      <option value="Master"> Master. </option>
                      <option value="Miss"> Miss. </option>
                    </select>*/}
                    {/*<input
                      type="text"
                      value={this.state.title}
                      class="form-control form-control-sm"
                      onChange={e =>
                        this.handleFieldValueChanges("title", e.target.value)
                      }
                    />*/}
                  </div>
                  <label class="col-sm-3 col-form-label text-left">
                    Family Name:
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      value={this.state.family_name}
                      class="form-control form-control-sm"
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "family_name",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-2 col-form-label text-left">
                    Given Name:
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      value={this.state.given_name}
                      class="form-control form-control-sm"
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "given_name",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <label class="col-sm-3 col-form-label text-left">
                    Middle Name
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      value={this.state.middle_name}
                      class="form-control form-control-sm"
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "middle_name",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-2 pr-0 col-form-label text-left">
                    Perferred Name
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      value={this.state.Perferred_name}
                      class="form-control form-control-sm"
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "Perferred_name",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <label class="col-sm-3 col-form-label text-left">
                    Date of Birth: (Age{this.state.age})
                  </label>
                  <div class="col-sm-3">
                    <DatePicker
                      selected={this.state.date_of_birth}
                      onChange={this.handleDate}
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                    />
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-2 col-form-label text-left">Sex:</label>
                  <div class="col-sm-3">
                    <select
                      type="text"
                      value={this.state.sex}
                      class="form-control form-control-sm"
                      onChange={e =>
                        this.handleFieldValueChanges("sex", e.target.value)
                      }
                    >
                     {
                        this.state.list_gender.map((item, index) => (
                          <option
                            value={item.value}
                            selected={this.state.sex === item.value}
                            key={index}
                          >
                            {item.display}
                          </option>
                        ))}
                    </select>

                      

                  </div>
                  <label class="col-sm-3 col-form-label text-left">
                    Ethnicity:
                  </label>
                  <div class="col-sm-3">
                    <select className="form-control" value={this.state.ethnicity} onChange={e =>
                        this.handleFieldValueChanges(
                          "ethnicity",
                          e.target.value
                        )
                      } >
                        <option value=""> Select </option>
                        <option value="Australian" > Australian</option>
                        <option value="Aboriginal" >  Aboriginal</option>
                        <option value="Torres Strait Islander" > Torres Strait Islander</option>
                        <option value="Other" > Other </option>
                        </select>
                    {/*<input
                      type="text"
                      value={this.state.ethnicity}
                      class="form-control form-control-sm"
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "ethnicity",
                          e.target.value
                        )
                      }
                    />*/}
                  </div>
                </div>
                { (this.state.showotherEthinicity) ? 
                <div class="row">
                  <label class="col-sm-3 col-form-label text-left">
                    Ethnicity: (Other)
                  </label>
                  <div class="col-sm-4">
                    <input type="text"
                    value={this.state.otherEthnicity}
                    class="form-control form-control-sm"
                    onChange={(e)=>this.setState({otherEthnicity:e.target.value})}/>
                  </div>
                </div>  

                :''} 
                <div class="row">
                  <label class="col-sm-2 pr-0 col-form-label text-left">
                    Address Line1:
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      value={this.state.address_line_1}
                      class="form-control form-control-sm"
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "address_line_1",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <label class="col-sm-3 col-form-label text-left">
                    Address Line2:
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      value={this.state.address_line_2}
                      class="form-control form-control-sm"
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "address_line_2",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-2 col-form-label text-left">
                    City/Suburb:
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      value={this.state.city}
                      class="form-control form-control-sm"
                      onChange={e =>
                        this.handleFieldValueChanges("city", e.target.value)
                      }
                    />
                  </div>
                  <label class="col-sm-3 col-form-label text-right">
                    Postcode:
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      value={this.state.postcode}
                      class="form-control form-control-sm"
                      onChange={e =>
                        this.handleFieldValueChanges("postcode", e.target.value)
                      }
                    />
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-2 pr-0 col-form-label text-left">
                    Postal Address:
                  </label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      value={this.state.postal_address}
                      class="form-control form-control-sm"
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "post_address",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-2 col-form-label text-left">
                    City/Suburb:
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      value={this.state.postal_address_city}
                      class="form-control form-control-sm"
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "postal_address_city",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <label class="col-sm-3 col-form-label text-right">
                    Postcode:
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      value={this.state.postal_address_postcode}
                      class="form-control form-control-sm"
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "postal_address_postcode",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-2 col-form-label text-left">
                    Home Phone:
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.home_phone}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "home_phone",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <label class="col-sm-3 col-form-label text-right">
                    Work Phone:
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.work_phone}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "work_phone",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-2 col-form-label text-left">
                    Mobile Phone:
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.mobile_phone}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "mobile_phone",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <label class="col-sm-3 col-form-label text-right">
                    Contact Via:
                  </label>
                  <div class="col-sm-3">
                    <select  class="form-control form-control-sm" value={this.state.contact_via}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "contact_via",
                          e.target.value
                        )
                      }> 
                      <option value=""> Select </option>
                      <option value="mobile"> Mobile Phone </option>
                      <option value="work">Work Phone </option>
                      <option value="home"> Home Phone </option>
                      <option value="sms"> SMS </option>
                      <option value="email"> Email </option>
                      <option value="letter"> Letter </option>
                      </select>
                    {/*<input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.contact_via}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "contact_via",
                          e.target.value
                        )
                      }
                    />*/}
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-2 col-form-label text-left">
                    Email:
                  </label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.email}
                      onChange={e =>
                        this.handleFieldValueChanges("email", e.target.value)
                      }
                    />
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-3 col-form-label text-left">
                    General Notes:
                  </label>
                  <div class="col-sm-8">
                    <textarea
                      rows="3"
                      cols="5"
                      class="form-control"
                      placeholder=""
                      value={this.state.general_notes}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "general_notes",
                          e.target.value
                        )
                      }
                    ></textarea>
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-3 col-form-label text-left">
                    Appointment Notes:
                  </label>
                  <div class="col-sm-8">
                    <textarea
                      rows="3"
                      cols="5"
                      class="form-control"
                      placeholder=""
                      value={this.state.appointment_notes}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "appointment_notes",
                          e.target.value
                        )
                      }
                    ></textarea>
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-12 col-form-label text-left alert-danger">
                   <span style={{color: '#000'}} >  * These name fields are used for Health Identifier lookups. </span>
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <label class="col-sm-2 pr-0 col-form-label text-left">
                    Health Identifier:
                  </label>
                  <div class="col-sm-9">
                    <div class="input-group input-group-button">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder=""
                        value={this.state.health_identifier}
                        onChange={e =>
                          this.handleFieldValueChanges(
                            "health_identifier",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-2 col-form-label text-left">
                    HI Status:
                  </label>
                  <div class="col-sm-5">
                    <div class="input-group">
                      <select
                        name="user"
                        class="form-control"
                        onChange={e =>
                          this.handleFieldValueChanges(
                            "hi_status_1",
                            e.target.value
                          )
                        }
                      >
                        <option value=""> Select </option>
                        {this.state.list_options.hi_status_1_options != null && this.state.list_options.hi_status_1_options.map((item, index) => (
                          <option
                            value={item.value}
                            selected={this.state.hi_status_1 === item.value}
                            key={index}
                          >
                            {item.label}
                          </option>
                        ))}
                      </select>
                      <span class="md-line"></span>
                    </div>
                  </div>
                   <div class="col-sm-4">
                    <div class="input-group">
                      <select
                        name="user"
                        class="form-control"
                        onChange={e =>
                          this.handleFieldValueChanges(
                            "hi_status_2",
                            e.target.value
                          )
                        }
                      >
                        <option value=""> Select </option>
                        {this.state.list_options.hi_status_2_options != null && this.state.list_options.hi_status_2_options.map((item, index) => (
                          <option
                            value={item.value}
                            selected={this.state.hi_status_2 === item.value}
                            key={index}
                          >
                            {item.label}
                          </option>
                        ))}
                      </select>
                      <span class="md-line"></span>
                    </div>
                  </div>  
                
                </div>

                <div class="row">
                <label class="col-sm-2 col-form-label text-left">
                    Medicare No:
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.medicare_no}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "medicare_no",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <label class="col-sm-1 col-form-label text-left">IRN:</label>
                  <div class="col-sm-2">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.medicare_irn}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "medicare_irn",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <label class="col-sm-1 col-form-label text-left">
                    Expiry:
                  </label>
                  <div class="col-sm-2 shrink">
                    <DatePicker
                      selected={this.state.medicare_expiry}
                      onChange={this.handleDate2}
                      dateFormat="MM/yyyy"
                      showMonthDropdown
                      showMonthYearPicker
                      useShortMonthInDropdown
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                    />

                     
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-2 pr-0 pl-0 col-form-label text-right">
                    Pension/HCC No
                  </label>
                  <div class="col-sm-4">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.pension}
                      onChange={e =>
                        this.handleFieldValueChanges("pension", e.target.value)
                      }
                    />
                  </div>
                  <label class="col-sm-1 col-form-label text-left">
                    Expiry:
                  </label>
                  <div class="col-sm-3 shrink">
                    <DatePicker
                      selected={this.state.pension_expiry}
                      onChange={this.handleDate3}
                      dateFormat="MM/yyyy"
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      showMonthYearPicker
                    />
                     
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-2 pr-0 col-form-label text-left">
                    Pension card
                  </label>
                  <div class="col-sm-9">
                  <select
                   value={this.state.pension_card}
                        name="user"
                        class="form-control"
                        onChange={e =>
                          this.handleFieldValueChanges(
                            "pension_card",
                            e.target.value
                          )
                        }
                      >
                        <option value=""> Select </option>
                        {this.state.list_options.pension_card != null && this.state.list_options.pension_card.map((item, index) => (
                          <option
                            value={item.value}
                            selected={this.state.pension_card === item.value}
                            key={index}
                          >
                            {item.label}
                          </option>
                        ))}
                      </select>
                     
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-2 col-form-label text-left">
                    DVA No:
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.dva_no}
                      onChange={e =>
                        this.handleFieldValueChanges("dva_no", e.target.value)
                      }
                    />
                  </div>
                  <div class="col-sm-3">
                    <div class="input-group">
                      <select
                        name="user"
                        class="form-control"
                        onChange={e =>
                          this.handleFieldValueChanges(
                            "dva_no_1",
                            e.target.value
                          )
                        }
                      > 
                      <option value=""> Select </option>
                        {this.state.list_options.dva_no_1_options != null && this.state.list_options.dva_no_1_options.map((item, index) => (
                          <option
                            value={item.value}
                            selected={this.state.dva_no_1 === item.value}
                            key={index}
                          >
                            {item.label}
                          </option>
                        ))}
                      </select>
                      <span class="md-line"></span>
                    </div>
                  </div>
                 
                  <div class="col-sm-3 shrink">
                    <DatePicker
                      selected={this.state.dva_no_2}
                      onChange={this.handleDate4}
                      dateFormat="MM/yyyy"
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      showMonthYearPicker
                    />
                     
                  </div>
                    
                      {/* <select
                        name="user"
                        class="form-control"
                        onChange={e =>
                          this.handleFieldValueChanges(
                            "dva_no_2",
                            e.target.value
                          )
                        }
                      >
                        <option value=""> Select </option>
                        {this.state.list_options.dva_no_2_options != null && this.state.list_options.dva_no_2_options.map((item, index) => (
                          <option
                            value={item.value}
                            selected={this.state.dva_no_2 === item.value}
                            key={index}
                          >
                            {item.label}
                          </option>
                        ))}
                      </select> */}
                      
                     
                   
                </div>

                <div class="row">
                  <label class="col-sm-2 pr-0 col-form-label text-left">
                    Sefety Net No:
                  </label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.sefety_net_no}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "sefety_net_no",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-2 col-form-label text-left">
                    Record No:
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.record_no}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "record_no",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <label class="col-sm-3 col-form-label text-left">
                    Patient ID:
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.patient_id}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "patient_id",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-2 col-form-label text-left">
                    Usual doctor:
                  </label>
                  <div class="col-sm-3">
                    <div class="input-group">
                      <select
                        name="user"
                        class="form-control"
                        onChange={e =>
                          this.handleFieldValueChanges(
                            "usual_doctor",
                            e.target.value
                          )
                        }
                      ><option value=""> Select </option>
                        {this.state.list_options.usual_doctor_options != null && this.state.list_options.usual_doctor_options.map(
                          (item, index) => (
                            <option
                              value={item.value}
                              selected={this.state.usual_doctor === item.value}
                              key={index}
                            >
                              {item.label}
                            </option>
                          )
                        )}
                      </select>
                      <span class="md-line"></span>
                    </div>
                  </div>
                  <label class="col-sm-3 col-form-label text-left">
                    Usual visit type:
                  </label>
                  <div class="col-sm-3">
                    <div class="input-group">
                      <select
                        name="user"
                        class="form-control"
                        onChange={e =>
                          this.handleFieldValueChanges(
                            "usual_visit_type",
                            e.target.value
                          )
                        }
                      > <option value=""> Select </option>
                        {this.state.list_options.usual_visit_type_options != null && this.state.list_options.usual_visit_type_options.map(
                          (item, index) => (
                            <option
                              value={item.value}
                              selected={
                                this.state.usual_visit_type === item.value
                              }
                              key={index}
                            >
                              {item.label}
                            </option>
                          )
                        )}
                      </select>
                      <span class="md-line"></span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-2 pr-0 col-form-label text-left">
                    Usual Account:
                  </label>
                  <div class="col-sm-9">
                    <div class="input-group">
                      <select
                        name="user"
                        class="form-control"
                        onChange={e =>
                          this.handleFieldValueChanges(
                            "usual_account",
                            e.target.value
                          )
                        } 
                      > <option value=""> Select </option>
                        {this.state.list_options.usual_account_options != null && this.state.list_options.usual_account_options.map(
                          (item, index) => (
                            <option
                              value={item.value}
                              selected={this.state.usual_account === item.value}
                              key={index}
                            >
                              {item.label}
                            </option>
                          )
                        )}
                      </select>
                      <span class="md-line"></span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-2 pr-0 pl-0 col-form-label text-right">
                    Health Ins. No:
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.health_ins_no}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "health_ins_no",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <label class="col-sm-3 col-form-label text-right">
                    Religion:
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.religion}
                      onChange={e =>
                        this.handleFieldValueChanges("religion", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-2 pr-0 col-form-label text-left">
                    Head of Family:
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.head_of_family}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "head_of_family",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <label class="col-sm-3 col-form-label text-right">
                    Next of kin:
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.next_to_kin}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "next_to_kin",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-2 pr-0 col-form-label text-left">
                    Emergency Contact:
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.emerygency_contact}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "emerygency_contact",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <label class="col-sm-3 col-form-label text-right">
                    Occuption:
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.occuption}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "occuption",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-3 col-form-label text-left">
                    Healthcare Home:
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.healthcare_home}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "healthcare_home",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>

                <div class="row" style={{marginTop:"10px"}}>
                  <div class="col-sm-12">
                    
                    <button
                      id="session-timeout-dialog-logout"
                      type="button"
                      class="btn btn-primary btn-xs"
                      onClick= {e => this.setState({show_tab: "bank"})}
                      style={{marginRight: '10px'}}
                    >
                      Bank Account
                    </button>
                    <button
                      id="session-timeout-dialog-logout"
                      type="button"
                      class="btn btn-primary btn-xs"
                      onClick= {e => this.setState({show_tab: "referral"})}
                      style={{marginRight: '10px'}}
                    >
                      Referral Details
                    </button>
                    <button
                      id="session-timeout-dialog-logout"
                      type="button"
                      class="btn btn-primary btn-xs"
                      style={{marginRight: '10px'}}
                    >
                      Medicare/DVA Eligibility Check
                    </button>
                  </div>
                </div>
                
                {this.state.show_tab=="bank" && <div>
                <div class="row">
                  <label class="col-sm-2 col-form-label text-left">
                    Bank Name: 
                  </label>
                  <div class="col-sm-3">
                    <select className="form-control" onChange={(e)=>this.setState({bank_name:e.target.value})}> <option value=""> Select </option>
                    {this.state.list_bank_name != null && this.state.list_bank_name.map((item, index) => (
                          <option
                            value={item.bank_id}
                            selected={this.state.bank_name === item.value}
                            key={index}
                          >
                            {item.bank_name}
                          </option>
                        ))}
                    </select>
                    
                  </div>

                  <label class="col-sm-2 pr-0 col-form-label text-left">
                    Account Name: 
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.account_name}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "account_name",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-2 pr-0 col-form-label text-left">
                    Account Number
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.account_number}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "account_number",
                          e.target.value
                        )
                      }
                    />
                  </div>
    
                  <label class="col-sm-2 pr-0 pl-0 col-form-label text-left">
                      BSB Number
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      value={this.state.bsb_number}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "bsb_number",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  </div></div>}
                    

                  {this.state.show_tab=="referral" && <div>

                  <div class="row">
                  <label class="col-sm-3 col-form-label text-left">
                  Referred To
                  </label>
                  <div class="col-sm-3">
                    <select>
                    {this.state.list_options.referral_to != null && this.state.list_options.referral_to.map((item, index) => (
                          <option
                            value={item.referral_to}
                            selected={this.state.referral_to === item.value}
                            key={index}
                          >
                            {item.label}
                          </option>
                        ))}
                    </select>
                    
                  </div>
                </div>

                  <div class="row">
                  <label class="col-sm-3 col-form-label text-left">
                  Referred By
                  </label>
                  <div class="col-sm-3">
                    <select>
                    {this.state.list_options.referral_by != null && this.state.list_options.referral_by.map((item, index) => (
                          <option
                            value={item.referral_by}
                            selected={this.state.referral_by === item.value}
                            key={index}
                          >
                            {item.label}
                          </option>
                        ))}
                    </select>
                    
                  </div>
                </div>
                
                  <div class="row">
                  <label class="col-sm-3 col-form-label text-left">
                  GP / Specialist
                  </label>
                  <div class="col-sm-3">
                    <select>
                    {this.state.list_options.referral_gp_specialist != null && this.state.list_options.referral_gp_specialist.map((item, index) => (
                          <option
                            value={item.referral_gp_specialist}
                            selected={this.state.referral_gp_specialist === item.value}
                            key={index}
                          >
                            {item.label}
                          </option>
                        ))}
                    </select>
                    
                  </div>
                </div>
                
                <div class="row">
                  <label class="col-sm-3 col-form-label text-left">
                    Referral Date: 
                  </label>
                  <div class="col-sm-3" style={{marginTop:"15px"}}>

                  <DatePicker
                      selected={this.state.referral_date}
                      onChange={this.handleDate_referral_date}
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                    />
                    
                  </div>
                </div>
              
                
                <div class="row">
                  <label class="col-sm-3 col-form-label text-left">
                  Referred Type
                  </label>
                  <div class="col-sm-3">
                    <select>
                    {this.state.list_options.referral_type != null && this.state.list_options.referral_type.map((item, index) => (
                          <option
                            value={item.referral_type}
                            selected={this.state.referral_type === item.value}
                            key={index}
                          >
                            {item.label}
                          </option>
                        ))}
                    </select>
                    
                  </div>
                </div>

                
              

                <div class="row">
                  <label class="col-sm-3 col-form-label text-left">
                    Referral Expiry: 
                  </label>
                  <div class="col-sm-3 shrink" style={{marginTop:"15px"}}>

                  <DatePicker
                      selected={this.state.referral_expiry}
                      onChange={this.handleDate_referral_expiry}
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      showMonthYearPicker
                    />
                    
                  </div>
                </div>
                </div>}



                    


                <div class="row">
                  <div class="col-sm-12">
                    <button
                      id="session-timeout-dialog-logout"
                      type="button"
                      class="btn btn-primary btn-xs"
                      disabled={this.state.submitdisbled}
                      onClick={(e) => this.addPatientData(e)}
                    >
                      Submit
                    </button>
                  </div>
                </div>
          
          </div>
        </div>















       </div>
          <div className="modal-footer">
            <div className="col-sm-12">
              <button
                type="button"
                className="btn btn-primary btn-xs"
                data-dismiss="modal"
                aria-label="Close"
                onClick={e => {this.addPatientData(e); this.props.close();}} //this.props.closeServiceNotes()}
              >
                Update
              </button>
              <button
                type="button"
                className="btn btn-primary btn-xs"
                 onClick = {e => this.props.close()}
                 
              >
                Cancel
              </button>
            </div>
          </div>
          </div>
          <ServiceNotes
          open={this.state.servicenotes && this.state.selectedInput != ""}
          type = "Invoice"
          selectedInput={this.state.selectedInput}
          closeServiceNotes={e => this.closeServiceNotes()}
          getServiceNote={this.getServiceNote}
        />
        </div>
      </Popup>
    );
  }
}


const mapDispatchToProps = dispatch => {
  return {
 
  
    listCareplans: data => dispatch(api_list_careplans(data)),
    addCreateAccountItem: data => dispatch(api_add_create_account_item(data)),
    list_options: data => dispatch(api_list_options(data)),
    getSelectedPatientDetails: data => dispatch(api_getSelectedPatientDetails(data)),
    addPatientAction: data => dispatch(addPaitentData(data)),
    getTitle:()=> dispatch(api_getTitle()),
    getBankNames:()=>dispatch(api_banknames()),
    getGender :()=>dispatch(api_getGender()),
    saveRecord:(data)=>dispatch(add_patient_account(data)),
    getBankAccount:(data)=>dispatch(get_patient_account(data))
     
  };
};

const mapStateToProps = state => {
  let selected_appointment =
    state.getDrAppointments.selected_appointment != undefined
      ? state.getDrAppointments.selected_appointment
      : [];
  // let logged_info  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info:[];
  console.log(
    "selected_appointment",
    state.getDrAppointments.getPatientDetailst
  );
  let patientDetails = state.getDrAppointments.getPatientDetails
    ? state.getDrAppointments.getPatientDetails
    : [];
  let selectedPatientDetails;
  if (patientDetails.length > 0) {
    selectedPatientDetails = patientDetails[0];
  } else {
    selectedPatientDetails = "";
  }
  console.log(">>>>>>>>>>>>>>>SELECTED APPOINTMENT", selected_appointment);
  let logged_info =
    state.getDrAppointments.logged_info != undefined
      ? state.getDrAppointments.logged_info
      : "";

  return {
    selectedPatientDetails,
    logged_info,
    selected_appointment,
    logged_info
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(editPatientDetails);
//export default finalisevisit;

 


