import React, { Component } from "react";

import Popup from "reactjs-popup";
import moment from "moment";
import {
  api_list_vaccines,
  api_get_immunisation
} from "./../../../DS/DS.Immunisations";
import {
  api_appointment_types_booking_sources,
  api_getDoctors
} from "./../../../DS/DS.Appointments";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cicon from "./../../../assets/img/cicon.png";
import Showhelp from './../../showHelp';


class addimmunisations extends Component {
  state = {
    mode: this.props.mode,
    immunization_id: "",
    patient_id: this.props.patientId,
    vaccines_master: [],
    diseases_id: "",
    doctors: [],
    billing_providers: [],
    vaccines: "",
    billing_provider: "2",
    given_by: "",
    date: new Date(),
    include_inactive_providers: "",
    site: "",
    sequence: "",
    route: "",
    batch_no: "",
    batch_expiry: new Date(),
    save_batch_details: "",
    comment: "",
    send_reminder: "",
    reminder_date: new Date(),
    order_by: this.props.drId,
    submitdisbled: "disabled",
    selected_immunisation: []
  };
  componentWillMount= ()=>{
  this.setState({billing_provider:this.props.redux_docId,given_by:this.props.redux_docId})
   }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.mode !== this.state.mode) {
     // alert(1);
      
    }
   
  //  alert(this.props.redux_docId)
  }

  focusTextInput_a() {
     document.getElementById("date-jumper_a").focus();
   }

   focusTextInput_b() {
    document.getElementById("date-jumper_b").focus();
  }

  focusTextInput_c() {
    document.getElementById("date-jumper_c").focus();
  }

  componentDidMount() {
    //moment().tz("America/Los_Angeles").format();
    moment().utcOffset("+05:30").format();

    var offset = new Date().getTimezoneOffset();
    console.log("immunisation datezone", offset);


    if (this.props.mode == "add") {
      this.setState({ immunization_id: "" });
      
        this.setState({
          selected_immunisation: [],
          vaccines: "",
          sequence: "",
          batch_no: "",
          site: "",
          route: "",
          comment: "",
          immunization_id: "",
          date: new Date(),
        //  billing_provider: "",
          save_batch_details: "",

          send_reminder: "",
          reminder_date: new Date(),
          batch_expiry: new Date(),
          submitdisbled: "disabled"
        });
      
    } else {
     // alert('edit');
    }
    // alert("addimm"+this.props.selectedRec);
    console.log("addimm", this.props.selectedRec);
    if (this.props.selectedRec != "") {
      //alert("asdf");

      this.props
        .get_immunisation({ immunisation_id: this.props.selectedRec })
        .then(res => {
          console.log("selected immunisation record", res.data.content);
          this.setState({
            selected_immunisation: res.data.content,
            vaccines: res.data.content[0].vaccine_id,
            given_by: res.data.content[0].given_by_guid,
            sequence: res.data.content[0].sequence,
            batch_no: res.data.content[0].batch_no,
            site: res.data.content[0].site,
            route: res.data.content[0].route,
            comment: res.data.content[0].comment,
            immunization_id: res.data.content[0].immunization_id,
            date: new Date(res.data.content[0].date),
            billing_provider: res.data.content[0].billing_provider,
            save_batch_details: res.data.content[0].save_batch_details,

            send_reminder: res.data.content[0].send_reminder,
            reminder_date: new Date(res.data.content[0].reminder_date),
            submitdisbled: ""
          });
          if(res.data.content[0].batch_expiry!='')
          {
            this.setState({
            batch_expiry: new Date(res.data.content[0].batch_expiry)})
          }
          else{
            this.setState({
              batch_expiry: new Date(res.data.content[0].date)})
          }
        });
    }

    this.props.list_vaccines({patient_id: this.state.patient_id}).then(res => {
      this.setState({ vaccines_master: res.data.content });
    });
    this.props.doctors().then(res => {
      this.setState({ doctors: res.data.content });
    });
    this.props.billing_providers_master().then(res => {
      this.setState({ billing_providers: res.data.content.booking_sources });
    });
  }
  componentWillUpdate() {
    //   alert("edit3", this.props.selectedRec);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    // alert("edit2", this.props.selectedRec);
  }

  handleDate1 = date => {
    console.log("DATE IMMUNISATION1", date);
    if (new Date() > new Date(date)) {
    } else {
      alert("Cannot set Future Date!");
      date = new Date();
    }
    console.log("DATE IMMUNISATION2", new Date(date));
    console.log("DATE IMMUNISATION3", moment(date).format("YYYY-MM-DD"));
    //this.setState({ date: new Date(date).toLocaleString("en-US", {timeZone: "Asia/Kolkata"})} );
    this.setState({ date: date} );
    console.log("DATE IMMUNISATION4",this.state);
  };

  handleDate2 = date => {
    this.setState({ reminder_date: date });
  };
  handleDate3 = date => {
    this.setState({ batch_expiry: date });
  };

  handleFieldValueChanges(key, value) {
    this.setState({
      [key]: value
    });
    if(key=='vaccines')
    {
     // alert("IIII")
      this.setState({vaccines:value})
    }
   
    if (this.state.given_by != "" && this.state.vaccines != "") {
      this.setState({submitdisbled: "" });
    }
  }
 
  render() {
    return (
      <Popup
        //trigger={this.props.mode=="add" ?
        //<button id="" type="button" className="btn btn-default" style={{marginRight: '10px', padding: ''}}>Add</button>:
        //<button onClick={this.props.editClick} id="" type="button" className="btn btn-default" style={{marginRight: '10px', padding: ''}}>Edit</button>
        //}

        open={this.props.open}
        position="right center"
        modal
        closeOnDocumentClick={false}
      >
        {this.props.open && (
          <div style={{ width:'800px', marginLeft:'123px', backgroundColor:'#FFF', float:'left'}}>
            <div className="modal-header">
              <div class="popup-title">
                <button
                  type="button"
                  className="close"
                  style={{
                    position: "inherit",
                    right: "10px",
                    fontSize: "25px"
                  }}
                  onClick={() => this.props.close()}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  ×
                </button>
                <span>Immunisations <Showhelp gotohelp="add_immunisation_help"/></span>
              </div>
            </div>

            <div className="modal-body newpostpopup newpostpopup2" style={{height: '450px',overflow:'unset', border:'0px'}}>
              <div
                className="col-sm-12"
                style={{
                  marginTop: "10px",
                  marginBottom: "10px"
                }}
              >
                <div className="row">
                  <label
                    className="col-sm-12 col-form-label text-left">
                    Available vaccines:
                  </label>
                  <div
                    className="col-sm-12"
                    style={{
                      marginTop: "10px"
                    }}
                  >
                    <table
                      className=" table table-bordered"
                      style={{ float: "left", width: "100%" }}
                    >
                      <thead>
                        <tr style={{ backgroundColor: "#f4f4f4", padding: '5px', fontSize:'12px'  }}>
                          <th>Vaccines</th>
                          <th>Against</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.vaccines_master.map(v => (
                          <tr>
                            <td>
                              <label>
                                <input
                                  type="radio"
                                  name="vaccine"
                                  disabled={
                                    this.state.immunization_id != "" &&
                                    this.props.mode != "add"
                                      ? true
                                      : false
                                  }
                                  checked={this.state.vaccines == v.vaccine_id}
                                  onChange={e => {
                                    this.handleFieldValueChanges(
                                      "vaccines",
                                      v.vaccine_id
                                    );
                                    this.handleFieldValueChanges(
                                      "diseases_id",
                                      v.against
                                    );
                                  }}
                                />
                                &nbsp;{v.name}
                                {/* {this.state.vaccines+' '+v.vaccine_id} */}
                              </label>
                            </td>
                            <td>&nbsp;{v.dname}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row row-distance">
                  <label className="col-sm-2 cus-wid pr-0">
                    Billing Provider:
                  </label>
                  <div className="col-sm-6 pl-0 pr-0">
                    <select
                      className="form-control mb-0"
                      style={{ width: "100%", float: "left" }}
                      value={this.state.billing_provider}
                      onChange={e =>
                        this.handleFieldValueChanges(
                          "billing_provider",
                          e.target.value
                        )
                       
                      }
                      
                      
                    >
                      {this.state.doctors.map((d, index) => (
                        <option value={d.doctor_id}
                        key={index}> Dr. {d.Firstname} </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-4 pr-0">
                  <label class="radio-inline pl-0 mt-3"><input
                      type="checkbox"
                      checked={this.state.include_inactive_providers == 1?true:false}

                      onChange={e =>
                        this.handleFieldValueChanges(
                          "include_inactive_providers",
                          e.target.checked?1:0
                        )
                      }
                    />

                      {" "}
                      Include inactive providers
                    </label>
                  </div>
                </div>
                <div className="row row-distance">
                  <label className="col-sm-2 cus-wid pr-0">
                    Given by:
                  </label>
                  <div className="col-sm-6 pl-0 pr-0">
                    <select
                      className="form-control mb-0"
                      value={this.state.given_by}
                      style={{ width: "100%", float: "left" }}
                      onChange={e =>
                        this.handleFieldValueChanges("given_by", e.target.value)
                      }
                    >
                   
                      {this.state.doctors.map(d => (
                        <option value={d.doctor_id} > Dr. {d.Firstname} </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row row-distance">
                  <label className="col-sm-2 mt-4 cus-wid pr-0">
                    Date:
                  </label>
                  <div className="col-sm-3 pl-0 pr-0">
                  <label
                      className="label-input100"
                      style={{ marginRight: "0px", marginBottom: "0px", fontSize: "12px", paddingBottom: "0px", right:"-25px", zIndex:"1", paddingTop: "0px", position: "absolute", marginLeft: "-30px" 
                      }}
                    >
                      <a onClick={this.focusTextInput_a} href="#" style={{}}>
                        {<img src={cicon} />}
                      </a>
                    </label>
                    <DatePicker
                      //ref={this.textInput}
                      id="date-jumper_a"
                       defaultValue={new Date()}   
                      selected={this.state.date}
                      onChange={this.handleDate1}
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      dateFormat="dd/MM/yyyy"
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      className="form-control popdate"
                    />
                     

                  </div>
                  <label className="col-sm-1">
                    Site:
                  </label>
                  <div className="col-sm-2 pl-0 pr-0" style={{marginLeft: '-20px'}}>
                    <select
                      className="form-control mb-0"
                      value={this.state.site}
                      onChange={e =>
                        this.handleFieldValueChanges("site", e.target.value)
                      }
                    >
                      <option>Select One</option>
                      <option value="Left Deltoid"> Left Deltoid </option>
                      <option value="Right Deltoid"> Right Deltoid </option>
                      <option value="Right Deltoid"> Right Deltoid </option>
                      <option value="Right Thigh"> Right Thigh </option>
                      <option value="Left Gluteus"> Left Gluteus </option>
                      <option value="Right Gluteus"> Right Gluteus </option>
                      <option value="Left Forearm"> Left Forearm </option>
                      <option value="Right Forearm"> Right Forearm </option>
                    </select>
                  </div>
                  <label className="col-sm-1">
                    Sequence:
                  </label>
                  <div className="col-sm-2 pr-0">
                    <input
                      type="text"
                      name="search_condition"
                      value={this.state.sequence}
                      className="form-control form-control-sm mb-0 rq-form-elemen"
                      onChange={e =>
                        this.handleFieldValueChanges("sequence", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="row row-distance">
                  <label className="col-sm-2 cus-wid pr-0">
                    Route:
                  </label>
                  <div className="col-sm-6 pl-0 pr-0">
                      <label className="radio-inline"
                        for="imi"
                        onClick={e =>
                          this.handleFieldValueChanges("route", "imi")
                        }
                      >
                        

                    <input
                      style={{ float: "left", marginRight:'5px', marginTop:'1px' }}
                      id="imi"
                      type="radio"
                      name="smoking"
                      value="imi"
                      checked={this.state.route == "imi" ? true : false}
                    />


                        {" "}
                        IMI{" "}
                      </label>
                  
                      <label className="radio-inline"
                        for="sc"
                        onClick={e =>
                          this.handleFieldValueChanges("route", "sc")
                        }
                      >
                          <input
                      style={{ float: "left", marginRight:'5px', marginTop:'1px' }}
                      id="sc"
                      type="radio"
                      name="smoking"
                      value="Non-smoker"
                      checked={this.state.route == "sc"}
                    />
                        {" "}
                        SC{" "}
                      </label>
                    
                      <label className="radio-inline"
                        for="Orl"
                        onClick={e =>
                          this.handleFieldValueChanges("route", "orl")
                        }
                      >
                        {" "}
                          <input
                      style={{ float: "left", marginRight:'5px', marginTop:'1px' }}
                      id="Orl"
                      // onChange={e =>
                      //   this.handleFieldValueChanges("route", "orl")
                      // }
                      checked={this.state.route == "orl" ? true : false}
                      type="radio"
                      name="smoking"
                      value="Non-smoker"
                    />
                        {" "}
                        Orl{" "}
                      </label>
                   
                      <label className="radio-inline"
                        onClick={e =>
                          this.handleFieldValueChanges("route", "Intradermal")
                        }
                        for="Intradermal"
                      >
                         <input
                      style={{ float: "left", marginRight:'5px', marginTop:'1px' }}
                      id="Intradermal"
                      type="radio"
                      name="smoking"
                      checked={this.state.route == "Intradermal"}

                      // onChange={e =>
                      //   this.handleFieldValueChanges("route", "Intradermal")
                      // }
                    />
                        {" "}
                        Intradermal{" "}
                      </label>
             
                  </div>
                </div>
                <div className="row row-distance">
                  <label className="col-sm-2 mt-4 cus-wid pr-0">
                    Batch No:
                  </label>
                  <div className="col-sm-3 pl-0 mt-2 pr-0">
                    <select
                      className="form-control mb-0"
                      onChange={e =>
                        this.handleFieldValueChanges("batch_no", e.target.value)
                      }
                    >
                      <option>Select One</option>
                      <option value="1">Need Clarification Data 1</option>
                      <option value="2">Need Clarification Data 2</option>
                    </select>
                  </div>
                  <label className="col-sm-1 mt-4 col-sm-01 pr-0 ">
                    Batch expiry: 
                  </label>
                  <div className="col-sm-3 pl-0 pr-0">
                  <label
                      className="label-input100"
                      style={{ marginRight: "0px", marginBottom: "0px", fontSize: "12px", paddingBottom: "0px", right:"-25px", zIndex:"1", paddingTop: "0px", position: "absolute", marginLeft: "-30px"
                      }}
                    >
                      <a onClick={this.focusTextInput_b} href="#">
                        {<img src={cicon} />}
                      </a>
                    </label>
                    <DatePicker
                      //ref={this.textInput}
                      dateFormat="dd/MM/yyyy"
                      id="date-jumper_b"
                      selected={this.state.batch_expiry}
                      onChange={this.handleDate3}
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      className="form-control"
                      style={{padding:'0px 0px 0px 5px', marginTop:"10px!important"}}
                    />
                    
                  </div>

                  <div className="col-sm-3 pr-0">
                  <label class="radio-inline pl-0"> <input
                      checked={
                        this.state.save_batch_details == 1 ? true : false
                      }
                      onChange={e =>
                        this.handleFieldValueChanges("save_batch_details", e.target.checked?1:0)
                      }
                      type="checkbox"
                      defaultValue
                    />
                    Save batch details
                      </label>
                  </div>
                  </div>

                  <div className="row row-distance">
                  <label className="col-sm-2 cus-wid pr-0">
                    Comment:
                  </label>
                  <div
                    className="col-sm-10 pr-0"
                    style={{
                      paddingLeft: "0px",
                      marginLeft: "0px",
                      marginTop: "0px"
                    }}
                  >
                    <textarea
                      value={this.state.comment}
                      onChange={e =>
                        this.handleFieldValueChanges("comment", e.target.value)
                      }
                      className="immuntcomment mb-0"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2 pl-0   pr-0">
                        <label for="send_reminder" className="radio-inline"> 
                      
                      <input style={{marginTop:'0px'}}
                      id="send_reminder"
                      type="checkbox"
                      checked={this.state.send_reminder == "1" ? true : false}
                      defaultValue
                      onChange={e =>
                        this.handleFieldValueChanges("send_reminder", e.target.checked?1:0)
                      }
                    />
                    {" "}
                      Send reminder </label>
              
                  </div>
                  {this.state.send_reminder == "1" ? 
                  <span className="col-sm-2 cus-wid pr-0"> 
                    Reminder Date:
                  </span>
                  : ''}
                  {this.state.send_reminder == "1" ? 
                  <div className="col-sm-3 pr-0">
                  
                    <DatePicker
                   id="date-jumper_c"
                      //ref={this.textInput}
                      dateFormat="dd/MM/yyyy"
                      selected={this.state.reminder_date}
                      onChange={this.handleDate2}
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      className="form-control"
                    />
                        <label
                      className="label-input100"
                      style={{
                        marginRight: "0px",
                        marginBottom: "0px",
                        fontSize: "12px",
                        paddingBottom: "0px",
                        paddingTop: "0px"
                      }}
                    >
                      <a onClick={this.focusTextInput_c} href="#">
                        {<img src={cicon} />}
                      </a>
                    </label>
                  </div>
                  : ''}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                disabled={this.state.submitdisbled}
                type="button"
                className="btn btn-primary btn-xs m-0"
                onClick={e => {
                  this.props.addimmunisations1(this.state);
                  this.props.close();
                }}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-primary btn-xs m-0 ml-2"
                data-dismiss="modal"
                onClick={() => this.props.close()}
                data-dismiss="modal"
                aria-label="Close"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Popup>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    list_vaccines: data => dispatch(api_list_vaccines(data)),
    billing_providers_master: data =>
      dispatch(api_appointment_types_booking_sources(data)),
    get_immunisation: data => dispatch(api_get_immunisation(data)),
    doctors: data => dispatch(api_getDoctors(data))
  };
};
const mapStateToProps = state => {
  console.log(">>> doc data",state.getDrAppointments.logged_info)
    let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
    let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';

  return {
    redux_patientId,redux_docId
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(addimmunisations);
