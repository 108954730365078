import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import axios from 'axios';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import { connect } from "react-redux";
import {
  api_appointment_types_booking_sources,
  api_getDoctors
} from "./../../../DS/DS.Appointments";
import {
  api_add_asthama,api_fetch_asthma
} from './../../../DS/DS.EPC';
import Showhelp from './../../showHelp';

class createasthma extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patient_id: this.props.redux_patientId,
      severity:0,
      devices:0,
      action:0,
      education:0,
      written:0,
      open:false
      }
    }

    handleFieldValueChanges(key, value) {
      this.setState({
        [key]: value
      });
    }

    saveAsthma(){
        this.props.addAshtama(this.state).then(
          req=>{
                  this.props.changeKey()
                 NotificationManager.success('Record Created',)
              }
      );
    
    }  
    componentWillMount=()=>
    {
      this.setState({open:this.props.showform})
      this.props.fetchAshtama({patient_id:this.props.redux_patientId}).then(res=>
        {
          if(res.data.content!='')
          {
            this.setState({
              severity:res.data.content[0].severity,
              devices:res.data.content[0].devices,
              action:res.data.content[0].action,
              education:res.data.content[0].education,
              written:res.data.content[0].written,
              })
          }
        })
    }
    render() {
      return (
        <Popup
        open={this.state.open}
        closeOnDocumentClick={false}
         position="right center"   modal
       >
         {close => (
           <div style={{ width:'800px', marginLeft:'250px', backgroundColor:'#FFF', float:'left'}}>
             <div className="modal-header">
                 <button type="button" className="close"  onClick={() =>this.props.changeKey()} data-dismiss="modal" aria-label="Close">
                   <span aria-hidden="true">×</span>
                 </button>
                 <span>Asthma <Showhelp gotohelp="create_asthma_help"/></span>
               </div>
               <div className="modal-body" style={{border:'0px'}}>
                  <div className="col-sm-12 pl-0" style={{marginBottom: '10px', textAlign: 'left'}}>
                    <div className="row mt-3">
                      <div className="col-sm-12">
                        <div className="col-sm-8">
                          <label className="col-form-label text-left">Documented diagnosis and assessment of level of asthma control and severity of asthma :</label>
                        </div>
                        <div className="col-sm-2">
                          <label>
                              <input type="radio" name="severity" id="severity" value="1" onChange={e =>this.handleFieldValueChanges("severity",1)}
                                checked={this.state.severity == 1}  />&nbsp;Yes
                          </label>                
                        </div>
                        <div className="col-sm-2">
                          <label>
                               <input type="radio" name="severity" id="severity" value="0" onChange={e =>this.handleFieldValueChanges("severity",0)}
                                checked={this.state.severity == 0} />&nbsp;No
                          </label>                
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="col-sm-8">
                          <label className="col-form-label text-left">Review of the patient's use of and access to asthma related medication and devices :</label>
                        </div>
                        <div className="col-sm-2">
                          <label>
                            <input type="radio" name="devices" id="devices" value="1" onChange={e =>this.handleFieldValueChanges("devices",1)}
                              checked={this.state.devices == 1}  />&nbsp;Yes
                          </label>
                        </div>
                        <div className="col-sm-2">
                          <label>
                            <input type="radio" name="devices" id="devices" value="0" onChange={e => this.handleFieldValueChanges( "devices", 0)}
                              checked={this.state.devices == 0} />&nbsp;No
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="col-sm-8">
                          <label className="col-form-label text-left">Asthma action plan :</label>
                        </div>
                        <div className="col-sm-2">
                          <label>
                            <input type="radio" name="action" id="action" value="1"  onChange={e =>this.handleFieldValueChanges("action",1 ) }
                              checked={this.state.action == 1}  />&nbsp;Yes
                          </label>
                        </div>
                        <div className="col-sm-2">
                          <label>
                            <input type="radio" name="action" id="action" value="0"  onChange={e =>this.handleFieldValueChanges("action",0)}
                                checked={this.state.action == 0} />&nbsp;No
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="col-sm-8">
                          <label className="col-form-label text-left">Provision of asthma self-management education to the patient :</label>
                        </div>
                        <div className="col-sm-2">
                          <label>
                            <input type="radio" name="education" id="education" value="1" onChange={e =>this.handleFieldValueChanges("education",1)}
                              checked={this.state.education == 1}  />&nbsp;Yes
                          </label>
                        </div>
                        <div className="col-sm-2">
                          <label>
                            <input type="radio" name="education" id="education" value="0" onChange={e =>this.handleFieldValueChanges("education",0)}
                              checked={this.state.education == 0} />&nbsp;No
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="col-sm-8">
                          <label className="col-form-label text-left">Review of the written or documented asthma action plan :</label>
                        </div>
                        <div className="col-sm-2">
                          <label>
                            <input type="radio" name="written" id="written" value="1"  onChange={e =>this.handleFieldValueChanges( "written",1)}
                              checked={this.state.written == 1}  />&nbsp;Yes
                          </label>
                        </div>
                        <div className="col-sm-2">
                          <label>
                            <input type="radio" name="written" id="written" value="0" onChange={e =>this.handleFieldValueChanges("written",0)}
                              checked={this.state.written == 0} />&nbsp;No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary btn-xs m-0" onClick={e => this.saveAsthma("add")}>Save changes</button>
                  <button type="button" className="btn btn-primary btn-xs m-0 ml-2" onClick={() => this.props.changeKey()} data-dismiss="modal" aria-label="Close">Close</button>
                </div>
              </div>
            )}
        </Popup>
      )}
    }

    const mapDispatchToProps = dispatch => {
      return {
        addAshtama: data => dispatch(api_add_asthama(data)),
        fetchAshtama: data => dispatch(api_fetch_asthma(data)),


        
       };
    };
    
    const mapStateToProps = state => {
    
        let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
    
      return {
        redux_patientId
      };
    };
    export default connect(
        mapStateToProps,
      mapDispatchToProps
    )(createasthma);