import React, { Component } from 'react';
import logo from 'assets/img/supadoc-logo-l1.png';
class AdminHeader extends Component {
    state = {  }
    render() {
        return (
            <header className="main-header">123
  {/* Logo */}
{/* Logo */}
  <a href="#" className="logo">
    {/* mini logo for sidebar mini 50x50 pixels */}
    <span className="logo-mini">
      <img src={logo} />
    </span>
    {/* logo for regular state and mobile devices */}
    <span className="logo-lg">
      <img src={logo} />
    </span>
  </a>
  {/* Header Navbar: style can be found in header.less */}
  <nav className="navbar navbar-static-top">
    {/* Sidebar toggle button*/}
    <a
      href="#"
      className="sidebar-toggle"
      data-toggle="push-menu"
      role="button"
    >
      <span className="sr-only">Toggle navigation</span>
    </a>
    <div className="navbar-custom-menu">
      <ul className="nav navbar-nav">
        <li>
          <a href="#" data-toggle="control-sidebar">
            <i className="fa fa-gears" />
          </a>
        </li>
      </ul>
    </div>
  </nav>
</header>
         );
    }
}

export default AdminHeader;
