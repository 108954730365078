import React, { Component } from "react";

import Popup from "reactjs-popup";
import "./addinvestigation.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { connect } from "react-redux";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import {
  api_list_options,
  api_loadSelectedInvestigation
} from "./../../../DS/DS.Investigation";
import { wflow } from "./../../../DS/general";
import cicon from "./../../../assets/img/cicon.png";
import Showhelp from './../../showHelp';

class Addinvestigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedData: [],
      data: {
        patientId: props.patientId,
        patients_report_id: "",
        provider: "",
        test_name: "",
        notation: "",
        checked_by: "",
        date_of_test: new Date(),
        condidential: false,
        action_to_be_taken: "",
        date_checked: new Date(),
        has_been_actioned: 0,
        comments: "",
        list_options: {}
      },
      saving: false
    };
  }
  options = {
    provider_options: [
      {
        value: "",
        label: "Select Provider"
      },
      {
        value: "1",
        label: "Provider 1"
      },
      {
        value: "2",
        label: "Provider 2"
      }
    ],
    notation_options: [
      {
        value: "",
        label: "Select Provider"
      },
      {
        value: "1",
        label: "Provider 1"
      },
      {
        value: "2",
        label: "Provider 2"
      }
    ],
    checked_by_options: [
      {
        value: "",
        label: "Select Provider"
      },
      {
        value: "1",
        label: "Provider 1"
      },
      {
        value: "2",
        label: "Provider 2"
      }
    ],
    action_to_be_taken_options: [
      {
        value: "",
        label: "Select Provider"
      },
      {
        value: "1",
        label: "Provider 1"
      },
      {
        value: "2",
        label: "Provider 2"
      }
    ]
  };

  focusTextInput_imm1(e) {
    e.preventDefault();
    document.getElementById("date-jumper_imm1").focus();
  }
  focusTextInput_imm2(e) {
    e.preventDefault();
    document.getElementById("date-jumper_imm2").focus();
  }


  handleFieldChange = (key, value) => {
    console.log(key, value);

    // let data = this.state.data;
    // data.eval(key) = value;
    // this.setState({data});
    this.setState({ data: { ...this.state.data, [key]: value } });
    /*
    this.setState({
      data: {
        [key]: value,
      },
    });
    */
  };

  handleSave = () => {
    console.log("add investigation state", this.state);

let canProceed = true;
let errorMessage = "";
if(this.state.data.date_of_test==""){
  canProceed = false;
  errorMessage += " Date of Test, "; 
}
if(this.state.data.test_name==undefined || this.state.data.test_name==""){
  canProceed = false;
  errorMessage += " Test Name,"; 
}

if(this.state.data.action_to_be_taken==""){
  canProceed = false;
  errorMessage += " Action to be Taken,"; 
}
if(this.state.data.checked_by==""){
  canProceed = false;
  errorMessage += " Checked By, "; 
}




   if(canProceed){
   //alert(this.state.test_name);
    this.props.addInvestigations(this.state).then(()=>{this.props.loadinvestigations();/*this.props.changeKey();*/this.props.close();NotificationManager.success("Data Added!");});
    
    
    
    
  } else {
    alert("Following required fields are missing: "+ errorMessage);
  }
  };

  componentDidMount() {
    this.props.list_options().then(res => {
      //   console.log("addPatientForm addPatientForm",res);
      this.setState({ list_options: res.data.content });
      console.log(this.state.list_options);
    });
  }
  componentWillReceiveProps(nextProps) {

    if (Object.keys(nextProps).length !== 0) {
 
      console.log("nextprops", nextProps);

      if (nextProps.editData != undefined) {
        this.props
          .loadSelectedInvestigation({ id: nextProps.editData[0] })
          .then(res => {
           
            let arr = [];
            arr.push(res.data.content[0]);

            //this.setState({ selectedData: res.data.content[0] })

            if (arr[0] != undefined) {
              this.setState({
                data: { ...this.state.data, patients_report_id: arr[0].patients_report_id }
              });
              this.setState({
                data: { ...this.state.data, provider: arr[0].provider }
              });
              this.setState({
                data: { ...this.state.data, test_name: arr[0].test_name }
              });
              this.setState({
                data: { ...this.state.data, checked_by: arr[0].checked_by_guid }
              });
              this.setState({
                data: { ...this.state.data, date_checked: arr[0].date_checked!="0000-00-00"?new Date(arr[0].date_checked):new Date() }
              });
              this.setState({
                data: { ...this.state.data, notation: arr[0].notation }
              });
              this.setState({
                data: { ...this.state.data, action_to_be_taken: arr[0].action_to_be_taken }
              });
              this.setState({
                data: { ...this.state.data, comments: arr[0].comments }
              });
                this.setState({
                  data: { ...this.state.data, date_of_test: new Date(arr[0].date) }
                });
            }
          })
          .then(() => {
            console.log("before setting", this.state);
            console.log(this.state.selectedData);

            // this.setState({
            //           data: { ...this.state.data, provider: this.state.selectedData.provider },
            //           data: { ...this.state.data, test_name: this.state.selectedData.test_name},
            //           data: { ...this.state.data, checked_by: this.state.selectedData.checked_by_guid },
            //           data: { ...this.state.data, date_of_test: this.state.selectedData.date },
            //           data: { ...this.state.data, condidential: this.state.selectedData.confindential } ,
            //           data: { ...this.state.data, date_checked: this.state.selectedData.given_time },
            //           data: { ...this.state.data, has_been_actioned: this.state.selectedData.action_to_be_taken },
            //           data: { ...this.state.data, comments: this.state.selectedData.comments }
            //         })
          })
          .then(() => {
            console.log("after", this.state);
          });
        // .then(res => { this.setState({ data: { ...this.state.data, provider: res.data.content[0].provider }})      })
        // .then(res => { this.setState({ data: { ...this.state.data, test_name: res.data.content[0].test_name }})      })
        // .then(res => { this.setState({ data: { ...this.state.data, checked_by: res.data.content[0].checked_by_guid }})      })
        // .then(res => { this.setState({ data: { ...this.state.data, date_of_test: res.data.content[0].date }})      })

        //let result = res.data.content[0];
        // this.setState({
        //   data: { ...this.state.data, provider: res.data.content[0].provider },
        //   data: { ...this.state.data, test_name: res.data.content[0].test_name},
        //   data: { ...this.state.data, checked_by: res.data.content[0].checked_by_guid },
        //   data: { ...this.state.data, date_of_test: res.data.content[0].date },
        //   data: { ...this.state.data, condidential: res.data.content[0].confindential } ,
        //   data: { ...this.state.data, date_checked: res.data.content[0].given_time },
        //   data: { ...this.state.data, has_been_actioned: res.data.content[0].action_to_be_taken },
        //   data: { ...this.state.data, comments: res.data.content[0].comments }
        // })
        // this.setState({ data: { ...this.state.data, test_name: result.test_name } });
        //  this.setState({ data: { ...this.state.data, checked_by: result.checked_by_guid } });
        //  this.setState({ data: { ...this.state.data, date_of_test: result.date } });
        //  this.setState({ data: { ...this.state.data, condidential: result.confindential } });
        //  this.setState({ data: { ...this.state.data, date_checked: result.given_time } });
        //  this.setState({ data: { ...this.state.data, has_been_actioned: result.action_to_be_taken } });
        //  this.setState({ data: { ...this.state.data, comments: result.comments } });

        //  }
        //);

        let editData = nextProps.editData;
        this.setState({
          patients_report_id: editData.patients_report_id
        });
      }
    }
  }

  render() {
    return (
      <Popup
        open={this.props.open}
        // trigger={
        //   <button
        //     id="session-timeout-dialog-logout"
        //     data-toggle="modal"
        //     data-target="#pastmedical"
        //     type="button"
        //     className="btn btn-default  past-history-action-button"
        //   >
        //     Add
        //   </button>
        // }
        modal
        closeOnDocumentClick={false}
      >
        <div style={{ width:'700px', marginLeft:'275px', backgroundColor:'#FFF', float:'left'}}>
        <div className="modal-header">
              <div class="popup-title">
                <button
                  type="button"
                  className="close"
                  style={{
                    position: "absolute",
                    right: "10px",
                    fontSize: "25px"
                  }}
                  onClick={() => this.props.close()}
                  data-dismiss="modal"
                  aria-label="Close"
                  style={{
                    position: "inherit",
                    right: "10px",
                    fontSize: "25px"
                  }}
                >
                  ×
                </button>
                <span>Investigations Report  <Showhelp gotohelp="add_investigation_help"/></span>
              </div>
            </div>

          <div>
            <div className="modal-body addinvestigation">
              <div className="col-sm-12">
                  <div className="row">
                    <label className="col-sm-2 mt-3 pr-0">
                      Provider
                    </label>
                    <div className="col-sm-4 pr-0">
                      <select
                        className="form-control"
                        value={this.state.data.provider}
                        onChange={e =>
                          this.handleFieldChange("provider", e.target.value)
                        }
                      >
                        <option value=""> Select Provider </option>
                        {this.state.list_options != undefined &&
                          this.state.list_options.provider_options != null &&
                          this.state.list_options.provider_options.map(
                            (item, index) => (
                              //      (item, index) => (
                              <option
                                value={item.value}
                                selected={
                                  this.state.data.provider === item.value
                                }
                                key={index}
                              >
                                Dr.{item.label}
                              </option>
                            )
                          )}
                      </select>
                    </div>

                    <label className="col-sm-2 mt-3 pr-0">
                      Date of Test
                    </label>
                    <div className="col-sm-3 pr-0">
                      {/* <DatePicker
                        className="datePicker form-control"
                        selected={this.state.data.date_of_test}
                        onChange={value =>
                          this.handleFieldChange("date_of_test", value)
                        }
                      /> */}

                <DatePicker
                   id="date-jumper_imm1"
                      //ref={this.textInput}
                      dateFormat="dd/MM/yyyy"
                      selected={this.state.data.date_of_test}
                        onChange={value =>
                          this.handleFieldChange("date_of_test", value)
                        }
                      
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      className="form-control"
                      style={{ marginTop:'20px', width:'100%'}}
                    />
                        <label
                      className="label-input100"
                      style={{
                        marginRight: "0px",
                        marginBottom: "0px",
                        fontSize: "12px",
                        paddingBottom: "0px",
                        paddingTop: "0px"
                      }}
                    >
                      <a onClick={e => this.focusTextInput_imm1(e)} href="#" style={{float: 'right', position: 'absolute', right: '5px', top:'15px'}}>
                        {<img src={cicon} />}
                      </a>
                    </label>


                    </div>

                  </div>
                  <div className="row">
                    <label className="col-sm-2 mt-2 pr-0">
                      Test Name
                    </label>
                    <div className="col-sm-4 mt-0 pr-0">
                      <input
                        className="form-control mt-0"
                        value={this.state.data.test_name}
                        onChange={e =>
                          this.handleFieldChange("test_name", e.target.value)
                        }
                        value={this.state.data.test_name}
                      />
                    </div>

                    <label
                      className="col-sm-2 mt-2"
                      for="confidential"
                    >
                      Confidential
                    </label>
                    <div className="col-sm-2  mt-1 cursor">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="confidential"
                        checked={this.state.data.condidential==1?true:false}
                        onChange={e =>
                          this.handleFieldChange(
                            "condidential",
                            e.target.checked?1:0
                          )
                        }
                      />
                    </div>

                  </div>
                  <div className="row">
                    <label className="col-sm-2 mt-3 pr-0">
                      Notation
                    </label>
                    <div className="col-sm-4 pr-0">
                      <select
                        className="form-control"
                        value={this.state.data.notation}
                        onChange={e =>
                          this.handleFieldChange("notation", e.target.value)
                        }
                      >
                        {this.state.list_options != undefined &&
                          this.state.list_options.notation_options != null &&
                          this.state.list_options.notation_options.map(
                            (item, index) => (
                              //    {this.options.notation_options.map(
                              //      (item, index) => (
                              <option
                                value={item.value}
                                selected={
                                  this.state.data.notation === item.value
                                }
                                key={index}
                              >
                                {item.label}
                              </option>
                            )
                          )}
                      </select>
                    </div>
                    <label className="col-sm-2 mt-2 pr-0">
                      Action to be Taken
                    </label>
                    <div className="col-sm-3 pr-0">
                      <select
                        value={this.state.data.action_to_be_taken}
                        className="form-control mt-0"
                        onChange={e =>
                          this.handleFieldChange(
                            "action_to_be_taken",
                            e.target.value
                          )
                        }
                      >
                        <option value=""> Select Action </option>
                        {this.state.list_options != undefined &&
                          this.state.list_options.action_to_be_taken_options !=
                            null &&
                          this.state.list_options.action_to_be_taken_options.map(
                            (item, index) => (
                              // {this.options.action_to_be_taken_options.map(
                              //   (item, index) => (
                              <option
                                value={item.value}
                                selected={
                                  this.state.data.action_to_be_taken ===
                                  item.label
                                }
                                key={index}
                              >
                                {item.label}
                              </option>
                            )
                          )}
                      </select>
                    </div>

                  </div>
                  <div className="row">
                    <label className="col-sm-2 mt-2 pr-0">
                      Checked By
                    </label>
                    <div className="col-sm-4 pr-0" style={{marginTop:'-15px'}}>
                      <select
                        value={this.state.data.checked_by}
                        className="form-control"
                        onChange={e =>
                          this.handleFieldChange("checked_by", e.target.value)
                        }
                      >
                        <option value=""> Select Doctor </option>
                        {this.state.list_options != undefined &&
                          this.state.list_options.checked_by_options != null &&
                          this.state.list_options.checked_by_options.map(
                            (item, index) => (
                              <option
                                value={item.value}
                                selected={
                                  this.state.data.checked_by === item.value
                                }
                                key={index}
                              >
                                 Dr. {item.label}
                              </option>
                            )
                          )}
                      </select>
                    </div>
                    <label className="col-sm-2 mt-3">
                      Date checked
                    </label>
                    <div className="col-sm-3 pr-0">
                      <DatePicker  style={{width:'100%'}}
                        className="datePicker form-control"
                        selected={this.state.data.date_checked}
                        // selected={Date.parse(this.state.startDate)}
                        onChange={value =>
                          this.handleFieldChange("date_checked", value)
                        }



                        id="date-jumper_imm2"
                        
                        showMonthDropdown
                        useShortMonthInDropdown
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        className="form-control"
                      />


<label
                      className="label-input100"
                      style={{
                        marginRight: "0px",
                        marginBottom: "0px",
                        fontSize: "12px",
                        paddingBottom: "0px",
                        paddingTop: "0px"
                      }}
                    >
                      <a onClick={e => this.focusTextInput_imm2(e)} href="#" style={{float: 'right', position: 'absolute', right: '5px', top:'15px'}}>
                        {<img src={cicon} />}
                      </a>
                    </label>

                    </div>
                  </div>

              </div>
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-12 cursor">
                    <input style={{ marginLeft:''}}
                      type="checkbox"  style={{ marginTop:'0px!important'}}

 

                      checked={this.state.data.has_been_actioned==1?true:false}
                      onChange={e =>
                        this.handleFieldChange("has_been_actioned", e.target.checked==true?1:0)
                      }
                    />
                    <label className="col-sm-2 pl-0 pr-0 col-form-label" style={{marginTop:'0px'}}>
                      Has been actioned
                    </label>
                  </div>
                </div>

                <div className="form-group row">
                  <textarea
                    value={this.state.data.comments}
                    onChange={e =>
                      this.handleFieldChange("comments", e.target.value)
                    }
                    style={{ width: "100%", height: "150px" }}
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                className="btn btn-primary btn-xs m-0"
                onClick={() => {
                  this.handleSave();
                }}
              >
                Save
              </button>

              <button
                type="button"
                className="btn btn-primary btn-xs m-0 ml-2"
                data-dismiss="modal"
                onClick={() => this.props.close()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Popup>
    );
  }
}

//export default AddPatientForm;
const mapDispatchToProps = dispatch => {
  return {
    list_options: data => dispatch(api_list_options(data)),
    loadSelectedInvestigation: data =>
      dispatch(api_loadSelectedInvestigation(data))
  };
};

export default connect(null, mapDispatchToProps)(Addinvestigation);

//export default AddPatientHistory;
