import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux';
import { NotificationContainer, NotificationManager} from 'react-notifications';
import MetricConversion  from './metricConversion'
import Showhelp from './../../showHelp';

class AddBabyDetails extends Component {
    constructor(props){
        super(props);
        this.state = {
            open: false,
            metric:false,
            weight:'',
            gender:0,
            babyname:'',
            apgar1:'',
            apgar5:''
           

           
        }
    }
    componentDidMount(){
        this.setState({open:this.props.showmodal})
     //   alert(this.props.add);
        if(this.props.add=='')
        {
            console.log('>>>>>>>>>',this.props.sendData)
            this.setState({
                weight:this.props.sendData.birth_weight,
                gender:this.props.sendData.gender,
                babyname:this.props.sendData.baby_name,
                apgar1:this.props.sendData.apgar1,
                apgar5:this.props.sendData.apgar5
            })
        }
        
    }
    weightValue=(val)=>
    {
        this.setState({metric:false})
        this.setState({weight:val})
    }
    checkIfValid=()=>
    {
        if(this.state.babyname!='')
        {
            const babyVlaues={
                weight:this.state.weight,
                gender:this.state.gender,
                babyname:this.state.babyname,
                apgar1:this.state.apgar1,
                apgar5:this.state.apgar5
            }
            this.props.changeKey(babyVlaues);
        }
        else
        {
            alert('You must enter baby name')
        }
    }
    render() {
        return ( 
        <Popup 
        position="right center"   modal
        closeOnDocumentClick={false}
        open={this.state.open}
        >
         {close => (
                <div style={{ width:'530px', margin:'0px auto'}} >
                <div style={{ width:'530px', marginLeft:'60px', backgroundColor:'#FFF', float:'left'}}> 
                  <div className="modal-header" style={{width:'auto', backgroundColor:'#f4f4f4', padding: '0px 15px',color:'black'}}>
                  <h6 className="popup-title">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.changeKey()} style={{ position: "inherit", right: "10px", fontSize: '25px' }}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <span>Birth details <Showhelp gotohelp="birth_details_help"/></span>
                        </h6>
                  </div>
                  <div className="modal-body" style={{ fontSize: "14px" }}>
                    <div className="col-sm-12" style={{marginBottom: '10'}}>
                        <div className="row mt-3">
                            <div className="col-sm-3"><label className="col-form-label text-left">Baby name:</label></div>
                            <div className="col-sm-8"><input type="text" name="search_condition" className="form-control mt-0" value={this.state.babyname} onChange={(e)=>this.setState({babyname:e.target.value})}/></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3"><label className="col-form-label text-left">Baby Weight(gms):</label></div>
                            <div className="col-sm-5"><input type="text" name="search_condition" className="form-control mt-0" value={this.state.weight} onChange={(e)=>this.setState({weight:e.target.value})}/></div>
                            <div className="col-sm-4"><button style={{ marginLeft:'-15px'}} type="button" className="btn btn-primary btn-xs mt-0" data-dismiss="modal" onClick={()=>this.setState({metric:true})}>Metric conversion</button></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3"><label className="col-form-label text-left">Sex:</label></div>
                            <div className="col-sm-8">
                                <select class="form-control mt-0" value={this.state.gender} onChange={(e)=>this.setState({gender:e.target.value})}>
                                    <option value="0"></option>
                                    <option value="1">Female</option>
                                    <option value="2">Male</option>
                                    <option value="3">Other</option>
                                    <option value="4">Unknown</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3"><label className="col-form-label text-left">Apgar 1:</label></div>
                            <div className="col-sm-2"><input type="text" name="search_condition" className="form-control mt-0" value={this.state.apgar1}  onChange={(e)=>this.setState({apgar1:e.target.value})}/></div>
                            <div className="col-sm-3"><label className="col-form-label text-left">Apgar 5:</label></div>
                            <div className="col-sm-2"><input type="text" style={{marginLeft:'-70px'}} name="search_condition" className="form-control mt-0" value={this.state.apgar5}  onChange={(e)=>this.setState({apgar5:e.target.value})}/></div>
                        </div>
                    </div>
                    {this.state.metric ?
                    <MetricConversion showmodal={this.state.metric} passValue={this.weightValue}/> : ''}
                </div>
                <div className="modal-footer">
                  {/*}  <button type="button"  onClick={() => this.close4()} className="btn btn-primary btn-xs" data-dismiss="modal">Add to patient database</button>
                    */}<button type="button"  onClick={() => this.checkIfValid()} className="btn btn-primary btn-xs m-0" data-dismiss="modal">Save</button>
                    <button type="button"  onClick={() => this.props.changeKey('')} className="btn btn-primary btn-xs m-0 ml-2" data-dismiss="modal">Cancel</button>
                </div>
            </div>
            </div>
        )}
    </Popup>)
}}

export default AddBabyDetails;
