import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import AuditCExplanatory from './auditCExplanatory';
import AuditAssessment from './auditAssessment';
import {api_audit_c} from '../../../DS/DS.FamilyHistory';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import Showhelp from './../../showHelp';

class AuditC extends Component
{
    constructor(props)
    {
        super(props)
        this.state=
        {
            open:this.props.showmodal,
            key:0,
            auditExpl:false,
            how_often:'',
            unit_alcohol:'',
            single_occasion:'',
            score:'',
            scored:0,
            how_often_char:'',
            unit_alcohol_char:'',
            single_occasion_char:'',
        }
    }
    reloadPage=()=>
    {
        this.setState({key:Math.random()})
        this.setState({auditExpl:false,auditAsses:false})
    }
    howOften=(val,action,textval)=>
    {
       
        if(action=='how_often')
        {
            this.setState({how_often:val});
            this.setState({how_often_char:textval})
        }
        if(action=='unit_alcohol')
        {
            this.setState({unit_alcohol:val});
            this.setState({unit_alcohol_char:textval})
        }
        if(action=='single_occasion')
        {
            this.setState({single_occasion:val});
            this.setState({single_occasion_char:textval})
        }
        
        setTimeout(()=>
        {
             this.calculateScore();
        },100);
    }
    calculateScore=()=>
    {
        this.state.scored=0;
        if(this.state.how_often!='')
        {
            
            this.state.scored+=parseInt(this.state.how_often)
        }
        if(this.state.unit_alcohol!='')
        {
            this.state.scored+=parseInt(this.state.unit_alcohol)
        }
        if(this.state.single_occasion!='')
        {
            this.state.scored+=parseInt(this.state.single_occasion)
        }
       // this.state.scored+=parseInt(this.state.single_occasion)+parseInt(this.state.unit_alcohol)+parseInt(this.state.how_often)
        this.setState({score:this.state.scored})
    }
    saveHandler=(action)=>
    {
        const values={
            how_often:this.state.how_often,
            unit_alcohol:this.state.unit_alcohol,
            single_occasion:this.state.single_occasion,
            patient_id:this.props.redux_patientId,
            doc_id:this.props.redux_docId,
            how_often_char:this.state.how_often_char,
            unit_alcohol_char:this.state.unit_alcohol_char,
            single_occasion_char:this.state.single_occasion_char,
            score:this.state.score
            }
        this.props.apiAuditC({values}).then(
            req=>
            {
                if(action=='close')
                {
                    this.props.changeKey()
                }
                NotificationManager.success('Data save successfully')
            }
        )
    }
    render() {
        return ( 
                <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
                {close => (
                    <div style={{ width:'800px', marginLeft:'220px', backgroundColor:'#FFF', float:'left'}}>
                        <div className="modal-header" style={{width:"100%", backgroundColor:"#f4f4f4", padding:" 0px 15px"}}>
                            <h6 style={{float:'left', fontSize: '16px'}}> Audit-c Assessment <Showhelp gotohelp="audit_assessment_help"/></h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.changeKey()} style={{ marginTop:"5px", fontSize: '25px'}}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{border:'0px'}}>
                            <div className="container" style={{width:'auto'}}>
                            <div className="row row-distance" style={{marginTop:'20px'}}>
                                <div className="col-sm-6">
                                    <label className="col-form-label text-left"> 1. How often do you have a drink containing alcohol? </label>
			                    </div>	
                                <div className="col-sm-4">
                                    <label style={{marginTop:"5px", marginRight:"5px", float:"left"}} >
                                        <input type="radio" value="0" name="howoften"  style={{margin:" 0px 5px 3px 5px", float:"left"}} onClick={(e)=>this.howOften(e.target.value,'how_often','Never')}
                                        />&nbsp;&nbsp;&nbsp;Never</label>
			                    </div>	 
			                    <div className="col-sm-1"></div>		
			                </div>
                            <div className="row">
                                <div className="col-sm-6"></div>		 
                                <div className="col-sm-4">
                                    <label className="radio-inline"> 
                                        <input type="radio"value="1" name="howoften"  style={{margin:"0px 5px 3px 5px", float:"left"}} onClick={(e)=>this.howOften(e.target.value,'how_often','Monthly or less')} />
                                        Monthly or less </label>
			                    </div>	  
			                    <div className="col-sm-1"></div>		
			                </div>
                            <div className="row">
                                <div className="col-sm-6"></div>		
                                <div className="col-sm-4">
                                    <label className="radio-inline">
				                     <input type="radio" value="2" name="howoften"  style={{margin:" 0px 5px 3px 5px", float:"left"}}  onClick={(e)=>this.howOften(e.target.value,'how_often','2-4 times per month')} />
				                     2-4 times per month </label>
			                    </div>	  	
			                    <div className="col-sm-1"></div>	
			                </div>
                            <div className="row">
                                <div className="col-sm-6"></div>		
                                <div className="col-sm-4">
                                 <label className="radio-inline" >
				                    <input type="radio" value="3" name="howoften" style={{margin:" 0px 5px 3px 5px", float:"left"}} onClick={(e)=>this.howOften(e.target.value,'how_often','2-3 times per week')}/>
				                     2-3 times per week </label>
			                    </div>	  	
			                    <div className="col-sm-1"></div>	
			                </div>
                            <div className="row">
                                <div className="col-sm-6"></div>		
                                <div className="col-sm-4">
                                    <label className="radio-inline" >
				                        <input type="radio" value="4" name="howoften" style={{margin:" 0px 5px 3px 5px", float:"left"}} onClick={(e)=>this.howOften(e.target.value,'how_often','4+ times per week')}/>
				                     4+ times per week </label>
			                    </div>	  	
			                    <div className="col-sm-1"></div>	
			                </div>			
                            <div className="row row-distance3"></div>
                            <div className="row row-distance" style={{marginTop:'15px'}}>
                                <div className="col-sm-6">
                                    <label className="col-form-label text-left"> 2. How many units of alcohol do you drink on a typical day when you are drinking? </label>
                                </div>		
                                <div className="col-sm-6">
                                    <label className="radio-inline">
				                        <input type="radio" value="0" name="unit_alcohol" onClick={(e)=>this.howOften(e.target.value,'unit_alcohol','1-2')}/>
				                    1-2  &nbsp;  &nbsp; &nbsp; </label> 
				                    <label className="radio-inline">
				                        <input type="radio" value="1" name="unit_alcohol" onClick={(e)=>this.howOften(e.target.value,'unit_alcohol','3-4')}/>
                                    3-4 &nbsp; &nbsp; &nbsp; </label>  
                                    <label className="radio-inline">
                                         <input type="radio" value="2" name="unit_alcohol" onClick={(e)=>this.howOften(e.target.value,'unit_alcohol','5-6')}/>
                                    5-6  &nbsp;  &nbsp;  &nbsp; </label> 
				                    <label className="radio-inline"> 
                                        <input type="radio" value="3" name="unit_alcohol" onClick={(e)=>this.howOften(e.target.value,'unit_alcohol','7-9')}/>
                                  7-9  &nbsp; &nbsp; &nbsp;</label> 
				                    <label className="radio-inline">
                                        <input type="radio" value="4" name="unit_alcohol" onClick={(e)=>this.howOften(e.target.value,'unit_alcohol','10+')}/>
                                    10+  &nbsp; &nbsp; &nbsp;</label> 
			                    </div>	 	
			                </div>
                            <div className="row row-distance3"></div>
                            <div className="row row-distance" style={{marginTop:'15px'}}>
                                <div className="col-sm-6">
                                    <label className="col-form-label text-left"> 3. How often have you had 6 or more units if female. or 8 or more if male,
					                on a single occasion in the last year? </label>
			                    </div>	
                                <div className="col-sm-4">
                                    <label className="radio-inline">
				                        <input type="radio" value="0" name="single_occasion"  onClick={(e)=>this.howOften(e.target.value,'single_occasion','Never')}/>
                                    Never</label>
			                    </div>	 
			                    <div className="col-sm-1"></div>		
			                </div>
                            <div className="row">
                                <div className="col-sm-6"></div>		
                                <div className="col-sm-4">
                                <label className="radio-inline">
				                    <input type="radio" value="1" name="single_occasion" onClick={(e)=>this.howOften(e.target.value,'single_occasion','Less than monthly')}/>
				                    Less than monthly </label>
			                </div>	  
			                <div className="col-sm-1"></div>		
			            </div>
                        <div className="row">
                            <div className="col-sm-6"></div>		
                            <div className="col-sm-4">
                                <label className="radio-inline">
				                    <input type="radio" value="2" name="single_occasion" onClick={(e)=>this.howOften(e.target.value,'single_occasion','Monthly')}/>
				                Monthly </label>
			                </div>	  	
			                <div className="col-sm-1"></div>	
			            </div>
                        <div className="row">
                            <div className="col-sm-6"></div>		
                            <div className="col-sm-4">
                                <label className="radio-inline">
				                    <input type="radio" value="3" name="single_occasion" onClick={(e)=>this.howOften(e.target.value,'single_occasion','Weekly')}/>
				                 Weekly </label>
			                </div>	  	
			                <div className="col-sm-1"></div>	
			            </div>
                        <div className="row">
                            <div className="col-sm-6"></div>		
                            <div className="col-sm-4">
                                <label className="radio-inline">
				                    <input type="radio" value="4" name="single_occasion" onClick={(e)=>this.howOften(e.target.value,'single_occasion','Daily or almost daily')}/>
				                 Daily or almost daily </label>
			                </div>	  	
			                <div className="col-sm-1"></div>	
			            </div>
                        <div className="row row-distance3"></div>
                        <div className="row row-distance" >
                                <div className="col-sm-1 pr-0">
                                        <label className="col-form-label text-right"> Score :</label>
                                </div>	 
                                <div className="col-sm-2">
                                        <input type="text" className="form-control form-control-sm" value={this.state.score}/>
                                </div>	
                                <div className="col-sm-9"></div>	
                            </div>
                        </div>
                        </div>
		                <div className="modal-footer">
			                <div className="col-sm-6"> 
			 	                <button type="button" className="btn btn-primary btn-xs m-0" data-toggle="modal" data-target="#previous-assessments" style={{float:'left'}}
                                 onClick={()=>this.setState({auditAsses:true})}> Previous assessments </button>
			 	                <button type="button" className="btn btn-primary btn-xs m-0 ml-2" data-toggle="modal" data-target="#audit-c-expanatory-notes" style={{float:'left'}}
                                 onClick={()=>this.setState({auditExpl:true})}> Audit-C Expanatory notes </button>
			                </div>
                            <div className="col-sm-2"> </div>
			                <div className="col-sm-4"> 
                                <button type="button" className="btn btn-primary btn-xs m-0" onClick={(e)=>this.saveHandler('')}>Save</button>
                                <button type="button" className="btn btn-primary btn-xs m-0 ml-2" onClick={(e)=>this.saveHandler('close')}>Save &amp; Close</button>
                                <button type="button" className="btn btn-primary btn-xs m-0 ml-2" data-dismiss="modal" onClick={()=>this.props.changeKey()}>Close</button>
			                </div>
                        </div>
                        {(this.state.auditExpl) ?
                        <AuditCExplanatory showmodal={this.state.auditExpl} changeKey={this.reloadPage} />:''}
                        {(this.state.auditAsses) ? 
                        <AuditAssessment showmodal={this.state.auditAsses} changeKey={this.reloadPage}/> : ''}
                    </div>
                )}
            </Popup>
        )
    }

}
const mapDispatchToProps = dispatch => {
    return {
        apiAuditC: (data) => dispatch(api_audit_c(data)),
            };
  };
  const mapStateToProps = state => {
     let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
     let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
 
   return {
     redux_patientId,redux_docId
   };
 };
export default connect(mapStateToProps,mapDispatchToProps)(AuditC);