import React, { Component } from "react"
import Popup from "reactjs-popup"
import Showhelp from "./../showHelp"
import moment from "moment"
import { connect } from "react-redux"
import {
  api_list_careplans,
  api_add_create_account_item,
} from "./../../DS/DS.VisitnotesFinalizeVisit"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { NotificationContainer, NotificationManager } from "react-notifications"
import { api_list_options } from "./../../DS/DS.Investigation"

import {
  api_summary_billing,
  api_savepaypopup,
  api_updateitemprice,
} from "./../../DS/DS.VisitnotesDiagnosis"
import ServiceNotes from "./../crudcomponent/serviceNotes"
class payNowPopup extends Component {
  state = {
    total_of_services: "",
    pay_type: "pay_full",
    location: "",
    service_date: new Date(),
    cash: "",
    eft: "",
    credit_card: "",
    payment_reference: "",
    outstanding_items: "",
    gap: "",
    payment_total: "",
    amt_tendered: "",
    change: "",
    balance_owing: "",
    selectedInvFull: [],
    patients_billing: [],
    paybutton: true, //disalbe is true
    collection_note: "",
  }
  clearselection = () => {
    this.setState({ selectedInvFull: [] })
  }
  componentDidMount() {
    this.props.list_options().then((res) => {
      //   console.log("addPatientForm addPatientForm",res);
      this.setState({ list_options: res.data.content })
      console.log(this.state.list_options)
    })

    this.props
      .listCareplans()
      .then((res) => this.setState({ careplanMaster: res.data.content }))
  }
  updatePaytypeAmt = (e, item) => {
    let updated_amt = parseFloat(e.target.value)
    let id = item.patients_careplan_items_id
    this.props.updateitemprice({ id: id, amt: updated_amt }).then(() => {
      this.props
        .api_summary_billing({ patient_id: this.props.patient_id })
        .then((res) => {
          this.setState({ patients_billing: res.data.content })
        })
    })
  }

  handleDate2 = (date) => {
    this.setState({ service_date: date })
  }
  totalCalculation = () => {
    //full loop and owing total
    //expect that
    ///alert(1);
    let total = 0

    if (
      this.state.patients_billing != undefined &&
      this.state.patients_billing.length > 0
    ) {
      for (let i = 0; i < this.state.patients_billing.length; i++) {
        if (this.state.patients_billing[i].status != "Sent") {
          total +=
            parseFloat(this.state.patients_billing[i].amt) +
            (parseFloat(this.state.patients_billing[i].amt) *
              parseFloat(this.state.patients_billing[i].tax)) /
              100
        }
      }
    }

    total = parseFloat(total).toFixed(2)
    this.setState({ total_of_services: total })
    this.setState({ payment_total: total })

    if (this.state.pay_type == "pay_full") {
      this.setState({ amt_tendered: total })
    }
  }

  paypartiallycalculation = () => {
    // full loop invoice vice stop and check
    //last crossed is the final amount they have to pay.
  }

  checkboxstatus = (id) => {
    let found = false
    console.log(found)
    for (let i = 0; i < this.state.selectedInvFull.length; i++) {
      if (this.state.selectedInvFull[i].patients_careplan_id == id) {
        found = true
        console.log(found)
        break
      }
    }
    return found
  }

  handleCheckbox = (e, item) => {
    // alert("handle");
    let idd = e.target.value

    if (
      this.state.selectedInvFull.filter((i) => i.patients_careplan_id == idd)
        .length > 0
    ) {
      //alert("aleready there and removin g");
      console.log("already checked - now removing")
      //removing
      this.setState({
        selectedInvFull: [
          ...this.state.patients_billing.filter(
            (item) => item.patients_careplan_id !== idd
          ),
        ],
      })
    } else {
      // alert("inserting selectInvFull");
      let neww = []
      neww = this.state.selectedInvFull.concat(
        this.state.patients_billing.filter(
          (item) => item.patients_careplan_id == idd
        )
      )

      let newtotal = 0
      for (let z = 0; z < neww.length; z++) {
        if (neww[z].status != "Sent") {
          newtotal +=
            parseFloat(neww[z].amt) +
            (parseFloat(neww[z].amt) * parseFloat(neww[z].tax)) / 100
        }
      }
      newtotal = parseFloat(newtotal).toFixed(2)
      this.setState({ payment_total: newtotal })
      if (this.state.pay_type == "pay_full") {
        this.setState({ amt_tendered: newtotal })
      }

      let balance = 0

      balance = this.state.total_of_services - newtotal
      balance = parseFloat(balance).toFixed(2)
      this.setState({ balance_owing: balance })

      console.log(neww, "newww", this.state.patients_billing)

      this.setState({
        selectedInvFull: neww,
      })

      console.log(this.state)
    }
  }
  amountTendered = (e) => {
    // if (this.state.pay_type == "pay_full") {
    let amt_tendered = e.target.value
    if (amt_tendered > this.state.payment_total) {
      console.log(
        parseFloat(this.state.amt_tendered).toFixed(2),
        parseFloat(this.state.payment_total).toFixed(2)
      )

      let change =
        parseFloat(amt_tendered).toFixed(2) -
        parseFloat(this.state.payment_total).toFixed(2)
      change = parseFloat(change).toFixed(2)
      this.setState({ change: change })

      this.setState({ paybutton: true })
      this.setState({
        collection_note:
          "Please collect " + this.state.payment_total + " or more",
      })
    } else {
      this.setState({ paybutton: true })
      this.setState({
        collection_note:
          "Please collect " + this.state.payment_total + " or more",
      })
    }

    //  }

    if (this.state.pay_type == "pay_gap") {
    }
  }

  componentWillReceiveProps() {
    this.props
      .api_summary_billing({ patient_id: this.props.patient_id })
      .then((res) => {
        this.setState({ patients_billing: res.data.content })
      })
      .then(() => {
        console.log(this.state, "summary state")
        this.totalCalculation()
      })
  }
  paynow = () => {
    console.log("paynoww", this.state)
    //this.props.handleSave("paid");

    this.props.savepaypopup(this.state).then(() => {
      NotificationManager.success("Selected Invoice Paid!")
      this.props.closefrompaypopup()
    })
  }
  inputChangeHandler = () => {}

  handleRadio = (e, name) => {
    this.setState({ [name]: e.target.checked ? e.target.value : "" })
  }
  handleinput = (e, name) => {
    this.setState({ [name]: e.target.value })
  }
  addItem = () => {
    this.props.addItem(this.state)
  }

  addcpitem = (e, id) => {
    this.setState({ cpid: id })
  }

  openServiceNotes = (e, i) => {
    this.setState({ servicenotes: true, selectedInput: i })
  }

  closeServiceNotes = () => {
    this.setState({ servicenotes: false, selectedInput: "" })
  }

  render() {
    return (
      <Popup
        // trigger={
        open={this.props.openP}
        close={() => this.props.close()}
        // }
        position="right center"
        modal
        closeOnDocumentClick={false}
      >
        <div className="paynowlab">
          <div className="modal-header">
            <div class="popup-title">
              <span> Pay Now </span>
              <Showhelp gotohelp="diagnosis_popup" />
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
          </div>
          <div className="modal-body">
            <div className="col-sm-12 mt-3">
              <div className="col-sm-2">Total of services:</div>
              <div className="col-sm-3">
                <input
                  type="text"
                  disabled={true}
                  value={this.state.total_of_services}
                  onChange={(e) => this.handleinput(e, "total_of_services")}
                />
              </div>

              <div className="col-sm-7">
                <label className="col-sm-4">
                  <input
                    type="radio"
                    value="pay_full"
                    checked={this.state.pay_type == "pay_full"}
                    onChange={(e) => this.handleRadio(e, "pay_type")}
                  />{" "}
                  Pay Full{" "}
                </label>
                <label>
                  <input
                    type="radio"
                    value="pay_gap"
                    checked={this.state.pay_type == "pay_gap"}
                    onChange={(e) => this.handleRadio(e, "pay_type")}
                  />{" "}
                  Pay Gap
                </label>
              </div>
              <div className="col-sm-12">
                <label
                  className="col-sm-1 col-form-label text-right"
                  style={{ paddingRight: "0px" }}
                >
                  Location:
                </label>
                <div className="col-sm-2 cursor">
                  <select
                    className="form-control"
                    onChange={(e) => this.handleinput(e, "location")}
                    value={this.state.location}
                  >
                    {this.state.list_options != undefined &&
                      this.state.list_options.location != null &&
                      this.state.list_options.location.map((item, index) => (
                        //      (item, index) => (
                        <option
                          value={item.value}
                          selected={this.state.location == item.value}
                          key={index}
                        >
                          {item.label}
                        </option>
                      ))}
                  </select>
                </div>

                <label
                  className="col-sm-1 col-form-label text-left"
                  style={{ paddingRight: "0px" }}
                >
                  Invoice date:
                </label>
                <div className="col-sm-2 cursor" style={{ paddingLeft: "0px" }}>
                  <DatePicker
                    //ref={this.textInput}

                    ref={(input) => {
                      this.textInput = input
                    }}
                    id="date-jumper"
                    inputRef={(ref) => {
                      this.textInput = ref
                    }}
                    todayButton="Go to Today"
                    selected={this.state.service_date}
                    onChange={this.handleDate2}
                    showMonthDropdown
                    useShortMonthInDropdown
                    showYearDropdown
                    dateFormatCalendar="MMMM"
                    dateFormat="dd-MMM-yyyy"
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                  />
                </div>
              </div>
            </div>

            <div className="col-sm-12">
              <div className="col-sm-3">
                Cash:
                <input
                  className="form-control"
                  type="number"
                  value={this.state.cash}
                  onChange={(e) => this.handleinput(e, "cash")}
                />
              </div>

              <div className="col-sm-3">
                EFT:
                <input
                  className="form-control"
                  type="number"
                  value={this.state.eft}
                  onChange={(e) => this.handleinput(e, "eft")}
                />
              </div>

              <div className="col-sm-3">
                Credit Card:
                <input
                  className="form-control"
                  type="number"
                  value={this.state.credit_card}
                  onChange={(e) => this.handleinput(e, "credit_card")}
                />
              </div>

              <div className="col-sm-3">
                Direct Credit
                <input
                  className="form-control"
                  type="number"
                  value={this.state.direct_credit}
                  onChange={(e) => this.handleinput(e, "direct_credit")}
                />
              </div>
            </div>

            <div className="col-sm-12">
              <div className="col-sm-3">
                Payment Reference:
                <input
                  className="form-control"
                  type="number"
                  value={this.state.payment_reference}
                  onChange={(e) => this.handleinput(e, "payment_reference")}
                />
              </div>
              <div className="col-sm-4">
                Payment Total
                <input
                  className="form-control"
                  type="number"
                  disabled={this.state.pay_type == "pay_gap" ? false : true}
                  value={this.state.payment_total}
                  onChange={(e) => this.handleinput(e, "payment_total")}
                />
              </div>
            </div>

            <div className="col-sm-12">
              {/* <button className="btn btn-info"> Allocate </button>
<button className="btn btn-info"> Auto Allocate </button> */}
            </div>
          </div>
          <div className="col-sm-12"></div>

          <div
            className="col-sm-12 newpostpopup"
            style={{ height: "250px", overflow: "scroll" }}
          >
            <table className="calendar table table-bordered tablestyle">
              <thead>
                <tr>
                  <th>
                    Select
                    <br />
                    <button
                      class="btn-sm btn"
                      onClick={(e) => this.clearselection()}
                    >
                      Clear
                    </button>
                  </th>
                  <th>Invoice No</th>
                  <th style={{ width: "200px" }}>Date</th>
                  <th>Doctor</th>
                  <th>MBS Item</th>
                  <th>Description</th>
                  <th>Fee</th>
                  <th>GST</th>
                  <th>Total</th>
                  {/* <th>Paid</th> */}

                  <th>Owing</th>
                </tr>
              </thead>

              <tbody>
                {this.state.patients_billing != undefined &&
                  this.state.patients_billing.length > 0 &&
                  this.state.patients_billing
                    .filter((item) => item.status != "Sent")
                    .map((item, i) => (
                      <tr

                      //   className={item.status!="Sent"? 'red_highlight': 'white_highlight'}
                      >
                        <td>
                          <input
                            type="checkbox"
                            name={item.patients_careplan_id}
                            id="check_allin"
                            value={item.patients_careplan_id}
                            checked={this.checkboxstatus(
                              item.patients_careplan_id
                            )}
                            onChange={(e) => this.handleCheckbox(e, item)}
                          />
                        </td>
                        <td> {item.patients_careplan_id} </td>
                        <td> {moment(item.recordedAt).format("DD/MM/YYYY")}</td>
                        <td> Dr.{item.doc_name} </td>
                        <td> {item.item_no} </td>
                        <td> {item.name} </td>
                        <td>
                          {this.state.pay_type == "pay_gap" ? (
                            <span>
                              {" "}
                              <input
                                defaultValue={item.amt}
                                onBlur={(e) => this.updatePaytypeAmt(e, item)}
                              />{" "}
                            </span>
                          ) : (
                            item.amt
                          )}
                        </td>
                        <td> {parseFloat(item.tax)} </td>
                        <td>
                          {" "}
                          {parseFloat(item.amt) +
                            (parseFloat(item.amt) * parseFloat(item.tax)) /
                              100}{" "}
                        </td>

                        {/* <td>
                        {" "}
                        {item.status == "Sent"
                          ? parseFloat(
                              parseFloat(item.amt) +
                                (parseFloat(item.amt) * parseFloat(item.tax)) /
                                  100
                            ).toFixed(2)
                          : "-"}{" "}
                      </td> */}
                        <td>
                          {" "}
                          {item.status != "Sent"
                            ? parseFloat(
                                parseFloat(item.amt) +
                                  (parseFloat(item.amt) *
                                    parseFloat(item.tax)) /
                                    100
                              ).toFixed(2)
                            : "-"}{" "}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>

          <div className="col-sm-12">
            <div className="col-sm-4">
              Amount Tendered
              <input
                className="form-control"
                type="number"
                readonly={true}
                value={this.state.amt_tendered}
                onChange={(e) => {
                  this.handleinput(e, "amt_tendered")
                  this.amountTendered(e)
                }}
              />
              <span> Note: {this.state.collection_note} </span>
            </div>

            <div className="col-sm-4">
              Change
              <input
                className="form-control"
                type="number"
                readonly={true}
                value={this.state.change}
                onChange={(e) => this.handleinput(e, "change")}
              />
            </div>

            <div className="col-sm-4">
              Balance Owing:
              <input
                className="form-control"
                type="number"
                readonly={true}
                value={this.state.balance_owing}
                onChange={(e) => this.handleinput(e, "balance_owing")}
              />
            </div>
          </div>
          <div className="modal-footer">
            <div className="col-sm-12">
              <button
                type="button"
                className="btn btn-primary btn-xs"
                data-dismiss="modal"
                aria-label="Close"
                // disabled={this.state.paybutton}
                onClick={(e) => {
                  this.paynow()
                  this.props.close()
                }} //this.props.closeServiceNotes()}
              >
                Pay
              </button>
              <button
                type="button"
                className="btn btn-primary btn-xs"
                onClick={(e) => this.props.close()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Popup>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    list_options: (data) => dispatch(api_list_options(data)),
    listCareplans: (data) => dispatch(api_list_careplans(data)),
    addCreateAccountItem: (data) => dispatch(api_add_create_account_item(data)),
    api_summary_billing: (data) => dispatch(api_summary_billing(data)),
    savepaypopup: (data) => dispatch(api_savepaypopup(data)),
    updateitemprice: (data) => dispatch(api_updateitemprice(data)),
  }
}

const mapStateToProps = (state) => {
  let selected_appointment =
    state.getDrAppointments.selected_appointment != undefined
      ? state.getDrAppointments.selected_appointment
      : []
  // let logged_info  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info:[];
  console.log(
    "selected_appointment",
    state.getDrAppointments.getPatientDetailst
  )
  let patientDetails = state.getDrAppointments.getPatientDetails
    ? state.getDrAppointments.getPatientDetails
    : []
  let selectedPatientDetails
  if (patientDetails.length > 0) {
    selectedPatientDetails = patientDetails[0]
  } else {
    selectedPatientDetails = ""
  }
  console.log(">>>>>>>>>>>>>>>SELECTED APPOINTMENT", selected_appointment)
  let logged_info =
    state.getDrAppointments.logged_info != undefined
      ? state.getDrAppointments.logged_info
      : ""

  return {
    selectedPatientDetails,
    logged_info,
    selected_appointment,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(payNowPopup)
//export default finalisevisit;
