import React, { Component } from "react";
import Popup from "reactjs-popup";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import { confirmAlert } from "react-confirm-alert";
import Showhelp from '../../showHelp';
import {api_reports} from '../../../DS/DS.IncomingReports'
   
 class incominReport extends Component {
  constructor(props) {
    super(props);
  this.state = {
    open:this.props.open,
    key:0,
    showselect:false,
    select:[],
    reminderList:[], 
    selectedindex:-1,
    selectedIds:'',
    showappointment:false,
    patienname:''
     }
 
}
componentDidMount=()=>
{
    this.props.getReports().then(res=>
        {
            this.setState({reminderList:res.data.content})
        })   
}
closeSelect=(e)=>
{
    console.log("&&&&&&&&&&&&&&&",e)
    if(e!='')
    {
      
        this.props.getReports().then(res=>
            {
                this.setState({reminderList:res.data.content})
            })
    }
    else
    {
        this.setState({reminderList:[]})
    }
    this.setState({showselect:false})
   
}
openPop(a){
    this.setState({showselect:a})
  //  alert(a)
}
componentWillMount=()=>{

}
closeSelect1=(v)=>
{
    console.log("&&&&&&&&&&&&&&&&&&&&",v)
}
setTrClass=(id)=>{
    let classs = "";
    classs +=
      id == this.state.selectedindex
        ? " selectedBg"
        : "";
    return classs;
  };
  showSelectedContent(e, item, i) {
      const patientname=item.display +"." +item.Firstname+"." +item.Middlename+"." +item.Surname
    this.setState({ showSelectedContent: item,selectedindex: i,selectedIds:item.reminder_id, patienname:item.display +"" +item.Firstname+"" +item.Middlename+"" +item.Surname });
  }

  removeSelected=()=>
  {
    var array = [...this.state.reminderList]; // make a separate copy of the array
      array.splice(this.state.selectedindex, 1);
      this.setState({reminderList: array});
 //   this.setState({reminderList: this.state.reminderList.splice(this.state.selectedindex, 1)});
  }
  deleteReminder=()=>
  {
   
 }

 closePop=()=>
 {
     this.setState({showappointment:false})
 }
    render(){
        return(
            <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
            {close => (
                <div style={{ width:'1000px', marginLeft:'0px', backgroundColor:'#FFF', float:'left'}}>  
                    <div className="modal-header" style={{width:'auto', backgroundColor:'#f4f4f4', padding: '0px 15px',color:'black'}}>
                        <h6 className="popup-title">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.close()} style={{ position: "inherit", right: "10px", fontSize: '25px' }}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <span>Incoming Reports <Showhelp gotohelp="incoming_reports_help"/></span>
                        </h6>

                    </div>
                    <div className="modal-body" style={{ fontSize: "14px" }}>
                       
                        {/** reminder listing */}
                        
                         <div id="referralstlist" className="panel-collapse collapse" style={{float:'left', display:'block',  marginTop:'20px', width:'100%'}}>
			                <div className="panel panel-default" style={{float: 'left', width: '100%'}}>
                               {/*} <div className="panel-heading" style={{float: 'left', padding:'0px 15px', width: '100%'}}>
                                    <h4 className="panel-title" style={{float:'left', display:'inline', marginRight:'10px'}}>
                                        <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" style={{fontSize:'24px'}}>Reminders</a>
                                    </h4>              
                                    </div>*/}
                                <div style={{float:'left',width:'100%',overflow:'scroll'}}>
                                    <table style={{width:'100%'}}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div style={{width:'100%',height:'232px',overflow: 'scroll'}}>
                                                        <table className="calendar table table-bordered" style={{float:'left', width:'2000px'}}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Date</th>
                                                                    <th>Patient name on report</th>
                                                                    <th>Test</th>
                                                                    <th>Addressed To</th>
                                                                    <th>Allocated to patient</th>
                                                                    <th>Allocated to user</th>
                                                                    <th>Location</th>
                                                                    <th>Complete</th>
                                                                    <th>Laboratory/Provider</th>
                                                                 </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.reminderList!=undefined && this.state.reminderList.map((item,i)=>
                                                                <tr onClick={e =>
                                                                    this.showSelectedContent(e, item, i) } className={this.setTrClass(i)}>
                                                                    <td>{item.request_date}</td>
                                                                    <td>{item.firstname}{" "}{item.surname}</td>
                                                                    <td>{item.test_name}</td>
                                                                    <td>{item.addressed_to}</td>
                                                                    <td>{item.firstname}{" "}{item.surname}</td>
                                                                    <td>{item.addressed_to}</td>
                                                                    <td>{item.provider_name}</td>
                                                                    <td>{item.completion_flag}</td>
                                                                    <td>{item.provider_name}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
			                </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                            <button type="button"  onClick={() => this.props.closeAll()} className="btn btn-primary btn-xs m-0" data-dismiss="modal">Close</button>
                    </div>   
                    </div>        
                    )}     
            </Popup>
        )}
    } 
    
    const mapDispatchToProps = dispatch => {
        return {
            getReports: () => dispatch(api_reports()),
        };
      };
      
      const mapStateToProps = state => {
        //console.log(state);
        let appointments = state.getDrAppointments.getAppointments
          ? state.getDrAppointments.getAppointments
          : [];
        let get_cut_copy_details = state.getDrAppointments.cut_copy_details
          ? state.getDrAppointments.cut_copy_details
          : {};
        let logged_info =
          state.getDrAppointments.logged_info != undefined
            ? state.getDrAppointments.logged_info
            : "";
      
        return {
          appointments,
          get_cut_copy_details,
          logged_info
        };
      };
      
      export default connect(mapStateToProps, mapDispatchToProps)(incominReport)

