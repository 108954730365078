import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    api_add_correspondence,
    api_get_recieved,
    api_get_sent,
    api_get_correspondence,
    api_update_correspondence,
    api_upload_image
} from '../../../DS/DS.Correspondence';
//import Files from 'react-files';
import { api_getDoctors } from '../../../DS/DS.Appointments';
import {
    NotificationContainer,
    NotificationManager,
  } from 'react-notifications';
//import "react-datepicker/dist/react-datepicker.css"
import SearchDoctors from './searchDoctors';
import cicon from "../../../assets/img/cicon.png";
import Showhelp from './../../showHelp';

class AddCorrespondence extends Component {
    constructor(props){
        super(props);
        this.state = {
            slectedCorrespondence: '',
            patient_id: this.props.patient_id,
            startDate:new Date(),
            dateShow: false,
            isChecked: false,
            selectedCategory : 'Clinical Photograph',
            from_guid: '',
            subject: '',
            details: '',
            ref: '',
            docs: [],
            selectedFile:null,
            errors:[],
            searchDoc:false,
            selected_doc_id:'',
            open:this.props.showmodal,
            errors:[],
            disable:''
            
        }
    }
    componentDidMount(){
      //  alert("in")
        this.props.getDoctors().then(res => {
            this.setState({
              docs: res.data.content ,
            })}
            );
        if(this.props.sid!='')
        {
            this.props.getCorrespondenceData({id:this.props.sid}).then(res=>
            {
                this.setState({
                    startDate: moment(new Date(res.data.content[0].correspondence_date)).format('YYYY/MM/DD'),
                    selectedCategory:res.data.content[0].category,
                    selected_doc_id:res.data.content[0].from_guid,
                    from_guid:res.data.content[0].display+''+res.data.content[0].first_name+'.'+res.data.content[0].surname,
                    subject:res.data.content[0].subject,
                    details:res.data.content[0].details,
                    ref:res.data.content[0].user_reference
                })
            })
        }
       
    }
    
handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
  
    //file
    if(!this.state.selectedFile){
       formIsValid = false;
       errors["file"] = "Cannot be empty";
    }
    //Date
    if(!this.state.startDate){
      formIsValid = false;
      errors["Date"] = "Cannot be empty";
   }
  
   //from
   if(!this.state.selected_doc_id){
    formIsValid = false;
    errors["from"] = "Cannot be empty";
 }

  
    
  
   this.setState({errors: errors});
   return formIsValid;
  }
     handleDate = date => {
        console.log('test');
        this.setState({
          startDate: moment(new Date(date)).format('YYYY/MM/DD')
        });

        if(moment(new Date(date)).format('MM/DD/YYYY') == moment(new Date()).format('MM/DD/YYYY')){

          this.setState({
            dateShow: true,
          });
        }else {
          this.setState({
            dateShow: false,
          });
        }
      };
      handleSave=(e) =>{
        e.preventDefault();
        if(this.handleValidation()){
          this.setState({disable:'disable'})
            const data = new FormData() 
            data.append('image',this.state.selectedFile)

            this.props.uploadimage(data).then(
                res=>{
                 // if(res.status=="Success")
                  //{
                    //alert(res.data.content)
                    const values = {
                        'patient_id': this.state.patient_id,
                        'correspondence_date' : this.state.startDate,
                        'createdAt':this.state.startDate,
                        'category': this.state.selectedCategory,
                        'from_guid': this.props.redux_docId,
                        'subject': this.state.subject,
                        'isConfidencial' :this.state.isChecked,
                        'isDeleted': 0,
                        'user_reference' :this.state.ref,
                        'details': this.state.details,
                        'date_checked': this.state.startDate,
                        'isInOut': 0,
                        'image_name': res.data.content,
                        'to_guid':this.state.selected_doc_id,
                        'has_image':1
                    }
                    this.props.add_correspondence({values}).then(
                        req=>
                            this.props.changekey(),
                            NotificationManager.success(
                            'Record Added',
                            //this.changeKey()
                            )
                            
                    );
                  /*}
                  else{
                    this.props.changeKey()
                    NotificationManager.success('Unable to add the record at this moment')
                  }*/
                    
                }
              )
                




        /*  this.props.add_correspondence({values}).then(
              req=>this.props.changekey(),
              NotificationManager.success(
              'Record Added',
              //this.changeKey()
              )
          ) 
          this.props.uploadimage(data);
         /* axios.post(`${process.env.REACT_APP_BASE_URL}api=clinical&action=upload_image`, data, { 
            // receive two    parameter endpoint url ,form data
     })
          this.setState({
              startDate: '',
              isChecked: false,
              selectedCategory: '',
              from_guid: '',
              subject: '',
              ref: '',
              details: ''
            });*/
//          console.log(values);
          this.props.get_recieved({patient_id: this.state.patient_id}).then(res => {
            this.setState({
              corr_recieve: res.data.content ,
            })}
            );
        }
      }

      toggleChange = () => {
        this.setState({
          isChecked: !this.state.isChecked,
        });
      }
      onChangeHandler=event=>{
        this.setState({
        selectedFile: event.target.files[0],
        loaded: 0,
      })
  }
  serachDoctor=()=>
  {
      this.setState({searchDoc:true})
  }
  addSelectedDoc=(id)=>
  {
      this.setState({searchDoc:false})
      if(id!='')
      {
      this.setState({selected_doc_id:id.contact_id,from_guid:id.display+''+id.first_name+'.'+id.surname})
      }
  }
  updateData=()=>
  {
    const values = {
        'patient_id': this.state.patient_id,
        'correspondence_date' : this.state.startDate,
        'createdAt':this.state.startDate,
        'category': this.state.selectedCategory,
        'from_guid': this.props.redux_docId,
        'subject': this.state.subject,
        'isConfidencial' :this.state.isChecked,
        'user_reference' :this.state.ref,
        'details': this.state.details,
        'date_checked': this.state.startDate,
        'patients_correspondence_id':this.props.sid,
        'to_guid':this.state.selected_doc_id
      }
    this.props.updatecorrespondence(values).then(
        req=>
            this.props.changekey(),
            NotificationManager.success(
                'Record Added',
                ) 
        
    )
    
  }
 /* escFunction(event){
    if(event.keyCode === 27) {
      //Do whatever when esc is pressed
      console.log(this.props)
      this.props.changekey();
    }
  }*/
  focusTextInput() {
        // Explicitly focus the text input using the raw DOM API
        // Note: we're accessing "current" to get the DOM node
        // this.textInput.current.focus();
        document.getElementById("correspondencecalendar").focus();
        // this.textInput.focus();
      }
    render() {
        return ( <Popup 
            position="right center"   modal
            closeOnDocumentClick={false}
            open={this.state.open}  
         >
         {close => (
             <div style={{ width:'550px', marginLeft:'125px', backgroundColor:'#FFF', float:'left'}}>
                <div className="modal-header" style={{width: '100%',height: '20',padding:'0 15px 0 15px'}} align="center">
                    <span><h6 style={{float: 'left', fontSize: 16}}>Correspondence  <Showhelp gotohelp="add_correspondence_help"/></h6>
                   
                    <button type="button" className="close" data-dismiss="modal" onClick={() => close()} style={{ position: "inherit", right: "10px", marginTop:"5px", fontSize: "25px"}} aria-label="Close">×</button></span>
                </div>
                <div className="modal-body">
                <div className="col-sm-12">
                {(this.props.sid=='')
        ?
                    <div className="row row-distance">
                            <div className="col-sm-2 mt-1 col-sm-02">
                                <label>Image :</label>
                            </div>
                                <div className="col-sm-5">
                                    <input type="file" className="mt-3" name="file" onChange={this.onChangeHandler} required/>
                                    <span className="error" style={{color:'red'}}>{this.state.errors["file"]}</span>
                                </div>
                    </div>:''}
                    <div className="row mt-3 mb-2">
                        <div className="col-sm-2 mt-1 col-sm-02">
                            <label>Date :</label>
                            <span className="error" style={{color:'red'}}>{this.state.errors["Date"]}</span> 
                        </div>
                        <div className="col-sm-5">
                        <DatePicker
                            className="datePicker"
                            selected={
                            this.state.dateShow
                                ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                : Date.parse(this.state.startDate)
                            }

                            // selected={Date.parse(this.state.startDate)}
                            onChange={this.handleDate}
                            id="correspondencecalendar"
                        />
                          <label
                            className="label-input100"
                            style={{
                              marginRight: "0px", marginBottom: "0px", fontSize: "12px", paddingBottom: "0px", paddingTop: "0px", position: "absolute", marginLeft: "-30px"
                            }}
                        >
                      <a onClick={this.focusTextInput} href="#">
                        {<img src={cicon} />}
                      </a>
                    </label>
                        
                        </div>
                        
                    </div>
                    <div  className="row">
                        <div className="col-sm-2 mt-1 col-sm-02">
                            <label>Category :</label>
                        </div>
                        <div className="col-sm-5">
                            <select class="form-control mt-0" onChange={(e) => this.setState({ selectedCategory: e.target.value })}  value={this.state.selectedCategory}>
                                <option value="Clinical Photograph"> Clinical Photograph </option>
                                <option value="Discrage Summary"> Discrage Summary </option>
                                <option value="ECG"> ECG </option>
                                <option value="Email"> Email </option>
                                <option value="Letter"> Letter </option>
                                <option value="Patient Consent"> Patient Consent </option>
                                <option value="Referral Letter"> Referral Letter </option>
                                <option value="Specialist Letter"> Specialist Letter </option>
                                <option value="Report"> Report </option>
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-2 mt-1 col-sm-02">
                            <label>From :</label>
                        </div>
                        <div className="col-sm-5">
                            <input type="text" class="form-control mt-0" value={this.state.from_guid} /> 
                            {/*<select style={{width:"50%",height:"25px"}} onChange={(e) => this.setState({ from_guid: e.target.value })}>
                                <option value={this.state.from_guid}></option>
                                { this.state.docs !=undefined && this.state.docs.map((item,i) => (
                                <option value={item.doctor_id}> {item.Firstname} </option>
                                ))}
                                </select>*/}
                                 <span className="error" style={{color:'red'}}>{this.state.errors["from"]}</span> 
                        </div>
                        <div className="col-md-4 pl-0">
                            <button className="btn btn-primary btn-xs mt-1" onClick={()=> this.serachDoctor()} >Search</button>
                        </div>
                    </div>
                    {(this.state.searchDoc) ?
                    <SearchDoctors searchDoc={this.state.searchDoc} changeKey={this.addSelectedDoc}/> : ''}
                    
                    <div className="row">
                        <div className="col-sm-2 mt-1 col-sm-02">
                            <label>Subject :</label>
                        </div>
                        <div className="col-sm-5">
                            <input type="text" class="form-control" onChange={(e) => this.setState({ subject: e.target.value })} value={this.state.subject}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-2 mt-1 col-sm-02">
                            <label>Details :</label>
                        </div>
                        <div className="col-sm-9">
                            <textarea rows="4" cols="61"  onChange={(e) => this.setState({ details: e.target.value })} value={this.state.details}></textarea>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-2 mt-1 col-sm-02">
                        </div>
                        <div className="col-sm-9">
                            <input type="checkbox" checked={this.state.isChecked} onChange={this.toggleChange} />&nbsp;<label>Confidential</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-2 mt-3 pr-0 col-sm-02">
                            <label>User Reference :</label>
                        </div>
                        <div className="col-sm-9">
                            <input type="text" class="form-control mt-2" onChange={(e) => this.setState({ ref: e.target.value })} value={this.state.ref}/>
                        </div>
                    </div>
                    </div>
                    <div class="modal-footer">
                        <div className="col-sm-8" style={{float:'left'}}></div>
                        <div className="col-sm-4" align="right" style={{float:'right'}}>
                            {(this.props.sid=='')?
                                <button className="btn btn-primary btn-xs" onClick={(e) => { this.handleSave(e);}} disabled={this.state.disable}>Save</button>
                            :   <button className="btn btn-primary btn-xs" onClick={() => { this.updateData();}}>Update</button>
                        }
                            <button className="btn btn-primary btn-xs" onClick={() => { close(); this.props.changekey()}}>Cancel</button>
                        </div>
                    </div>
                </div>
             </div>
          )}
          </Popup>)
    }
}
const mapStateToProps = (state,ownProps) => {
  if(ownProps.patient!='')
  {
    let redux_patientId  = ownProps.patient_id//state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
    let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
   //console.log(state.getDrAppointments.logged_info.content.details[0])
  return {
    redux_patientId,redux_docId
  };
 }
 else{
  let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
  let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
 //console.log(state.getDrAppointments.logged_info.content.details[0])
return {
  redux_patientId,redux_docId
};
 }
};
const mapDispatchToProps = (dispatch) => {
	return {
    add_correspondence: data => dispatch(api_add_correspondence(data)),
    get_recieved: data => dispatch(api_get_recieved(data)),
    get_sent: data => dispatch(api_get_sent(data)),
    getDoctors: data => dispatch(api_getDoctors(data)),
    getCorrespondenceData:data => dispatch(api_get_correspondence(data)),
    updatecorrespondence:data=>dispatch(api_update_correspondence(data)),
    uploadimage : data => dispatch(api_upload_image(data))
  }
}
export default connect (mapStateToProps, mapDispatchToProps) (AddCorrespondence);
