import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import Showhelp from './../../../showHelp';


class cns extends Component {
    state = {  }
    render() {
        return (
            <Popup lockScroll={true} trigger={<a  title="CNS" className="collapsed" aria-expanded="false"><span className="cns"></span></a>} position="right center"   modal
            closeOnDocumentClick={false}
            >
             {close => (
                <div style={{ width:'800px', margin:'0px auto'}} >
                 <div className="cnslay" style={{ width:'800px', marginLeft:'0px', backgroundColor:'#FFF', float:'left'}}>

              <div className="modal-header" 
              //  style={{width: '100%', backgroundColor: '', padding: '5px 15px'}}
               >

                      <button type="button" className="close" style={{ marginTop: '0px',fontSize: '25px' }} onClick={() => close()} data-dismiss="modal" aria-label="Close">
                      ×
                      </button>
                      <span> CNS <Showhelp gotohelp="cns_help"/></span>
                      {/* <h4 style={{backgroundColor: '', float: 'left', width: '100%', fontSize: '25px', margintop: '-5px'}}>CNS</h4> */}
                    </div>
                    <div className="modal-body" style={{ border:'0px' }}>
                      
                    <div className="col-sm-12-0 pr-0 ml-0"><h4 className="mt-0">History:</h4></div>

        <div className="col-sm-12">
                  <div className="col-sm-3 pr-0">
                            <label>Headache</label>
                        </div>
                        <div className="col-sm-3 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Headache === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Headache")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Headache === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Headache")}
                    /> No</label>

                        </div>

                    <div className="col-sm-2 pr-0">
                            <label>Neck stiffness</label>
                        </div>
                        <div className="col-sm-3">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.NeckStiffness === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "NeckStiffness")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.NeckStiffness === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "NeckStiffness")}
                    /> No</label>

                        </div>

                 </div>

                 <div className="col-sm-12">
                  <div className="col-sm-3 pr-0">
                            <label>Photophobia</label>
                        </div>
                        <div className="col-sm-3 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Photofobia === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Photofobia")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Photofobia === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Photofobia")}
                    /> No</label>

                        </div>
                    <div className="col-sm-2 pr-0">
                            <label>Hyperacusis</label>
                        </div>
                        <div className="col-sm-3 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Hyperacusis === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Hyperacusis")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Hyperacusis === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Hyperacusis")}
                    /> No</label>
                        </div>

                 </div>

                 <div className="col-sm-12">
                  <div className="col-sm-3 pr-0">
                            <label>Fits</label>
                        </div>
                        <div className="col-sm-3 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Fits === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Fits")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Fits === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Fits")}
                    /> No</label>

                        </div>
                    <div className="col-sm-2 pr-0">
                            <label>Faints</label>
                        </div>
                        <div className="col-sm-3 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Faints === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Faints")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Faints === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Faints")}
                    /> No</label>

                        </div>

                 </div>

                 <div className="col-sm-12">
                  <div className="col-sm-3 pr-0">
                            <label>Weakness</label>
                        </div>
                        <div className="col-sm-3 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Weakness === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Weakness")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Weakness === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Weakness")}
                    /> No</label>
                        </div>

                    <div className="col-sm-2 pr-0">
                            <label>Numbness</label>
                        </div>
                        <div className="col-sm-3 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Numbness === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Numbness")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Numbness === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Numbness")}
                    /> No</label>
                        </div>

                 </div>


                 <div className="col-sm-12">
                  <div className="col-sm-3 pr-0">
                            <label>Slurred speech</label>
                        </div>
                        <div className="col-sm-3 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.SlurredSpeech === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "SlurredSpeech")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.SlurredSpeech === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "SlurredSpeech")}
                    /> No</label>
                        </div>

                    <div className="col-sm-2 pr-0">
                            <label>Incoordination</label>
                        </div>
                        <div className="col-sm-3 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Incoordination === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Incoordination")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Incoordination === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Incoordination")}
                    /> No</label>

                        </div>

                 </div>



                 <div className="col-sm-12">
                  <div className="col-sm-3 pr-0">
                            <label>Confusion</label>
                        </div>
                        <div className="col-sm-3 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Confusion === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Confusion")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Confusion === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Confusion")}
                    /> No</label>

                        </div>
                    <div className="col-sm-2 pr-0">
                            <label>Disorientation</label>
                        </div>
                        <div className="col-sm-3 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Disorientation === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Disorientation")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Disorientation === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Disorientation")}
                    /> No</label>
                        </div>

                 </div>


                 <div className="col-sm-12">
                  <div className="col-sm-3 pr-0">
                            <label>Tremor </label>
                        </div>
                        <div className="col-sm-3 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Tremor === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Tremor")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Tremor === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Tremor")}
                    /> No</label>
                        </div>


                 </div>

                 <div className="col-sm-12 pl-0 pr-0 ml-0"><h4>Examination:</h4></div>

                 <div className="col-sm-12">
                  <div className="col-sm-3 pr-0">
                            <label>Essential tremor</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.EssentialTremor === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "EssentialTremor")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.EssentialTremor === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "EssentialTremor")}
                    /> No</label>

                        </div>

                    <div className="col-sm-3 pr-0">
                            <label>Upper facial palsy</label>
                        </div>
                        <div className="col-sm-4 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.UpperFacialPalsy === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "UpperFacialPalsy")}
                    /> Right</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.UpperFacialPalsy === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "UpperFacialPalsy")}
                    /> Left</label>
                      &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.UpperFacialPalsy === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "UpperFacialPalsy")}
                    /> Neither</label>
                        </div>

                 </div>

                 <div className="col-sm-12">
                  <div className="col-sm-3 pr-0">
                            <label>Intension tremor</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.IntensionTremor === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "IntensionTremor")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.IntensionTremor === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "IntensionTremor")}
                    /> No</label>
                        </div>
                    <div className="col-sm-3 pr-0">
                            <label>Lower facial palsy</label>
                        </div>
                        <div className="col-sm-4 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.LowerFacialPalsy === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "LowerFacialPalsy")}
                    /> Right</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.LowerFacialPalsy === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "LowerFacialPalsy")}
                    /> Left</label>
                      &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.LowerFacialPalsy === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "LowerFacialPalsy")}
                    /> Neither</label>

                        </div>

                 </div>

                 <div className="col-sm-12">
                  <div className="col-sm-3 pr-0">
                            <label>Involuntary movements</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.InvoluntaryMovements === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "InvoluntaryMovements")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.InvoluntaryMovements === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "InvoluntaryMovements")}
                    /> No</label>
                        </div>
                    <div className="col-sm-3 pr-0">
                            <label>Arms wickness</label>
                        </div>
                        <div className="col-sm-4 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.ArmsWickness === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "ArmsWickness")}
                    /> Right</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.ArmsWickness === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "ArmsWickness")}
                    /> Left</label>
                      &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.ArmsWickness === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "ArmsWickness")}
                    /> Neither</label>

                        </div>

                 </div>


                 <div className="col-sm-12">
                  <div className="col-sm-3 pr-0">
                            <label>Cogwheel rigidity</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.CogwheelRigidity === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "CogwheelRigidity")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.CogwheelRigidity === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "CogwheelRigidity")}
                    /> No</label>

                        </div>

                    <div className="col-sm-3 pr-0">
                            <label>Leg weakness</label>
                        </div>
                        <div className="col-sm-4 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.LegWeakness === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "LegWeakness")}
                    /> Right</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.LegWeakness === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "LegWeakness")}
                    /> Left</label>
                      &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.LegWeakness === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "LegWeakness")}
                    /> Neither</label>
                        </div>

                 </div>


                 <div className="col-sm-12">
                    <div className="col-sm-3 pr-0">
                            <label>Arm numbness</label>
                        </div>
                        <div className="col-sm-4 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.ArmNumbness === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "ArmNumbness")}
                    /> Right</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.ArmNumbness === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "ArmNumbness")}
                    /> Left</label>
                      &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.ArmNumbness === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "ArmNumbness")}
                    /> Neither</label>
                        </div>
                        </div>

                        <div className="col-sm-12">
                        <div className="col-sm-3 pr-0">
                            <label style={{marginTop: '5px'}}>Foot drop</label>
                        </div>
                        <div className="col-sm-4 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.FootDrop === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "FootDrop")}
                    /> Right</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.FootDrop === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "FootDrop")}
                    /> Left</label>
                      &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.FootDrop === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "FootDrop")}
                    /> Neither</label>
                        </div>

                 </div>


                 <div className="col-sm-12">
                    <div className="col-sm-3 pr-0">
                            <label>Leg numbness</label>
                        </div>
                        <div className="col-sm-4 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.LegNumbness === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "LegNumbness")}
                    /> Right</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.LegNumbness === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "LegNumbness")}
                    /> Left</label>
                      &nbsp; &nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.LegNumbness === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "LegNumbness")}
                    /> Neither</label>
                        </div>

                 </div>



                 <div className="col-sm-12 mt-3">
                 <div className="col-sm-1"></div>
                  <div className="col-sm-3">
                  <label style={{ fontWeight: 'bold' }}>Right eye:</label>
                        </div>
                        <div className="col-sm-3" style={{float: 'left',marginTop: ''}}>
                        <label style={{ fontWeight: 'bold' }}>Left eye:</label>
                        </div>
                     </div>

            <div className="col-sm-12">
                  <div className="col-sm-1">
                            <label>Ptosis</label>
                        </div>
                        <div className="col-sm-3">
                        <label class="checkbox-inline">
                          <input type="checkbox" value="Yes"
                      checked={this.props.vals_examination.RightEyePtosis === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "RightEyePtosis")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline" style={{marginLeft: '18px' }}><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.RightEyePtosis === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "RightEyePtosis")}
                    /> No</label>

                        </div>
                        <div className="col-sm-3">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.LeftEyePtosis === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "LeftEyePtosis")}
                    /> Yes</label> 
                    &nbsp; &nbsp;
                    <label class="checkbox-inline" style={{marginLeft: '18px' }}><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.LeftEyePtosis === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "LeftEyePtosis")}
                    /> No</label>

                        </div>

             </div>

                 <div className="col-sm-12">
                  <div className="col-sm-1">
                            <label>EOM</label>
                        </div>
                        <div className="col-sm-3">
                           <label class="checkbox-inline"><input type="checkbox"
                           value="Normal" 
                           checked={this.props.vals_examination.RightEyeEOM == "Normal"}
                           onChange={e => this.props.handleOptionChange(e, "cns_examination", "RightEyeEOM")}
                           
                           /> Normal</label>
                           <label class="checkbox-inline"><input type="checkbox" 
                            value="Abnormal" 
                            checked={this.props.vals_examination.RightEyeEOM == "Abnormal"}
                            onChange={e => this.props.handleOptionChange(e, "cns_examination", "RightEyeEOM")}
                           
                           /> Abnormal</label>
                        </div>
                        <div className="col-sm-3">
                           <label class="checkbox-inline"><input type="checkbox"  
                            value="Normal" 
                            checked={this.props.vals_examination.LeftEyeEOM == "Normal"}
                            onChange={e => this.props.handleOptionChange(e, "cns_examination", "LeftEyeEOM")}
                            
                           /> Normal</label>
                           <label class="checkbox-inline"><input type="checkbox" 
                            value="Abnormal" 
                            checked={this.props.vals_examination.LeftEyeEOM == "Abnormal"}
                            onChange={e => this.props.handleOptionChange(e, "cns_examination", "LeftEyeEOM")}
                            
                            /> Abnormal</label>

                        </div>

            </div>

                 <div className="col-sm-12">
                  <div className="col-sm-1">
                            <label>Fields</label>
                        </div>
                        <div className="col-sm-3">
                           <label class="checkbox-inline"><input type="checkbox"  
                             value="Normal" 
                             checked={this.props.vals_examination.RightFields == "Normal"}
                             onChange={e => this.props.handleOptionChange(e, "cns_examination", "RightFields")}
                           /> Normal</label>
                           <label class="checkbox-inline"><input type="checkbox" 
                             value="Abnormal" 
                             checked={this.props.vals_examination.RightFields == "Abnormal"}
                             onChange={e => this.props.handleOptionChange(e, "cns_examination", "RightFields")}
                           /> Abnormal</label>

                        </div>
                        <div className="col-sm-3">
                           <label class="checkbox-inline"><input type="checkbox"  
                           value="Normal" 
                           checked={this.props.vals_examination.LeftFields == "Normal"}
                           onChange={e => this.props.handleOptionChange(e, "cns_examination", "LeftFields")}
                           /> Normal</label>
                           <label class="checkbox-inline"><input type="checkbox" 
                             value="Abnormal" 
                             checked={this.props.vals_examination.LeftFields == "Abnormal"}
                             onChange={e => this.props.handleOptionChange(e, "cns_examination", "LeftFields")}
                           />Abnormal</label>

                        </div>


             </div>


                 <div className="col-sm-12">
                  <div className="col-sm-1">
                            <label>Pupils</label>
                        </div>
                        <div className="col-sm-3">
                           <label class="checkbox-inline"><input type="checkbox"  
                           
                           value="Normal" 
                           checked={this.props.vals_examination.RightPupils == "Normal"}
                           onChange={e => this.props.handleOptionChange(e, "cns_examination", "RightPupils")}


                           /> Normal</label>
                           <label class="checkbox-inline"><input type="checkbox" 
                           
                           value="Abnormal" 
                           checked={this.props.vals_examination.RightPupils == "Abnormal"}
                           onChange={e => this.props.handleOptionChange(e, "cns_examination", "RightPupils")}

                           /> Abnormal</label>

                        </div>
                        <div className="col-sm-3">
                        <label class="checkbox-inline"><input type="checkbox"  
                           
                           value="Normal" 
                           checked={this.props.vals_examination.LeftPupils == "Normal"}
                           onChange={e => this.props.handleOptionChange(e, "cns_examination", "LeftPupils")}


                           /> Normal</label>
                           <label class="checkbox-inline"><input type="checkbox" 
                           
                           value="Abnormal" 
                           checked={this.props.vals_examination.LeftPupils == "Abnormal"}
                           onChange={e => this.props.handleOptionChange(e, "cns_examination", "LeftPupils")}

                           /> Abnormal</label>

                        </div>

                     </div>
           </div>

                <div className="modal-footer" >
                      <button type="button" className="btn btn-primary btn-xs m-0" data-dismiss="modal"
                       onClick={e => 
                        {this.props.handleSaveOptions(e, "cns");
                        close();}
                        }
                      >Save</button>
                      <button type="button" className="btn btn-primary btn-xs m-0 ml-2" onClick={() => close()} data-dismiss="modal">Cancel</button>
                    </div>
 </div>
 </div>




)}
</Popup>
         );
    }
}

export default cns ;
