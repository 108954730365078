import React, { Component } from 'react';
import Popup from 'reactjs-popup';

import Viewer from 'react-viewer';
import {api_upload_image,api_get_image_name} from '../../../../DS/DS.VisitsNotes'
import { connect } from 'react-redux';
import Showhelp from './../../../showHelp';

class skin extends Component {
   constructor(props)
   {
       super(props);
       this.state={
        errors:[],
        selectedFile:'',
        image_name:'',
        key:0,
        viewFull:false
       }
   }
   
  componentWillMount=()=>
  {
      console.log("&&&&&&&&&&&&&&&&&&",this.props.selected_appointment)
      this.props.getImage({id:this.props.selected_appointment.appoinment_id}).then(res=>{
          if(res.data.content!='')
          {
            this.setState({ image_name: res.data.content[0].image_name});
            this.setState({appendPath:'/Skin/'}) 
            this.setState({key:Math.random()})
          }
         
      })
  }
  
    onChangeHandler=event=>{
        this.setState({
        selectedFile: event.target.files[0],
        loaded: 0,
      })
      const data = new FormData() 
      data.append('image',event.target.files[0])
      data.append('appoint',this.props.selected_appointment.appoinment_id)
      this.props.uploadimage(data).then(
        res=>{
            this.setState({ image_name: res.data.content});
                this.setState({appendPath:'/Skin/'}) 
                this.setState({key:Math.random()})   
        }
      )
  }

  changeKey=()=>
  {
      this.setState({key:Math.random(),viewFull:false})
  }
  
    render() {
        return (
            <Popup trigger={<a  title="Skin" className="collapsed" aria-expanded="false"><span className="skin"></span></a>} position="right center"   modal
            closeOnDocumentClick={false}
           >
             {close => (

<div style={{ width:'800px', margin:'0px auto'}} >
             <div className="skinlay" style={{ width:'800px', marginLeft:'0px', backgroundColor:'#FFF', float:'left'}}>

                 <div className="modal-header" 
                //  style={{width: '100%', backgroundColor: '', padding: '5px 15px'}}
                 >

                      <button type="button" className="close" style={{ marginTop: '0px',fontSize: '25px' }} onClick={() => close()} data-dismiss="modal" aria-label="Close">
                      ×
                      </button>
                      <span> Skin <Showhelp gotohelp="skin_help"/></span>
                      {/* <h4 style={{backgroundColor: '', float: 'left', width: '100%', fontSize: '25px', margintop: '-5px'}}>Skin</h4> */}
                    </div>
                    <div className="modal-body" style={{ border:'0px' }}>
                    <div className="col-sm-12 pl-0 pr-0 ml-0"><h4 className="mt-0">History:</h4></div>

                        <div className="col-sm-12 mb-3">
                  <div className="col-sm-1 cus-widf pr-0">
                            <label>Rash</label>
                        </div>
                        <div className="col-sm-3 pr-0">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Rash === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "skin", "Rash")}
                    /> Yes</label> 

                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Rash === "No"}
                      onChange={e => this.props.handleOptionChange(e, "skin", "Rash")}
                    /> No</label>
                        </div>
                    <div className="col-sm-1 cus-widf">
                            <label>Itch</label>
                        </div>
                        <div className="col-sm-3 pr-0">
                       
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Itch === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "skin", "Itch")}
                    /> Yes</label> 

                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Itch === "No"}
                      onChange={e => this.props.handleOptionChange(e, "skin", "Itch")}
                    /> No</label>


                        </div>

                        <div className="col-sm-1 cus-widf pr-0 pl-0">
                            <label>Lesion</label>
                        </div>
                        <div className="col-sm-3 pr-0">
                       
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Lesion === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "skin", "Lesion")}
                    /> Yes</label> 
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Lesion === "No"}
                      onChange={e => this.props.handleOptionChange(e, "skin", "Lesion")}
                    /> No</label>


                        </div>
                </div>
				
				<div className="col-sm-12 pl-0 pr-0 ml-0"><h4>Examination:</h4></div>

                <div className="col-sm-12 mb-2">
                        <div className="col-sm-1 mt-2 pr-0">
                            <label>Lesions:</label>
                        </div>
                         <div className="col-sm-9 pr-0" style={{float: 'left',marginTop: '0px', display:'none'}}>
                        <input type="text" style={{width: '70px'}} 
                        value={this.props.vals_examination.Lesion}
                        onChange={e => this.props.handleOptionChange(e, "skin_examination", "Lesion")}
                        /> %
                        </div>
               


                        {/*<div className="col-sm-1">
                        <label style={{marginTop: '0px'}}>Digrams:</label>
                        </div>
                        <div className="col-sm-6 ">
                        <select style={{width:"100%"}}>
                                <option>Full body.front </option>
                            </select>
                        </div>*/}
                </div>


                <div className="col-sm-12">
                <div className="col-sm-6">
                <textarea rows="4" cols="45" className="mt-0" placeholder='Description' value={this.props.vals_examination.Description}
                        onChange={e => this.props.handleOptionChange(e, "skin_examination", "Description")}>

                      </textarea>
                        </div>
                 <div className="col-sm-6" style={{float:'right'}}>
                        <img src={`${process.env.REACT_APP_IMAGE_BASE_URL}`+this.state.appendPath+this.state.image_name}
                   style={{height:'90px',width:'300px'}} />
                 </div>       

                </div>


                <div className="col-sm-12">
                    <div className="col-sm-1 pr-0 mt-1"><label>Image :</label></div>
                    <div className="col-md-9">
                        <input type="file" name="file" style={{ border:'0px'}} onChange={this.onChangeHandler} required/>
                        <span className="error" style={{color:'red'}}>{this.state.errors["file"]}</span>
                    </div>

                {/*}<div className="col-sm-1" style={{float: 'left',marginTop: '0px'}}>
                 <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal">Add</button>

                        </div>
                        <div className="col-sm-1" style={{float: 'left',marginTop: '0px'}}>
                 <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal">Edit</button>

                        </div>

                        <div className="col-sm-1" style={{float: 'left',marginTop: '0px'}}>
                 <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal">Remove</button>

                    </div>*/}

                </div>

                <div className="col-sm-12">
                <div className="col-sm-1 pr-0 mt-2">
                        <label>Rash:</label>
             </div>
                        <div className="col-sm-3 " style={{textAlign:'left'}}>
                        <select className="mt-1" style={{width:"100%"}}
                        value={this.props.vals_examination.Rash}
                        onChange={e => this.props.handleOptionChange(e, "skin_examination", "Rash")}>
                                <option value="Macular">Macular</option>
                                <option value="Papular">Papular</option>
                                <option value="Maculo-papular">Maculo-papular</option>
                                <option value="Erythematous">Erythematous</option>
                                <option value="Scaly">Scaly</option>
                                <option value="Vesicular">Vesicular</option>
                                <option value="Pustular">Pustular</option>
                                <option value="Follicular">Follicular</option>
                                <option value="Secondarily infected">Secondarily infected</option>
                                <option value="Ulcerated">Ulcerated</option>
                                <option value="Excoriated">Excoriated</option>
                                <option value="Psoriatic">Psoriatic</option>
                                <option value="Depigmented">Depigmented</option>
                                <option value="Hyperpigmented">Hyperpigmented</option>
                                <option value="Atrophic">Atrophic</option>

                                
                            </select>
                        </div>
                          {this.state.viewFull ?  
                            <Viewer
                              visible={this.state.viewFull}
                              images={[{src: `${process.env.REACT_APP_IMAGE_BASE_URL}`+this.state.appendPath+this.state.image_name, alt: ''}]}
                              onClose={()=>{this.setState({viewFull:false}) ; this.changeKey()}}
                           //   onClick={()=>this.saveData()}
                              />:''
                        }
                        </div>


                            <div className="col-lg-12 m-b-20">
                            <div className="row" style={{padding: '10px 0'}}>


                            </div>{/*row*/}
                            <div className="card" style={{paddingRight: '0px', paddingLeft: '0px', marginTop: '5px', overflow: 'scroll', height: '300px' }}>
                                <div className="card-block">
                                <table className="calendar table table-bordered tablestyle">
                                <thead>
                                    <th style={{backgroundColor:'#333', color:'#000'}}>Distribution</th>
                                    </thead><tbody>
                                    <tr>
                                        <td> <input type="checkbox" /> &nbsp; Face </td>
                                    </tr>

                                    <tr>
                                        <td> <input type="checkbox" /> &nbsp; Scalp </td>
                                    </tr>
                                    <tr>
                                        <td> <input type="checkbox" /> &nbsp; Neck </td>
                                    </tr>
                                    <tr>
                                        <td> <input type="checkbox" /> &nbsp; Forehead</td>
                                    </tr>
                                    <tr>
                                        <td> <input type="checkbox" /> &nbsp; Cheek</td>
                                    </tr>
                                    <tr>
                                        <td> <input type="checkbox" /> &nbsp; Nose </td>
                                    </tr>
                                    <tr>
                                        <td> <input type="checkbox" /> &nbsp; Chest </td>
                                    </tr>
                                    <tr>
                                        <td> <input type="checkbox" /> &nbsp; Back </td>
                                    </tr>
                                    <tr>
                                        <td> <input type="checkbox" /> &nbsp; Abdomen</td>
                                    </tr>
                                    <tr>
                                        <td> <input type="checkbox" /> &nbsp; Shoulders(s)</td>
                                    </tr>
                                    <tr>
                                        <td> <input type="checkbox" /> &nbsp; Axilla(e) </td>
                                    </tr>
                                    <tr>
                                        <td> <input type="checkbox" /> &nbsp; Arm(s)</td>
                                    </tr>
                                    <tr>
                                        <td> <input type="checkbox" /> &nbsp; Forearm(s)</td>
                                    </tr>
                                    <tr>
                                        <td> <input type="checkbox" /> &nbsp; Hand(s) </td>
                                    </tr>
                                    <tr>
                                        <td> <input type="checkbox" /> &nbsp; Finger(s)</td>
                                    </tr>
                                    <tr>
                                        <td> <input type="checkbox" /> &nbsp; Palm(s)</td>
                                    </tr>
                                    <tr>
                                        <td> <input type="checkbox" /> &nbsp; Buttock(s) </td>
                                    </tr>
                                    <tr>
                                        <td> <input type="checkbox" /> &nbsp; Groin</td>
                                    </tr>
                                    <tr>
                                        <td> <input type="checkbox" /> &nbsp; Thigh(s)</td>
                                    </tr>
                                    <tr>
                                        <td> <input type="checkbox" /> &nbsp; Leg(s) </td>
                                    </tr>
                                    <tr>
                                        <td> <input type="checkbox" /> &nbsp; Feet</td>
                                    </tr>
                                    <tr>
                                        <td> <input type="checkbox" /> &nbsp; Toe(s)</td>
                                    </tr>
                                    <tr>
                                        <td> <input type="checkbox" /> &nbsp; Sole(s)</td>
                                    </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                         </div>

      </div>

          <div className="modal-footer"   >
          <button className="btn btn-primary btn-xs m-0" onClick={() => this.setState({viewFull:true})}>Fullscreen</button>
                      
                      <button type="button" className="btn btn-primary btn-xs m-0 ml-2" data-dismiss="modal" 
                      onClick={e => 
                        {this.props.handleSaveOptions(e, "skin");
                        close();}
                        }

>Save</button>
                      <button type="button" className="btn btn-primary btn-xs m-0 ml-2" onClick={() => close()} data-dismiss="modal">Cancel</button>
                    </div>

                    </div>


          </div>
)}
</Popup>
         );
    }
}
const mapStateToProps = state => {
        let selected_appointment =
        state.getDrAppointments.selected_appointment != undefined
          ? state.getDrAppointments.selected_appointment
          : [];
    return {
     selected_appointment
    };
  };
const mapDispatchToProps = dispatch => {
    return {
      uploadimage : data => dispatch(api_upload_image(data)),
      getImage:data => dispatch(api_get_image_name(data))
     };
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(skin);