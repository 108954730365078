import axios from 'axios';

export const SET_VISITNOTES_DETAILS = 'SET_VISITNOTES_DETAILS';
export const setVisitnotesDetails = VisitNotesDetails => ({
	type: SET_VISITNOTES_DETAILS,
	VisitNotesDetails,
});
export const api_set_visitnotes_details = Details => dispatch => {
	console.log("pushing to redux", Details);

	dispatch(setVisitnotesDetails(Details));
};

export const api_savewordfill = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=visit&action=savewordfill`, data);

}
export const api_listwordfill = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=visit&action=listwordfill`, data);

}
export const api_deletewordfill = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=visit&action=deletewordfill`, data);

}

export const api_list_tests = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=visit&action=list_tests`, data);
}
export const api_list_group = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=visit&action=list_groups`, data);
}

export const api_addnewtest = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=visit&action=addnewtest`, data);
}

export const api_addnewgroup = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=visit&action=addnewgroup`, data);
}

export const api_save_pathology = (data) => dispatch => {
	console.log(data);
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=visit&action=save_pathology`, data);
}
export const api_save_pathology2 = (data) => dispatch => {
	console.log(data);
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=visit&action=save_pathology2`, data);
}
export const api_addenchance = (data) => dispatch => {
	console.log(data);
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=visit&action=api_addenchance`, data);
}

 
/*
export const api_save_pathology = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=visit&action=save_pathology`, data);
} */
export const api_previous_requests = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=visit&action=list_previous_requests`, data);
}
export const api_deleteTest = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=visit&action=delete_test`, data);

}

export const api_deleteGroup = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=visit&action=delete_group`, data);

}
export const api_deleteLab = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=visit&action=api_delete_Lab`, data);

}
export const api_toggleFav = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=visit&action=toggleFav`, data);

}
export const api_addNewLab = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=visit&action=api_addNewLab`, data);

}
export const api_add_other = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=visit&action=api_add_other`, data);

}
export const api_list_options2 = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=visit&action=api_list_options2`, data);

}
export const api_getVisitTypes = (data) => dispatch => {
	return axios.get(`${process.env.REACT_APP_BASE_URL}api=visit&action=visit_type`, data);

}


export const api_patients_pastvisits = (data) => dispatch => {
	//console.log(data);
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=visit&action=patients_pastvisits`, data);
}

export const api_addVisitNote = (data) => dispatch => {
	//console.log(data);
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=visit&action=add_visit_notes`, data);
}
export const api_visitnotes_list_options = (data) => dispatch => {
	//console.log(data);
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=visit&action=visitnotes_list_options`, data);
}

export const list_providers = (data) => dispatch => {
	return axios.get(`${process.env.REACT_APP_BASE_URL}api=visit&action=list_providers`, data);
}


export const patients_visit_reason = (data) => dispatch => {
	return axios.get(`${process.env.REACT_APP_BASE_URL}api=visit&action=list_patients_visit_reason`, data);
}

export const search_visit_notes = (data) => dispatch => {
	return axios.get(`${process.env.REACT_APP_BASE_URL}api=visit&action=search_visit_notes`, data);
}


export const api_get_notes= (data) => dispatch => {
	return axios.get(`${process.env.REACT_APP_BASE_URL}api=visit&action=get_notes`, data);
}
export const api_upload_image = data => dispatch => {
	console.log(">>>",data)
  return axios
	.post( `${process.env.REACT_APP_BASE_URL}api=visit&action=upload_image_skin`,data);
   
};

export const api_get_image_name = data => dispatch => {
	console.log(">>>",data)
  return axios
	.post( `${process.env.REACT_APP_BASE_URL}api=visit&action=get_image_name`,data);
   
};


