import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import Showhelp from './../../../showHelp';


class musculoskeletal extends Component {
    state = {  }
    render() {
        return (
            <Popup trigger={<a title="Musculo-skeletal" className="collapsed" aria-expanded="false"><span className="musculoskeletal"></span></a>} position="right center"   modal
            closeOnDocumentClick={false}>
             {close => (
                <div style={{ width:'800px', margin:'0px auto'}} >
                 <div className="muscluo" style={{ width:'800px', marginLeft:'0px', backgroundColor:'#FFF', float:'left'}}>

       <div className="modal-header" 
      //  style={{width: '100%', backgroundColor: '', padding: '5px 15px'}}
       >

                      <button type="button" className="close" style={{ marginTop: '0px',fontSize: '25px' }} onClick={() => close()} data-dismiss="modal" aria-label="Close">
                      ×
                      </button>
                      <span> Musculo skeletal <Showhelp gotohelp="musculo_help"/></span>
                      {/* <h4 style={{backgroundColor: '', float: 'left', width: '100%', fontSize: '25px', margintop: '-5px'}}>Musculo-skeletal</h4> */}
                    </div>
                    <div className="modal-body" style={{ border:'0px' }}>
                    <div className="col-sm-12 pl-0 pr-0 ml-0"><h4 className="mt-0">History:</h4></div>


                        <div className="col-sm-12">
                  <div className="col-sm-2 ">
                            <label>Neck pain</label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.NeckPain === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "NeckPain")}
                    /> Yes</label> 
                    &nbsp;&nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.NeckPain === "No"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "NeckPain")}
                    /> No</label>

                        </div>
                    <div className="col-sm-2 ">
                            <label>Back pain</label>
                        </div>
                        <div className="col-sm-4">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.BackPain === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "BackPain")}
                    /> Yes</label> 
                    &nbsp;&nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.BackPain === "No"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "BackPain")}
                    /> No</label>

                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 ">
                            <label>Shoulder pain</label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.ShoulderPain === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "ShoulderPain")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.ShoulderPain === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "ShoulderPain")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.ShoulderPain === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "ShoulderPain")}
                    /> Neither</label>
                        </div>
                    <div className="col-sm-2 ">
                            <label>Hip pain</label>
                        </div>
                        <div className="col-sm-4">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.HipPain === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "HipPain")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.HipPain === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "HipPain")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.HipPain === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "HipPain")}
                    /> Neither</label>
                        </div>
                </div>



                <div className="col-sm-12">
                  <div className="col-sm-2 ">
                            <label>Elblow pain</label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.ElblowPain === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "ElblowPain")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.ElblowPain === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "ElblowPain")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.ElblowPain === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "ElblowPain")}
                    /> Neither</label>
                        </div>
                    <div className="col-sm-2 ">
                            <label>Knee pain</label>
                        </div>
                        <div className="col-sm-4">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.KneePain === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "KneePain")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.KneePain === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "KneePain")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.KneePain === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "KneePain")}
                    /> Neither</label>
                        </div>
                </div>



                <div className="col-sm-12">
                  <div className="col-sm-2 ">
                            <label>Wrist pain</label>
                        </div>
                        <div className="col-sm-4">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.WristPain === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "WristPain")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.WristPain === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "WristPain")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.WristPain === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "WristPain")}
                    /> Neither</label>
                        </div>
                    <div className="col-sm-2 ">
                            <label>Ankle pain</label>
                        </div>
                        <div className="col-sm-4">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.AnklePain === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "AnklePain")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.AnklePain === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "AnklePain")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.AnklePain === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "AnklePain")}
                    /> Neither</label>

                        </div>
                </div>



                <div className="col-sm-12">
                  <div className="col-sm-2 ">
                            <label>Hand pain</label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.HandPain === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "HandPain")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.HandPain === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "HandPain")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.HandPain === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "HandPain")}
                    /> Neither</label>
                        </div>
                    <div className="col-sm-2 ">
                            <label>Foot pain</label>
                        </div>
                        <div className="col-sm-4">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.FootPain === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "FootPain")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.FootPain === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "FootPain")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.FootPain === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "FootPain")}
                    /> Neither</label>

                        </div>
                </div>



                <div className="col-sm-12">
                  <div className="col-sm-2 ">
                            <label>Finger pain</label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.FingerPain === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "FingerPain")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.FingerPain === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "FingerPain")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.FingerPain === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "FingerPain")}
                    /> Neither</label>
                        </div>
                    <div className="col-sm-2 ">
                            <label>Toe pain</label>
                        </div>
                        <div className="col-sm-4">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.ToePain === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "ToePain")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.ToePain === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "ToePain")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.ToePain === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "ToePain")}
                    /> Neither</label>

                        </div>
                </div>



                <div className="col-sm-12">
                  <div className="col-sm-2 ">
                            <label>Injury</label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Injury === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "Injury")}
                    /> Yes</label> 
                    &nbsp;&nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Injury === "No"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "Injury")}
                    /> No</label>

                        </div>

                        <div className="col-sm-4">


                        </div>
                </div>
               
                <div className="col-sm-12 pl-0 pr-0 ml-0"><h4>Examination:</h4></div>

                <div className="col-sm-12">
                <div className="col-sm-1 pr-0">
                            <label>Joints:</label>
                        </div>
                        <div className="col-sm-9 pr-0">
                        <textarea rows="4" cols="50"  placeholder='Description'
                        value={this.props.vals_examination.Joint}
                        onChange={e => this.props.handleOptionChange(e, "musculoskeletal_examination", "Joint")}
                        >

                        
                        </textarea>

                        </div>

                     {/*}   <div className="col-sm-2"  style={{}}>
                      <button type="button" className="btn btn-primary" style={{padding:'6px 12px', fontSize:'14px',width:'',height:'',marginTop:'15px'}} data-dismiss="modal">Add</button>
                      <button type="button" className="btn btn-default" style={{padding:'6px 12px', fontSize:'14px',width:'',height:'',marginTop:'5px'}} data-dismiss="modal">Remove</button>
             </div>*/}
                    <div class="col-sm-1 mt-3 pr-0" style={{textAlign:'right'}}>
          <button type="button" class="btn btn-primary btn-xs" data-dismiss="modal" style={{width:'70px', marginBottom:'5px'}}>Add</button>
          <button type="button" class="btn btn-primary btn-xs" data-dismiss="modal" style={{width:'70px'}}>Remove</button></div>
                        </div> 


             </div>

                  <div className="modal-footer"   >
                      <button type="button" className="btn btn-primary btn-xs m-0" data-dismiss="modal"
                         onClick={e => 
                          {this.props.handleSaveOptions(e, "musculoskeletal");
                          close();}
                          }
                          >Save</button>
                      <button type="button" className="btn btn-primary btn-xs m-0 ml-2" onClick={() => close()} data-dismiss="modal">Cancel</button>
                    </div>
                    </div>
        </div>
)}
</Popup>
         );
    }
}

export default musculoskeletal ;
