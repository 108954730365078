import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import Showhelp from './../../../showHelp';


class ent extends Component {
    state = {  }
    render() {
        return (
            <Popup trigger={<a  title="ENT" className="collapsed" aria-expanded="false"><span className="ent"></span></a>} position="right center"   modal
            closeOnDocumentClick={false}
          >
             {close => (
               <div style={{ width:'800px', margin:'0px auto'}} >
          <div className="entlay" style={{ width:'800px', marginLeft:'0px', backgroundColor:'#FFF', float:'left'}}>

            <div className="modal-header"
            //  style={{width: '100%', backgroundColor: '', padding: '5px 15px'}}
             >

                      <button type="button" className="close" style={{marginTop: '0px',fontSize: '25px'}} onClick={() => close()} data-dismiss="modal" aria-label="Close">
                      ×
                      </button>
                      <span> ENT <Showhelp gotohelp="ent_help"/></span>
                      {/* <h4 style={{backgroundColor: '', float: 'left', width: '100%', fontSize: '25px', margintop: '-5px'}}>ENT</h4> */}
                    </div>
                    <div className="modal-body" style={{ border:'0px' }}>
                    <div className="col-sm-12 pl-0 pr-0 ml-0"><h4 className="mt-0">History:</h4></div>

                        <div className="col-sm-12">
                  <div className="col-sm-3 ">
                            <label>Earache</label>
                        </div>
                        <div className="col-sm-4">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.Earache === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "Earache")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.Earache == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "Earache")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.Earache === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "Earache")}
                    /> Neither</label>
                           
                        </div>
                    <div className="col-sm-2 ">
                            <label>Sore throat</label>
                        </div>
                        <div className="col-sm-3">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.SoreThroat === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "SoreThroat")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.SoreThroat === "No"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "SoreThroat")}
                    /> No</label>

                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-3">
                            <label>Ear discharge</label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.EarDischarge === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "EarDischarge")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.EarDischarge === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "EarDischarge")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.EarDischarge === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "EarDischarge")}
                    /> Neither</label>
                           
                        </div>
                    <div className="col-sm-2 ">
                            <label>Hoarse voice</label>
                        </div>
                        <div className="col-sm-3">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.HoarseVoice === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "HoarseVoice")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.HoarseVoice === "No"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "HoarseVoice")}
                    /> No</label>

                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-3 ">
                            <label>Deafness</label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.Deafness === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "Deafness")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.Deafness === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "Deafness")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.Deafness === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "Deafness")}
                    /> Neither</label>
                           
                        </div>
                    <div className="col-sm-2 ">
                            <label>Lost voice</label>
                        </div>
                        <div className="col-sm-3">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.LostVoice === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "LostVoice")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.LostVoice === "No"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "LostVoice")}
                    /> No</label>

                        </div>
                </div>



                <div className="col-sm-12">
                  <div className="col-sm-3 ">
                            <label>Rhinorrhoea</label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Rhinorrhoea === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "Rhinorrhoea")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Rhinorrhoea === "No"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "Rhinorrhoea")}
                    /> No</label>
                        </div>
                    <div className="col-sm-2 ">
                            <label>Facial pain</label>
                        </div>
                        <div className="col-sm-3">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.FacialPain === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "FacialPain")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.FacialPain === "No"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "FacialPain")}
                    /> No</label>

                        </div>
                </div>
                <div className="col-sm-12">
                  <div className="col-sm-3 ">
                            <label>Epistaxis</label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Epistaxis === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "Epistaxis")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Epistaxis === "No"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "Epistaxis")}
                    /> No</label>
                        </div>

                        <div className="col-sm-3">


                        </div>
                </div>

                <div className="col-sm-12">
                  <div className="col-sm-3 ">
                            <label>Nasal congestion</label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.NasalCongestion === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "NasalCongestion")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.NasalCongestion === "No"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "NasalCongestion")}
                    /> No</label>
                        </div>

                        <div className="col-sm-3">


                        </div>
                </div>

                <div className="col-sm-12 pl-0 pr-0 ml-0"><h4>Examination:</h4></div>

                <div className="col-sm-12">
                  <div className="col-sm-2 ">
                            <label>Drum red</label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.DrumRed == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "DrumRed")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.DrumRed == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "DrumRed")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.DrumRed == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "DrumRed")}
                    /> Neither</label>
                           
                        </div>
                    <div className="col-sm-2 ">
                            <label>Red throat</label>
                        </div>
                        <div className="col-sm-4">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.RedThroat == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "RedThroat")}
                    /> Yes</label> 
                    &nbsp;&nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.RedThroat == "No"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "RedThroat")}
                    /> No</label>

                        </div>
                </div>



                <div className="col-sm-12">
                  <div className="col-sm-2 ">
                            <label>Discharge </label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Discharge == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Discharge")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Discharge == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Discharge")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Discharge == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Discharge")}
                    /> Neither</label>
                           
                        </div>
                    <div className="col-sm-2 ">
                            <label>Pus on tonsils</label>
                        </div>
                        <div className="col-sm-4">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.PusOnTonsils == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "PusOnTonsils")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.PusOnTonsils == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "PusOnTonsils")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.PusOnTonsils == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "PusOnTonsils")}
                    /> Neither</label>
                           

                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 ">
                            <label>Perforation</label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Perforation == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Perforation")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Perforation == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Perforation")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Perforation == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Perforation")}
                    /> Neither</label>
                           
                        </div>
                    <div className="col-sm-2 ">
                            <label>Enlarged tonsils</label>
                        </div>
                        <div className="col-sm-4">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.EnlargedTonsils == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "EnlargedTonsils")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.EnlargedTonsils == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "EnlargedTonsils")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.EnlargedTonsils == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "EnlargedTonsils")}
                    /> Neither</label>
                           
                        </div>
                </div>

                <div className="col-sm-12">
                  <div className="col-sm-2 ">
                            <label>Glue </label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Glue == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Glue")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Glue == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Glue")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Glue == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Glue")}
                    /> Neither</label>
                           
                        </div>
                    <div className="col-sm-2 ">
                            <label>Nose discharge</label>
                        </div>
                        <div className="col-sm-4">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.NoseDischarge == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "NoseDischarge")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.NoseDischarge == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "NoseDischarge")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.NoseDischarge == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "NoseDischarge")}
                    /> Neither</label>
                           

                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 ">
                            <label>Canal red</label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.CanalRed == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "CanalRed")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.CanalRed == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "CanalRed")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.CanalRed == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "CanalRed")}
                    /> Neither</label>
                           
                        </div>
                    <div className="col-sm-2 ">
                            <label>Nasal obstruction</label>
                        </div>
                        <div className="col-sm-4">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.NasalObstruction == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "NasalObstruction")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.NasalObstruction == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "NasalObstruction")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.NasalObstruction == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "NasalObstruction")}
                    /> Neither</label>
                           
                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 ">
                            <label>Wax </label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Wax == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Wax")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Wax == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Wax")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Wax == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Wax")}
                    /> Neither</label>
                           
                        </div>
                    <div className="col-sm-2 ">
                            <label>Nose redness</label>
                        </div>
                        <div className="col-sm-4">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.NoseRedness == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "NoseRedness")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.NoseRedness == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "NoseRedness")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.NoseRedness == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "NoseRedness")}
                    /> Neither</label>
                           
                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 ">
                            <label>FB</label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Fb == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Fb")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Fb == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Fb")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Fb == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Fb")}
                    /> Neither</label>
                           
                        </div>
                    <div className="col-sm-2 ">
                            <label>Nasal FB</label>
                        </div>
                        <div className="col-sm-4">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.NasalFB == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "NasalFB")}
                    /> Right</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.NasalFB == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "NasalFB")}
                    /> Left</label>
                      
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.NasalFB == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "NasalFB")}
                    /> Neither</label>
                           
                        </div>
                </div>

                <div className="col-sm-12 mt-3">
                  <div className="col-sm-2 ">
                            <label>Mouth lesion</label>
                        </div>
                        <div className="col-sm-4">

                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.MouthLesion == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "MouthLesion")}
                    /> Yes</label> 
                    &nbsp;&nbsp;
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.MouthLesion == "No"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "MouthLesion")}
                    /> No</label>

                        </div>
                    <div className="col-sm-2 ">
                            <label>Description:</label>
                        </div>
                        <div className="col-sm-3 pr-0">
                        <input type="text" 
                        value={this.props.vals_examination.Description}
                        onChange={e => this.props.handleOptionChange(e, "ent_examination", "Description")}
                        />

                        </div>
                </div>

            </div>

                <div className="modal-footer"  >
                      <button type="button" className="btn btn-primary btn-xs m-0" data-dismiss="modal"
                      
                      onClick={e => 
                        {this.props.handleSaveOptions(e, "ent");
                        close();}
                        }
                        >Save</button>
                      <button type="button" className="btn btn-primary btn-xs m-0 ml-2" onClick={() => close()} data-dismiss="modal">Cancel</button>
                    </div>
                    </div>

 </div>


)}
</Popup>
         );
    }
}

export default ent;
