import React, { Component } from 'react';

import './womenhistory.css';
import axios from 'axios';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert';
import Popup from "reactjs-popup";
import { connect } from "react-redux";
import {
    api_getDoctors
  } from "./../../../DS/DS.Appointments";
  import DatePicker from "react-datepicker";
import {api_add_screening,api_get_whistory,api_update_history} from '../../../DS/DS.WomenHistory'
import Showhelp from './../../showHelp';

class AddHistory extends Component {
    constructor(props)
      {
          super(props)
          this.state={
              openModal:this.props.showmodal,
              doctors:[],
              performed_date:new Date(),
              reminder_date:new Date(),
              performed_by:'',
              test:'PAP',
              unsatisfactory_spec:'',
              endoservical:'',
              hpv_changes:'',
              hpv16:'',
              hpv18:'',
              hpv:'',
              lbc_result:'',
              result:'',
              other_info:''

          }    
      }

    componentWillMount=()=>
    {
        
    }

      handleFieldValueChanges(key, value) {
        this.setState({
          [key]: value
        });
      }
      componentDidMount(){                   
        this.props.doctors().then(res => {
          this.setState({ doctors: res.data.content });
        });
      //  alert(this.props.sid)
        if(this.props.sid!='')
        {
              this.props.getHistory({id:this.props.sid}).then(res=>{
               console.log('&&&&&&&&&&&&&&&&&&',res.data.content[0])
                this.setState({
                performed_date:new Date(),
                reminder_date:'',
                performed_by:res.data.content[0].performed_by,
                unsatisfactory_spec:res.data.content[0].unsatisfactory_spec,
                endoservical:res.data.content[0].endoservical,
                hpv_changes:res.data.content[0].hpv_changes,
                hpv16:res.data.content[0].hpv16,
                hpv18:res.data.content[0].hpv18,
                hpv:res.data.content[0].hpv,
                lbc_result:res.data.content[0].lbc_result,
                result:res.data.content[0].result,
                other_info:res.data.content[0].other_info,
                test:res.data.content[0].test,
                result:res.data.content[0].result
               })
               
            })  
        }
      }
      
      handleDate= date =>
      {
        this.setState({
            performed_date: date //moment(new Date(date)).format('DD/MM/YYYY')
        }); 

      }
      handleDate1=date=>
      {
          this.setState({reminder_date:date})
      }

      onSubmit=()=>
      {

        const sendVal=({
            performed_date:this.state.performed_date,
            performed_by:this.state.performed_by,
            test:this.state.test,
            reminder_date:this.state.reminder_date,
            unsatisfactory_spec:this.state.unsatisfactory_spec,
            endoservical:this.state.endoservical,
            hpv_changes:this.state.hpv_changes,
            hpv16:this.state.hpv16,
            hpv18:this.state.hpv18,
            hpv:this.state.hpv,
            lbc_result:this.state.lbc_result,
            result:this.state.result,
            patientId:this.props.redux_patientId,
            other_info:this.state.other_info,
            send_reminder:this.state.send_reminder
        })
        this.props.addScreening(sendVal).then(res=>{
            console.log("&&&&&&&&&&&&&&",res)
            this.props.changeKey()
        })
      }
      onEdit=()=>{
        const sendVal=({
            performed_date:this.state.performed_date,
            performed_by:this.state.performed_by,
            test:this.state.test,
            reminder_date:this.state.reminder_date,
            unsatisfactory_spec:this.state.unsatisfactory_spec,
            endoservical:this.state.endoservical,
            hpv_changes:this.state.hpv_changes,
            hpv16:this.state.hpv16,
            hpv18:this.state.hpv18,
            hpv:this.state.hpv,
            lbc_result:this.state.lbc_result,
            result:this.state.result,
            patientId:this.props.redux_patientId,
            other_info:this.state.other_info,
            send_reminder:this.state.send_reminder,
            id:this.props.sid
        })
        this.props.updateHistory(sendVal).then(res=>{
            this.props.changeKey()
        })
      }
      render() 
      { 
        return (
         
             <Popup  open={this.state.openModal}  position="right center" modal closeOnDocumentClick={false}>
                  <div style={{ width:'550px', marginLeft:'320px', backgroundColor:'#FFF', float:'left'}}>
                <div className="modal-header">
                <button type="button" className="close" onClick={() =>this.props.changeKey()} data-dismiss="modal" aria-label="Close">
                    {" "}×
                </button>
                <span>Cervical screening result <Showhelp gotohelp="add_cervical_history_help"/></span>
                </div>
                <div className="modal-body"  style={{ fontSize: "14px",background:'white',color:'black' }}>
                    <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-sm-2 mt-2 col-sm-02 pr-0">
                            <label>Date performed:</label>
                        </div>
                        <div className="col-sm-3 pr-0">
                                <DatePicker
                                        className="datePicker"
                                     //   value= {this.state.StartDate}
                                        selected={this.state.performed_date}
                                        onChange={this.handleDate}
                                        dateFormat="dd/MM/yyyy"
                                        id="remindercalendar"
                                    />
                        </div>
                        <div className="col-sm-6">
                        &nbsp;
                        </div>
                    </div>
                    <div className="row row-distance">
                        <div className="col-sm-2 col-sm-02 pr-0">
                        <label >Performed by:</label>
                        </div>
                        <div className="col-sm-4 pr-0">
                        <select name="performed_by" style={{ width: "100%", float: "left" }} value={this.state.performed_by}
                        onChange={e =>this.handleFieldValueChanges("performed_by",e.target.value)}>
                                <option value="">Select One</option>
                            {this.state.doctors.map(d => (
                                <option value={d.doctor_id}> Dr. {d.Firstname} </option>
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-5 pr-0">
                            <input type="checkbox" name="inactive" />&nbsp;<label>Include inactive providers</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-2 col-sm-02 pr-0">
                        <label>&nbsp;</label>
                        </div>
                        <div className="col-sm-6 pr-0">
                        <label className="radio-inline">
                        <input type="radio" name="test" onChange={e =>this.handleFieldValueChanges("test","CST")} 
                            value="CST" checked={this.state.test == "CST"} />&nbsp;Cervical screening test
                       </label>
                            <label className="radio-inline">
                        <input type="radio"  name="test" onChange={e =>this.handleFieldValueChanges("test","PAP")}
                            value="PAP" checked={this.state.test == "PAP"}/>&nbsp;Pap smear
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-2 col-sm-02 pr-0">
                        <label>&nbsp;</label>
                        </div>
                        <div className="col-sm-4">
                            <label>
                                <input type="checkbox" name="unsatisfactory_spec" value='1'
                                    onChange={e =>this.handleFieldValueChanges("unsatisfactory_spec",e.target.checked?1:0)}
                                    checked={this.state.unsatisfactory_spec == 1} />&nbsp;Unsatisfactory specimen
                             </label>       
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-2 col-sm-02 pr-0">
                        <label>&nbsp;</label>
                        </div>
                        <div className="col-sm-6 pr-0">
                        <label className="checkbox-inline">
                            <input type="checkbox" name="endoservical" value='1' 
                            onChange={e =>this.handleFieldValueChanges("endoservical",e.target.checked?1:0)}
                            checked={this.state.endoservical == 1} />&nbsp;Endoservical Cells
                         </label>   
                            <label className="checkbox-inline">
                                <input type="checkbox" name="hpv_changes" value='1' 
                                onChange={e =>this.handleFieldValueChanges("hpv_changes",e.target.checked?1:0)}
                                checked={this.state.hpv_changes == 1} />&nbsp;HPV Changes
                             </label>   
                        </div>
                    </div>
                    {this.state.test == "CST" ? 
                        <div id="cs">                            
                        <div className=" row">
                            <div className="col-sm-2 col-sm-02 pr-0">
                            <label>HPV PCR:</label>
                            </div>
                            <div className="col-sm-3">
                            HPV 16
                            </div>
                            <div className="col-sm-6">
                                <label className="radio-inline">
                                    <input type="radio" name="hpv16" id="hpv16" value="1" 
                                    onChange={e =>this.handleFieldValueChanges("hpv16",1)}
                                    checked={this.state.hpv16 == 1}  />&nbsp;Detected
                                 </label>
                                <label className="radio-inline">
                                    <input type="radio" name="hpv16" id="hpv16" value="0" 
                                    onChange={e =>this.handleFieldValueChanges("hpv16",0)}
                                    checked={this.state.hpv16 == 0} />&nbsp;Not Detected
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-2 col-sm-02 pr-0">
                            <label></label>
                            </div>
                            <div className="col-sm-3">
                            HPV 18
                            </div>
                            <div className="col-sm-6">
                                <label className="radio-inline">
                                    <input type="radio" name="hpv18" value="1" onChange={e =>this.handleFieldValueChanges("hpv18",1)}
                                    checked={this.state.hpv18 == 1} />&nbsp;Detected
                                </label>
                                <label className="radio-inline">
                                    <input type="radio" name="hpv18" value="0" onChange={e =>this.handleFieldValueChanges("hpv18",0)}
                                    checked={this.state.hpv18 == 0} />&nbsp;Not Detected
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-2 col-sm-02 pr-0">
                            <label></label>
                            </div>
                            <div className="col-sm-3">
                            HPV not 16/18
                            </div>
                            <div className="col-sm-6">
                                <label className="radio-inline">
                                    <input type="radio" name="hpv" value="1" onChange={e =>this.handleFieldValueChanges("hpv",1)} checked={this.state.hpv == 1} />&nbsp;Detected
                                </label>
                                <label className="radio-inline">
                                    <input type="radio" name="hpv" value="0" onChange={e =>this.handleFieldValueChanges("hpv", 0)}checked={this.state.hpv == 0} />&nbsp;Not Detected
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-2 mt-2 col-sm-02 pr-0">
                            <label>LBC Result</label>
                            </div>
                            <div className="col-sm-6">
                            <select name="lbc_result" value={this.state.lbc_result} 
                                onChange={e =>this.handleFieldValueChanges("lbc_result",e.target.value)}>
                                <option value="">select one</option>
                                <option value="Negative">Negative</option>                                  
                                <option value="Unsatisfactory">Unsatisfactory</option>
                                <option value="Possible low grade squamous intraepithelial lesion">Possible low grade squamous intraepithelial lesion</option>
                                <option value="Low grade squamous intraepithelial lesion">Low grade squamous intraepithelial lesion</option>
                                <option value="High grade squamous intraepithelial lesion">High grade squamous intraepithelial lesion</option>
                                <option value="Atypical endometrial cells of undetermined significance">Atypical endometrial cells of undetermined significance</option>
                                <option value="Atypical glandular cells of undetermined significance">Atypical glandular cells of undetermined significance</option>
                                <option value="Possible high grade glandular lesion">Possible high grade glandular lesion</option>
                                <option value="Endocervical adenocarcinoma in situ">Endocervical adenocarcinoma in situ</option>
                                <option value="Adenocarcinoma of the cervix">Adenocarcinoma of the cervix</option>
                            </select>
                            </div>
                        </div>
                        </div>
                    :''}
                    {this.state.test == "PAP" ? 
                        <div id="ps">
                        <div className="row">
                            <div className="col-sm-2 mt-3 col-sm-02 pr-0">
                            <label>Result</label>
                            </div>
                            <div className="col-sm-6">
                            <select name="result" value={this.state.result} onChange={e =>this.handleFieldValueChanges("result",e.target.value)}>
                                <option value="">select one</option>
                                <option value="Negative">Negative</option>                                  
                                <option value="Unsatisfactory">Unsatisfactory</option>
                                <option value="Possible low grade squamous intraepithelial lesion">Possible low grade squamous intraepithelial lesion</option>
                                <option value="Low grade squamous intraepithelial lesion">Low grade squamous intraepithelial lesion</option>
                                <option value="High grade squamous intraepithelial lesion">High grade squamous intraepithelial lesion</option>
                                <option value="Squamous cell carcinoma cervix">Squamous cell carcinoma cervix</option>
                                <option value="Atypical endometrial cells of undetermined significance">Atypical endometrial cells of undetermined significance</option>
                                <option value="Atypical glandular cells of undetermined significance">Atypical glandular cells of undetermined significance</option>
                                <option value="Possible high grade glandular lesion">Possible high grade glandular lesion</option>
                                <option value="Endocervical adenocarcinoma in situ">Endocervical adenocarcinoma in situ</option>
                                <option value="Adenocarcinoma of the cervix">Adenocarcinoma of the cervix</option>
                                <option value="Inflamatory">Inflamatory</option>
                                <option value="C.I.N.1">C.I.N.1</option>
                                <option value="C.I.N.2">C.I.N.2</option>
                                <option value="C.I.N.3">C.I.N.3</option>
                                <option value="Invasive cancer">Invasive cancer</option>
                                <option value="Atypic">Atypic</option>
                                <option value="Atypia">Atypia</option>
                                <option value="Low grade abnormality">Low grade abnormality</option>
                                <option value="High grade abnormality">High grade abnormality</option>
                                <option value="Unknown">Unknown</option>
                            </select>
                            </div>
                        </div>
                        </div>
                    :''}
                    <div className="row">
                        <div className="col-sm-2 mt-1 col-sm-02 pr-0">
                        <label>&nbsp;Other information</label>
                        </div>
                        <div className="col-sm-9">
                        <textarea name="other_info" className="mt-0 mb-0" style={{width: '100%', height: '60px', border: '1px solid #ddd'}} defaultValue={this.state.other_info} onChange={(e)=>this.setState({other_info:e.target.value})}/>
                        </div>
                    </div>  
                    <div className="row">
                        <div  className="col-sm-12 mb-3" >
                        <label for="send_reminder" className="immuntext" > 
                            <input style={{marginTop:'0px'}} id="send_reminder" type="checkbox"
                            checked={this.state.send_reminder == "1" ? true : false}
                            onChange={e =>this.handleFieldValueChanges("send_reminder", e.target.checked?1:0)}/>
                            {" "}Send reminder 
                        </label>
                        </div>
                        {this.state.send_reminder == "1" ? 
                        <span className="col-sm-2 col-form-label text-right immun">
                            Reminder Date:
                        </span>
                        : ''}
                        {this.state.send_reminder == "1" ? 
                        <div className="col-sm-6">
                             <DatePicker
                                        className="datePicker"
                                     //   value= {this.state.StartDate}
                                        selected={this.state.reminder_date}
                                        onChange={this.handleDate1}
                                        dateFormat="dd/MM/yyyy"
                                        id="remindercalendar"
                                    />
                        </div>
                        : ''}
                    </div>
                    </div>
                </div>
                <div className="modal-footer" style={{background:'white',marginTop:'unset'}}>
                   {this.props.sid!=''? 
                        <button type="button" className="btn btn-primary btn-xs m-0"  onClick={()=>this.onEdit()}>Save</button>  
                    :<button type="button" className="btn btn-primary btn-xs m-0"  onClick={()=>this.onSubmit()}>Save</button>  }<button type="button"
                        onClick={() => this.props.changeKey()} className="btn btn-primary btn-xs m-0 ml-2" data-dismiss="modal">
                        Cancel
                    </button>
                </div>
                </div>
            </Popup>
           );
      }
  }
  const mapDispatchToProps = dispatch => {
    return {    
      doctors: data => dispatch(api_getDoctors(data)),
      addScreening:data=>dispatch(api_add_screening(data)),
      getHistory:data=>dispatch(api_get_whistory(data)),
      updateHistory:data=>dispatch(api_update_history(data))

    };
  };
  
  const mapStateToProps = state => {
  
    let redux_patientId =
      state.getDrAppointments.getPatientDetails != undefined
        ? state.getDrAppointments.getPatientDetails[0].patient_id
        : "";
    let patient_age =
      state.getDrAppointments.getPatientDetails != undefined
        ? state.getDrAppointments.getPatientDetails[0].age
        : "";
        let logged_info =
          state.getDrAppointments.logged_info != undefined
            ? state.getDrAppointments.logged_info
            : "";   
  
    return {
      redux_patientId,
      patient_age, logged_info
    };
  };
  export default connect(mapStateToProps,mapDispatchToProps) (AddHistory);

