import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import {api_search_condition,api_add_family_relationship,api_get_relationship,api_get_relationship_master} from '../../../DS/DS.FamilyHistory';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import Showhelp from './../../showHelp';

class FamilyRelationship extends Component
{
    constructor(props)
    {
        super(props)
        this.state=
        {
            open:this.props.showmodal,
            key:0,
            relationship:1,
            relationship_no:'',
            relationship_condition:'',
            selected_condition:'',
            other_info:'',
            all_condition:[],
            save_condition:'',
            all_relations:[]
           
        }
    }
    componentWillMount=()=>
    {
        this.props.getRelations().then(
            req=>{
                console.log(req.data.content);
                this.setState({all_relations:req.data.content})
            }
        )
        if(this.props.showfor=='Edit')
        {
                const rdata={
                    relationship_id:this.props.sid
                }
                this.props.getRelationship(rdata).then(
                    req=>
                    {this.setState({relationship:req.data.content.relationship,
                    relationship_no:req.data.content.relation_no,
                relationship_condition:req.data.content.name,
                    save_condition:req.data.content.relationship_condition,
                other_info:req.data.content.other_info})
                    
                    }
                )
        }
    }
    searchCondition=(e)=>
    {
        const searchtext={
            searchText:e.target.value
        }
        this.props.searchCondition(searchtext).then(
            req=>
            {
                this.setState({all_condition:req.data.content})
            }
        )
        this.setState({selected_condition:e.target.value})
    }
    selectClick=(Condition)=>
    {
        this.setState({relationship_condition:Condition.name,save_condition:Condition.condition_id})
    }
    saveClickHandler=()=>
    {
        if(this.props.showfor=='Add')
        {
            const values={
                relationship:this.state.relationship,
                relationship_condition:this.state.save_condition,
                relation_no:this.state.relationship_no,
                other_info:this.state.other_info,
                sid:'',
                patient_id:this.props.redux_patientId
            }
            this.props.addUpdateRelationship({values}).then(
                req=>
                
                    this.props.changeKey(),
                    NotificationManager.success('Relationship condition stored successfully')
                
            )
        }
        else
        {
            const values={
                relationship:this.state.relationship,
                relationship_condition:this.state.save_condition,
                relation_no:this.state.relationship_no,
                other_info:this.state.other_info,
                sid:this.props.sid,
               
            }
            this.props.addUpdateRelationship({values}).then(
                req=>
                    this.props.changeKey(),
                    NotificationManager.success('Relationship condition stored successfully')
                
            )
        }
    }
    render() {
        return ( 
                <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
                {close => (
                    <div style={{ width:'500px', margin: '0px auto'}}> 
                    <div style={{ width:'100%', backgroundColor:'#FFF', float:'left'}}>       
                        <div className="modal-header" style={{width:'100%', backgroundColor:'', padding: '0px 15px'}}>
                            <h6 style={{backgroundColor:'', float:'left', width:'100%', fontSize:'16px'}}> Family history details <Showhelp gotohelp="add_relationship_help"/></h6>
                                <button type="button" className="close" data-dismiss="modal" style={{marginTop:'-28px'}} aria-label="Close" onClick={()=>this.props.changeKey()}>
                                    <span aria-hidden="true">×</span>
                                </button>
                        </div>
                        <div className="modal-body problem-div">
                        <div className="col-sm-12">
                           <div className="div-margin"></div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="col-form-label text-right"> Relationship :</label>
                                </div>		
                                <div className="col-sm-5">
                                    <select name="select" className="form-control mt-0" style={{padding:'.0rem .5rem', height:'30px',
                                        fontSize:'14px'}} onChange={(e)=>this.setState({relationship:e.target.value})} value={this.state.relationship}>
                                            {this.state.all_relations != undefined && this.state.all_relations.length> 0 && this.state.all_relations.map((relcat) => 
                                            <option key={relcat.relation_id} value={relcat.relation_id}>{relcat.relation_name}</option>)}
                                     </select>
			                    </div>	  
			                    <div className="col-sm-1 pl-0 pr-0">
			                        <label className="col-form-label text-right"> No :</label>
			                    </div>	
			                    <div className="col-sm-2" style={{ marginLeft:'-25px'}}>
                                    <input type="text" value="" name="search_condition" className="form-control mt-0 form-control-sm rq-form-elemen"
                                    value={this.state.relationship_no} onChange={(e)=>this.setState({relationship_no:e.target.value})}/>
                                </div>	
                                <div className="col-sm-2"></div>
                            </div>
                            <div className="row">
			                    <div className="col-sm-3">
			                        <label className="col-form-label text-right"> Condition :</label>
			                    </div>	
			                    <div className="col-sm-9">
                                    <input type="text" value="" name="search_condition" className="form-control mt-0 form-control-sm rq-form-elemen"
                                    value={this.state.relationship_condition}/>
                                </div>
			                </div>
			                <div className="row">
			                    <div className="col-sm-3">
			                        <label className="col-form-label text-right"> Search list :</label>
			                    </div>	
			                    <div className="col-sm-9">
                                    <input type="text" value="" name="search_condition" className="form-control mt-0 form-control-sm rq-form-elemen"
                                    value={this.state.selected_condition} onChange={(e)=>this.searchCondition(e)}/>
                                </div>	
			                </div>
			                <div className="row">
			                    <div className="col-sm-3"> </div>	
			                    <div className="col-sm-9">
			   	                  <table>
                                     {this.state.all_condition != undefined && this.state.all_condition.length> 0 && this.state.all_condition.map((allCond) =>
                                         <tr onClick={(e)=>this.selectClick(allCond)}>
                                             <td>
                                                {allCond.name}
                                             </td>
                                         </tr>
                                     )}
                                     </table>
                                </div>	
			                </div>
			                <div className="row">
			                    <div className="col-sm-3 pr-0">
			   	                    <label className="col-form-label text-right"> Other information :</label>
			                    </div>	
			                    <div className="col-sm-9">
			   	                    <textarea className="mt-0" style={{width:'100%', height:'80px', border:'1px solid #cccccc'}}
                                       value={this.state.other_info} onChange={(e)=>this.setState({other_info:e.target.value})}></textarea>
                                </div>	
			                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary btn-xs m-0" onClick={()=>this.saveClickHandler()}>Save</button>
                                <button type="button" className="btn btn-primary btn-xs m-0 ml-2" data-dismiss="modal" onClick={()=>this.props.changeKey()}>Cancel</button>
                            </div>
                        </div>
                    </div>
                    </div>
                )
            }
        </Popup>
    )}
}
const mapDispatchToProps = dispatch => {
    return {
            searchCondition: (data) => dispatch(api_search_condition(data)),
            addUpdateRelationship:(data)=>dispatch(api_add_family_relationship(data)),
            getRelationship:(data)=>dispatch(api_get_relationship(data)),
            getRelations:()=>dispatch(api_get_relationship_master())
            };
  };
  const mapStateToProps = state => {
     let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
     let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
 
   return {
     redux_patientId,redux_docId
   };
 };

export default connect(
mapStateToProps,
mapDispatchToProps
) (FamilyRelationship);
