import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import moment from "moment";
import { connect } from 'react-redux';
import axios from "axios";
import {NotificationContainer,NotificationManager,} from 'react-notifications';
import {
    api_searchPatients
    
  } from "./../../../DS/DS.Appointments";
import Showhelp from './../../showHelp';

class mims extends Component {
  constructor(props) {
    super(props);
    this.state={
		open:this.props.open,
        products: [],
        searchType: "product",
        searchText: "",
        isActive:""
    }
  }
     
  handleChangeText = e => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
    this.searchProduct(e.target.value);
  };
  
  handleChangeText2 = e => {
	this.setState({ searchType: e.target.value });
	this.setState({searchText: ""});
	this.setState({ products: "" });
	document.getElementById('pinfo').innerHTML = '';
  };
  
  searchProduct(searchvalue) {	  
    this.setState({searchText: searchvalue});
	if(searchvalue.length > 2) {
		var searchParam = {
		  searchType: this.state.searchType,
		  searchText: searchvalue
		};
		//document.getElementById('loadingmsg').innerHTML = 'Loading...'; 
		axios
		  .post(
			`${process.env.REACT_APP_BASE_URL}api=mims&action=getmimsresponse`,
			searchParam
		  )
		  .then(res => {
			this.setState({ products: res.data });
		  });
	} else {
		this.setState({ products: "" });
		document.getElementById('pinfo').innerHTML = '';
	}
  }
  

 mimsclose() {
  this.setState({ showmims: false }); 
 }

  toggleActive = i => {
    if (i === this.state.isActive) {
      this.setState({
        isActive: null
      });
    } else {
      this.setState({
        isActive: i
      });
    }
  };
 
 getProductInfo(productId){ 
	//alert(productId);
		var searchParam = {
		  searchType: "productinfo",
		  searchText: productId
		};
		document.getElementById('pinfo').innerHTML = 'Loading...';	
		axios
		  .post(
			`${process.env.REACT_APP_BASE_URL}api=mims&action=getmimsresponse`,
			searchParam
		  )
		  .then(res => {
			let htmlresponse =  ' \
							<table id="table_03" class="table" border="1px solid black"> \
								<thead> \
									<th>Brand</th> \
									<th>Product name</th> \
									<th>OffMarket</th> \
									<th>BlackTriangle</th> \
									<th>Aip</th> \
									<th>AirCode</th> \
									<th>Drowsy</th> \
									<th>Prescription</th> \
								</thead> \
								<tbody> \
									<tr> \
										<td>'+res.data.brand.brandName+'</td> \
										<td>'+res.data.productName+'</td> \
										<td>'+res.data.offMarket+'</td> \
										<td>'+res.data.blackTriangle+'</td> \
										<td>'+res.data.aip+'</td> \
										<td>'+res.data.airCode+'</td> \
										<td>'+res.data.drowsy+'</td> \
										<td>'+res.data.prescription+'</td> \
									</tr> \
								</tbody> \
							</table> \
							 \
							<h4>ProductPacks (Product pack(s))</h4> \
							<table id="table_03_productPacks" class="table" border="1px solid black">  \
								<thead> \
									<tr> \
										<th>productPack name</th> \
									</tr> \
								</thead> \
								<tbody>';
								
					res.data.productPacks.forEach((element, index, array) => {						
						htmlresponse += '<tr> \
											<td>'+element.productPackName+'</td> \
										</tr> ';
						});	
						
					htmlresponse += '</tbody> \
							</table> \
							 \
							<h4>Roas (Route(s) of administration)</h4> \
							<table id="table_03_roas" class="table" border="1px solid black"> \
								<thead> \
									<tr> \
										<th>roas</th> \
									</tr> \
								</thead> \
								<tbody>';
								
					res.data.roas.forEach((element, index, array) => {						
						htmlresponse += '<tr> \
											<td>'+element+'</td> \
										</tr> ';
						});	
								
						htmlresponse += '</tbody> \
						</table> \
							 \
							<h4>atcCodes (Anatomical Therapeutic and Chemical (ATC) codes)</h4> \
							<table id="table_03_atcCodes" class="table" border="1px solid black"> \
								<thead> \
									<tr> \
										<th>atcCodes</th> \
									</tr> \
								</thead> \
								<tbody>';
								
					res.data.atcCodes.forEach((element, index, array) => {						
						htmlresponse += '<tr> \
											<td>'+element+'</td> \
										</tr> ';
						});	
								
						htmlresponse += '</tbody> \
							</table> \
							 \
							<h4>vp (Virtual Product)</h4> \
							<table id="table_03_vp" class="table" border="1px solid black"> \
								<thead> \
									<tr> \
										<th>vp</th> \
									</tr> \
								</thead> \
								<tbody>';
								
						htmlresponse += '<tr> \
											<td>'+res.data.vp.vpName+'</td> \
										</tr> ';
								
						htmlresponse += '</tbody> \
							</table> \
							 \
							<h4>monographs (monographs)</h4> \
							<table id="table_03_monographs" class="table" border="1px solid black"> \
								<thead> \
									<tr> \
										<th>monographs</th> \
									</tr> \
								</thead> \
								<tbody>';
								
					res.data.monographs.forEach((element, index, array) => {						
						htmlresponse += '<tr> \
											<td>'+element.monographType+'</td> \
										</tr> ';
						});	
								
						htmlresponse += '</tbody> \
							</table> \
							 \
							<h4>amt (Australian Medicines Terminology)</h4> \
							<table id="table_03_amt" class="table" border="1px solid black"> \
								<thead> \
									<tr> \
										<th>tpCode</th> \
										<th>tpName</th> \
									</tr> \
								</thead> \
								<tbody>';
								
					res.data.amt.forEach((element, index, array) => {						
						htmlresponse += '<tr> \
											<td>'+element.tpCode+'</td> \
											<td>'+element.tpName+'</td> \
										</tr> ';
						});	
							
						htmlresponse += '</tbody> \
							</table> \
							 \
							<h4>acgs (Composition)</h4> \
							<table id="table_03_acgs" class="table" border="1px solid black"> \
								<thead> \
									<tr> \
										<th>acg Name</th> \
									</tr> \
								</thead> \
								<tbody>';
								
					res.data.acgs.forEach((element, index, array) => {						
						htmlresponse += '<tr> \
											<td>'+element.acgName+'</td> \
										</tr> ';
						});	
							
						htmlresponse += '</tbody> \
							</table> \
							 \
							<h4>mimsClasses (therapeutic classification)</h4> \
							<table id="table_03_mimsClasses" class="table" border="1px solid black"> \
								<thead> \
									<tr> \
										<th>mimsClasses</th> \
									</tr> \
								</thead> \
								<tbody>';
								
					res.data.mimsClasses.forEach((element, index, array) => {						
						htmlresponse += '<tr> \
											<td>'+element+'</td> \
										</tr> ';
						});	
							
						htmlresponse += '</tbody> \
							</table> \
							 \
							<h4>indications (uses of a drug)</h4> \
							<table id="table_03_indications" class="table" border="1px solid black"> \
								<thead> \
									<tr> \
										<th>indications</th> \
									</tr> \
								</thead> \
								<tbody>';
								
					res.data.indications.forEach((element, index, array) => {						
						htmlresponse += '<tr> \
											<td>'+element+'</td> \
										</tr> ';
						});	
							
						htmlresponse += '</tbody> \
							</table> \
							 \
							<h4>vis (Virtual Item)</h4> \
							<table id="table_03_vis" class="table" border="1px solid black"> \
								<thead> \
									<tr> \
										<th>vis</th> \
									</tr> \
								</thead> \
								<tbody>';
								
					res.data.vis.forEach((element, index, array) => {						
						htmlresponse += '<tr> \
											<td>'+element.viName+'</td> \
										</tr> ';
						});	
							
						htmlresponse += '</tbody> \
							</table> \
							 \
							<h4>cmis (Consumer Medicines Information)</h4> \
							<table id="table_03_cmis" class="table" border="1px solid black"> \
								<thead> \
									<tr> \
										<th>cmis</th> \
									</tr> \
								</thead> \
								</thead> \
								<tbody>';
								
					res.data.cmis.forEach((element, index, array) => {						
						htmlresponse += '<tr> \
											<td>'+element.cmiName+'</td> \
										</tr> ';
						});	
							
						htmlresponse += '</tbody> \
							</table><p>&nbsp;</p>\
						';
						
			document.getElementById('pinfo').innerHTML = htmlresponse;			
		  });
}
 
 
 
 
 render() {
    return (
	  <Popup position="right center"   modal closeOnDocumentClick={true} open={this.state.open} >
	  {close => (
         <div style={{ width:'1250px', margin:'0px auto'}}>
         <div style={{ width:'1250px', marginLeft:'-120px', backgroundColor:'#FFF', float:'left'}}>
                <div className="modal-header">
                <div className="popup-title">
                    <span id=""><b>MIMS</b><Showhelp gotohelp="select_patients_values"/></span>	 
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.close()} style={{ position: "inherit", right: "10px", fontSize: '25px' }}>
                            <span aria-hidden="true">×</span>
                        </button>
                </div>
                </div>
                <div className="modal-body" style={{color: '#000'}}>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <div className="col-sm-4 pr-0 cursor">
                                    <input type="text" name="text"  className="form-control" autocomplete="off"              
									value={this.state.searchText}
									className="form-control input-lg app-seaqch"
									placeholder="Enter Search Keyword"
									onChange={this.handleChangeText}
									/> 
									</div>
									
							<div class="col-sm-12 col-md-6 col-xl-4 m-b-0 listbox">
								<label class="label-input100 listinbox">
									<span style={{color: "#000",marginRight: "10px"}}>Search By :</span>
									<input type="radio" value="product" name="searchType" checked={this.state.searchType === "product"} onClick={this.handleChangeText2} />
									<span style={{color: "#000",marginRight: "10px"}}>Product</span>
									<input type="radio" value="composition" name="searchType" checked={this.state.searchType === "composition"} onClick={this.handleChangeText2} />
									<span style={{color: '#000'}}>Composition</span>
								</label>
								
							</div>		   
						   </div>
                        </div>
                    </div>
                    
                    <div className="row row-distance">
                        <div className="col-sm-12 pl-1"> 
                        <div
              className="box-body no-padding patsearch "
              style={{
                height: "400px",
                overflowY: "scroll",
                marginLeft: "20px",
                marginTop: "10px"
              }}
            >
			 <table style={{width: '100%'}} className="table table-striped input-sm">
				 <tr>
					<td valign="top" style={{width: '30%'}}>
					  <table className="table table-striped input-sm">
						<tbody>
						  <tr>
							<th>Product Name</th>
						  </tr>
						  {this.state.products != undefined && this.state.products.length > 0 ? (
							this.state.products.map((product, i) => (
							  <tr
								className="pointercursor"
								style={
								  this.state.isActive === i
									? { background: "black", color: "white" }
									: { background: "", }
								}
								key={i}
								name="patient_id"
								onClick={() => {
								  //this.props.getPatient(product, i);
								  this.getProductInfo(product.productId);
								  this.toggleActive(i);
								  
								}}
							  >
								<td>
									{product.productName}						
								</td>
							  </tr>
							))
						  ) : (
							<tr>
							  <td><span id="loadingmsg">No Records Found</span></td>
							</tr>
						  )}
						</tbody>
					  </table>
				  </td>
				 <td valign="top" style={{width: '70%'}}>
					  <table style={{width: '100%'}} className="table table-striped input-sm">
						<tbody>
						  <tr>
							<th id="pinfo">
							
							</th>
						  </tr>
						</tbody>
					  </table>		  
				  </td>
				  </tr>
			  </table>
              </div>
                        </div>
                         
                         
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-sm-6"> </div>
                        <div className="col-sm-6">
                            <button type="button" className="btn btn-primary" data-dismiss="modal" aria-label="Close"  onClick={()=>this.props.close()}>Close</button> 
                        </div>
                    </div>
                </div>
                </div>
            </div>
         )} 
        </Popup>
    )}
}

const mapDispatchToProps = dispatch => {
    return {
        searchPatients: searchParam => dispatch(api_searchPatients(searchParam))
        
     };
  };
  const mapStateToProps = state => {
  
    let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
    
    return {
        redux_userId
    };
  };
  export default connect(mapStateToProps,mapDispatchToProps)(mims);
