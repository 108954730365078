import React, { Component } from "react";
import Popup from "reactjs-popup";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import { confirmAlert } from "react-confirm-alert"; // Import
import { connect } from "react-redux";

//services
import {
  api_list_appointment_care_plans,
  api_deleteCreateAccountItem,
  api_deleteCreateAccountItems,
  api_add_create_account_item,
  api_savecreateaccount
} from "./../../DS/DS.VisitnotesFinalizeVisit";
import { api_list_options } from "./../../DS/DS.Investigation";
import { api_getPatientDetails } from "./../../DS/DS.Appointments";

//popups
import Showhelp from "./../showHelp";
import EditPatientDetails from "./../appointment/editPatientDetails";
import PayNowPopup from "./../appointment/paynowPopup";
import BillingHistory from "./../patientHistory/summary/billingHistory";
import AddItem from "./addItem";
import EditItem from "./edititemCreateaccount";
import UselastaccountPopup from "./UselastaccountPopup";
import ServiceNotes from "./../crudcomponent/serviceNotes";

class createAccount extends Component {
  state = {
    //popups
    additem: false,
    patientDetailsPopup: false,
    billingHistoryPopup: false,
    paynowPopup: false,
    edititemPopup: false,
    uselastaccountPopup: false,
    openAddItem: false,
    createAccount: false,
    servicenotes: false,

    // patients_careplan: [], - check


    disableButtons: false,

    //form details
    patient_careplan_id: "",
    selectedPatientDetails: [],
    invoice_date: new Date(),
    service_date: new Date(),
    invoice_no: "",
    location: "",
    provider: "",
    billTo: "Medicare Direct bill",
    billingSchedule: "Rebate Only",
    patient_id: "",
    patients_careplan: [],
    visit_duration: "",
    provider_notes: "",
    notes: "",
    notes_to_reception: "",

    //saved from service
    selectedInvoiceFull: [],
    selectedInvoiceItem: [],
    written_servicenotes: [],

    notnormal: "",
    in_hospital: ""
  };
  constructor(props) {
    super(props);
    this.addItem = this.addItem.bind(this);
  }
  componentDidMount() {
    this.props.list_options().then(res => {
      //   console.log("addPatientForm addPatientForm",res);
      this.setState({ list_options: res.data.content });
      console.log(this.state.list_options);
    });
  }

  closefrompaypopup(){
   // this.props.closeCreateAccount();
  }
  componentWillReceiveProps(nextProps) {
    //FINALIZE BILLING
    if (
      this.props.from == "finalizebilling" &&
      this.props.selected_appointment2 == undefined &&
      this.props.selectedPatientDetails != undefined &&
      this.props.selectedPatientDetails.patient_id > 0
    ) {
      this.props
        .list_appointment_care_plans({
          app_id: nextProps.selected_appointment.appoinment_id
        })
        .then(res => {
          this.setState({ patients_careplan: res.data.content });
          let selectedInvoiceItems = res.data.patients_careplan;
          
          if(selectedInvoiceItems!= undefined && selectedInvoiceItems[0].status == "Sent"){
            this.setState({disableButtons: true});
          } else {
            this.setState({disableButtons: false});
          }

          this.setState({
            patients_careplan_header: res.data.patients_careplan
          });

          // CAREPLAN ID
          if (
            res.data.patients_careplan != undefined &&
            res.data.patients_careplan.length > 0
          ) {
            this.setState({
              patient_careplan_id:
                res.data.patients_careplan[0].patients_careplan_id,
              invoice_no: res.data.patients_careplan[0].appoinment_id,
              notes_to_reception: res.data.patients_careplan[0].reception_notes,
              notnormal: res.data.patients_careplan[0].not_normal_aftercare,
              in_hospital: res.data.patients_careplan[0].in_hospital,
              visit_duration: res.data.patients_careplan[0].visit_length
            });
          } else {
            //  alert("elsefrom finalize billing patients careplan undefined");
          }
        });
    }

    //FROM CALENDAR
    if (
      this.props.from != "finalizebilling" &&
      this.props.selected_appointment2 != undefined &&
      this.props.selected_appointment2.appoinment_id != undefined
    ) {
      this.props
        .list_appointment_care_plans({
          app_id: this.props.selected_appointment2.appoinment_id,
          app_details: this.props.selected_appointment2,
          notes_to_reception: this.state.notes_to_reception
        })
        .then(res => {
          this.setState({ patients_careplan: res.data.content });
          let selectedInvoiceItems = res.data.patients_careplan;
          
          if(selectedInvoiceItems!= undefined && selectedInvoiceItems.length > 0 && selectedInvoiceItems[0].status == "Sent"){
            this.setState({disableButtons: true});
          } else {
            this.setState({disableButtons: false});
          }
          this.setState({
            patients_careplan_header: res.data.patients_careplan
          });

          // CAREPLAN ID
          if (
            res.data.patients_careplan != undefined &&
            res.data.patients_careplan.length > 0
          ) {
            console.log(
              "patients careplan appointement id",
              res.data.patients_careplan[0]
            );

            this.setState({
              patient_careplan_id:
                res.data.patients_careplan[0].patients_careplan_id,
              invoice_no: res.data.patients_careplan[0].appointment_id,
              notes_to_reception: res.data.patients_careplan[0].reception_notes,
              visit_duration: res.data.patients_careplan[0].visit_length,

              notnormal: res.data.patients_careplan[0].not_normal_aftercare,
              in_hospital: res.data.patients_careplan[0].in_hospital
            });
          } else {
            //      alert("elsefrom finalize billing patients careplan undefined");
          }

          //GETTING PATIENT DETAILS
          if (this.state.selectedPatientDetails.length == 0) {
            this.props.getPatientDetails({
              patient_id: this.props.selected_appointment2.patient_id,
              invoice_no: nextProps.selected_appointment.appoinment_id
            });
          }
        });
    }

    //FROM SELECTED PATIENT DETAILS - NON RIGHT CLICK / GENERAL

    if (
      nextProps.selectedPatientDetails !== this.props.selectedPatientDetails
    ) {
      this.setState({
        selectedPatientDetails: nextProps.selectedPatientDetails
      });

      this.setState({
        selected_appointment: nextProps.selected_appointment,
        invoice_no: nextProps.selected_appointment.appoinment_id
      });

      this.setState({ logged_info: nextProps.logged_info });
    }
  }

  reload = () => {
    
    this.props
    .list_appointment_care_plans({
      app_id: this.props.selected_appointment2.appoinment_id,
      app_details: this.props.selected_appointment2,
      notes_to_reception: this.state.notes_to_reception
    })
    .then(res => {
      this.setState({ patients_careplan: res.data.content });
      let selectedInvoiceItems = res.data.patients_careplan;
          
      if(selectedInvoiceItems != undefined && selectedInvoiceItems.length > 0 && selectedInvoiceItems[0].status == "Sent"){
        this.setState({disableButtons: true});
      } else {
        this.setState({disableButtons: false});
      }
      this.setState({
        patients_careplan_header: res.data.patients_careplan
      });

      // CAREPLAN ID
      if (
        res.data.patients_careplan != undefined &&
        res.data.patients_careplan.length > 0
      ) {
        console.log(
          "patients careplan appointement id",
          res.data.patients_careplan[0]
        );

        this.setState({
          patient_careplan_id:
            res.data.patients_careplan[0].patients_careplan_id,
          invoice_no: res.data.patients_careplan[0].appointment_id,
          notes_to_reception: res.data.patients_careplan[0].reception_notes,
          visit_duration: res.data.patients_careplan[0].visit_length,

          notnormal: res.data.patients_careplan[0].not_normal_aftercare,
          in_hospital: res.data.patients_careplan[0].in_hospital
        });
      } else {
        //      alert("elsefrom finalize billing patients careplan undefined");
      }
     
    });
  
  }

  toggleCheckboxnotnormal = (e, name, value) => {
    this.setState({ [name]: value });
  };

  openPatientDetails = () => {
    this.setState({ patientDetailsPopup: true });
  };

  closePatientDetails = () => {
    this.setState({ patientDetailsPopup: false });
  };

  //billing details
  closebillingHistoryPopup = () => {
    this.setState({ billingHistoryPopup: false });
    this.props.closeCreateAccount();
  };

  openbillingHistoryPopup = () => {
    this.setState({ billingHistoryPopup: true });
  };

  //paynow popup
  closepaynowPopup = () => {
    this.setState({ paynowPopup: false });
  };

  openpaynowPopup = () => {
     
    this.setState({ paynowPopup: true });
  };

  //edit item popup
  closeedititemPopup = () => {
    this.setState({ edititemPopup: false });
  };

  openedititemPopup = () => {
    if (this.state.selectedInvoiceFull.length == 1) {
      this.setState({ edititemPopup: true });
    } else {
      alert("Please select any one record to perform edit");
    }
  };
  //edit item popup
  closeuselastaccountPopup = () => {
    this.setState({ uselastaccountPopup: false });
  };

  openuselastaccountPopup = () => {
    this.setState({ uselastaccountPopup: true });
  };

  openServiceNotes = (e, i) => {
    // alert(i);
    this.setState({ servicenotes: true, selectedInput: i });
  };

  closeServiceNotes = () => {
    this.setState({ servicenotes: false, selectedInput: "" });
  };
  getServiceNoteValue = position => {
    let written_servicenotes = this.state.written_servicenotes;
    let selectedNote = "";
    for (let i = 0; i < written_servicenotes.length; i++) {
      if (written_servicenotes[i].position == position) {
        selectedNote = written_servicenotes[i].servicenote;
        break;
      }
    }
    return selectedNote;
  };
  getServiceNote = (servicenote, position) => {
    let written_servicenotes = this.state.written_servicenotes;
    let service_note_item = {
      position: position,
      servicenote: servicenote
    };
    let added = 0;
    for (let i = 0; i < written_servicenotes.length; i++) {
      if (written_servicenotes[i].position == position) {
        written_servicenotes[i].servicenote = servicenote;
        added = 1;
        break;
      }
    }
    if (added != 1) {
      written_servicenotes.push(service_note_item);
    }
    this.setState({ written_servicenotes: written_servicenotes });
  };

  deleteBillingItems = e => {
    if (this.state.selectedInvoiceFull.length > 0) {
      this.props
        .deleteCreateAccountItems({ items: this.state.selectedInvoiceFull })
        .then(() => {
          this.setState({ selectedInvoiceFull: [] });

          this.props
            .list_appointment_care_plans({
              app_id: this.props.selected_appointment2.appoinment_id,
              app_details: this.props.selected_appointment2,
              notes_to_reception: this.state.notes_to_reception
            })
            .then(res => {
              this.setState({ patients_careplan: res.data.content });
              let selectedInvoiceItems = res.data.patients_careplan;
          
              if(selectedInvoiceItems != undefined && selectedInvoiceItems.length > 0 && selectedInvoiceItems[0].status == "Sent"){
                this.setState({disableButtons: true});
              } else {
                this.setState({disableButtons: false});
              }
              this.setState({
                patients_careplan_header: res.data.patients_careplan
              });

              // CAREPLAN ID
              if (
                res.data.patients_careplan != undefined &&
                res.data.patients_careplan.length > 0
              ) {
                console.log(
                  "patients careplan appointement id",
                  res.data.patients_careplan[0]
                );

                this.setState({
                  patient_careplan_id:
                    res.data.patients_careplan[0].patients_careplan_id,
                  invoice_no: res.data.patients_careplan[0].appointment_id,
                  notes_to_reception:
                    res.data.patients_careplan[0].reception_notes,
                  visit_duration: res.data.patients_careplan[0].visit_length
                });
              } else {
                //      alert("elsefrom finalize billing patients careplan undefined");
              }

              //GETTING PATIENT DETAILS
              // if (this.state.selectedPatientDetails.length == 0) {
              //   this.props.getPatientDetails({
              //     patient_id: this.props.selected_appointment2.patient_id,
              //     //invoice_no: this.props.selected_appointment2.appoinment_id,
              //   });
              // }
            });
        });
    } else {
      alert("Please select any record to perfom delete");
    }
  };

  deleteBillingItem = (e, id) => {
    this.props.deleteCreateAccountItem({ id: id }).then(() => {
      this.props
        .list_appointment_care_plans({
          app_id: this.state.selected_appointment.appoinment_id
        })
        .then(res => {
          this.setState({ patients_careplan: res.data.content });

          let selectedInvoiceItems = res.data.patients_careplan;
          
          if(selectedInvoiceItems != undefined && selectedInvoiceItems.length > 0 && selectedInvoiceItems[0].status == "Sent"){
            this.setState({disableButtons: true});
          } else {
            this.setState({disableButtons: false});
          }

          this.setState({
            patients_careplan_header: res.data.patients_careplan
          });

          // CAREPLAN ID
          if (
            res.data.patients_careplan != undefined &&
            res.data.patients_careplan.length > 0
          ) {
            console.log(
              "patients careplan appointement id",
              res.data.patients_careplan[0]
            );

            this.setState({
              patient_careplan_id:
                res.data.patients_careplan[0].patients_careplan_id,
              invoice_no: res.data.patients_careplan[0].appointment_id,
              notes_to_reception: res.data.patients_careplan[0].reception_notes,
              visit_duration: res.data.patients_careplan[0].visit_length
            });
          } else {
            //     alert("elsefrom finalize billing patients careplan undefined");
          }

      
        });
    });
  };

  checkboxstatus = id => {
    let found = false;
    for (let i = 0; i < this.state.selectedInvoiceFull.length; i++) {
      if (this.state.selectedInvoiceFull[i].patients_careplan_items_id === id) {
        found = true;
      }
    }
    return found;
  };

  handleCheckbox = (e, data, id) => {
    let idd = e.target.value;
    console.log(
      data,
      id,
      this.state.patients_careplan,
      this.state.selectedInvoiceFull
    );

    if (
      this.state.selectedInvoiceFull.filter(
        i => i.patients_careplan_items_id === id
      ).length > 0
    ) {
      console.log("already checked - now removing");
      //removing
      this.setState({
        selectedInvoiceItem: [
          ...this.state.selectedInvoiceItem.filter(
            patients_careplan_items_id => patients_careplan_items_id !== idd
          )
        ],
        selectedInvoiceFull: [
          ...this.state.selectedInvoiceFull.filter(
            data => data.patients_careplan_items_id !== idd
          )
        ]
      });
    } else {
      //setting checkboxes
      console.log("checking new - now adding");

      this.setState(
        {
          selectedInvoiceFull: this.state.selectedInvoiceFull.concat(
            this.state.patients_careplan.filter(function(items) {
              return items.patients_careplan_items_id == id;
            })
          )
        },
        () => {
          console.log(this.state.selectedInvoiceFull);
        }
      );

      //having existing checkboxes + only selected date when any of the date already exist
      //having existing checkboxes + all dates of selected date
      let found = true;
      for (let i = 0; i < this.state.selectedInvoiceFull.length; i++) {
        // if (this.state.selectedInvoiceFull[i].date === date) {
        //   found = true;
        // }
      }

      // if (found == true) {

      //   this.setState({
      //     selectedInvoiceFull: this.state.selectedInvoiceFull.concat(
      //       this.state.patients_careplan.filter(function(items) {
      //         return items.patients_careplan_items_id == id;
      //       })
      //     )
      //   });
      // } else {
      //   console.log(false);
      //   // this.setState({
      //   //   selectedInvoiceFull: this.state.selectedInvoiceFull
      //   //     .filter(function(investigation) {
      //   //       return investigation.date !== date;
      //   //     })
      //   //     .concat(
      //   //       this.state.patients_careplan.filter(function(investigation) {
      //   //         return investigation.date == date;
      //   //       })
      //   //     )
      //   // });
      // }
    }
  };

  addItem = addjson => {
    //  alert("addfunction");
    let send = {
      cpid: addjson.cpid,
      service_note:
        addjson.written_servicenotes[0] != undefined
          ? addjson.written_servicenotes[0].servicenote
          : "",
      patients_careplan_items_id: addjson.patients_careplan_items_id,

      patients_careplan_id: this.state.patient_careplan_id,

      tax: addjson.tax,
      amt: addjson.amt
    };

    this.props.addCreateAccountItem(send).then(() => {
      this.props
        .list_appointment_care_plans({
          app_id: this.props.selected_appointment2.appoinment_id,
          app_details: this.props.selected_appointment2,
          notes_to_reception: this.state.notes_to_reception
        })
        .then(res => {
          this.setState({ patients_careplan: res.data.content });

          let selectedInvoiceItems = res.data.patients_careplan;
          
          if(selectedInvoiceItems != undefined && selectedInvoiceItems.length > 0 && selectedInvoiceItems[0].status == "Sent"){
            this.setState({disableButtons: true});
          } else {
            this.setState({disableButtons: false});
          }

          this.setState({
            patients_careplan_header: res.data.patients_careplan
          });

          // CAREPLAN ID
          if (
            res.data.patients_careplan != undefined &&
            res.data.patients_careplan.length > 0
          ) {
            this.setState({
              patient_careplan_id:
                res.data.patients_careplan[0].patients_careplan_id
            });
            this.setState({
              invoice_no: res.data.patients_careplan[0].appoinment_id,
              notes_to_reception: res.data.patients_careplan[0].reception_notes,
              visit_duration: res.data.patients_careplan[0].visit_length
            });
          }
        });
    });
  };

  handleDate2 = date => {
    this.setState({ service_date: date });
  };

  handleDate = date => {
    this.setState({ invoice_date: date });
  };

  toggleAddItem = () => {
    this.setState({ additem: true });
  };

  closeAddItem = () => {
    this.setState({ additem: false });
  };

  inputChangeHandler = (event, key) => {
    this.setState({ [key]: event.target.value });
  };

  handleSave = (name) => {
    alert("saving billing details ");
    this.props.savecreateaccount({invoice_id: this.state.patient_careplan_id, type: name }).then(() => {
      alert("status updated");
    });


  };

  render() {
    let z = 0;
    return (
      <Popup
        open={this.props.open}
        close={this.props.closeCreateAccount}
        // trigger={
        //   <button
        //     type="button"
        //     className="btn btn-default"
        //     style={{
        //       marginTop: "20px",
        //       padding: "5px 10px",
        //       marginRight: "6px"
        //     }}
        //   >
        //     {this.props.popupName}
        //   </button>
        // }
        modal
        closeOnDocumentClick={false}
      >
       <div style={{ width:'1075px', margin:'0px auto'}} >
          <div className="entlay" style={{ width:'1075px', backgroundColor:'#FFF', float:'left'}}>

          <div className="modal-header">
            <div class="popup-title">
              <span> Create Account </span>
              <Showhelp gotohelp="diagnosis_popup" />
              <EditPatientDetails
              patient_id={
                this.props.selected_appointment2 != undefined &&
                this.props.selected_appointment2.patient_id
              }
                open={this.state.patientDetailsPopup}
                close={e => this.closePatientDetails()}
              />
              <BillingHistory
                 patient_id={
                  this.props.selected_appointment2 != undefined &&
                  this.props.selected_appointment2.patient_id
                }
                open={this.state.billingHistoryPopup}
                close={e => this.closebillingHistoryPopup()}
              />
              <PayNowPopup
                patient_id={
                  this.props.selected_appointment2 != undefined &&
                  this.props.selected_appointment2.patient_id
                }
                //openP={true}
                openP={this.state.paynowPopup}
                handleSave = {() => this.handleSave}
                closefrompaypopup={this.closefrompaypopup}
                close={e => this.closepaynowPopup()}
              />
              <EditItem
                selectedRec={this.state.selectedInvoiceFull}
                open={this.state.edititemPopup}
                close={e => this.closeedititemPopup()}
                addItem={this.addItem}
              />
              <UselastaccountPopup
              reload={this.reload}
                open={this.state.uselastaccountPopup}
                close={e => this.closeuselastaccountPopup()}
                target_invoice={this.state.patient_careplan_id}
                patient_id={
                  this.props.selected_appointment2 != undefined &&
                  this.props.selected_appointment2.patient_id
                }
              />
              <ServiceNotes
                open={this.state.servicenotes && this.state.selectedInput != ""}
                type="Invoice"
                selectedInput={this.state.selectedInput}
                closeServiceNotes={e => this.closeServiceNotes()}
                getServiceNote={this.getServiceNote}
              />
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span
                  aria-hidden="true"
                  onClick={e => this.props.closeCreateAccount()}
                >
                  x
                </span>
              </button>
            </div>
          </div>
          <div
            className="modal-body visit-notes-wrapper"
            style={{ overflow: "scroll" }}
          >
              <div class="form">
                <div class="col-sm-12" style={{ paddingRight: "0px", paddingTop: "20px" }}>
                  <div class="row">
                    <div 
                      className="col-sm-1 pr-0"><label>
                      Invoice date:
                    </label></div>
                    <div
                      className="col-sm-2 cursor">
                      <DatePicker
                        //ref={this.textInput}
                        readOnly={true}
                        ref={input => {
                          this.textInput = input;
                        }}
                        style={{ marginTop: "20px" }}
                        id="date-jumper"
                        inputRef={ref => {
                          this.textInput = ref;
                        }}
                        todayButton="Go to Today"
                        selected={this.state.invoice_date}
                        onChange={this.handleDate}
                        showMonthDropdown
                        useShortMonthInDropdown
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        dateFormat="dd/MM/yyyy"
                        yearDropdownItemNumber={15}
                        className="form-control mt-0"
                        scrollableYearDropdown
                      />
                    </div>
                    <div 
                      className="col-sm-1"><label>
                      Invoice No:
                    </label></div>
                    <div className="col-sm-2 cursor">
                      <input
                        type="text"
                        name="search_condition"
                        className="form-control mt-0"
                        value={this.state.patient_careplan_id}
                        disabled={true}
                      />
                    </div>
                    <div 
                      className="col-sm-1"><label>
                      Location:
                    </label></div>
                    <div className="col-sm-2 cursor">
                      <select
                        className="form-control mt-0"
                        onChange={e => this.inputChangeHandler(e, "location")}
                        value={this.state.provider}
                      >
                        {this.state.list_options != undefined &&
                          this.state.list_options.location != null &&
                          this.state.list_options.location.map(
                            (item, index) => (
                              //      (item, index) => (
                              <option
                                value={item.value}
                                selected={this.state.location == item.value}
                                key={index}
                              >
                                {item.label}
                              </option>
                            )
                          )}
                      </select>
                      {/* <input
                        type="text"
                        name="search_condition"
                        className="form-control form-control-sm rq-form-elemen"
                        onChange={e => this.inputChangeHandler(e, "location")}
                        value={this.state.location}
                      /> */}
                    </div>
                    {/* <div className="col-sm-3 cursor">
                      <select className="form-control">
                        <option>Main Surgery</option>
                      </select>
                    </div> */}
                  </div>
                </div>
                <div class="col-sm-12" style={{ paddingRight: "0px" }}>
                  <div class="row">
                  <div 
                      className="col-sm-1 pr-0"><label>
                      Provider:
                    </label></div>
                    <div className="col-sm-2">
                      <select
                        className="form-control mt-0"
                        onChange={e => this.inputChangeHandler(e, "provider")}
                        value={this.state.provider}
                      >
                        {this.state.list_options != undefined &&
                          this.state.list_options.provider_options != null &&
                          this.state.list_options.provider_options.map(
                            (item, index) => (
                              //      (item, index) => (
                              <option
                                value={item.value}
                                selected={this.state.provider == item.value}
                                key={index}
                              >
                                Dr.{item.label}
                              </option>
                            )
                          )}
                      </select>
                    </div>
                    <div 
                      className="col-sm-1 pr-0"><label>
                      Service date:
                    </label></div>
                    <div
                      className="col-sm-2 cursor">
                      <DatePicker
                        //ref={this.textInput}

                        ref={input => {
                          this.textInput = input;
                        }}
                        id="date-jumper"
                        inputRef={ref => {
                          this.textInput = ref;
                        }}
                        todayButton="Go to Today"
                        selected={this.state.service_date}
                        onChange={this.handleDate2}
                        showMonthDropdown
                        useShortMonthInDropdown
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        dateFormat="dd/MM/yyyy"
                        yearDropdownItemNumber={15}
                        className="form-control mt-0"
                        scrollableYearDropdown
                      />
                    </div>
                    <div className="col-sm-2 cursor">
                      <button
                        id="session-timeout-dialog-logout"
                        type="button"
                        className="btn btn-primary btn-xs"  onClick={e => this.openuselastaccountPopup()}
                      >
                        Use last account details
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12" style={{ paddingRight: "0px" }}>
                  <div class="row">
                  <div 
                      className="col-sm-1 pr-0 pl-0"><label style={{ width:'175px', marginLeft:'15px'}}>
                      Billing Schedule:
                    </label></div>
                    <div className="col-sm-2">
                      <select
                        className="form-control mt-0"
                        onChange={e =>
                          this.inputChangeHandler(e, "billingSchedule")
                        }
                        value={this.state.billingSchedule}
                      >
                        <option value="medicare">Medicare</option>
                        <option value="patient">Patient</option>
                        <option value="rebate">Rebate Only</option>
                      </select>
                    </div>
                    <div
                      className="col-sm-1 cursor"
                      style={{ paddingLeft: "0px", marginLeft:'-10px' }}
                    >
                      <button
                        id="session-timeout-dialog-logout"
                        type="button"
                        className="btn btn-primary btn-xs"
                      >
                        Search
                      </button>
                    </div>
                    <label
                      className="col-sm-1 mt-2" style={{ marginLeft:'-30px'}}>
                      Bill to:
                    </label>
                    <div
                      className="col-sm-3 cursor pl-0">
                      <label
                        className="col-form-label text-left"
                        style={{ paddingRight: "0px" }}
                      >
                        <select
                          className="form-control mt-0"
                          onChange={e => this.inputChangeHandler(e, "billto")}
                          value={this.state.patient_id}
                        >
                          {this.state.list_options != undefined &&
                            this.state.list_options.patients != null &&
                            this.state.list_options.patients.map(
                              (item, index) => (
                                //      (item, index) => (
                                <option
                                  value={item.value}
                                  selected={this.state.billto == item.value}
                                  key={index}
                                >
                                  {item.label}
                                </option>
                              )
                            )}
                        </select>

                        {/* {this.state.selectedPatientDetails.Firstname}
                        <br />
                        {this.state.selectedPatientDetails.Address1}
                        <br />
                        {this.state.selectedPatientDetails.Address2}
                        {this.state.selectedPatientDetails.City} &nbsp;
                        {this.state.selectedPatientDetails.Postcode} */}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12" style={{ paddingRight: "0px" }}>
                  <div class="row">
                  <div 
                      className="col-sm-1"><label>
                      Patient
                    </label></div>
                    <div className="col-sm-2">
                      <label
                        className="col-form-label text-left"
                        style={{ paddingRight: "0px" }}
                      >
                        {this.state.selectedPatientDetails.Firstname}
                        <br />
                        {this.state.selectedPatientDetails.Address1}
                        <br />
                        {this.state.selectedPatientDetails.Address2}
                        {this.state.selectedPatientDetails.City} &nbsp;
                        {this.state.selectedPatientDetails.Postcode}
                      </label>
                    </div>
                    <div
                      className="col-sm-1 cursor pl-0"
                      style={{ marginTop: "15px", marginLeft:'-10px' }}
                    >
                      <button
                        id="session-timeout-dialog-logout"
                        type="button"
                        className="btn btn-primary btn-xs"
                        onClick={e => this.openPatientDetails()}
                      >
                        Patient details
                      </button>
                    </div>
                    <div
                      className="col-sm-3 cursor"
                      style={{ marginTop: "15px" }}
                    >
                      <button
                        id="session-timeout-dialog-logout"
                        type="button"
                        className="btn btn-primary btn-xs"
                      >
                        Verify Medicare/DVA eligbility
                      </button>
                    </div>
                  </div>
                </div>
              
                    <div className="row">
                      <div class="col-sm-12 ml-3 mt-3">
                      {/* <button
                        id="session-timeout-dialog-logout"
                        data-toggle="modal"
                        data-target="#popaccountitem"
                        type="button"
                        className="btn btn-default"
                        style={{ marginTop: '-6px', marginRight: '6px' }}
                        onClick={() =>
                          (this.state.openAddItem = !this.state.openAddItem)
                        }
                      >
                        Add Item
                      </button> */}
                      <AddItem
                        open={this.state.additem}
                        close={this.closeAddItem}
                        addItem={this.addItem}
                      />
                      <button
                        type="button"
                        data-toggle="modal"
                        data-target="#popaccountitem"
                        disabled={this.state.disableButtons}
                        className="btn btn-primary btn-xs"  onClick={e => this.toggleAddItem(e)}
                      >
                        Add Item
                      </button>
                      <button
                        id="session-timeout-dialog-logout"
                        type="button"
                        className="btn btn-primary btn-xs"  onClick={e => this.deleteBillingItems(e)}
                        disabled={this.state.disableButtons}
                      >
                        Delete Item
                      </button>
                      <button
                        disabled={this.state.disableButtons}
                        id="session-timeout-dialog-logout"
                        type="button"
                        className="btn btn-primary btn-xs"
                        onClick={e => this.openedititemPopup()}
                      >
                        Vary Item
                      </button>
                    </div>
                  </div>
                </div>

              <div
                className="col-sm-12"
                style={{ paddingRight: "0px", marginTop: "10px" }}
              >
                <div style={{ float: "left", width: "100%", height: "auto" }}>
                  <table
                    className="calendar table table-bordered tablestyle"
                    style={{ width: "100%", marginBottom: "0px" }}
                  >
                    <thead>
                      <tr style={{ backgroundColor: "#f4f4f4" }}>
                        <th style={{ textAlign: "left" }}> #</th>
                        <th style={{ textAlign: "left" }}> Date</th>
                        <th style={{ textAlign: "left" }}> MBS Item</th>
                        <th style={{ textAlign: "left" }}> Description</th>
                        <th style={{ textAlign: "left" }}> Amount</th>
                        <th style={{ textAlign: "left" }}> GST</th>
                        <th style={{ textAlign: "left" }}>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.patients_careplan != undefined &&
                        this.state.patients_careplan.length > 0 &&
                        this.state.patients_careplan.map((item, i) => (
                          <tr title={item.service_note}>
                            <td>
                              <input
                                type="checkbox"
                                name={item.patients_careplan_items_id}
                                id="check_allin"
                                value={item.patients_careplan_items_id}
                                checked={this.checkboxstatus(
                                  item.patients_careplan_items_id
                                )}
                                onChange={e =>
                                  this.handleCheckbox(
                                    e,
                                    item,
                                    item.patients_careplan_items_id
                                  )
                                }
                              />
                            </td>
                            <td> {moment().format("DD/MM/YYYY")} </td>
                            <td> {item.item_no}</td>
                            <td>
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#feeamount"
                                className="collapsed"
                                aria-expanded="false"
                              >
                                {item.name}
                              </a>
                            </td>
                            <td> {item.amt}</td>
                            <td> {parseFloat(item.tax)}</td>

                            <td
                              key={
                                (z +=
                                  parseInt(item.amt) +
                                  parseInt(item.amt) *
                                    (parseFloat(item.tax) / 100))
                              }
                            >
                              {" "}
                              {parseInt(item.amt) +
                                parseInt(item.amt) *
                                  (parseFloat(item.tax) / 100)}
                            </td>
                            {/* <td>
                              <i
                                className="fas fa-trash-alt"
                                aria-hidden="true"
                                onClick={e =>
                                  this.deleteBillingItem(
                                    e,
                                    item.patients_careplan_items_id
                                  )
                                }
                                style={{ fontSize: 12, marginRight: 5 }}
                              />
                            </td> */}
                          </tr>
                        ))}
                      <tr>
                        <td colSpan={6}>
                          <b>Total</b>
                        </td>
                        <td style={{ color: "#0000ff" }}>
                          <b>{z}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className="col-sm-12"
                style={{
                  paddingRight: "0px",
                  marginTop: "10px",
                  marginBottom: "10px"
                }}
              >
                <div className="col-sm-12">
                  <div className="row mt-2">
                    <label
                      className="col-sm-1 pl-0"
                      style={{ paddingRight: "0px" }}
                    >
                      Visit duration:
                    </label>
                    <div
                      className="col-sm-2 cursor"
                      style={{ paddingLeft: "0px" }}
                    >
                      <input
                        type="text"
                        name="search_condition"
                        className="form-control form-control-sm rq-form-elemen"
                        value={this.state.visit_duration}
                        onChange={e =>
                          this.inputChangeHandler(e, "visit_duration")
                        }
                        // defaultValue
                      />
                    </div>
                    <div style={{ width: "150px", float: "left" }}>
                      <input 
                        type="checkbox"
                        name="fasting"
                        className="form-control form-control-sm"
                        style={{
                          float: "left",
                          width: "auto",
                          marginRight: "3px", display:'none'
                        }}
                      />
                      <label>
                        <input
                          type="checkbox"
                          onChange={e =>
                            this.toggleCheckboxnotnormal(
                              e,
                              "notnormal",
                              e.target.checked ? true : false
                            )
                          }
                          checked={this.state.notnormal == 1 ? true : false}
                        />
                        Not normal aftercare
                      </label>
                    </div>
                    <div style={{ width: "120px", float: "left" }}>
                      <label>
                        <input
                          checked={this.state.in_hospital == 1 ? true : false}
                          onChange={e =>
                            this.toggleCheckboxnotnormal(
                              e,
                              "in_hospital",
                              e.target.checked ? true : false
                            )
                          }
                          type="checkbox"
                          name="fasting"
                          // className="form-control form-control-sm"
                          // style={{
                          //   float: "left",
                          //   width: "auto",
                          //   marginRight: "3px"
                          // }}
                        />
                        In hospital
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="col-sm-4 pl-0">
                    <label
                      className="col-sm-5 mt-2 pl-0"
                      style={{ paddingRight: "0px" }}
                    >
                      Notes from provider:
                    </label>
                    <div
                      className="col-sm-7 cursor"
                      style={{ paddingLeft: "0px" }}
                    >
                      <textarea
                        style={{
                          width: "100%",
                          height: "60px",
                          border: "1px solid #cccccc"
                        }}
                        id="accute"
                        placeholder
                        value={this.state.notes_to_reception}
                        onChange={e =>
                          this.inputChangeHandler(e, "notes_to_reception")
                        }
                        // defaultValue={''}
                      />
                    </div>
                </div>
                <div className="col-sm-8">
                  <div className="row mt-2">
                    <label
                      className="col-sm-1 col-form-label text-left"
                      style={{ paddingRight: "0px" }}
                    >
                      Notes:
                    </label>
                    <div
                      className="col-sm-8 cursor"
                      style={{ paddingLeft: "0px" }}
                    >
                      <input
                        type="tedt"
                        name="search_condition"
                        className="form-control form-control-sm rq-form-elemen"
                        // defaultValue
                        value={this.state.notes}
                        onChange={e => this.inputChangeHandler(e, "notes")}
                        value={this.getServiceNoteValue(1)}
                      />
                    </div>
                    <div
                      className="col-sm-1 cursor"
                      style={{ paddingLeft: "0px" }}
                    >
                      <button style={{marginTop:'15px'}}
                        onClick={e => this.openServiceNotes(e, 1)}
                        type="button"
                        className="btn btn-primary btn-xs"
                        data-toggle="modal"
                        data-target="#invoicenotes1"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div style={{ width: "200px", float: "left", marginLeft:'60px', fontSize:'12px', marginTop:'5px' }}>
                      <input
                        type="checkbox" />&nbsp; Print Medicare cliam form
                    </div>
                    <div style={{ width: "150px", float: "left", fontSize:'12px', marginTop:'5px' }}>
                      <input
                        type="checkbox" />&nbsp; Do another account
                    </div>
                    <div style={{ width: "150px", float: "left", fontSize:'12px', marginTop:'5px' }}>
                      <input
                        type="checkbox" />&nbsp; Open Billing History
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div className="modal-footer" style={{ marginTop:'0px'}}>
          <div className="row">
            <div className="col-sm-6"></div>
            <div className="col-sm-6">
              {/* <button type="button" className="btn btn-primary" data-dismiss="modal">Pay now</button>
          <button type="button" className="btn btn-primary" data-dismiss="modal">Print</button> */}

              <button
                type="button"
                className="btn btn-primary btn-xs m-0"
                onClick={() => {
                  //   this.handleSave();
                  // this.props.closeCreateAccount();
                  this.openpaynowPopup();
                }}
                data-dismiss="modal"
              >
                Pay
              </button>
              <button
                type="button"
                className="btn btn-primary btn-xs m-0 ml-2"
                onClick={() => {
                  this.handleSave("store");
                  this.openbillingHistoryPopup();
                }}
                data-dismiss="modal"
              >
                Store
              </button>
              <button
                type="button"
                className="btn btn-primary btn-xs m-0 ml-2"
                data-dismiss="modal"
                onClick={() => {
                  this.handleSave("hold");
                  //this.openbillingHistoryPopup();
                }}
              >
                Hold
              </button>
              <button
                onClick={e => this.props.closeCreateAccount()}
                type="button"
                className="btn btn-primary btn-xs m-0 ml-2"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        </div>
      </Popup>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    list_options: data => dispatch(api_list_options(data)),

    deleteCreateAccountItem: data =>
      dispatch(api_deleteCreateAccountItem(data)),
    deleteCreateAccountItems: data =>
      dispatch(api_deleteCreateAccountItems(data)),
    list_appointment_care_plans: data =>
      dispatch(api_list_appointment_care_plans(data)),
    getPatientDetails: patient_id =>
      dispatch(api_getPatientDetails(patient_id)),
    addCreateAccountItem: data => dispatch(api_add_create_account_item(data)),
    savecreateaccount: data => dispatch(api_savecreateaccount(data))
  };
};

const mapStateToProps = state => {
  let selected_appointment =
    state.getDrAppointments.selected_appointment != undefined
      ? state.getDrAppointments.selected_appointment
      : [];

  let patientDetails = state.getDrAppointments.getPatientDetails
    ? state.getDrAppointments.getPatientDetails
    : [];
  let selectedPatientDetails;
  if (patientDetails.length > 0) {
    selectedPatientDetails = patientDetails[0];
  } else {
    selectedPatientDetails = "";
  }

  let logged_info =
    state.getDrAppointments.logged_info != undefined
      ? state.getDrAppointments.logged_info
      : "";

  return {
    selectedPatientDetails,
    logged_info,
    selected_appointment,
    logged_info
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(createAccount);
//export default finalisevisit;
