import axios from 'axios';


export const api_getMeds = (data) => dispatch => {
	return  axios.get(`${process.env.REACT_APP_BASE_URL}api=medication&action=list_medication`)
	
}
export const api_getOnGoing = (data) => dispatch => {
	return  axios.post(`${process.env.REACT_APP_BASE_URL}api=medication&action=list_on_going`,data)
	
}
export const api_getOneOff = (data) => dispatch => {
	return  axios.post(`${process.env.REACT_APP_BASE_URL}api=medication&action=list_oneoff`,data)
	
}
export const api_getAllMeds = (data) => dispatch => {
	return  axios.post(`${process.env.REACT_APP_BASE_URL}api=medication&action=list_master_medications`, data)
	
}
export const api_addMedication = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=medication&action=add_medication`, data);
}
export const api_deleteMedication = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=medication&action=delete_medication`, data);
}

export const api_listpast = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=medication&action=list_past`, data);
}

export const api_updateMedication = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=medication&action=update_medication ` , data);}
	
export const api_get_drugs = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=medication&action=get_drugs ` , data);}
	
	
	
	
export const api_dosage_data = (data) => dispatch => {
return axios.post(`${process.env.REACT_APP_BASE_URL}api=medication&action=api_dosage_data ` , data);}	

export const api_get_edit_data = (data) => dispatch => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=medication&action=api_get_edit_data ` , data);}	

export const api_get_drug_detail = (data) => dispatch => {
		return axios.post(`${process.env.REACT_APP_BASE_URL}api=medication&action=get_drug_detail ` , data);}	
		
export const api_get_product_detail = (data) => dispatch => {
		return axios.post(`${process.env.REACT_APP_BASE_URL}api=medication&action=get_product_detail ` , data);}	
	
export const api_get_patient_allergies = (data) => dispatch => {
			return axios.post(`${process.env.REACT_APP_BASE_URL}api=medication&action=get_patient_allergies ` , data);}	
	
		
		