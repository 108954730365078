import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import {api_visitnotes_list_options} from './../../../../DS/DS.VisitsNotes';
import { connect } from "react-redux";
import Showhelp from './../../../showHelp';

class gastrointestinal extends Component {
  state = { 
    list_options: {}
  }
  componentDidMount() {
    this.props.visitnotes_list_options().then(res => {
      //   console.log("addPatientForm addPatientForm",res);
      this.setState({ list_options: res.data.content });
      console.log(this.state.list_options);
    });
  }
    render() {
        return (
            <Popup lockScroll={false} trigger={<a  title="Gastro-intestinal" className="collapsed" aria-expanded="false"><span className="gastrointestinal"></span></a>} position="right center"   modal
            closeOnDocumentClick={false}
         >
             {close => (
                  <div style={{ width:'800px', margin:'0px auto'}} >
     <div className="gstro" style={{ width:'800px', marginLeft:'0px', backgroundColor:'#FFF', float:'left'}}>
            <div className="modal-header" 
            // style={{width: '100%', backgroundColor: '', padding: '5px 15px'}}
            >
                    <span> Gastro-Intestinal <Showhelp gotohelp="gastro_intestinal_help"/></span>
                      <button type="button" className="close" style={{ marginTop: '0px',fontSize: '25px' }} onClick={() => close()} data-dismiss="modal" aria-label="Close">
                      ×
                      </button>
                      
                      {/* <h4 style={{backgroundColor: '', float: 'left', width: '100%', fontSize: '25px', margintop: '-5px'}}>Gastro-intestinal</h4> */}
                    </div>



         <div className="modal-body" style={{ border:'0px' }}>
            <div className="col-sm-12 pl-0 pr-0 ml-0"><h4 className="mt-0">History:</h4></div>

            <div className="col-sm-12">
                  <div className="col-sm-2 pr-0">
                            <label>Abdominal pain</label>
                        </div>
                        <div className="col-sm-2">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.AbdominalPain === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "AbdominalPain")}
                    /> Yes</label> 
                    <label class="checkbox-inline">
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.AbdominalPain === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "AbdominalPain")}
                    /> No</label>
                        </div>
                    <div className="col-sm-2 pr-0 pr-0">
                            <label>Nausea</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Nausea === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Nausea")}
                    /> Yes</label>
                    <label class="checkbox-inline">
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Nausea === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Nausea")}
                    /> No</label>
                        </div>
                        <div className="col-sm-2 pr-0 pl-0">
                            <label>Vomiting</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Vomiting === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Vomiting")}
                    /> Yes</label>
                    <label class="checkbox-inline">
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Vomiting === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Vomiting")}
                    /> No</label>
                        </div>
                </div>

                <div className="col-sm-12">
                  <div className="col-sm-2 pr-0">
                            <label>Heartburn</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Hurtburn === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Hurtburn")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Hurtburn === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Hurtburn")}
                    /> No</label>
                        </div>
                    <div className="col-sm-2 pr-0 pr-0">
                            <label>Dysphagia</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Dysphagia === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Dysphagia")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Dysphagia === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Dysphagia")}
                    /> No</label>
                        </div>
                        <div className="col-sm-2 pr-0 pr-0">
                            <label>Anorexia</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Anorexia === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Anorexia")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Anorexia === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Anorexia")}
                    /> No</label>
                        </div>



                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 pr-0 pr-0">
                            <label>Reflux</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Reflux === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Reflux")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Reflux === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Reflux")}
                    /> No</label>

                        </div>
                    <div className="col-sm-2 pr-0 pr-0">
                            <label>Haematemesis</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Haematemesis === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Haematemesis")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Haematemesis === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Haematemesis")}
                    /> No</label>

                        </div>
                        <div className="col-sm-2 pr-0 pr-0">
                            <label>Weight loss</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.WeightLoss === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "WeightLoss")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.WeightLoss === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "WeightLoss")}
                    /> No</label>
                        </div>



                </div>



                <div className="col-sm-12">
                  <div className="col-sm-2 pr-0 pr-0">
                            <label>Diarrhoea</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Diarrhoea === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Diarrhoea")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Diarrhoea === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Diarrhoea")}
                    /> No</label>
                        </div>
                    <div className="col-sm-2 pr-0 pr-0">
                            <label>Constipation</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Constipation === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Constipation")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Constipation === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Constipation")}
                    /> No</label>
                        </div>
                        <div className="col-sm-2 pr-0 pl-0">
                            <label>Changed bowel habit</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.ChangedBowelHabit === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "ChangedBowelHabit")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.ChangedBowelHabit === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "ChangedBowelHabit")}
                    /> No</label>

                        </div>



                </div>



                <div className="col-sm-12">
                  <div className="col-sm-2 pr-0 pr-0">
                            <label>PR bleeding</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.PrBleeding === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "PrBleeding")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.PrBleeding === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "PrBleeding")}
                    /> No</label>
                        </div>
                    <div className="col-sm-2 pr-0 pr-0">

                        </div>
                        <div className="col-sm-2 pr-0">

                        </div>
                        <div className="col-sm-2 pr-0 pr-0">

                        </div>
                        <div className="col-sm-2 pr-0">

                        </div>
                    </div>

                    <div className="col-sm-12 pl-0 pr-0 ml-0"><h4>Examination:</h4></div>

                    <div className="col-sm-12">

                        <div className="col-sm-5">
                        <label class="checkbox-inline"><input type="checkbox"  /> No</label> tenderness 
                           <label class="checkbox-inline"><input type="checkbox" /> No</label> masses
                        </div>
                      </div>
                      <div className="col-sm-12 mt-3 mt-2">
                      <div className="col-sm-2 pr-0" style={{float: 'left'}}>
                    <label>Tenderness</label>

                    </div>
                    <div className="col-sm-2 pr-0" style={{float: 'left'}}>
                    <label> Mass</label>

                    </div>

                  </div>

                  <div className="col-sm-12">
                    <div className="col-sm-2 pr-0">

                    <label class="checkbox-inline"><input type="checkbox"  /> RUQ </label>

                    </div>
                    <div className="col-sm-2 pr-0">
                    <label class="checkbox-inline"><input type="checkbox"  /> RUQ </label>

                    </div>
                    <div className="col-sm-2 pr-0" style={{float: 'left'}}>
                    <label style={{marginTop: '5px'}}>Guarding</label>

                    </div>
                    <div className="col-sm-2 pr-0">
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Gaurding === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Gaurding")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Gaurding === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Gaurding")}
                    /> No</label>
                        </div>
                  </div>
                  <div className="col-sm-12">

                    <div className="col-sm-2 pr-0">
                    <label class="checkbox-inline"><input type="checkbox"  /> Epigastric </label>

                    </div>
                    <div className="col-sm-2 pr-0">
                    <label class="checkbox-inline"><input type="checkbox"  /> Epigastric</label>

                    </div>
                    <div className="col-sm-2 pr-0" style={{float: 'left'}}>
                    <label style={{marginTop: '5px'}}>Rigidity</label>

                    </div>
                    <div className="col-sm-2 pr-0">
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Rigidity === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Rigidity")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Rigidity === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Rigidity")}
                    /> No</label>

                        </div>
                  </div>

                  <div className="col-sm-12">

                    <div className="col-sm-2 pr-0">
                    <label class="checkbox-inline"><input type="checkbox"  /> LUQ </label>

                    </div>
                    <div className="col-sm-2 pr-0">
                    <label class="checkbox-inline"><input type="checkbox"  /> LUQ</label>

                    </div>
                    <div className="col-sm-2 pr-0" style={{float: 'left'}}>
                    <label style={{marginTop: '5px'}}>Rebound</label>

                    </div>
                    <div className="col-sm-2 pr-0">
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Rebound === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Rebound")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Rebound === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Rebound")}
                    /> No</label>

                        </div>
                  </div>

                  <div className="col-sm-12">

                    <div className="col-sm-2 pr-0">
                    <label class="checkbox-inline"><input type="checkbox"  /> R Loin </label>

                    </div>
                    <div className="col-sm-2 pr-0">
                    <label class="checkbox-inline"><input type="checkbox"  /> R Loin</label>

                    </div>
                    <div className="col-sm-2 pr-0" style={{float: 'left'}}>
                    <label style={{marginTop: '5px'}}>Distension</label>

                    </div>
                    <div className="col-sm-2 pr-0">
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Distension == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Distension")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Distension == "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Distension")}
                    /> No</label>

                        </div>
                  </div>




                  <div className="col-sm-12">

                    <div className="col-sm-2 pr-0">
                    <label class="checkbox-inline"><input type="checkbox"  /> Central </label>

                    </div>
                    <div className="col-sm-2 pr-0">
                    <label class="checkbox-inline"><input type="checkbox"  /> Central</label>

                    </div>
                    <div className="col-sm-2 pr-0" style={{float: 'left'}}>
                    <label style={{marginTop: '5px'}}>Hepatomegaly</label>

                    </div>
                    <div className="col-sm-2 pr-0">
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Hepatomegaly === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Hepatomegaly")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Hepatomegaly === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Hepatomegaly")}
                    /> No</label>

                        </div>
                  </div>


                  <div className="col-sm-12">

                    <div className="col-sm-2 pr-0">
                    <label class="checkbox-inline"><input type="checkbox"  /> L Loin </label>

                    </div>
                    <div className="col-sm-2 pr-0">
                    <label class="checkbox-inline"><input type="checkbox"  /> L Loin</label>

                    </div>
                    <div className="col-sm-2 pr-0" style={{float: 'left'}}>
                    <label style={{marginTop: '5px'}}>Splenomegaly</label>

                    </div>
                    <div className="col-sm-2 pr-0">
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Splenomegaly === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Splenomegaly")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Splenomegaly === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Splenomegaly")}
                    /> No</label>
                        </div>
                  </div>



                  <div className="col-sm-12">

                    <div className="col-sm-2 pr-0">
                    <label class="checkbox-inline"><input type="checkbox"  /> RIF </label>

                    </div>
                    <div className="col-sm-2 pr-0">
                    <label class="checkbox-inline"><input type="checkbox"  /> RIF</label>

                    </div>
                    <div className="col-sm-2 pr-0" style={{float: 'left'}}>
                    <label style={{marginTop: '5px'}}>Bowel sounds</label>

                    </div>
                    <div className="col-sm-2 pr-0">
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.BowelSound === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "BowelSound")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.BowelSound === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "BowelSound")}
                    /> No</label>
                        </div>
                  </div>


                  <div className="col-sm-12">

                    <div className="col-sm-2 pr-0">
                    <label class="checkbox-inline"><input type="checkbox"  /> Suprapubic </label>

                    </div>
                    <div className="col-sm-2 pr-0">
                    <label class="checkbox-inline"><input type="checkbox"  /> Suprapubic</label>

                    </div>
                    <div className="col-sm-2 pr-0" style={{float: 'left'}}>
                    <label style={{marginTop: '5px'}}>Ascites</label>

                    </div>
                    <div className="col-sm-2 pr-0">
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Ascites === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Ascites")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Ascites === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Ascites")}
                    /> No</label>

                        </div>
                  </div>




                  <div className="col-sm-12">

                    <div className="col-sm-2 pr-0">
                    <label class="checkbox-inline"><input type="checkbox"  /> LIF </label>

                    </div>
                    <div className="col-sm-2 pr-0">
                    <label class="checkbox-inline"><input type="checkbox"  /> LIF</label>

                    </div>
                    <div className="col-sm-2 pr-0" style={{float: 'left'}}>


                    </div>

                  </div>

                  <div className="col-sm-12">
                    <div className="col-sm-8 pl-0"><h4 style={{}}>PR Exam:</h4></div>
                    <div className="col-sm-4 pl-0"><h4 style={{}}>Hernias:</h4></div>

                  </div>
                  <div className="col-sm-12">
                  <div className="col-sm-2 pr-0 pr-0">
                            <label>PR Blood</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.PrBlood === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "PrBlood")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.PrBlood === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "PrBlood")}
                    /> No</label>

                        </div>
                    <div className="col-sm-2 pr-0 pr-0">
                            <label>Right Inguinal</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.RightInguinal === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "RightInguinal")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.RightInguinal === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "RightInguinal")}
                    /> No</label>
                        </div>
                        <div className="col-sm-2 pr-0 pr-0">
                            <label>Left Inguinal</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.LeftInguinal === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "LeftInguinal")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.LeftInguinal === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "LeftInguinal")}
                    /> No</label>

                        </div>



                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 pr-0 pr-0">
                            <label>PR Mass</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.PrMass === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "PrMass")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.PrMass === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "PrMass")}
                    /> No</label>
                        </div>
                    <div className="col-sm-2 pr-0 pr-0">
                            <label>Right Femoral</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.RightFemoral === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "RightFemoral")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.RightFemoral === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "RightFemoral")}
                    /> No</label>
                        </div>
                        <div className="col-sm-2 pr-0 pr-0">
                            <label>Left Femoral</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.LeftFemoral === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "LeftFemoral")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.LeftFemoral === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "LeftFemoral")}
                    /> No</label>
                        </div>



                </div>



                <div className="col-sm-12">
                  <div className="col-sm-2 pr-0 pr-0">
                            <label>PR Tenderness</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.PrTenderness === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "PrTenderness")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.PrTenderness === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "PrTenderness")}
                    /> No</label>
                        </div>
                    <div className="col-sm-2 pr-0 pr-0">
                            <label>Umbilical</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Umbilical === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Umbilical")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Umbilical === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Umbilical")}
                    /> No</label>
                        </div>
                        <div className="col-sm-2 pr-0 pr-0">
                            <label>Incisional</label>
                        </div>
                        <div className="col-sm-2 pr-0">
                        <label class="checkbox-inline"><input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Incisional === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Incisional")}
                    /> Yes</label> 
                    
                    <label class="checkbox-inline"><input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Incisional === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Incisional")}
                    /> No</label>
                        </div>

                   </div>



                   <div className="col-sm-12">
                   <div className="col-sm-2 mt-3 pr-0 pr-0">
                            <label>Prostatomegaly:</label>
                        </div>
                        <div className="col-sm-2 pr-0 pr-0">
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.Prostatomegaly}
                            onChange={e => this.props.handleOptionChange(e, "general_examination", "Prostatomegaly")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Prostatomegaly != null &&
                          this.state.list_options.Prostatomegaly.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>

                 </div>

            </div>



          <div className="modal-footer"   >
                      <button type="button" className="btn btn-primary btn-xs m-0" data-dismiss="modal"
                      onClick={e => 
                        {this.props.handleSaveOptions(e, "gastrointestinal");
                        close();}
                        }
                        >Save</button>
                      <button type="button" className="btn btn-primary btn-xs m-0 ml-2" onClick={() => close()} data-dismiss="modal">Cancel</button>
                    </div>
                    </div>
   </div>


)}
</Popup>
         );
    }
}


const mapDispatchToProps = dispatch => {
  return {
    visitnotes_list_options: data => dispatch(api_visitnotes_list_options(data)),
    
  };
};

export default connect(null, mapDispatchToProps)(gastrointestinal);