import axios from 'axios';
import { restElement } from '@babel/types';
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { wflow } from './general';
export const GET_APPOINTMENTS = 'GET_APPOINTMENTS';
export const GET_DR_APPOINTMENTS = 'GET_DR_APPOINTMENTS';
export const PATIENT_DETAILS_RESULT = 'PATIENT_DETAILS_RESULT';
//Providing all services regarding Appointment Component

export const SET_CUT_COPY_DETAILS = 'SET_CUT_COPY_DETAILS';
export const setCutCopyDetails = CutCopyDetails => ({
  type: SET_CUT_COPY_DETAILS,
  CutCopyDetails,
});
export const api_set_cut_copy_details = cutCopyDetails => dispatch => {
  console.log("cutcopy dsappointments", cutCopyDetails );
  
    dispatch(setCutCopyDetails(cutCopyDetails));
};


export const SET_SELECTED_APP = 'SET_SELECTED_APP';
export const setSelectedApp = thisapp => ({
  type: SET_SELECTED_APP,
  thisapp,
});
export const api_setSelectedThisAppointment = thisapp => dispatch => {
  console.log("thisapp dsappointments", thisapp );
  
    dispatch(setSelectedApp(thisapp));
};



export const GET_ACUTE = 'GET_ACUTE';

export const getAcute = acute => ({
  type: GET_ACUTE,
  acute,
});
export const GET_TAGS = 'GET_TAGS';
export const REMOVE_TAGS = 'REMOVE_TAGS';

export const addTagsRedux = data => 
({
  type: GET_TAGS,
  data,
});


export const removeTagsRedux = data => 
({
  type: REMOVE_TAGS,
  data,
});


export const api_addTags = data => dispatch => {
  
  wflow("PATIENT_TAGS api", data);
  
  addTagsRedux(data);
  let returnjson = [{test:'test'}];
  return returnjson;
  
};

export const api_removeTags = data => dispatch => {
  
  wflow("PATIENT_TAGS remove api", data);
  
  dispatch(removeTagsRedux(data));
  let returnjson = [{test:'test'}];
  return returnjson;
  
};


export const acute = data => dispatch => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=past_history&action=list_conditions`,
      data
    )
    .then(res => res.data.content)
    .then(data => dispatch(getAcute(data)));
};

/*
export const api_searchDrConditions = data => dispatch => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=past_history&action=search_conditions`,
      data
    )
    .then(res => res.data.content)
    .then(data => dispatch(getDrFav(data)));
};
*/
export const api_searchConditions = data => dispatch => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=past_history&action=search_conditions`,
    data
  );
};
export const api_list_options = data => dispatch => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=patients&action=list_options`,
    data
  );
};

export const api_addConditions = data => dispatch => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=past_history&action=add_condition`,
    data
  );
};

export const ChangeDragData = data => dispatch => {
  console.clear();
  console.log(data);
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=past_history&action=modify_conditions`,
    data
  );
  // return axios.get(`${process.env.REACT_APP_BASE_URL}api=past_history&action=list_conditions`)
  // .then(res => res.data.content)
  // .then(data => dispatch(getAcute(data)))
};
export const deleteData = data => dispatch => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=past_history&action=delete_conditions`,
    data
  );
};

export const addPaitentData = data => dispatch => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=patients&action=add_patient`,
      data
    );
    // .then(res => {
    //   dispatch(acute({ patientId: data.userId }));
    // });
};
export const editPaitentData = data => dispatch => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=past_history&action=update_patient_conditions`,
      data
    )
    .then(res => {
      dispatch(acute({ patientId: data.userId }));
    });
};
export const api_loadPatientCondition = data => dispatch => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=past_history&action=load_selected_condition`,
      data
    );
    // .then(res => {
    //   dispatch(acute({ patientId: data.userId }));
    // });
};

export const deletePatientId = data => dispatch => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=past_history&action=delete_master_conditions`,
      data
    )
    .then(res => {
      dispatch(api_searchConditions());
    });
};

export const getAppointmentsdata = getAppointmentsData => ({
  type: GET_APPOINTMENTS,
  getAppointmentsData,
});
//setting specific reducer
export const getDrAppointmentsdata = getDrAppointmentsData => ({
  type: GET_DR_APPOINTMENTS,
  getDrAppointmentsData,
});
export const patientDetailsResult = patientDetailsResult => ({
  type: PATIENT_DETAILS_RESULT,
  patientDetailsResult,
});

console.log(">>>>>>>>>>>>>>>>>>>",patientDetailsResult)
// const base_url = 'http://localhost/ai/drafts_projects/supadoc_api/project/?';
// //const base_url = 'http://supadocuat.fiveminutes.in/api/project/?';
// //const base_url = 'http://dev46.fiveminutes.in/supadoc/api/project/?';
//General Search Function
export const api_General = data => dispatch => {
  //console.log(data);
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
    data
  );
};
export const api_cancellation_list = data => dispatch => {
  //console.log(data);
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=appointments&action=api_cancellation_list`,
    data
  );
};
export const api_patientdetails_savecomments = data => dispatch => {
  //console.log(data);
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=patients&action=patientdetails_savecomments`,
    data
  );
};
export const api_patientdetails_saveBookingDetailsapi = data => dispatch => {
  //console.log(data);
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=patients&action=patientdetails_savebookingdetails`,
    data
  );
};
export const api_patientdetails_savePatientDetails = data => dispatch => {
  //console.log(data);
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=patients&action=patientdetails_savepatientdetails`,
    data
  );
};

//list of active doctors in medical center
export const api_getDoctors = () => dispatch => {
  //console.log('doctors');
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}api=appointments&action=doctors`
  );
};

//timeslot / practice sessions
export const api_getTimeslots = () => dispatch => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}api=appointments&action=timeslots`
  );
};

//api_modifyAppointments
export const api_modifyAppointments = data => dispatch => {
  console.log("api modify app",data);
  dispatch(showLoading())
// do long running stuff
//dispatch(hideLoading())
  //console.log(axios.post(`${process.env.REACT_APP_BASE_URL}api=appointments&action=modify_appointment`, data));
  //axios.post(`${process.env.REACT_APP_BASE_URL}api=appointments&action=modify_appointment`, data).then(data => {console.log(data)});
  axios.post(
    `${process.env.REACT_APP_BASE_URL}api=appointments&action=modify_appointment`,
    data
  ).then(() =>{
    dispatch(api_getAppointments(data.dateSearchParam));
    dispatch(api_getDrAppointments(data.doctorSearchParam));

  });
  
};
export const api_add_finalizevisit = data => dispatch => {
   
   
  axios.post(
    `${process.env.REACT_APP_BASE_URL}api=appointments&action=finalize_visit`,
    data
  ).then(() =>{
    dispatch(api_getAppointments(data.dateSearchParam));
    dispatch(api_getDrAppointments(data.doctorSearchParam));
 
  });
  
};


export const api_patientsCareplan = data => dispatch => {   
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=appointments&action=api_patients_careplan`,
    data
  );
  
};

export const api_saveFavCareplan = data => dispatch => {   
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=appointments&action=save_careplan_fav`,
    data
  );
  
};

export const api_getPatientDetails = data => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=patients&action=patientdetails`,
      data
    )
    .then(res => {
      dispatch(patientDetailsResult(res.data.content));
    });
};
export const api_getSelectedPatientDetails = data => dispatch => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=patients&action=patientdetails`,
      data
    );
   
};

//search Patients
export const api_searchPatients = data => dispatch => {
  //console.log(data);
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=patients&action=search_patients`,
    data
  );
};

//load appointment types, booking sources
export const api_appointment_types_booking_sources = data => dispatch => {
  //console.log(data);
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=patients&action=api_appointment_types_booking_sources`,
    data
  );
};

//search Patients
export const api_selectPatients = data => dispatch => {
  //console.log(data);
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=patients&action=select_patients`,
    data
  );
};

export const api_bookappointment = data => dispatch => {
  //console.log(data);
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=appointments&action=add_appointment`,
      data
    )
    .then(response => {
      dispatch(api_getAppointments(data.dateSearchParam));
      dispatch(api_getDrAppointments(data.doctorSearchParam));
    });
};
export const api_editappointment = data => dispatch => {
  //console.log(data);
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=appointments&action=edit_appointment`,
      data
    )
    .then(response => {
      dispatch(api_getAppointments(data.dateSearchParam));
      dispatch(api_getDrAppointments(data.doctorSearchParam));
    });
};

//List of appointments records in given time range - ALL DOCTORS
export const api_getAppointments = data => dispatch => {
  //console.log(data);
  //return axios.get(`${process.env.REACT_APP_BASE_URL}api=appointments&action=individual_doctor_calendar&doctor_guid=2&start_datetime=2019-08-10 04:14:00&end_datetime=2019-08-18 04:16:00`);
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=appointments&action=all_doctors_calendar`,
      data
    )
    .then(res => res.data.content)
    .then(getAppointments => {
      // console.log(getAppointments);
      dispatch(getAppointmentsdata(getAppointments));
    });
};

//List of appointments records in given time range - ALL DOCTORS
export const api_getAppointmentskiplist = data => dispatch => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=appointments&action=all_doctors_calendar`,
      data
    )
 
};

export const api_get_account = data => dispatch => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}api=patients&action=get_account`,
    data
  );
};
//List of appointments records in given time range - INDIVIDUAL DOCTOR

// Axios result will be sent to redux ***
export const api_getDrAppointments = data => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api=appointments&action=individual_doctor_calendar`,
      data
    )
    .then(res => res.data.content)
    .then(getDrAppointments => {
      console.log(getDrAppointments);
      dispatch(getDrAppointmentsdata(getDrAppointments));
    });
};

export const getAllUser = () => dispatch => {
  return axios
    .get('http://jsonplaceholder.typicode.com/users')
    .then(res => {
      return res;
    })
    .then(getUser => {
      let getData = getUser;
      //dispatch(getUserData(getData));
      return getData;
    });
  //alert('getall user api');
};
export const api_getTitle=() => dispatch =>
{
	return axios.get(`${process.env.REACT_APP_BASE_URL}api=patients&action=get_title`);
};
export const api_banknames=() => dispatch =>
{
	return axios.get(`${process.env.REACT_APP_BASE_URL}api=patients&action=get_banknames`);
};
export const api_getGender=() => dispatch =>
{
	return axios.get(`${process.env.REACT_APP_BASE_URL}api=patients&action=get_gender`);
};

export const api_appoinment_type=() => dispatch =>
{
	return axios.get(`${process.env.REACT_APP_BASE_URL}api=appointments&action=get_appoinment_type`);
};

export const api_appointment_length=() => dispatch =>
{
	return axios.get(`${process.env.REACT_APP_BASE_URL}api=appointments&action=api_appointment_length`);
};


export const api_waiting_list=(data) => dispatch =>
{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=appointments&action=waiting_list`,data);
};

export const api_save_waiting=(data) => dispatch =>
{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=appointments&action=add_waiting_list`,data);
};   
export const api_get_recall_list=(data) => dispatch =>
{
	return axios.post(`${process.env.REACT_APP_BASE_URL}api=appointments&action=get_recall_list`,data);
};   

export const api_cancel_appointment=(data) => dispatch =>
{ 
  console.log("***********************************",data)
 return  axios.post(
      `${process.env.REACT_APP_BASE_URL}api=appointments&action=cancel_appointment`,
      data
    ).then(() =>{
      dispatch(api_getAppointments(data.mod.dateSearchParam));
      dispatch(api_getDrAppointments(data.mod.doctorSearchParam));
  
    });
//	return axios.post(`${process.env.REACT_APP_BASE_URL}api=appointments&action=cancel_appointment`,data);
};   

export const get_appointment_details=(data) =>dispatch=>
{
  return axios.post(`${process.env.REACT_APP_BASE_URL}api=appointments&action=get_last_visit`,data);
}
