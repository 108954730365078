import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import  {api_patientdetails_savecomments, api_patientdetails_saveBookingDetailsapi, 
    api_patientdetails_savePatientDetails } from '../../../DS/DS.Appointments';
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import jsPDF from 'jspdf';
class Viewallergy extends Component {
constructor(props){
    super(props);
    
     this.state = {
      patient_id : this.props.selectedPatientDetails.patient_id,
      //detailsAboutPatient: this.props.selectedPatientDetails.detailsAboutPatient,
      //comments: this.props.selectedPatientDetails.comments,
      //detailsAboutBooking: this.props.selectedPatientDetails.detailsAboutBooking,
      showPatientHistory: false,
      isTabOpen: false
    }

  }
 print=()=>{
   var doc=new jsPDF();
   var docs=document.getElementById('allergyview');
   console.log(docs);
   doc.fromHTML(docs,10,10
                    )
       doc.save("generated.pdf")
 }


render()
{

    return(
  <Popup trigger={<a href="#" title="View"><i className="fa fa-eye" aria-hidden="true" style={{fontSize: 12, marginRight: 5}} /></a>}   modal
            closeOnDocumentClick={false}>
             {close => (
          <div>

<div className="modal-header" style={{width: '100%', backgroundColor: '#f4f4f4', padding: '5px 15px'}}>
                      
                      <button type="button" className="close" style={{position: 'absolute',right: '10px',fontSize: '25px'}} onClick={() => close()} data-dismiss="modal" aria-label="Close">
                      ×
                      </button>
                      {/*<h4 style={{backgroundColor: '#f4f4f4', float: 'left', width: '100%', fontSize: '25px', margintop: '-5px'}}>Allergies</h4>*/}
					  <h4 style={{float: 'left', width: '100%', fontSize: '25px', margintop: '-5px'}}>Allergies</h4>		
                    </div>
                    <div className="modal-body"  id="allergyview" ref="allergyview">
                       <table style={{width: '100%'}}>
                        <tbody>
                            <tr style={{backgroundColor: '#f4f4f4'}}>
                             <th style={{padding: '5px 0px', width: '30%'}}>&nbsp;Item</th>
                             <th style={{padding: '5px 0px', width: '30%'}}>&nbsp;Reaction</th>
                             <th style={{padding: '5px 0px', width: '40%'}}>&nbsp;Severity</th>
                            </tr>
                            {this.props.selectedPatientDetails.allergies != undefined ? this.props.selectedPatientDetails.allergies.map((allergy) => (
                                    <tr key={allergy.allergy_name}>
                                        <td style={{ width: '30%'}}>&nbsp;{allergy.allergy_name}</td>
                                        <td style={{ width: '30%'}}>&nbsp;{allergy.reaction}</td>
                                        <td style={{ width: '30%'}}>&nbsp;{allergy.severity}</td>
                                    </tr>
                                )) : <tr><td colSpan='3'>No Allergies</td></tr>}
                        </tbody>
                    </table>
                  {/* <div style={{float: 'left', width: '100%', display: 'block'}}>
                        <table style={{width: '100%', marginBottom: 0}} className="calendar table table-bordered">
                            <tbody>
                                {this.props.selectedPatientDetails.allergies != undefined ? this.props.selectedPatientDetails.allergies.map((allergy) => (
                                    <tr key={allergy.allergy_name}>
                                        <td style={{ width: '30%'}}>&nbsp;{allergy.allergy_name}</td>
                                        <td style={{ width: '30%'}}>&nbsp;{allergy.reaction}</td>
                                        <td style={{ width: '30%'}}>&nbsp;{allergy.severity}</td>
                                    </tr>
                                )) : <tr><td colSpan='3'>No Allergies</td></tr>}
                            </tbody>
                        </table>
                    </div>*/} 
                </div>

					{/*<div className="modal-footer"  style={{marginTop: '90px'}}>*/}
					<div className="modal-footer">
                      <button type="button" className="btn btn-primary" style={{marginRight:'5px',width:'70px',height:'35px'}} data-dismiss="modal" onClick={this.print}>Print</button>
                      <button type="button" className="btn btn-default" style={{marginRight:'2px',width:'70px',height:'35px'}} onClick={() => close()} data-dismiss="modal">Cancel</button>
                    </div>
</div>


)}
</Popup>
    )
    
  }
}
const mapStateToProps = state => {
  console.log(state);
 let patientDetails = state.getDrAppointments.getPatientDetails
   ? state.getDrAppointments.getPatientDetails
   : [];
 let selectedPatientDetails;
 console.log(">>>>>>", patientDetails);
 if (patientDetails !='') {
   
   selectedPatientDetails = patientDetails[0];
   console.log("IN",selectedPatientDetails);
 } else {
  
   selectedPatientDetails = "";
   console.log("else");
 }
 return {
   selectedPatientDetails
 };
};

export default connect (mapStateToProps) (Viewallergy);
