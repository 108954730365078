import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import Summary from '../summary/summary';
import { Editor } from 'react-draft-wysiwyg';
import PastVisit from './pastVisit';
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import MyList from './myList';
import axios from 'axios';
import moment from 'moment';
import {
  api_getVisitTypes,
  api_addVisitNote,
  list_providers,
  patients_visit_reason,
  search_visit_notes,
  api_set_visitnotes_details,
  api_listwordfill,
  api_get_notes
} from '../../../DS/DS.VisitsNotes';
import TabList from '../../VisitTab/TabList';
import '../../VisitTab/Tab.css';
import Diagonsis from './diagonsis';
import { diagonsisSave } from '../../../DS/DS.VisitnotesDiagnosis';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import './visitNotes.css';
import Reviewin from './reviewin';
import General from './leftpanel/general';
import Cardiovascular from './leftpanel/cardiovascular';
import Respiratory from './leftpanel/respiratory';
import Gastrointestinal from './leftpanel/gastro-intestinal';
import Cns from './leftpanel/cns';
import Genitourinary from './leftpanel/genito-urinary';
import Ent from './leftpanel/ENT';
import Eye from './leftpanel/Eye';
import Musculoskeletal from './leftpanel/musculo-skeletal';
import Skin from './leftpanel/skin';
import Wordfill from './wordfill';
import PathologyPop from './toppanel/pathologyPop';
import MedicalCertificate from './medicalCertificate/medicalCertificate';
import CommonGrid from '../../crudcomponentV2/combinedcrud';
import MedicationPop from '../medications/medicationPop';
import Gynae from './leftpanel/gynae';
import draftToHtml from 'draftjs-to-html';
import Showhelp from './../../showHelp';

class VisitsNotes extends Component {
  constructor(props) {
    super(props);
    this.startTimer = this.startTimer.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
    this.resetTimer = this.resetTimer.bind(this);
   // this.submiteVisitNote = this.submiteVisitNote.bind(this);
    this.state = {
      medicationpop:false,
      selcdays:false,
      selweeks:false,
      suggestions: [
       
      ],
      openDiagonsistv: false,
      openDiagonsis: false,
      visit_type: [],
      patientId: this.props.redux_patientId,
      doctor_id: this.props.logged_info.content.details[0].doctor_id,
      date: new Date().toLocaleString(),
      hours:
        new Date().getHours() +
        ':' +
        new Date().getMinutes() +
        ':' +
        new Date().getSeconds(),
      visit_reason: '',
      visit_note:  this.props.visitnotes_details,
      selected_visit_type: '',
      doc_providers: [],
      selected_provider: '',
      patient_visit_reason: [],
      selected_patient_visit_reason: '',
      all_visit_list: [],
      selected_past_visit: '',

      editorContent: this.props.visitnotes_details,
      editorState: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML('<p>' + this.strreplaceables(this.props.visitnotes_details) + '</p>')
        )
      ),

      general: {
        Fever: '',
        Lathargy: '',
        Malaise: '',
        Anorexia: '',
        Nausea: '',
        Giddiness: '',
        WeightLoss: '',
        WeightGain: '',
        RecentTravel: '',
      },
      general_examination: {
        pulse: '',
        PulseUnit: '',
        BloodPressureSitting: '',
		BloodPressureSittingRight: '',
        BloodPressureStanding: '',
        BloodPressureLying: '',
        Temp: '',
        TempUnit: '',
        RespRate: '',
        WeightKG: '',
        HeightCM: '',
        BSL: '',
        BSLUnit: '',
        WaistMeasurement: '',
        HeadMeasurement: '',
        Dehydration: '',
        DehydrationInPercentage: '',
        O2Saturation: '',
        Clinically:'',
        Jaundiced:''
      },
      cardiovascular: {
        ChestPain: '',
        Dyspnoea: '',
        Orthospnoea: '',
        SwollenAnkles: '',
        Fever: '',
        Claudication: '',
        Palpitations: '',
      },
      cardiovascular_examination: {
        JVP: '',
        IntercostalSpace: '',
        CarotidBruit: '',
        ApexBeat: '',
        AnkleOedema: '',
        HeartsSounds: '',
        Description: '',
        Radiation: '',
        Brachial: '',
        Radial: '',
        Popliteal: '',
        PT: '',
        DP: '',
      },
      respiratory: {
        Cough: '',
        Sputum: '',
        Haemoptysis: '',
        Pleutritic: '',
        Wheeze: '',
        asthama: '',
      },
      respiratory_examination: {
        respiratoryDistress: '',
        Recession: '',
        UsingAccessoryMuscles: '',
        ReducesAirEntry: '',
        Creps: '',
        PeakFlow: '',
        Expiration: '',
        Inspiration: '',
        Rhonchi: '',
      },
      gastrointestinal: {
        Incisional: '',
        Umbilical: '',
        PrTenderness: '',
        LeftInguinal: '',
        RightInguinal: '',
        PrMass: '',
        RightFemoral: '',
        LeftFemoral: '',
        PrBlood: '',
        Ascites: '',
        BowelSound: '',
        Splenomegaly: '',
        Hepatomegaly: '',
        Distension: '',
        Rebound: '',
        Rigidity: '',

        PrBleeding: '',
        ChangedBowelHabit: '',
        Constipation: '',
        Diarrhoea: '',
        WeightLoss: '',
        Haematemesis: '',
        Reflux: '',
        Anorexia: '',
        Vomiting: '',
        Hurtburn: '',
        Dysphagia: '',
        Nausea: '',
        AbdominalPain: '',
      },
      gastrointestinal_examination: {
        PrBlood: '',
        RightInguinal: '',
        LeftInguinal: '',
        PrMass: '',
        RightFemoral: '',
        LeftFemoral: '',
        PrTenderness: '',
        Umbilical: '',
        Incisional: '',
        Gaurding: '',
        Rigidity: '',
        Rebound: '',
        Distension: '',
        Hepatomegaly: '',
        Splenomegaly: '',
        BowelSound: '',
        Spgravity: '',
        Ascites: '',
      },
      cns: {
        Headache: '',
        NeckStiffness: '',
        Photofobia: '',
        Hyperacusis: '',
        Fits: '',
        Faints: '',
        Weakness: '',
        Numbness: '',
        SlurredSpeech: '',
        Incoordination: '',
        Confusion: '',
        Disorientation: '',
        Tremor: '',
      },
      cns_examination: {
        EssentialTremor: '',
        UpperFacialPalsy: '',
        IntensionTremor: '',
        LowerFacialPalsy: '',
        ArmsWickness: '',
        CogwheelRigidity: '',
        LegWeakness: '',
        ArmNumbness: '',
        LegNumbness: '',
        FootDrop: '',

        RightEyePtosis: '',
        LeftEyePtosis: '',
        RightEyeEOM: '',
        LeftEyeEOM: '',

        RightFields: '',
        LeftFields: '',
        RightPupils: '',
        LeftPupils: '',
        InvoluntaryMovements:'',
      },
      genitourinary: {
        Dysuria: '',
        Frequency: '',
        Noctoria: '',

        RightLoinPain: '',
        LeftLoinPain: '',
        SubrapubicPain: '',

        Haematuria: '',
        Urgency: '',
        StressIncontinence: '',
        TesticularPain: '',
		TesticularLump: '',
        Impotence: '',
        DifficultyVoiding: '',
        TermianlDribbling: '',
        InCompleteEmptying: '',

        PenileDischarge: '',
        PenileLesin: '',
        Haematospermia: '',
      },
      genitourinary_examination: {
        UrethralDischarge: '',
        PenileWarts: '',
        LointenTerness: '',
        Scrotalswelling: '',
        Scrotalredness: '',
        Blood: '',
        Protein: '',
        Prostatomegaly: '',
        Spgravity: '',
        pH: '',
        Ketones: '',
        Urobilinogen: '',
        Testicularswelling: '',
        Testiculartenderness: '',
        Undescendedtesties: '',
        Hydrocoele: '',
        Varicocoele: '',
        Glucose: '',
        Bilirubin: '',
        Leucocytes: '',
        Nitrites: '',
        Urobilinoge: '',

        PrBlood: '',
        PrMass: '',
        PrTenderness: '',
      },
      ent: {
        Earache: '',
		EarDischarge: '',
		Deafness: '',
		Rhinorrhoea: '',
		Epistaxis: '',
		NasalCongestion: '',		
        SoreThroat: '',
		HoarseVoice: '',
        LostVoice: '',        
        FacialPain: '',
      },
      ent_examination: {
        DrumRed: '',
        RedThroat: '',
        Disharge: '',
        PusOnTonsils: '',
        Perforation: '',
        Enlargedtonsils: '',
        Glue: '',
        NoseDischarge: '',
		NasalObstruction: '',
        Canalred: '',
        Wax: '',
        NoseRedness: '',
        Fb: '',
        NasalFB: '',
        MouthLesion: '',
        Description: '',
      },
      eye: {
        VisualDeterioration: '',
        VisualLoss: '',
        SomethingInEye: '',
        ItcyEye: '',
        RedEye: '',
        SoreEye: '',
        WateringEye: '',
        EyeDischarge: '',
        DoubleVision: '',
      },
      eye_examination: {
        Redness: '',
        Discharge: '',
        Swelling: '',
        Ulcer: '',
        Pterygium: '',
        IngrowingLashes: '',
        Ecropion: '',
        Hyphaema: '',
        Cataract: '',
        ColourVision: '',
        Papilloedema: '',
        withoutglassesLeft: '',
        withoutglassesright: '',
        withoutglassesboth: '',
        withglassesLeft: '',
        withglassesright: '',
        withglassesboth: '',
        foreignbodyright: '',
        foreignbodyleft: '',
        Retinopathyright: '',
        Retinopathyleft: '',
		Entropion: '',
      },
      skin: {
        Rash: '',
        Itch: '',
        Lesion: '',
      },
      skin_examination: {
        Lesion: '',
        Description: '',
        Rash: ''
      },
      musculoskeletal: {
        NeckPain: '',
        BackPain: '',
        ShoulderPain: '',
        HipPain: '',
        ElblowPain: '',
        KneePain: '',
        WristPain: '',
        AnklePain: '',
        HandPain: '',
        FootPain: '',
        FingerPain: '',
        ToePain: '',
        Injury: '',
      },      
      musculoskeletal_examination: {
		 Joint: '', 
	  },
      gynae: {
        PvBleeding: '',
        PvDischarge: '',
        Amenorrhoea: '',
        Dysmenorrhoea: '',
        VulvalItch: '',
        IntermenstrutalBleeding: '',
        PostCoitalBleeding: '',
        PostMenopausalBleeding: '',
        Dyspareunia: '',
        UnprotectedIntercourse: '',
        HeavyPeriods: '',
        IrregularPeriod: '',
        PregnancyTestPositive: '',
      },
      gynae_examination: {
        VaginalDischarge: '',
        CervixSuspicious: '',
        CSTPerformed: '',
        CervicalPolyp: '',
        CervicalEctopy: '',
        Cystocele: '',
        Rectocele: '',
        BulkyUterus: '',
        UterineTenderness: '',
        RetrovertedUterus: '',
        FornicealTenderness: '',
        FornicealMass: '',
      },
      reviewin: {
        problem_status: "",
        days: "",
        prn: "",
        Interval:"",
        daystext:"days",
        problem_worsen:"",
        problem_recurs:"",
        not_better:"",
        in_days:"",
        indaystext:"days",
        on_date:"",
        Other:"",
        other_text:"",
        weeks:"weeks"
        

      },
      wordfill: {
        text: ""
      },
      medicalmodal:false,
      time: 0,
      start: 0,
      isOn: false,
      pathologyPop:false,
      radiologyPop:false,
      keych:0
    };
  }

  strreplaceables = (content) => {
    content  = content.toString().replace("AAA", "different text word fill");
    return content;
  }

  onEditorStateChange = editorState => {
    
  
    const currentContent = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
    console.log('editor notes', currentContent);
    //vnote_content += currentContent;
    //this.props.set_visitnotes_details(vnote_content);



    this.setState({
      editorState,
    });
    this.submiteVisitNote(currentContent)
  };
  handleOptionChange = (e, section, name) => {
    if (section == 'general') {
      this.setState({
        general: {
          ...this.state.general,
          [name]: e.target.value,
        },
      });
    }

    if (section == 'cardiovascular') {
      this.setState({
        cardiovascular: {
          ...this.state.cardiovascular,
          [name]: e.target.value,
        },
      });
    }
    if (section == 'respiratory') {
      this.setState({
        respiratory: {
          ...this.state.respiratory,
          [name]: e.target.value,
        },
      });
    }
    if (section == 'gastrointestinal') {
      this.setState({
        gastrointestinal: {
          ...this.state.gastrointestinal,
          [name]: e.target.value,
        },
      });
    }
    if (section == 'cns') {
      this.setState({
        cns: {
          ...this.state.cns,
          [name]: e.target.value,
        },
      });
    }
    if (section == 'genitourinary') {
      this.setState({
        genitourinary: {
          ...this.state.genitourinary,
          [name]: e.target.value,
        },
      });
    }
    if (section == 'ent') {
      this.setState({
        ent: {
          ...this.state.ent,
          [name]: e.target.value,
        },
      });
    }
    if (section == 'eye') {
      this.setState({
        eye: {
          ...this.state.eye,
          [name]: e.target.value,
        },
      });
    }
    if (section == 'skin') {
      this.setState({
        skin: {
          ...this.state.skin,
          [name]: e.target.value,
        },
      });
    }

    if (section == 'musculoskeletal') {
      this.setState({
        musculoskeletal: {
          ...this.state.musculoskeletal,
          [name]: e.target.value,
        },
      });
    }

    if (section == 'reviewin') {
     // alert("review in");
      this.setState({
        reviewin: {
          ...this.state.reviewin,
          [name]: e.target.value,
        },
      });
    }

    if (section == 'wordfill') {
     //  alert(e);
      let text = this.state.wordfill.text;
       this.setState({
        wordfill: {
           ...this.state.wordfill,
           [name]: text+" "+ e,
         },
       });
     }

    if (section == 'general_examination') {
      this.setState({
        general_examination: {
          ...this.state.general_examination,
          [name]: e.target.value,
          //[name]: e.target.value + e.target.attributes.getNamedItem('right').value != undefined ? e.target.attributes.getNamedItem('right').value : ''
        },
      });
    }
    if (section == 'cardiovascular_examination') {
      this.setState({
        cardiovascular_examination: {
          ...this.state.cardiovascular_examination,
          [name]: e.target.value,
        },
      });
    }
    if (section == 'respiratory_examination') {
      this.setState({
        respiratory_examination: {
          ...this.state.respiratory_examination,
          [name]: e.target.value,
        },
      });
    }
    if (section == 'gastrointestinal_examination') {
      this.setState({
        gastrointestinal_examination: {
          ...this.state.gastrointestinal_examination,
          [name]: e.target.value,
        },
      });
    }
    if (section == 'cns_examination') {
      this.setState({
        cns_examination: {
          ...this.state.cns_examination,
          [name]: e.target.value,
        },
      });
    }
    if (section == 'genitourinary_examination') {
      this.setState({
        genitourinary_examination: {
          ...this.state.genitourinary_examination,
          [name]: e.target.value,
        },
      });
    }
    if (section == 'ent_examination') {
      this.setState({
        ent_examination: {
          ...this.state.ent_examination,
          [name]: e.target.value,
        },
      });
    }
    if (section == 'eye_examination') {
      this.setState({
        eye_examination: {
          ...this.state.eye_examination,
          [name]: e.target.value,
        },
      });
    }
    if (section == 'skin_examination') {
      this.setState({
        skin_examination: {
          ...this.state.skin_examination,
          [name]: e.target.value,
        },
      });
    }
    if (section == 'musculoskeletal_examination') {
      this.setState({
        musculoskeletal_examination: {
          ...this.state.musculoskeletal_examination,
          [name]: e.target.value,
        },
      });
    }

    if (section == 'gynae') {
      this.setState({
        gynae: {
          ...this.state.gynae,
          [name]: e.target.value,
        },
      });
    }

    
    if (section == 'gynae_examination') {
      this.setState({
        gynae_examination: {
          ...this.state.gynae_examination,
          [name]: e.target.value,
          //[name]: e.target.value + e.target.attributes.getNamedItem('right').value != undefined ? e.target.attributes.getNamedItem('right').value : ''
        },
      });
    }

    console.log(this.state);
  };

  setVisitNoteData() {
    let vnote_content = '<br><b>History</b>';
    let appendTitle = false;
    const stateObjectKey = [
      'general',
      'cardiovascular',
      'respiratory',
      'gastrointestinal',
      'cns',
      'genitourinary',
      'ent',
      'eye',
      'skin',
      'musculoskeletal',
      'general_examination',
      'cardiovascular_examination',
      'respiratory_examination',
      'gastrointestinal_examination',
      'cns_examination',
      'genitourinary_examination',
      'ent_examination',
      'eye_examination',
      'skin_examination',
      'musculoskeletal_examination',
      'reviewin',
      'gynae',
      'gynae_examination',
      'wordfill'
    ];
    stateObjectKey.forEach(
      function(stateKey) {
        if (
          stateKey == 'general' ||
          stateKey == 'cardiovascular' ||
          stateKey == 'respiratory' ||
          stateKey == 'gastrointestinal' ||
          stateKey == 'cns' ||
          stateKey == 'genitourinary' ||
          stateKey == 'ent' ||
          stateKey == 'eye' ||
          stateKey == 'skin' ||
          stateKey == 'musculoskeletal' ||
          stateKey == 'gynae' 
      
        ) {
          // vnote_content += `</br><b>${stateKey.toUpperCase()}</b><br>`;
          const data = this.state[stateKey];
          let data_content = '';
          Object.keys(data).forEach(function(key) {
            if (data[key] === 'Yes') {
              data_content += ' ' + key + ', ';
            }
            if (data[key] === 'No') {
              data_content += ' ' + data[key] + ' ' + key + ', ';
            }
            if (data[key] !== 'No' && data[key] !== 'Yes' && data[key] !== '') {
              data_content += ' ' + key + ' ' + data[key] + ', '; // +'<br>';
            }
          });
          if (data_content) {
            vnote_content += `</br><b>${stateKey.toUpperCase()}</b><br>`;
            vnote_content += data_content;
          }
        }
        if (
          stateKey == 'general_examination' ||
          stateKey == 'cardiovascular_examination' ||
          stateKey == 'respiratory_examination' ||
          stateKey == 'gastrointestinal_examination' ||
          stateKey == 'cns_examination' ||
          stateKey == 'genitourinary_examination' ||
          stateKey == 'ent_examination' ||
          stateKey == 'eye_examination' ||
          stateKey == 'skin_examination' ||
          stateKey == 'musculoskeletal_examination' ||
          stateKey == 'gynae_examination'

        ) {
          const data = this.state[stateKey];
          let data_content = '';
          Object.keys(data).forEach(function(key) {
            if (data[key] == 'Yes') {
              data_content += ' ' + key + ', ';
            }
            if (data[key] == 'No') {
              data_content += ' ' + data[key] + ' ' + key + ', ';
            }
            if (
              data[key] != 'No' &&
              data[key] != 'Yes' &&
              data[key] != '' &&
              key != ''
            ) {
              data_content += ' ' + key + ' ' + data[key] + ', '; // + '<br>';
            }
          });
          if (data_content) {
            if (!appendTitle) {
              vnote_content += '<br><br><b>Examination</b>';
              appendTitle = true;
            }
            const header = stateKey.split('_')[0];
            vnote_content += `</br><b>${header.toUpperCase()}</b><br>`;
            vnote_content += data_content;
          }
        


        }
        if (stateKey == 'reviewin'){
          const data = this.state[stateKey];
            let data_content = '';
            Object.keys(data).forEach(function(key) {
            
              if (
                data[key] != 'No' &&
                data[key] != 'Yes' &&
                data[key] != '' &&
                key != ''
              ) {
                data_content += ' ' + key + ' ' + data[key] + ', '; // + '<br>';
              }
            });
            //vnote_content += `</br><b>${header.toUpperCase()}</b><br>`;
            vnote_content += `</br><b> Review In </b><br>`;
            vnote_content += data_content;
        }

        if (stateKey == 'wordfill'){
        
          const data = this.state[stateKey];
            let data_content = '';
            Object.keys(data).forEach(function(key) {
            
              if (
                data[key] != 'No' &&
                data[key] != 'Yes' &&
                data[key] != '' &&
                key != ''
              ) {
                data_content += ' ' + key + ' ' + data[key] + ', '; // + '<br>';
              }
            });
            //vnote_content += `</br><b>${header.toUpperCase()}</b><br>`;
            //vnote_content += `</br><b> Other </b><br>`;
            vnote_content += data_content;
        }

      }.bind(this)
    );
    console.log("&&&&&&&&&&&&&&",this.state.visit_note)
    this.props.set_visitnotes_details(vnote_content)
    this.submiteVisitNote(vnote_content)
  }

// timer starts
startTimer() {
  let starttime = this.props.selected_appointment.withdoctor_time;
  //let starttime = "2020-03-13 08:00";
  var now = moment(new Date()); //todays date
  var end = moment(starttime); // another date
  var diff_duration = moment.duration(now.diff(end));
  var hours = diff_duration.asSeconds();
  // alert(
  //   this.secondsToHms(hours) +
  //     "diff" +
  //     diff_duration +
  //     " hours" +
  //     hours +
  //     "time now" +
  //     Date.now()
  // );

  this.setState({
    time: this.state.time,
    start: hours - this.state.time,
    isOn: true
  });
 // console.log("***********************", this.state.time)

  //

  //alert("this.state.start"+ this.state.start);
  this.timer = setInterval(() => {
    //let duration = diff_duration - this.state.start
    //var hours = duration/1000;

    let starttime = this.props.selected_appointment.withdoctor_time;
    //let starttime = "2020-03-13 08:00";
    var now = moment(new Date()); //todays date
    var end = moment(starttime); // another date
    var diff_duration = moment.duration(now.diff(end));
    var hours = diff_duration.asSeconds();
    var differents_in_seconds = diff_duration.asSeconds();
    let duration = hours - this.state.start;
    let paused = hours - duration;
   // console.log("***********************", this.state.start)
  
   // console.log("MONITOR start", starttime, "now", new Date(), "gap", differents_in_seconds, "isOn", this.state.isOn,  "start & time", this.state.start, this.state.time );
    this.setState({
      time: duration,
      visitlength: this.secondsToHms(duration)
    });
  }, 1000);
}
stopTimer() {
  this.setState({ isOn: false });
  clearInterval(this.timer);
}
resetTimer() {
  this.setState({ time: 0 });
}

//   starttimer = () => {
//     setInterval(() => {
//         var now = moment(new Date()); //todays date

//         let starttime = this.state.selected_appointment.withdoctor_time;
//         //var end = moment("2020-1-5 0:00:00"); // another date
//         var end = moment(starttime); // another date
//         var duration = moment.duration(now.diff(end));
//         var hours = duration.asSeconds();
//         this.setState({
//           visitlength: this.secondsToHms(hours)
//         });
//       }, this.timegap());
//   }
timegap = () => {
  return this.state.isActivetime * parseInt(this.state.pause);
};

toggleTimer = () => {
  if (this.state.pause == 0) {
    this.setState({ isActivetime: 0, isSkiptime: 1000 });
    clearInterval(this.starttimer);
  //  alert("is active 0");
  }
  if (this.state.pause == 1) {
    this.setState({ isActivetime: 1000, isSkiptime: 0 });
    // alert("is active 1");
  }

  this.setState({ pause: this.state.pause == 0 ? 1 : 0 });
};
//timer ends






  handleSaveOptions = (e, section) => {
    this.setVisitNoteData();
    //   //preparing text
    //   //saving to props
    //   let vnote_content = '';
    //   if (
    //     section == 'general_popup' ||
    //     section == 'cardiovascular' ||
    //     section == 'respiratory' ||
    //     section == 'gastrointestinal' ||
    //     section == 'genitourinary' ||
    //     section == 'cns' ||
    //     section == 'ent' ||
    //     section == 'eye' ||
    //     section == 'skin' ||
    //     section == 'musculoskeletal'
    //   ) {
    //     vnote_content += '<br><b>History</b>';
    //   }
    //   if (section == 'general_popup') {
    //     let data = this.state.general;
    //     vnote_content += '</br><b>General</b><br>';
    //     Object.keys(data).forEach(function(key) {
    //       if (data[key] == 'Yes') {
    //         vnote_content +=
    //           ' ' + key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') + ', ';
    //       }
    //       if (data[key] == 'No') {
    //         vnote_content +=
    //           ' ' +
    //           data[key] +
    //           ' ' +
    //           key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') +
    //           ',';
    //       }
    //       if (data[key] != 'No' && data[key] != 'Yes' && data[key] != '') {
    //         vnote_content +=
    //           key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') +
    //           ' ' +
    //           data[key] +
    //           '<br>';
    //       }
    //     });
    //   }
    //   if (section == 'cardiovascular') {
    //     let data = this.state.cardiovascular;
    //     vnote_content += '</br><b>Cardiovascular</b>';
    //     Object.keys(data).forEach(function(key) {
    //       console.table('Key : ' + key + ', Value : ' + data[key]);
    //       vnote_content += key + ':' + data[key];
    //     });
    //   }
    //   if (section == 'respiratory') {
    //     let data = this.state.respiratory;
    //     vnote_content += '<b>Respiratory</b>';
    //     Object.keys(data).forEach(function(key) {
    //       console.table('Key : ' + key + ', Value : ' + data[key]);
    //       vnote_content += key + ':' + data[key];
    //     });
    //   }
    //   if (section == 'gastrointestinal') {
    //     let data = this.state.gastrointestinal;
    //     vnote_content += '<br><b>Gastrointestinal</b><br>';
    //     Object.keys(data).forEach(function(key) {
    //       if (data[key] == 'Yes') {
    //         vnote_content +=
    //           ' ' + key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') + ', ';
    //       }
    //       if (data[key] == 'No') {
    //         vnote_content +=
    //           ' ' +
    //           data[key] +
    //           ' ' +
    //           key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') +
    //           ',';
    //       }
    //     });
    //   }
    //   if (section == 'cns') {
    //     let data = this.state.cns;
    //     vnote_content += '</br><b>cns</b>';
    //     Object.keys(data).forEach(function(key) {
    //       console.table('Key : ' + key + ', Value : ' + data[key]);
    //       vnote_content += key + ':' + data[key];
    //     });
    //   }
    //   if (section == 'genitourinary') {
    //     let data = this.state.genitourinary;
    //     vnote_content += '<br><b>Genitourinary</b><br>';
    //     Object.keys(data).forEach(function(key) {
    //       if (data[key] == 'Yes') {
    //         vnote_content +=
    //           ' ' + key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') + ', ';
    //       }
    //       if (data[key] == 'No') {
    //         vnote_content +=
    //           ' ' +
    //           data[key] +
    //           ' ' +
    //           key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') +
    //           ',';
    //       }
    //     });
    //   }
    //   if (section == 'ent') {
    //     let data = this.state.ent;
    //     vnote_content += '<b></br>ent</b>';
    //     Object.keys(data).forEach(function(key) {
    //       console.table('Key : ' + key + ', Value : ' + data[key]);
    //       vnote_content += key + ':' + data[key];
    //     });
    //   }
    //   if (section == 'eye') {
    //     let data = this.state.eye;
    //     vnote_content += '<b></br>eye</b>';
    //     Object.keys(data).forEach(function(key) {
    //       console.table('Key : ' + key + ', Value : ' + data[key]);
    //       vnote_content += key + ':' + data[key];
    //     });
    //   }
    //   if (section == 'skin') {
    //     let data = this.state.skin;
    //     vnote_content += '</br><b>skin</b>';
    //     Object.keys(data).forEach(function(key) {
    //       console.table('Key : ' + key + ', Value : ' + data[key]);
    //       vnote_content += key + ':' + data[key];
    //     });
    //   }
    //   if (section == 'musculoskeletal') {
    //     let data = this.state.musculoskeletal;
    //     vnote_content += '<b></br> musculoskeletal</b>';
    //     Object.keys(data).forEach(function(key) {
    //       console.table('Key : ' + key + ', Value : ' + data[key]);
    //       vnote_content += key + ':' + data[key];
    //     });
    //   }
    //   if (section == 'reviewin') {
    //     let data = this.state.reviewin;
    //     vnote_content += '<b>reviewin</b>';
    //     Object.keys(data).forEach(function(key) {
    //       console.table('Key : ' + key + ', Value : ' + data[key]);
    //       vnote_content += key + ':' + data[key];
    //     });
    //   }
    //   // if (
    //   //   section == "general_examination"
    //   //   //||
    //   //   // section == "cardiovascular_examination" ||
    //   //   // section == "respiratory_examination" ||
    //   //   // section == "gastrointestinal_examination" ||
    //   //   // section == "cns_examination" ||
    //   //   // section == "ent_examination" ||
    //   //   // section == "eye_examination" ||
    //   //   // section == "skin_examination" ||
    //   //   // section == "musculoskeletal_examination"
    //   // ) {
    //   //   vnote_content += "<br><b>Examination</b>";
    //   // }
    //   vnote_content += '<br><b>Examination</b>';
    //   if (section == 'general_popup') {
    //     let data = this.state.general_examination;
    //     vnote_content += '<br><b>General </b><br>';
    //     Object.keys(data).forEach(function(key) {
    //       if (data[key] == 'Yes') {
    //         vnote_content +=
    //           ' ' + key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') + ', ';
    //       }
    //       if (data[key] == 'No') {
    //         vnote_content +=
    //           ' ' +
    //           data[key] +
    //           ' ' +
    //           key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') +
    //           ',';
    //       }
    //       if (
    //         data[key] != 'No' &&
    //         data[key] != 'Yes' &&
    //         data[key] != '' &&
    //         key != ''
    //       ) {
    //         vnote_content += key + ' ' + data[key] + '<br>';
    //       }
    //     });
    //   }
    //   if (section == 'cardiovascular') {
    //     let data = this.state.cardiovascular_examination;
    //     vnote_content += '<br><b> Cardiovascular  </b><br>';
    //     Object.keys(data).forEach(function(key) {
    //       if (data[key] == 'Yes') {
    //         vnote_content +=
    //           ' ' + key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') + ', ';
    //       }
    //       if (data[key] == 'No') {
    //         vnote_content +=
    //           ' ' +
    //           data[key] +
    //           ' ' +
    //           key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') +
    //           ',';
    //       }
    //       if (data[key] != 'No' && data[key] != 'Yes') {
    //         vnote_content += key + ' ' + data[key] + ',';
    //       }
    //       if (
    //         data[key] != 'No' &&
    //         data[key] != 'Yes' &&
    //         data[key] != '' &&
    //         key != ''
    //       ) {
    //         vnote_content += key + ' ' + data[key] + '<br>';
    //       }
    //     });
    //   }
    //   if (section == 'respiratory') {
    //     let data = this.state.respiratory_examination;
    //     vnote_content += '<br><b> Respiratory  </b><br>';
    //     Object.keys(data).forEach(function(key) {
    //       if (data[key] == 'Yes') {
    //         vnote_content +=
    //           ' ' + key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') + ', ';
    //       }
    //       if (data[key] == 'No') {
    //         vnote_content +=
    //           ' ' +
    //           data[key] +
    //           ' ' +
    //           key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') +
    //           ',';
    //       }
    //       if (data[key] != 'No' && data[key] != 'Yes') {
    //         // vnote_content += key + " " + data[key] + ",";
    //       }
    //       if (
    //         data[key] != 'No' &&
    //         data[key] != 'Yes' &&
    //         data[key] != '' &&
    //         key != ''
    //       ) {
    //         vnote_content += key + ' ' + data[key] + '<br>';
    //       }
    //     });
    //   }
    //   if (section == 'gastrointestinal') {
    //     let data = this.state.gastrointestinal_examination;
    //     vnote_content += '<br><b> gastrointestinal  </b><br>';
    //     Object.keys(data).forEach(function(key) {
    //       if (data[key] == 'Yes') {
    //         vnote_content +=
    //           ' ' + key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') + ', ';
    //       }
    //       if (data[key] == 'No') {
    //         vnote_content +=
    //           ' ' +
    //           data[key] +
    //           ' ' +
    //           key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') +
    //           ',';
    //       }
    //       if (
    //         data[key] == 'Left' ||
    //         data[key] == 'Right' ||
    //         data[key] == 'Both Right Left'
    //       ) {
    //         vnote_content +=
    //           key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') +
    //           ' ' +
    //           data[key] +
    //           '<br>';
    //       }
    //       if (data[key] == 'Normal' || data[key] == 'Abnormal') {
    //         vnote_content +=
    //           key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') +
    //           ' ' +
    //           data[key] +
    //           '<br>';
    //       }
    //     });
    //   }
    //   if (section == 'cns') {
    //     let data = this.state.cns_examination;
    //     vnote_content += '<br><b> CNS  </b><br>';
    //     Object.keys(data).forEach(function(key) {
    //       if (data[key] == 'Yes') {
    //         vnote_content +=
    //           ' ' + key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') + ', ';
    //       }
    //       if (data[key] == 'No') {
    //         vnote_content +=
    //           ' ' +
    //           data[key] +
    //           ' ' +
    //           key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') +
    //           ',';
    //       }
    //       if (
    //         data[key] == 'Left' ||
    //         data[key] == 'Right' ||
    //         data[key] == 'Both Right Left'
    //       ) {
    //         vnote_content +=
    //           key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') +
    //           ' ' +
    //           data[key] +
    //           '<br>';
    //       }
    //       if (data[key] == 'Normal' || data[key] == 'Abnormal') {
    //         vnote_content +=
    //           key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') +
    //           ' ' +
    //           data[key] +
    //           '<br>';
    //       }
    //     });
    //   }
    //   if (section == 'genitourinary') {
    //     let data = this.state.genitourinary_examination;
    //     vnote_content += '<br><b> genitourinary  </b><br>';
    //     Object.keys(data).forEach(function(key) {
    //       if (data[key] == 'Yes') {
    //         vnote_content +=
    //           ' ' + key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') + ', ';
    //       }
    //       if (data[key] == 'No') {
    //         vnote_content +=
    //           ' ' +
    //           data[key] +
    //           ' ' +
    //           key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') +
    //           ',';
    //       }
    //       if (
    //         data[key] == 'Left' ||
    //         data[key] == 'Right' ||
    //         data[key] == 'Both Right Left'
    //       ) {
    //         vnote_content +=
    //           key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') +
    //           ' ' +
    //           data[key] +
    //           '<br>';
    //       }
    //       if (data[key] != 'No' && data[key] != 'Yes') {
    //         // vnote_content += key + " " + data[key] + ",";
    //       }
    //       if (
    //         data[key] != 'No' &&
    //         data[key] != 'Yes' &&
    //         data[key] != '' &&
    //         key != ''
    //       ) {
    //         vnote_content += key + ' ' + data[key] + '<br>';
    //       }
    //     });
    //   }
    //   if (section == 'ent') {
    //     let data = this.state.ent_examination;
    //     vnote_content += '<br><b> Ent  </b><br>';
    //     Object.keys(data).forEach(function(key) {
    //       if (data[key] == 'Yes') {
    //         vnote_content +=
    //           ' ' + key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') + ', ';
    //       }
    //       if (data[key] == 'No') {
    //         vnote_content +=
    //           ' ' +
    //           data[key] +
    //           ' ' +
    //           key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') +
    //           ',';
    //       }
    //       if (
    //         data[key] == 'Left' ||
    //         data[key] == 'Right' ||
    //         data[key] == 'Both Right Left'
    //       ) {
    //         vnote_content +=
    //           key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') +
    //           ' ' +
    //           data[key] +
    //           '<br>';
    //       }
    //       if (data[key] != 'No' && data[key] != 'Yes') {
    //         // vnote_content += key + " " + data[key] + ",";
    //       }
    //       if (
    //         data[key] != 'No' &&
    //         data[key] != 'Yes' &&
    //         data[key] != '' &&
    //         key != ''
    //       ) {
    //         vnote_content += key + ' ' + data[key] + '<br>';
    //       }
    //     });
    //   }
    //   if (section == 'eye') {
    //     let data = this.state.eye_examination;
    //     vnote_content += '<br><b> eye  </b><br>';
    //     Object.keys(data).forEach(function(key) {
    //       if (data[key] == 'Yes') {
    //         vnote_content +=
    //           ' ' + key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') + ', ';
    //       }
    //       if (data[key] == 'No') {
    //         vnote_content +=
    //           ' ' +
    //           data[key] +
    //           ' ' +
    //           key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') +
    //           ',';
    //       }
    //       if (
    //         data[key] == 'Left' ||
    //         data[key] == 'Right' ||
    //         data[key] == 'Both Right Left'
    //       ) {
    //         vnote_content +=
    //           key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') +
    //           ' ' +
    //           data[key] +
    //           '<br>';
    //       }
    //       if (data[key] != 'No' && data[key] != 'Yes') {
    //         // vnote_content += key + " " + data[key] + ",";
    //       }
    //     });
    //   }
    //   if (section == 'Skin') {
    //     let data = this.state.Skin_examination;
    //     vnote_content += '<br><b> Skin  </b><br>';
    //     Object.keys(data).forEach(function(key) {
    //       if (data[key] == 'Yes') {
    //         vnote_content +=
    //           ' ' + key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') + ', ';
    //       }
    //       if (data[key] == 'No') {
    //         vnote_content +=
    //           ' ' +
    //           data[key] +
    //           ' ' +
    //           key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') +
    //           ',';
    //       }
    //       if (
    //         data[key] == 'Left' ||
    //         data[key] == 'Right' ||
    //         data[key] == 'Both Right Left'
    //       ) {
    //         vnote_content +=
    //           key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') +
    //           ' ' +
    //           data[key] +
    //           '<br>';
    //       }
    //       if (data[key] != 'No' && data[key] != 'Yes') {
    //         // vnote_content += key + " " + data[key] + ",";
    //       }
    //       if (
    //         data[key] != 'No' &&
    //         data[key] != 'Yes' &&
    //         data[key] != '' &&
    //         key != ''
    //       ) {
    //         vnote_content += key + ' ' + data[key] + '<br>';
    //       }
    //     });
    //   }
    //   if (section == 'SkMusculoskeletalin') {
    //     let data = this.state.SkMusculoskeletalin_examination;
    //     vnote_content += '<br><b> SkMusculoskeletalin  </b><br>';
    //     Object.keys(data).forEach(function(key) {
    //       if (data[key] == 'Yes') {
    //         vnote_content +=
    //           ' ' + key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') + ', ';
    //       }
    //       if (data[key] == 'No') {
    //         vnote_content +=
    //           ' ' +
    //           data[key] +
    //           ' ' +
    //           key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') +
    //           ',';
    //       }
    //       if (
    //         data[key] == 'Left' ||
    //         data[key] == 'Right' ||
    //         data[key] == 'Both Right Left'
    //       ) {
    //         vnote_content +=
    //           key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ') +
    //           ' ' +
    //           data[key] +
    //           '<br>';
    //       }
    //       if (data[key] != 'No' && data[key] != 'Yes') {
    //         // vnote_content += key + " " + data[key] + ",";
    //       }
    //       if (
    //         data[key] != 'No' &&
    //         data[key] != 'Yes' &&
    //         data[key] != '' &&
    //         key != ''
    //       ) {
    //         vnote_content += key + ' ' + data[key] + '<br>';
    //       }
    //     });
    //   }
    //   this.props.set_visitnotes_details(vnote_content);
  };



  closeDiagonsis = () => {
    this.setState({openDiagonsis:false})
  }
 
  closediagnosistodaynotes = () => {
    this.setState({openDiagonsistv:false})
  }
  openDiagonsis = () => {
   // alert('IN')
    this.setState({openDiagonsis:true})
  }
  opendiagnosistodaynotes = () => {
    this.setState({openDiagonsistv:true})
  }

  appendContent = () => {
    let data = EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML('<p>My initial content by custom function.</p>')
      )
    );

    this.setState({ editorState: data });
  };

  _getInitialHTML() {
    const contentBlocks = convertFromHTML(this.strreplaceables(this.props.visitnotes_details));
    const contentState = ContentState.createFromBlockArray(contentBlocks);
    return convertToRaw(contentState);
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    if (nextProps.redux_patientId !== this.props.redux_patientId) {
      this.setState({ patientId: nextProps.redux_patientId });
      this.setState({
         selected_appointment: nextProps.selected_appointment ,
        doctor_id: nextProps.logged_info.content.details[0].doctor_id,
      });
    }

    if (nextProps.visitnotes_details !== this.props.visitnotes_details) {
      console.log('changes in receive props');
      this.setState({ editorContent: this.props.visitnotes_details });
      this.setState({
        editorState: EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML('<p>' + this.strreplaceables(nextProps.visitnotes_details) + '</p>')
          )
        ),
      });

      //this.setState({editorState: this._getInitialHTML()})
    }
  }

  componentWillMount() {
    console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&",this.props.selected_appointment)
    //Get All visit type
    this.props.getVisitType().then(visit_type => {
      this.setState({ visit_type: visit_type.data.content });
    });
    //Get All providers (doctors) for patient so far
    this.props
      .getProviders({ params: { patient_id: this.state.patientId } })
      .then(list => {
        this.setState({ doc_providers: list.data.content });
      });

    //Get All visit type(reason) stored for the patient so far
    this.props
      .getPatientsVisitReason({ params: { patient_id: this.state.patientId } })
      .then(rsnlist => {
        this.setState({ patient_visit_reason: rsnlist.data.content });
        console.log(rsnlist.data.content);
      });

    // Get all visit notes
    this.props
      .searchVisitNotes({ params: { patient_id: this.state.patientId } })
      .then(visitlist => {
        console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
        console.log(visitlist.data.content[0].notes);
        this.setState({ all_visit_list: visitlist.data.content });
        
        /*this.setState({
          editorState: EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML('<p>' +visitlist.data.content[0].notes + '</p>')
            )
          ),
        });*/
      });

      this.props
      .searchNotes({ params: { patient_id: this.state.patientId,appoinment_id:this.props.selected_appointment.appoinment_id} })
      .then(res => {
        if(res.data.content!='') {	
		  //alert(res.data.content[0].reason_for_visit)
		  //this.setState({ visit_reason: res.data.content[0].reason_for_visit });	
		  this.setState(
		  {
			  visit_reason: res.data.content[0].reason_for_visit,
			  general: {
					Fever: res.data.content[0].general.Fever,
					Lathargy: res.data.content[0].general.Lathargy,
					Malaise: res.data.content[0].general.Malaise,
					Anorexia: res.data.content[0].general.Anorexia,
					Nausea: res.data.content[0].general.Nausea,
					Giddiness: res.data.content[0].general.Giddiness,
					WeightLoss: res.data.content[0].general.WeightLoss,
					WeightGain: res.data.content[0].general.WeightGain,
					RecentTravel: res.data.content[0].general.RecentTravel
			 },
			  general_examination: {
					pulse: res.data.content[0].general_examination.pulse,
					PulseUnit: res.data.content[0].general_examination.PulseUnit,
					BloodPressureSitting: res.data.content[0].general_examination.BloodPressureSitting,
					BloodPressureSittingRight: res.data.content[0].general_examination.BloodPressureSittingRight,
					BloodPressureStanding: res.data.content[0].general_examination.BloodPressureStanding,
					BloodPressureLying: res.data.content[0].general_examination.BloodPressureLying,
					Temp: res.data.content[0].general_examination.Temp,
					TempUnit: res.data.content[0].general_examination.TempUnit,
					RespRate: res.data.content[0].general_examination.RespRate,
					WeightKG: res.data.content[0].general_examination.WeightKG,
					HeightCM: res.data.content[0].general_examination.HeightCM,
					BSL: res.data.content[0].general_examination.BSL,
					BSLUnit: res.data.content[0].general_examination.BSLUnit,
					WaistMeasurement: res.data.content[0].general_examination.WaistMeasurement,
					HeadMeasurement: res.data.content[0].general_examination.HeadMeasurement,
					Dehydration: res.data.content[0].general_examination.Dehydration,
					DehydrationInPercentage: res.data.content[0].general_examination.DehydrationInPercentage,
					O2Saturation: res.data.content[0].general_examination.O2Saturation,
					Clinically: res.data.content[0].general_examination.Clinically,
					Jaundiced: res.data.content[0].general_examination.Jaundiced
			 },			 
			 cardiovascular: {
					ChestPain: res.data.content[0].cardiovascular.ChestPain,
					Dyspnoea: res.data.content[0].cardiovascular.Dyspnoea,
					Orthospnoea: res.data.content[0].cardiovascular.Orthospnoea,
					SwollenAnkles: res.data.content[0].cardiovascular.SwollenAnkles,
					Fever: res.data.content[0].cardiovascular.Fever,
					Claudication: res.data.content[0].cardiovascular.Claudication,
					Palpitations: res.data.content[0].cardiovascular.Palpitations
			 },
			 cardiovascular_examination: {
					JVP: res.data.content[0].cardiovascular_examination.JVP,
					IntercostalSpace: res.data.content[0].cardiovascular_examination.IntercostalSpace,
					CarotidBruit: res.data.content[0].cardiovascular_examination.CarotidBruit,
					ApexBeat: res.data.content[0].cardiovascular_examination.ApexBeat,
					AnkleOedema: res.data.content[0].cardiovascular_examination.AnkleOedema,
					HeartsSounds: res.data.content[0].cardiovascular_examination.HeartsSounds,
					Description: res.data.content[0].cardiovascular_examination.Description,
					Radiation: res.data.content[0].cardiovascular_examination.Radiation, 
					Brachial: res.data.content[0].cardiovascular_examination.Brachial,
					Radial: res.data.content[0].cardiovascular_examination.Radial,
					Popliteal: res.data.content[0].cardiovascular_examination.Popliteal,
					PT: res.data.content[0].cardiovascular_examination.PT,
					DP: res.data.content[0].cardiovascular_examination.DP
			 },			 
			 respiratory: {
					Cough: res.data.content[0].respiratory.Cough,
					Sputum: res.data.content[0].respiratory.Sputum,
					Haemoptysis: res.data.content[0].respiratory.Haemoptysis,
					Pleutritic: res.data.content[0].respiratory.Pleutritic,
					Wheeze: res.data.content[0].respiratory.Wheeze,
					asthama: res.data.content[0].respiratory.asthama
			 },
			 respiratory_examination: {
					respiratoryDistress: res.data.content[0].respiratory_examination.respiratoryDistress,
					Recession: res.data.content[0].respiratory_examination.Recession,
					UsingAccessoryMuscles: res.data.content[0].respiratory_examination.UsingAccessoryMuscles,
					ReducesAirEntry: res.data.content[0].respiratory_examination.ReducesAirEntry,
					Creps: res.data.content[0].respiratory_examination.Creps,
					PeakFlow: res.data.content[0].respiratory_examination.PeakFlow,
					Expiration: res.data.content[0].respiratory_examination.Expiration,
					Inspiration: res.data.content[0].respiratory_examination.Inspiration, 
					Rhonchi: res.data.content[0].respiratory_examination.Rhonchi
			 },
			 gastrointestinal: {
					/*Incisional: res.data.content[0].gastrointestinal.Incisional,
					Umbilical: res.data.content[0].gastrointestinal.Umbilical,
					PrTenderness: res.data.content[0].gastrointestinal.PrTenderness,
					LeftInguinal: res.data.content[0].gastrointestinal.LeftInguinal,
					RightInguinal: res.data.content[0].gastrointestinal.RightInguinal,
					PrMass: res.data.content[0].gastrointestinal.PrMass,
					RightFemoral: res.data.content[0].gastrointestinal.RightFemoral,
					LeftFemoral: res.data.content[0].gastrointestinal.LeftFemoral,
					PrBlood: res.data.content[0].gastrointestinal.PrBlood,
					Ascites: res.data.content[0].gastrointestinal.Ascites,
					BowelSound: res.data.content[0].gastrointestinal.BowelSound,
					Splenomegaly: res.data.content[0].gastrointestinal.Splenomegaly,
					Hepatomegaly: res.data.content[0].gastrointestinal.Hepatomegaly,
					Distension: res.data.content[0].gastrointestinal.Distension,
					Rebound: res.data.content[0].gastrointestinal.Rebound,
					Rigidity: res.data.content[0].gastrointestinal.Rigidity,*/
					PrBleeding: res.data.content[0].gastrointestinal.PrBleeding,
					ChangedBowelHabit: res.data.content[0].gastrointestinal.ChangedBowelHabit,
					Constipation: res.data.content[0].gastrointestinal.Constipation,
					Diarrhoea: res.data.content[0].gastrointestinal.Diarrhoea,
					WeightLoss: res.data.content[0].gastrointestinal.WeightLoss,
					Haematemesis: res.data.content[0].gastrointestinal.Haematemesis,
					Reflux: res.data.content[0].gastrointestinal.Reflux,
					Anorexia: res.data.content[0].gastrointestinal.Anorexia,
					Vomiting: res.data.content[0].gastrointestinal.Vomiting,
					Hurtburn: res.data.content[0].gastrointestinal.Hurtburn,
					Dysphagia: res.data.content[0].gastrointestinal.Dysphagia,
					Nausea: res.data.content[0].gastrointestinal.Nausea,
					AbdominalPain: res.data.content[0].gastrointestinal.AbdominalPain
			 },
			 gastrointestinal_examination: {			 
			 		PrBlood: res.data.content[0].gastrointestinal_examination.PrBlood,
					RightInguinal: res.data.content[0].gastrointestinal_examination.RightInguinal,
					LeftInguinal: res.data.content[0].gastrointestinal_examination.LeftInguinal,
					PrMass: res.data.content[0].gastrointestinal_examination.PrMass,
					RightFemoral: res.data.content[0].gastrointestinal_examination.RightFemoral,
					LeftFemoral: res.data.content[0].gastrointestinal_examination.LeftFemoral,
					PrTenderness: res.data.content[0].gastrointestinal_examination.PrTenderness,
					Umbilical: res.data.content[0].gastrointestinal_examination.Umbilical,
					Incisional: res.data.content[0].gastrointestinal_examination.Incisional,
					Gaurding: res.data.content[0].gastrointestinal_examination.Gaurding,
					Rigidity: res.data.content[0].gastrointestinal_examination.Rigidity,
					Rebound: res.data.content[0].gastrointestinal_examination.Rebound,
					Distension: res.data.content[0].gastrointestinal_examination.Distension,
					Hepatomegaly: res.data.content[0].gastrointestinal_examination.Hepatomegaly,
					Splenomegaly: res.data.content[0].gastrointestinal_examination.Splenomegaly,
					BowelSound: res.data.content[0].gastrointestinal_examination.BowelSound,
					Spgravity: res.data.content[0].gastrointestinal_examination.Spgravity,
					Ascites: res.data.content[0].gastrointestinal_examination.Ascites
			 },			 
			 cns: {
					Headache: res.data.content[0].cns.Headache,
					NeckStiffness: res.data.content[0].cns.NeckStiffness,
					Photofobia: res.data.content[0].cns.Photofobia,
					Hyperacusis: res.data.content[0].cns.Hyperacusis,
					Fits: res.data.content[0].cns.Fits,
					Faints: res.data.content[0].cns.Faints,
					Weakness: res.data.content[0].cns.Weakness,
					Numbness: res.data.content[0].cns.Numbness,
					SlurredSpeech: res.data.content[0].cns.SlurredSpeech,
					Incoordination: res.data.content[0].cns.Incoordination,
					Confusion: res.data.content[0].cns.Confusion,
					Disorientation: res.data.content[0].cns.Disorientation,
					Tremor: res.data.content[0].cns.Tremor
			 },
			 cns_examination: {			 
			 		EssentialTremor: res.data.content[0].cns_examination.EssentialTremor,
					UpperFacialPalsy: res.data.content[0].cns_examination.UpperFacialPalsy,
					IntensionTremor: res.data.content[0].cns_examination.IntensionTremor,
					LowerFacialPalsy: res.data.content[0].cns_examination.LowerFacialPalsy,
					ArmsWickness: res.data.content[0].cns_examination.ArmsWickness,
					CogwheelRigidity: res.data.content[0].cns_examination.CogwheelRigidity,
					LegWeakness: res.data.content[0].cns_examination.LegWeakness,
					ArmNumbness: res.data.content[0].cns_examination.ArmNumbness,
					LegNumbness: res.data.content[0].cns_examination.LegNumbness,
					FootDrop: res.data.content[0].cns_examination.FootDrop,
					RightEyePtosis: res.data.content[0].cns_examination.RightEyePtosis,
					LeftEyePtosis: res.data.content[0].cns_examination.LeftEyePtosis,
					RightEyeEOM: res.data.content[0].cns_examination.RightEyeEOM,
					LeftEyeEOM: res.data.content[0].cns_examination.LeftEyeEOM,
					RightFields: res.data.content[0].cns_examination.RightFields,
					LeftFields: res.data.content[0].cns_examination.LeftFields,
					RightPupils: res.data.content[0].cns_examination.RightPupils,					
					LeftPupils: res.data.content[0].cns_examination.LeftPupils,
					InvoluntaryMovements: res.data.content[0].cns_examination.InvoluntaryMovements
			 },			 
			 genitourinary: {
					Dysuria: res.data.content[0].genitourinary.Dysuria,
					Frequency: res.data.content[0].genitourinary.Frequency,
					Noctoria: res.data.content[0].genitourinary.Noctoria,
					RightLoinPain: res.data.content[0].genitourinary.RightLoinPain,
					LeftLoinPain: res.data.content[0].genitourinary.LeftLoinPain,
					SubrapubicPain: res.data.content[0].genitourinary.SubrapubicPain,
					Haematuria: res.data.content[0].genitourinary.Haematuria,
					Urgency: res.data.content[0].genitourinary.Urgency,
					StressIncontinence: res.data.content[0].genitourinary.StressIncontinence,
					TesticularPain: res.data.content[0].genitourinary.TesticularPain,
					TesticularLump: res.data.content[0].genitourinary.TesticularLump,
					Impotence: res.data.content[0].genitourinary.Impotence,
					DifficultyVoiding: res.data.content[0].genitourinary.DifficultyVoiding,
					TermianlDribbling: res.data.content[0].genitourinary.TermianlDribbling,
					InCompleteEmptying: res.data.content[0].genitourinary.InCompleteEmptying,
					PenileDischarge: res.data.content[0].genitourinary.PenileDischarge,
					PenileLesin: res.data.content[0].genitourinary.PenileLesin,
					Haematospermia: res.data.content[0].genitourinary.Haematospermia
			 },
			 genitourinary_examination: {
					UrethralDischarge: res.data.content[0].genitourinary_examination.UrethralDischarge,
					PenileWarts: res.data.content[0].genitourinary_examination.PenileWarts,
					LointenTerness: res.data.content[0].genitourinary_examination.LointenTerness,
					Scrotalswelling: res.data.content[0].genitourinary_examination.Scrotalswelling,
					Scrotalredness: res.data.content[0].genitourinary_examination.Scrotalredness,
					Blood: res.data.content[0].genitourinary_examination.Blood,
					Protein: res.data.content[0].genitourinary_examination.Protein,
					Prostatomegaly: res.data.content[0].genitourinary_examination.Prostatomegaly,
					Spgravity: res.data.content[0].genitourinary_examination.Spgravity,
					pH: res.data.content[0].genitourinary_examination.pH,
					Ketones: res.data.content[0].genitourinary_examination.Ketones,
					Urobilinogen: res.data.content[0].genitourinary_examination.Urobilinogen,
					Testicularswelling: res.data.content[0].genitourinary_examination.Testicularswelling,
					Testiculartenderness: res.data.content[0].genitourinary_examination.Testiculartenderness,
					Undescendedtesties: res.data.content[0].genitourinary_examination.Undescendedtesties,
					Hydrocoele: res.data.content[0].genitourinary_examination.Hydrocoele,
					Varicocoele: res.data.content[0].genitourinary_examination.Varicocoele,
					Glucose: res.data.content[0].genitourinary_examination.Glucose,
					Bilirubin: res.data.content[0].genitourinary_examination.Bilirubin,
					Leucocytes: res.data.content[0].genitourinary_examination.Leucocytes,
					Nitrites: res.data.content[0].genitourinary_examination.Nitrites,
					Urobilinoge: res.data.content[0].genitourinary_examination.Urobilinoge,
					PrBlood: res.data.content[0].genitourinary_examination.PrBlood,
					PrMass: res.data.content[0].genitourinary_examination.PrMass,
					PrTenderness: res.data.content[0].genitourinary_examination.PrTenderness
			 },
			 ent: {
					Earache: res.data.content[0].ent.Earache,
					EarDischarge: res.data.content[0].ent.EarDischarge,
					Deafness: res.data.content[0].ent.Deafness,
					Rhinorrhoea: res.data.content[0].ent.Rhinorrhoea,
					Epistaxis: res.data.content[0].ent.Epistaxis,
					NasalCongestion: res.data.content[0].ent.NasalCongestion,
					SoreThroat: res.data.content[0].ent.SoreThroat,
					HoarseVoice: res.data.content[0].ent.HoarseVoice,
					LostVoice: res.data.content[0].ent.LostVoice,
					FacialPain: res.data.content[0].ent.FacialPain
			 },
			 ent_examination: {
					DrumRed: res.data.content[0].ent_examination.DrumRed,
					RedThroat: res.data.content[0].ent_examination.RedThroat,
					Discharge: res.data.content[0].ent_examination.Discharge,
					PusOnTonsils: res.data.content[0].ent_examination.PusOnTonsils,
					Perforation: res.data.content[0].ent_examination.Perforation,
					EnlargedTonsils: res.data.content[0].ent_examination.EnlargedTonsils,
					Glue: res.data.content[0].ent_examination.Glue,
					NoseDischarge: res.data.content[0].ent_examination.NoseDischarge,
					NasalObstruction: res.data.content[0].ent_examination.NasalObstruction,
					Canalred: res.data.content[0].ent_examination.Canalred,
					Wax: res.data.content[0].ent_examination.Wax,
					NoseRedness: res.data.content[0].ent_examination.NoseRedness,
					Fb: res.data.content[0].ent_examination.Fb,
					NasalFB: res.data.content[0].ent_examination.NasalFB,
					MouthLesion: res.data.content[0].ent_examination.MouthLesion,
					Description: res.data.content[0].ent_examination.Description
			 },
			 eye: {
					VisualDeterioration: res.data.content[0].eye.VisualDeterioration,
					VisualLoss: res.data.content[0].eye.VisualLoss,
					SomethingInEye: res.data.content[0].eye.SomethingInEye,
					ItcyEye: res.data.content[0].eye.ItcyEye,
					RedEye: res.data.content[0].eye.RedEye,
					SoreEye: res.data.content[0].eye.SoreEye,
					WateringEye: res.data.content[0].eye.WateringEye,
					EyeDischarge: res.data.content[0].eye.EyeDischarge,
					DoubleVision: res.data.content[0].eye.DoubleVision
			 },
			 eye_examination: {
					Redness: res.data.content[0].eye_examination.Redness,
					Discharge: res.data.content[0].eye_examination.Discharge,
					Swelling: res.data.content[0].eye_examination.Swelling,
					Ulcer: res.data.content[0].eye_examination.Ulcer,
					Pterygium: res.data.content[0].eye_examination.Pterygium,
					IngrowingLashes: res.data.content[0].eye_examination.IngrowingLashes,
					Ecropion: res.data.content[0].eye_examination.Ecropion,
					Hyphaema: res.data.content[0].eye_examination.Hyphaema,
					Cataract: res.data.content[0].eye_examination.Cataract,
					ColourVision: res.data.content[0].eye_examination.ColourVision,
					Papilloedema: res.data.content[0].eye_examination.Papilloedema,
					withoutglassesLeft: res.data.content[0].eye_examination.withoutglassesLeft,
					withoutglassesright: res.data.content[0].eye_examination.withoutglassesright,
					withoutglassesboth: res.data.content[0].eye_examination.withoutglassesboth,
					withglassesLeft: res.data.content[0].eye_examination.withglassesLeft,
					withglassesright: res.data.content[0].eye_examination.withglassesright,
					withglassesboth: res.data.content[0].eye_examination.withglassesboth,
					foreignbodyright: res.data.content[0].eye_examination.foreignbodyright,
					foreignbodyleft: res.data.content[0].eye_examination.foreignbodyleft,
					Retinopathyright: res.data.content[0].eye_examination.Retinopathyright,
					Retinopathyleft: res.data.content[0].eye_examination.Retinopathyleft,
					Entropion: res.data.content[0].eye_examination.Entropion
			 },
			 skin: {
					Rash: res.data.content[0].skin.Rash,
					Itch: res.data.content[0].skin.Itch,
					Lesion: res.data.content[0].skin.Lesion
			},
			 skin_examination: {
					Rash: res.data.content[0].skin_examination.Rash,
					Description: res.data.content[0].skin_examination.Description,
					Lesion: res.data.content[0].skin_examination.Lesion
			 },
			musculoskeletal: {
					NeckPain: res.data.content[0].musculoskeletal.NeckPain,
					BackPain: res.data.content[0].musculoskeletal.BackPain,
					ShoulderPain: res.data.content[0].musculoskeletal.ShoulderPain,
					HipPain: res.data.content[0].musculoskeletal.HipPain,
					ElblowPain: res.data.content[0].musculoskeletal.ElblowPain,
					KneePain: res.data.content[0].musculoskeletal.KneePain,
					WristPain: res.data.content[0].musculoskeletal.WristPain,
					AnklePain: res.data.content[0].musculoskeletal.AnklePain,
					HandPain: res.data.content[0].musculoskeletal.HandPain,
					FootPain: res.data.content[0].musculoskeletal.FootPain,
					FingerPain: res.data.content[0].musculoskeletal.FingerPain,
					ToePain: res.data.content[0].musculoskeletal.ToePain,
					Injury: res.data.content[0].musculoskeletal.Injury
		 },
		musculoskeletal_examination: {
					Joint: res.data.content[0].musculoskeletal_examination.Joint
			},		 
		 gynae: {
					PvBleeding: res.data.content[0].gynae.PvBleeding,
					PvDischarge: res.data.content[0].gynae.PvDischarge,
					ShoulderPain: res.data.content[0].gynae.ShoulderPain,
					Amenorrhoea: res.data.content[0].gynae.Amenorrhoea,
					Dysmenorrhoea: res.data.content[0].gynae.Dysmenorrhoea,
					VulvalItch: res.data.content[0].gynae.VulvalItch,
					IntermenstrutalBleeding: res.data.content[0].gynae.IntermenstrutalBleeding,
					PostCoitalBleeding: res.data.content[0].gynae.PostCoitalBleeding,
					PostMenopausalBleeding: res.data.content[0].gynae.PostMenopausalBleeding,
					Dyspareunia: res.data.content[0].gynae.Dyspareunia,
					UnprotectedIntercourse: res.data.content[0].gynae.UnprotectedIntercourse,
					HeavyPeriods: res.data.content[0].gynae.HeavyPeriods,
					IrregularPeriod: res.data.content[0].gynae.IrregularPeriod,
					PregnancyTestPositive: res.data.content[0].gynae.PregnancyTestPositive
		 },
		 gynae_examination: {
					VaginalDischarge: res.data.content[0].gynae_examination.VaginalDischarge,
					CervixSuspicious: res.data.content[0].gynae_examination.CervixSuspicious,
					CSTPerformed: res.data.content[0].gynae_examination.CSTPerformed,
					CervicalPolyp: res.data.content[0].gynae_examination.CervicalPolyp,
					CervicalEctopy: res.data.content[0].gynae_examination.CervicalEctopy,
					Cystocele: res.data.content[0].gynae_examination.Cystocele,
					Rectocele: res.data.content[0].gynae_examination.Rectocele,
					BulkyUterus: res.data.content[0].gynae_examination.BulkyUterus,
					UterineTenderness: res.data.content[0].gynae_examination.UterineTenderness,
					RetrovertedUterus: res.data.content[0].gynae_examination.RetrovertedUterus,
					FornicealTenderness: res.data.content[0].gynae_examination.FornicealTenderness,
					FornicealMass: res.data.content[0].gynae_examination.FornicealMass
		}
	});

          this.setState({
            editorState: EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML('<p>' +res.data.content[0].notes + '</p>')
              )
            ),
          });
        }
      });
    //  this.setState({ editorContent: this.props.visitnotes_details });    
  }
  
  
  submiteVisitNote = (vnote) => {
    const visiData = {
      vd_visittype: this.state.selected_visit_type,
      vd_reason: this.state.visit_reason,
      vd_visitnote: vnote,
      vd_patient: this.state.patientId,
      vd_doctor: this.state.doctor_id,
      vd_appointment: this.props.selected_appointment.appoinment_id,
      vd_careitem: '237',
      vd_reviewdate: this.state.date,
      vd_reviewtime: this.state.hours,
      vd_confidential: 0,
      vd_deleted: 1,
    };
    this.props.apiAddVisitnote(visiData).then(()=> {
    //  alert("Visit Notes Details Updated - WIP Feature");
     /*NotificationManager.success(
       "Visit Notes AutoUpdated !"
     );*/

    });
  //  const currentContent = draftToHtml(convertToRaw(vnote));
    //console.log('editor notes123', currentContent);
  }
  diagonsisSave = (data,popupfor) => {
 // alert(popupfor)
    let acute_selected = "";

    if(Object.keys(data.acuteCheckedConditions).length>0 || 
    Object.keys(data.chronicCheckedConditions).length>0 ||
    Object.keys(data.drFavCheckedConditions).length>0 || 
    Object.keys(data.drFavCheckedConditions2).length>0 ||
    Object.keys(data.patientFavCheckedConditions).length>0){
     //  acute_selected = data.acuteCheckedConditions.join()+ "<br> "+ data.chronicCheckedConditions.join() + " <br>" + data.drFavCheckedConditions.join() + " <br>" + data.patientFavCheckedConditions.join() + " <br>" ;
      
     
  Object.keys(data.acuteCheckedConditions).forEach(function(key) {
    acute_selected +=    key + ",";
  });
  
  Object.keys(data.chronicCheckedConditions).forEach(function(key) {
    acute_selected +=    key + ",";
  });
  
  Object.keys(data.drFavCheckedConditions).forEach(function(key) {
    acute_selected +=    key + ",";
  });
  
  Object.keys(data.drFavCheckedConditions2).forEach(function(key) {
    acute_selected +=    key + ",";
  });
  
  Object.keys(data.patientFavCheckedConditions).forEach(function(key) {
    acute_selected +=    key + ",";
  });

    } else {
      acute_selected = " - ";
    }



    let content = popupfor+":</br>"+ acute_selected;
    let existing_content = this.props.visitnotes_details + "<br><br>";
    content = content.replace(/(\r\n|\n|\r)/gm, " </br>");
    existing_content += content;

    this.props.set_visitnotes_details(existing_content);
   
    this.props.diagonsisSave(data);
    const currentContent = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
    this.submiteVisitNote(currentContent)
    
  };
  setTrClass = (y, z, id) => {
    console.log(y);
    console.log(z);
    console.log(id);
    let classs = '';
    classs += z % 2 == 0 ? ' firstColor ' : ' secondColor ';
    classs += y ? ' yearSeperator ' : '';
    //   classs += id == this.state.showSelectedContent.patients_report_id ? ' selectedBg ': '';
    return classs;
  };
  amendData() {
    console.log(this.state.visit_note);
    console.log(this.state.selected_past_visit);
    this.state.visit_note = this.state.selected_past_visit;
    console.log(this.state.visit_note);
  }
  medicalcenter=()=>
  {
   // alert("IN")
   this.setState({medicalmodal:true})
  }
  changeKey=()=>
  {
    this.setState({medicalmodal:false,pathologyPop:false,radiologyPop:false,medicationpop:false})
  }

  componentDidMount(){
    this.startTimer();
    this.props.listwordfill({type:"visit_notes"}).then(res => {
      this.setState({suggestions: res.data.content});
    });
   // this.autosave();


   setInterval(function(){ 
    //code goes here that will be run every 5 seconds.    
     //this.submiteVisitNote();
    // this.submiteVisitNote.bind(this);
   //alert("5sec");
  }, 20000);

  }
  autosave = ()  => {

  //   setInterval(function(){ 
  //     //code goes here that will be run every 5 seconds.    
  //    this.submiteVisitNote();
  //    //alert("5sec");
  // }, 20000);

     
     
  } 
 
  secondsToHms = d => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
 //   console.log("<><><><><><><><><><><>",d)
    return hDisplay + mDisplay + sDisplay;
  };
  //onClick={this.focusTextInput}
   opentodaynotes() {
    //alert("Today's Note");
    document.getElementById("Today's Note").click();
    //document.getElementsByTagName("Today's Note").click();
  }
  opentodaynotes1() {
   // alert("Today's Note");
    document.getElementById("Past Visits").click();
    //document.getElementsByTagName("Today's Note").click();
  }
  reviewinRadio=(valfor)=>
  {
    if(valfor=='days')
    {
    // this.setState({selcdays:1,selweeks:0})
    }
    else if(valfor=='weeks')
    {
      //this.setState({selcdays:0,selweeks:1})
    }
  }
  render() {

    const styleMap = {
      'STRIKETHROUGH': {
        textDecoration: 'line-through',
      },
    };

    let z = 0;
    let start =
      this.state.time == 0 ? (
        <button onClick={this.startTimer}>
          {" "}
          <i class="fas fa-play"></i>{" "}
        </button>
      ) : null;
    let stop = this.state.isOn ? (
      <button onClick={this.stopTimer}>
        {" "}
        <i class="fas fa-pause"></i>
      </button>
    ) : null;
    let reset =
      this.state.time != 0 && !this.state.isOn ? (
        <button onClick={this.resetTimer}>
          {" "}
          <i class="fas fa-redo-alt"></i>{" "}
        </button>
      ) : null;
    let resume =
      this.state.time != 0 && !this.state.isOn ? (
        <button onClick={this.startTimer}>
          {" "}
          <i class="fas fa-play"></i>{" "}
        </button>
      ) : null;
    const { editorState } = this.state;
    return (
      <div className="up" style={{ color: '' }}>
        <TabList>
          <div label="Today's Note" className="tab-content" id="todaynote">
            <div
              id="notesdiv21"
              className="col-lg-12"
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <div
                id="recentvisitlistshort"
                className="col-lg-12"
                style={{ paddingLeft: 0, paddingRight: 0 }}
              >
                <div className="panel panel-default">
                  <div
                    className="panel-heading"
                    style={{
                      float: 'left',
                      width: '100%',
                      padding: '0px 0px 0px 0px',
                    }}
                  >
                    <div
                      className="col-lg-12 col-xl-12"
                      style={{ paddingLeft: 0, paddingRight: 0, top: '-29px' }}
                    >
                      <div
                        style={{
                          width: 'auto',
                          height: 30,
                          position: 'absolute',
                          right: 0,
                        }}
                      >
                        <a
                          href="#"
                          title="Email"
                          className="collapsed"
                          aria-expanded="false"
                        >
                          <i
                            className="fa fa-envelope  panelicon"
                            aria-hidden="true" />
                        </a>
                        <a
                          href="#"
                          title="Save"
                          className="collapsed"
                          aria-expanded="false"
                        >
                          <i
                            className="fa fa-save panelicon"
                            aria-hidden="true"  />
                        </a>
                        <a
                          href="#"
                          title="Print"
                          className="collapsed"
                          aria-expanded="false"
                        >
                          <i
                            className="fas fa-print panelicon"
                            aria-hidden="true"  />
                        </a>
                        <a
                          title="Export PDF"
                          className="collapsed"
                          aria-expanded="false"
                        >
                          <i
                            className="fa fa-file-pdf-o panelicon"
                            aria-hidden="true" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    id="recentvisitlist2"
                    className="col-lg-7 col-xl-7"
                    style={{
                      display: 'block',
                      paddingLeft: '0px',
                      height: '640px',
                      paddingRight: '0px',
                      overflow: 'none',
                    }}
                  >
                    <table
                      className="table table-bordered"
                      style={{
                        marginBottom: '0px',
                        backgroundColor: '#ffffff',
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              padding: '10px 0 10px 0',
                              width: '50%',
                              paddingBottom: 'unset',
                              backgroundColor: '#fff',
                            }}
                          >
                            <div style={{ float: 'left', width: '100%' }}>
                              <input
                                type="text"
                                style={{
                                  width: '60%',
                                  float: 'left',
                                  paddingLeft: '5px 5px',
                                  marginBottom: '3px',
                                  marginTop: '0px',
                                  border: '1px solid #666',
                                  marginLeft: '7px',
                                  fontSize: '14px',
                                }}
                                placeholder={this.state.date}
                                value={this.state.date}
                              />

                           
                              {/* <input
                                type="text"
                                style={{
                                  width: '30%',
                                  float: 'left',
                                  paddingLeft: '2px 5px',
                                  marginBottom: '3px',
                                  border: '1px solid #666',
                                  marginLeft: '7px',
                                  fontSize: '14px',
                                }}
                                placeholder="5:59:06 PM"
                                value={this.state.hours}
                              /> */}
                              {/*<select style={{width:'33%', float:'left', 'marginLeft':'5px', 'marginBottom':'0px',height: '28px'}}>
                                                                <option>Visit Type</option>
                                                                <option>Surgery</option>
                                                                <option>Home</option>
                                                                <option>Hospital</option>
                                                                <option>RACF</option>
                                                                <option>Telephone</option>
                                                                <option>Email</option>
                                                                <option>SMS</option>
                                                                <option>Locum Service</option>
                                                                <option>Out of office</option>
                                                                <option>Other</option>
                                                                <option>Hostel</option>
                                                                <option>Telehealth</option>
                                                                <option>Non visit</option>
                                                            </select>I*/}
                              <select
                                style={{
                                  width: '35%',
                                  float: 'left',
                                  marginLeft: '7px',
                                  marginBottom: '0px',
                                  padding: '5px 5px',
                                  fontSize: '14px',
                                  marginTop: '0px',
                                  border: '1px solid #666',
                                }}
                                onChange={e =>
                                  this.setState({
                                    selected_visit_type:
                                      e.target.value,
                                  })
                                }
                              >
                                {this.state.visit_type != undefined &&
                                  this.state.visit_type.length > 0 &&
                                  this.state.visit_type.map(visits => (
                                    <option
                                      key={visits.value}
                                      value={visits.value}
                                    >
                                      {visits.display}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div
                              style={{
                                float: 'left',
                                width: 'auto',
                                marginTop: '5px',
                              }}
                            >
                              <MyList
                                listType="patient_tasks"
                                showGrid="1"
                                doctor_id={this.state.doctor_id}
                                patientId={this.state.patientId}
                              />

                                 <input readOnly={true} value={this.state.visitlength} />
                            </div>
                          </td>
                          <td
                            style={{
                              width: '50%',
                              padding: '5px',
                              backgroundColor: '#fff',
                            }}
                          >
                            <textarea
                              style={{
                                width: '100%',
                                height: '100%',
                                border: 'none',
                              }}
                              id="accute"
                              placeholder="Reason for visit"
                              value={this.state.visit_reason}
                              onChange={e =>
                                this.setState({ visit_reason: e.target.value })
                              }
                              row="4"
                            ></textarea>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div id="Notesenew">
                      <div
                        id="Notesclose" 
                        style={{
                          float: 'left',
                          width: '100%',
                          display: 'block',
                          marginTop: '0px',
                          height: '205px',
                          minHeight: 'auto',
                          paddingLeft: '0px',
                          paddingRight: '0px',
                          background: '#f5f5f5',
                        }}
                      >
                        <div
                          style={{
                            width: '60%',
                            height: '50px',
                            position: 'absolute',
                            left: '0px',
                            top: '0px',
                          }}
                          align="left"
                        ></div>
                        <div className="visitlink"
                          style={{
                            width: '40%',
                            height: '50px',
                            position: 'absolute',
                            right: '0px',
                            top: '80px',
                            padding: '0px 0',
                          }}
                          align="right"
                        >
                          <a
                            href=""
                            title="Medical Certificate"
                            data-toggle="modal"
                            data-target="#mcertificate"
                            className="collapsed"
                            aria-expanded="false"
                            onClick={()=>this.medicalcenter()}
                          >
                            <i
                              className="fas fa-certificate"
                              aria-hidden="true"
                              style={{
                                fontSize: '20px',
                                margin: '5px',
                                padding: '1px 0px 0px 0px',
                                marginRight: '12px',
                              }}
                            ></i>
                          </a>
                          <a
                            href=""
                            title="New Medication"
                            data-toggle="modal"
                            data-target="#mcertificate"
                            className="collapsed"
                            aria-expanded="false"
                            onClick={()=>this.setState({medicationpop:true})}
                          >
                            <i
                              className="fas fa-medkit"
                              aria-hidden="true"
                              style={{
                                fontSize: '20px',
                                margin: '5px',
                                padding: '1px 0px 0px 0px',
                                marginRight: '12px',
                              }}
                            ></i>
                          </a>
                          <a
                            href=""
                            title="Travel Medicine"
                            className="collapsed"
                            aria-expanded="false"
                           
                          >
                            <i
                              className="fa fa-plane"
                              aria-hidden="true"
                              style={{
                                fontSize: '20px',
                                margin: '0px 0px 0px 4px',
                              }}
                            ></i>
                          </a>
                          <a href="" title="Pathology" data-toggle="modal" data-target="#Pathologypop" className="collapsed"
                            aria-expanded="false" onClick={()=>this.setState({pathologyPop:true})}>
                              <i className="fas fa-microscope" aria-hidden="true" style={{ fontSize: "22px", padding: "10px" }}  ></i>
                          </a>
                          
                          <a href="" title="Radiology" data-toggle="modal" data-target="#Radiology" className="collapsed"
                            aria-expanded="false"  onClick={()=>this.setState({radiologyPop:true})}>
                              <i className="fas fa-x-ray" aria-hidden="true" style={{ fontSize: "22px", padding: "10px" }}></i>
                          </a>
                          {/*<a href="#" title="Pathology" data-toggle="modal" data-target="#Pathologypop" className="collapsed" aria-expanded="false">

                                                        <i className="fas fa-microscope" aria-hidden="true" style={{'fontSize':'20px', 'margin':'4px', marginRight:'10px'}}></i>
                                                        </a>*/}
                        
                     
                      {(this.state.pathologyPop)?
                          <PathologyPop type="pathology" showNote={this.state.pathologyPop} changeKey={this.changeKey}/>:''}
                      {(this.state.radiologyPop)?  <PathologyPop type="radiology" showNote={this.state.radiologyPop} changeKey={this.changeKey} />      :''}                     
                      {(this.state.medicationpop)?  <MedicationPop  showForm={this.state.medicationpop} changeKey={this.changeKey} />      :''}                     
                       
                          {/* <a
                            href="#"
                            title="Radiology"
                            data-toggle="modal"
                            data-target="#Radiologypop"
                            className="collapsed"
                            aria-expanded="false"
                          >
                            <i
                              className="fas fa-x-ray"
                              aria-hidden="true"
                              style={{
                                fontSize: '20px',
                                margin: '0 0 0 4px',
                                marginRight: '12px',
                              }}
                            ></i>
                          </a> */}
                          <a
                            href="#"
                            title="Recording"
                            className="collapsed"
                            aria-expanded="false"
                          >
                            {/*<img src="images/recordingCircel.png" style={{'marginTop':'-5px', width:'25px', height:'25px'}} />
                             */}
                            <i
                              className="fas fa-microphone"
                              aria-hidden="true"
                              style={{
                                fontSize: '20px',
                                margin: '0 0 0 4px',
                                color: 'red',
                                marginRight: '12px',
                              }}
                            ></i>
                          </a>
                          <a className="collapsed" aria-expanded="false"  title="Tele-health">
                            <i
                              className="fas fa-phone"
                              aria-hidden="true"
                              style={{
                                marginTop: '-15px',
                                fontSize: '20px',
                                marginRight: '12px',
                              }}
                            ></i>
                            </a>
                          <a className="collapsed" aria-expanded="false">
                            <i
                              className="fa fa-window-maximize"
                              aria-hidden="true"
                              style={{
                                marginTop: '-15px',
                                fontSize: '20px',
                                marginRight: '12px',
                              }}
                            ></i>
                            </a>
                          <a
                            style={{
                              width: '25px',
                              height: '25px',
                              padding: ' 5px',
                              ' marginRight': '5px',
                              fontSize: '12px',
                              textAlign: 'center',
                              float: 'left',
                              borderRadius: '20px',
                              backgroundColor: '#333333',
                              color: '#ffffff',
                              margin: '5px 10px 0 0',
                              display: 'none',
                            }}
                            title="Multi Day"
                            className="collapsed"
                            aria-expanded="false"
                          >
                            M
                          </a>
                          <a
                            style={{
                              width: '25px',
                              height: '25px',
                              padding: '5px',
                              marginRight: '7px',
                              fontSize: '11px',
                              textAlign: 'center',
                              float: 'left',
                              borderRadius: '20px',
                              backgroundColor: '#333333',
                              color: '#ffffff',
                              margin: '5px 10px 0 0',
                              display: 'none',
                            }}
                            title="School/Uni"
                            className="collapsed"
                            aria-expanded="false"
                          >
                            S/U
                          </a>
                        </div>
                        <div
                          style={{
                            width: 'auto',
                            height: '30px',
                            position: 'absolute',
                            left: '0px',
                            bottom: '0px',
                            width: '100%',
                          }}
                        >
                          <div
                            className="col-sm-12"
                            style={{
                              backgroundColor: '#f4f4f4',
                              width: '100%',
                              padding: '18px 10px 3px 10px',
                            }}
                          >
                            <div
                              className="col-sm-1"
                              style={{
                                float: 'left',
                                paddingLeft: '0px',
                              }}
                            >
                              {/*<button id="session-timeout-dialog-logout" data-toggle="modal" data-target="#reviewin" type="button" className="btn btn-primary btn-xs">Review in</button>*/}
                              <Reviewin
                                handleOptionChange={this.handleOptionChange}
                                vals={this.state.reviewin}
                                handleSaveOptions={this.handleSaveOptions}
                              />
                            </div>
                            <div
                              className="col-sm-1"
                              style={{
                                float: 'left',
                                paddingLeft: '0px',
                                paddingRight: '0px',
                              }}
                            >
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                style={{
                                  width: '100%',
                                  marginRight: '5px',
                                  marginTop: '0px  ',
                                  marginLeft: '5px',
                                }}
                                value={this.state.reviewInText}
                              
                              />
                            </div>
                            <div
                              className="col-sm-1"
                              style={{
                                float: 'left',
                                textAlign: 'left',
                                paddingRight: '0px',
                                marginTop: '0px',
                                paddingLeft: '0px',
                                marginLeft: '10px',
                                marginRight: '10px',
                                fontSize: '16px',
                              }}
                            >
                              <label
                                style={{
                                  fontSize: '12px',
                                  fontWeight: 'normal',
                                }}
                              >
                                <input
                                  type="radio"
                                  value={this.state.selcdays}
                                  checked={this.state.selcdays==1?true:''}
                                  onClick={this.reviewinRadio('days')}
                                />
                                &nbsp; Days
                              </label>
                              
                              {/* <input type="radio" name="review" defaultChecked="" className="form-control form-control-sm" style={{float:'left', 'marginRight':'5px', width:'auto'}} />Days
                               */}
                            </div>
                            <div
                              className="col-sm-1"
                              style={{
                                float: 'left',
                                paddingRight: '0px',
                                paddingLeft: '0px',
                                marginTop: '0px',
                                marginLeft: '-25px',                                
                                fontSize: '16px',
                              }}
                            >
                              <label
                                style={{
                                  fontSize: '12px',
                                  fontWeight: 'normal',
                                }}
                              >
                                <input
                                  type="radio"
                                  value={this.state.selweeks}
                                  checked={this.state.selweeks==1?true:''}
                                  onClick={this.reviewinRadio('weeks')}
                                />
                                &nbsp; Weeks
                              </label>
                              {/* <input type="radio" name="review" className="form-control form-control-sm" style={{float:'left', 'marginRight':'5px', width:'auto'}} />Weeks
                               */}
                            </div>
                            <Diagonsis
                              openDiagonsis={this.state.openDiagonsis}
                              closeDiagonsis={e => this.closeDiagonsis()}
                              popupName="Diagnosis"
                              diagonsisSave={this.diagonsisSave}
                              showfor='Diagnosis'
                    
                            
                            />
                            <Diagonsis
                              openDiagonsis={this.state.openDiagonsistv}
                              closeDiagonsis={e => this.closediagnosistodaynotes()}
                              popupName="Today's Reason"
                              diagonsisSave={this.diagonsisSave}
                              showfor="Today's Reason"
                             
                            />

<button
                 onClick={e => this.setState({openDiagonsis: true})}
            type="button"
            className="btn btn-primary btn-xs">
             Diagnosis
          </button>
          <button
                 onClick={e => this.setState({openDiagonsistv:true})}
            type="button"
            className="btn btn-primary btn-xs">
             Today's Reason
          </button>
                            <Wordfill 
                             handleOptionChange={this.handleOptionChange}
                             vals={this.state.wordfill}
                             handleSaveOptions={this.handleSaveOptions}
                            />

                            {/* <button id="session-timeout-dialog-logout" data-toggle="modal" data-target="#autofill" type="button" className="btn btn-primary btn-xs">Word Fill</button>
                             */}
                            <button
                              type="button"
                              className="btn btn-primary btn-xs"
                              onClick={this.opentodaynotes1}
                            >
                              Last Visit
                            </button>
                            {/*<button
                              type="button"
                              className="btn btn-default topbutton"
                              onClick={this.submiteVisitNote.bind(this)}
                            >
                             Save
                            </button>*/}
                          </div>
                        </div>
                        {/*<div style={{width:'100%', height:'20px', float:'left',  'marginTop':'0px'}}>
                                                      <textarea name="editor4" style={{'height': '400px', float: 'left', 'paddingLeft': '200px', width: '100%', visibility: 'hidden', display: 'none'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;strong&gt;Actions:&lt;/strong&gt;&lt;br&gt;
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Acute C1 Esterase deficiency.
                                                        </textarea>
                                                        <div id="cke_editor4" className="cke_2 cke cke_reset cke_chrome cke_editor_editor4 cke_ltr cke_browser_webkit" dir="ltr" lang="en" role="application" aria-labelledby="cke_editor4_arialbl">

                                                            <div className="cke_inner cke_reset" role="presentation">
                                                               <span id="cke_2_top" className="cke_top cke_reset_all" role="presentation" style={{height: 'auto', 'user-select': 'none'}}>

                                                                    <span id="cke_2_toolbox" className="cke_toolbox" role="group" aria-labelledby="cke_40" onmousedown="return false;">
                                                                        <span id="cke_41" className="cke_toolbar cke_toolbar_last" role="toolbar">
                                                                                <span className="cke_toolbar_start"></span>
                                                                                <span className="cke_toolgroup" role="presentation">
                                                                                    <a id="cke_42" className="cke_button cke_button__cut cke_button_disabled  collapsed" href="javascript:void('Cut')" title="Cut (Ctrl+X)" tabindex="-1" hidefocus="true" role="button" aria-labelledby="cke_42_label" aria-describedby="cke_42_description"                  aria-haspopup="false" aria-disabled="true" onkeydown="return CKEDITOR.tools.callFunction(37,event);" onfocus="return CKEDITOR.tools.callFunction(38,event);" onClick="CKEDITOR.tools.callFunction(39,this);return false;" aria-expanded="false">

                                                                                    </a>
                                                                                </span>
                                                                                <span className="cke_toolbar_end"></span>
                                                                            </span>
                                                                        </span>
                                                                    </span>

                                                                        <span id="cke_2_bottom" className="cke_bottom cke_reset_all" role="presentation" style={{'user-select': 'none'}}>
                                                                            <span id="cke_2_resizer" className="cke_resizer cke_resizer_vertical cke_resizer_ltr" title="Resize" onmousedown="CKEDITOR.tools.callFunction(35, event)">◢</span>
                                                                            <span id="cke_2_path_label" className="cke_voice_label">Elements path</span>
                                                                                <span id="cke_2_path" className="cke_path" role="group" aria-labelledby="cke_2_path_label">
                                                                                <span className="cke_path_empty">&nbsp;</span>
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                        </div>*/}
                        <div className="col-md-12" style={{ top: '39px' }}>
                          <div
                            style={{
                              position: 'absolute',
                              width: '45px',
                              left: '0px',
                              height: '485px',
                              backgroundColor: '#e5e5e5',
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: '#e5e5e5',
                                float: 'left',
                              }}
                            >
                              {/*<a href="#" title="General" className="collapsed" aria-expanded="false"><i className="fa fa-heartbeat" style={{'fontSize':'20px' ,'padding':'10px'}} aria-hidden="true"></i></a>*/}
                              <General
                                handleOptionChange={this.handleOptionChange}
                                vals_examination={
                                  this.state.general_examination
                                }
                                vals={this.state.general}
                                handleSaveOptions={this.handleSaveOptions}
                              />
                              {/*  <a href="#" title="Cardiovascular" className="collapsed" aria-expanded="false"><i className="fa fa-h-square" style={{'fontSize':'20px', padding:'10px'}} aria-hidden="true"></i></a>*/}
                              <Cardiovascular
                                handleOptionChange={this.handleOptionChange}
                                vals={this.state.cardiovascular}
                                vals_examination={
                                  this.state.cardiovascular_examination
                                }
                                handleSaveOptions={this.handleSaveOptions}
                              />

                              {/*<a href="#" title="Respiratory" className="collapsed" aria-expanded="false"><i className="fa fa-ambulance" style={{'fontSize':'20px', padding:'10px'}} aria-hidden="true"></i></a>*/}
                              <Respiratory
                                handleOptionChange={this.handleOptionChange}
                                vals={this.state.respiratory}
                                vals_examination={
                                  this.state.respiratory_examination
                                }
                                handleSaveOptions={this.handleSaveOptions}
                              />
                              {/*<a href="#" title="Gastro-intestinal" className="collapsed" aria-expanded="false"><i className="fa fa-hospital-o" style={{'fontSize':'20px', padding:'10px'}} aria-hidden="true"></i></a>*/}
                              <Gastrointestinal
                                handleOptionChange={this.handleOptionChange}
                                vals={this.state.gastrointestinal}
                                vals_examination={
                                  this.state.gastrointestinal_examination
                                }
                                handleSaveOptions={this.handleSaveOptions}
                              />
                              {/* <a href="#" title="CNS" className="collapsed" aria-expanded="false"><i className="fa fa-heart" style={{'fontSize':'20px', padding:'10px'}} aria-hidden="true"></i></a>*/}
                              <Cns
                                handleOptionChange={this.handleOptionChange}
                                vals={this.state.cns}
                                vals_examination={this.state.cns_examination}
                                handleSaveOptions={this.handleSaveOptions}
                              />
                              {/* <a href="#" title="Genito-urinary" className="collapsed" aria-expanded="false"><i className="fa fa-medkit" style={{'fontSize':'20px', padding:'10px'}} aria-hidden="true"></i></a>*/}
                              <Genitourinary
                                handleOptionChange={this.handleOptionChange}
                                vals={this.state.genitourinary}
                                vals_examination={
                                  this.state.genitourinary_examination
                                }
                                handleSaveOptions={this.handleSaveOptions}
                              />

                              {/*<a href="#" title="ENT" className="collapsed" aria-expanded="false"><i className="fa fa-plus-square" style={{'fontSize':'20px' ,padding:'10px'}} aria-hidden="true"></i></a>*/}
                              <Ent
                                handleOptionChange={this.handleOptionChange}
                                vals={this.state.ent}
                                vals_examination={this.state.ent_examination}
                                handleSaveOptions={this.handleSaveOptions}
                              />
                              {/*<a href="#" title="Eye" className="collapsed" aria-expanded="false"><i className="fa fa-heart" style={{'fontSize':'20px', padding:'10px'}} aria-hidden="true"></i></a>*/}
                              <Eye
                                handleOptionChange={this.handleOptionChange}
                                vals={this.state.eye}
                                vals_examination={this.state.eye_examination}
                                handleSaveOptions={this.handleSaveOptions}
                              />

                              {/* <a href="#" title="Skin" className="collapsed" aria-expanded="false"><i className="fa fa-stethoscope" style={{'fontSize':'20px', padding:'10px'}} aria-hidden="true"></i></a>*/}
                              <Skin
                                handleOptionChange={this.handleOptionChange}
                                vals={this.state.skin}
                                vals_examination={this.state.skin_examination}
                                handleSaveOptions={this.handleSaveOptions}
                              />
                              {/* <a href="#" title="Musculo-skeletal" className="collapsed" aria-expanded="false"><i className="fa fa-user-md" style={{'fontSize':'20px', padding:'10px'}} aria-hidden="true"></i></a>*/}
                              <Musculoskeletal
                                handleOptionChange={this.handleOptionChange}
                                vals={this.state.musculoskeletal}
                                vals_examination={
                                  this.state.musculoskeletal_examination
                                }
                                handleSaveOptions={this.handleSaveOptions}
                              />

                              <Gynae
                                handleOptionChange={this.handleOptionChange}
                                vals={this.state.gynae}
                                vals_examination={
                                  this.state.gynae_examination
                                }
                                handleSaveOptions={this.handleSaveOptions}
                              />
                            </div>
                          </div>
                          <div
                            className="col-md-10 col-lg-10 col-sm-10"
                            style={{
                              marginLeft: '30px',
                              width: 'inherit',
                              background: 'white',
                              height: '485px',
                              overflowY: 'auto',
                            }}
                          >
                            <Editor
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              name={this.state.visit_note}
                              editorState={editorState}
                              onEditorStateChange={this.onEditorStateChange}
                              mention={{
                                separator: '',
                                trigger: ' ',
                                suggestions: this.state.suggestions,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Summary />
                </div>
              </div>
            </div>
          </div>

          <div label="Past Visits" className="tab-content">
            <div id="pastvistlistnew" className="tabcontent col-md-12">
              {' '}
              <PastVisit opentodaynotes={this.opentodaynotes}/>{' '}
            </div>
            {/* <div
              id="pastvistlistnew"
              className="tabcontent col-md-6"
              style={{
                marginTop: "0px",
                paddingLeft: "0px",
                paddingRight: "0px",
                display: "block",
                background: "white"
              }}
            >
              <div
                style={{ paddingLeft: "0px", width: "100%", overflow: "auto" }}
              >
                 
              </div>
            </div>
            <div
              id="singlevist2new"
              className="col-md-6"
              style={{
                float: "right",
                overflowY: "auto",
                border: "0px",
                padding: "0px",
                display: "block",
                background: "white"
              }}
            >
              <div className="panel panel-default">
                <div
                  className="panel-heading"
                  style={{
                    float: "right",
                    width: "100%",
                    padding: "10px",
                    marginBottom: "5px",
                    height: "auto"
                  }}
                >
                  <div
                    className="col-lg-12 col-xl-12"
                    style={{ paddingLeft: "0px", paddingRight: "0px" }}
                  >
                    <h4
                      className="panel-title"
                      style={{
                        width: "100%",
                        display: "inline",
                        marginRight: "10px"
                      }}
                    >
                      <a
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseOne"
                        style={{ float: "left", fontSize: "18px" }}
                        aria-expanded="true"
                        className=""
                      >
                        Recent Visit
                      </a>
                    </h4>
                    <div
                      style={{
                        width: "auto",
                        height: "30px",
                        position: "absolute",
                        right: "15px",
                        top: "2px"
                      }}
                    >
                      <span className="makescreen">
                        <a
                          href="#"
                          title="Make This Default Screen"
                          className=""
                          aria-expanded="true"
                        >
                          <i
                            className="fa fa-star"
                            aria-hidden="true"
                            style={{ fontSize: "18px", marginRight: "10px" }}
                          ></i>
                        </a>
                      </span>
                      <a
                        href="#"
                        title="Email"
                        className=""
                        aria-expanded="true"
                      >
                        <i
                          className="fas fa-envelope"
                          aria-hidden="true"
                          style={{ fontSize: "18px", marginRight: "10px" }}
                        ></i>
                      </a>
                      <a
                        href="#"
                        title="Print"
                        className=""
                        aria-expanded="true"
                      >
                        <i
                          className="fas fa-print"
                          aria-hidden="true"
                          style={{ fontSize: "18px", marginRight: "10px" }}
                        ></i>
                      </a>
                      <a title="Export PDF" className="" aria-expanded="true">
                        <i
                          className="fa fa-file-pdf-o"
                          aria-hidden="true"
                          style={{
                            marginTop: "-15px",
                            fontSize: "18px",
                            marginRight: "10px"
                          }}
                        ></i>
                      </a>
                      <a
                        title="Back to Summary"
                        onclick="summaryVisitclose();"
                        className=""
                        aria-expanded="true"
                      >
                        <i
                          className="fa fa-clipboard"
                          aria-hidden="true"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  float: "left",
                  padding: "0px 10px 10px 10px",
                  width: "100%",
                  height: "450px",
                  overflowY: "auto"
                }}
              >
                <a
                  title="Previous"
                  onclick="detailsvisit2();"
                  style={{ paddingRight: "5px", float: "right" }}
                  className=""
                  aria-expanded="true"
                >
                  <i
                    className="fa fa-arrow-circle-up"
                    aria-hidden="true"
                    style={{
                      float: "right",
                      marginTop: "10px",
                      fontSize: "16px"
                    }}
                  ></i>
                </a>
                <h3>
                  21/07/2018 -{" "}
                  <span style={{ fontSize: "14px" }}>Reason for visit</span>
                  <button
                    id="session-timeout-dialog-logout"
                    type="button"
                    className="btn btn-default"
                    style={{ margin: "0px auto 0px 10px", padding: "5px 10px" }}
                  >
                    Amend
                  </button>
                </h3>
                <p>{this.state.selected_past_visit}</p>
              </div>
                  </div> */}
                  
          </div>
         
        </TabList>
        {this.state.medicalmodal ? 
          <MedicalCertificate showModal={this.state.medicalmodal} changeKey={this.changeKey} isSingle="No"/> :''}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getVisitType: () => dispatch(api_getVisitTypes()),
    apiAddVisitnote: data => dispatch(api_addVisitNote(data)),
    set_visitnotes_details: data => dispatch(api_set_visitnotes_details(data)),
    diagonsisSave: data => dispatch(diagonsisSave(data)),
    getProviders: patientid => dispatch(list_providers(patientid)),
    getPatientsVisitReason: patientid =>
    dispatch(patients_visit_reason(patientid)),
    searchVisitNotes: searchParam => dispatch(search_visit_notes(searchParam)),
    listwordfill: searchParam => dispatch(api_listwordfill(searchParam)),
    searchNotes: searchParam => dispatch(api_get_notes(searchParam)),    
  };
};
const mapStateToProps = state => {
  let redux_patientId =
    state.getDrAppointments.getPatientDetails != undefined
      ? state.getDrAppointments.getPatientDetails[0].patient_id
      : '';
  let logged_info =
    state.getDrAppointments.logged_info != undefined
      ? state.getDrAppointments.logged_info
      : '';
  let visitnotes_details =
    state.getDrAppointments.visitnotes_details != undefined
      ? state.getDrAppointments.visitnotes_details
      : '';
      let selected_appointment =
      state.getDrAppointments.selected_appointment != undefined
        ? state.getDrAppointments.selected_appointment
        : [];
  return {
    redux_patientId,
    logged_info,
    visitnotes_details, selected_appointment
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VisitsNotes);
